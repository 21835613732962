import React, { Component, Fragment } from "react";

import ListOrderDataTableComponent from "../views/list-order-datatable";
import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";
import * as toast from "components/common/toast";
import Emitter from "common/event-emitter";

class ListOrderDataTableContainer extends Component {
  state = {
    data: [],
    pages: null,
    pageSize: 0,
    selectedId: [],
    open: false,
    loading: false,
    filtered: [],
    sorted: [],
  };

  constructor() {
    super();
  }

  fetchSalesOrder(state, instance) {
    this.setState({
      loading: true,
    });
    this.getDaTableRecords(state);
  }

  getDaTableRecords(state) {
    this.props.requestRetrieveAllSalesOrders(
      {
        filtered: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
        limit: state.pageSize,
        page: state.page == 0 ? 1 : state.page + 1,
      },
      (response) => {
        const data = response.data;
        this.setDataTable(data.data, data.total, state.pageSize);
      },
      (error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    );
  }

  setDataTable(data, total, pageSize) {
    this.setState({
      data: data,
      pages: Math.ceil(total / pageSize),
      pageSize: pageSize,
      loading: false,
      total: total,
    });
  }

  render() {
    return (
      <ListOrderDataTableComponent
        {...this.state}
        {...this.props}
        fetchSalesOrder={this.fetchSalesOrder.bind(this)}
      />
    );
  }
}

export default connect(null, {
  requestRetrieveAllSalesOrders: Action.requestRetrieveAllSalesOrders,
})(ListOrderDataTableContainer);
