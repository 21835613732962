const LOAD_SHOP_LIST = "home/shoplist";
const LOAD_TRANSACTIONS = "home/load-transactions";
const SHOP_SELECTED = "home/shop-selected";
const CHANGE_LIVE_STOCK = "home/change-live-stock";
const DESTROY = "home/destroy";

export {
  DESTROY,
  LOAD_SHOP_LIST,
  SHOP_SELECTED,
  LOAD_TRANSACTIONS,
  CHANGE_LIVE_STOCK,
};
