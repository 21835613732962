const FETCH_ALL = 'product/brand/load-all';
const FETCH = 'product/brand/load';
const FORM_STATUS = 'product/brand/form-status';
const LOADING = 'product/brand/progress-loading';
const RESPONSE = 'product/brand/response';
const UPDATE_STATUS = "product/brand/update/status/boolean";
const CHOOSEN = "product/brand/choosen";

export {
	FETCH_ALL,
	FETCH,
	FORM_STATUS,
	LOADING,
	RESPONSE,
	UPDATE_STATUS,
	CHOOSEN
}
