import React, { Component } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class Cookie {
  static set = (name, value, path) => {
    let _path = path == undefined ?? "/";
    localStorage.setItem(name, JSON.stringify(value), _path);
  };

  static get = (name) => {
    return JSON.parse(localStorage.getItem(name));
  };
}

export default Cookie;
