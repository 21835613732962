import React, { useState, Fragment } from "react";

import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import * as Action from "../../redux/actions/payload";

import BrandItemsListDatatableComponent from "../views/brand-items-list-datatable";

const BrandItemsListDatatableContainer = (props) => {
  const [brand_list_loading, setBrandListLoading] = useState(false);
  const [brand_items_list_loading, setBrandItemsListLoading] = useState(false);

  const [brand_list_datatable, setBrandListDataTable] = useState({
    data: [],
    pages: 0,
    pageSize: 0,
    total: 0,
    sorted: [],
    filtered: [],
  });
  const [brand_items_list_datatable, setBrandItemsListDataTable] = useState({
    data: [],
    pages: 0,
    pageSize: 0,
    total: 0,
    sorted: [],
    filtered: [],
  });

  const requestBrandList = (state, instance) => {
    setBrandListLoading(true);
    props
      .requestBrandList({
        filtered: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
        limit: state.pageSize,
        page: state.page == 0 ? 1 : state.page + 1,
      })
      .then((response) => {
        setBrandListLoading(false);
        setBrandListDataTable({
          data: response.data.data,
          pages: Math.ceil(response.data.total) / state.pageSize,
          pageSize: state.pageSize,
          total: response.data.total,
        });
      })
      .catch((error) => {
        setBrandListLoading(false);
      });
  };

  const requestBrandItemsList = (state, instance) => {
    setBrandItemsListLoading(true);
    props
      .requestBrandItemsList(state.brandId, {
        filtered: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
        limit: state.pageSize,
        page: state.page == 0 ? 1 : state.page + 1,
      })
      .then((response) => {
        setBrandItemsListLoading(false);
        setBrandItemsListDataTable({
          [state.brandId]: {
            data: response.data.data,
            pages: Math.ceil(response.data.total) / state.pageSize,
            pageSize: state.pageSize,
            total: response.data.total,
          },
        });
      })
      .catch((error) => {
        setBrandItemsListLoading(false);
      });
  };

  return (
    <BrandItemsListDatatableComponent
      {...props}
      brand_list_loading={brand_list_loading}
      brand_list_datatable={brand_list_datatable}
      brand_items_list_loading={brand_items_list_loading}
      brand_items_list_datatable={brand_items_list_datatable}
      requestBrandList={requestBrandList.bind(this)}
      requestBrandItemsList={requestBrandItemsList.bind(this)}
    />
  );
};

export default connect(
  (state) => {
    return {
      inventory_stocking_reducer: state.inventoryStockingReducer,
    };
  },
  {
    requestBrandList: Http.requestBrandList,
    requestBrandItemsList: Http.requestBrandItemsList,
    addBrandToSelectedTemplate: Action.addBrandToSelectedTemplate,
    addBrandItemToSelectedTemplate: Action.addBrandItemToSelectedTemplate,
    addBrandItemVariantToSelectedTemplate:
      Action.addBrandItemVariantToSelectedTemplate,
  }
)(BrandItemsListDatatableContainer);
