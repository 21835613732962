import { empty } from "common";

export const valdateAddBasket = (next, store, action) => {
  const product = action.payload.product;
  const variant = action.payload.variant;
  console.log(store.getState());
  console.log(variant);
  validateBasket(store, product, variant);

  next(action);
};

export const ValidateIncreaseBasket = (next, store, action) => {
  const baskets = store.getState().inStoreOrderReducer.basket;
  const basket = baskets.find((n) => n.id == action.payload.id);
  const product = basket.product;
  const variant = basket.variant;

  validateBasket(store, product, variant, 1);

  next(action);
};

function validateBasket(store, product, variant) {
  if (empty(variant)) {
    validateProductBasket(store, product);
    return;
  }

  validateTotalVariantBasket(store, product, variant);
}

function validateTotalVariantBasket(store, product, variant) {
  if (calculateTotalVariantInventory(variant) < 1) {
    throw "Item is out of stock.";
  }
  if (
    calculateTotalVariantInABasket(store, product, variant) >
    calculateTotalVariantInventory(variant)
  ) {
    throw "Item total quantity in a basket exceed the available stock.";
  }
}

function validateProductBasket(store, product) {
  if (calculateTotalProductInventory(product) < 1) {
    throw "Item is out of stock.";
  }
  if (
    calculateTotalProducttInABasket(store, product) >
    calculateTotalProductInventory(product)
  ) {
    throw "Item total quantity in a basket exceed the available stock.";
  }
}

function calculateTotalVariantInABasket(store, product, variant) {
  console.log(store.getState().inStoreOrderReducer);
  const find = store
    .getState()
    .inStoreOrderReducer.basket.find(
      (item) =>
        product.id == item.product.id &&
        variant.id == (item?.variant?.id ?? null)
    );

  return find?.quantity ?? 0;
}

function calculateTotalVariantInventory(variant) {
  return (variant?.inventory ?? []).reduce((total, inventory) => {
    total += inventory.stock;
    return total;
  }, 0);
}

function calculateTotalProducttInABasket(store, product) {
  const find = store
    .getState()
    .inStoreOrderReducer.basket.find((item) => product.id == item.product.id);

  return find?.quantity ?? 0;
}

function calculateTotalProductInventory(product) {
  return (product?.inventory ?? []).reduce((total, inventory) => {
    total += inventory.stock;
    return total;
  }, 0);
}
