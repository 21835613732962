import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import * as Toast from "components/common/toast";

import AffectedOrderListComponent from "../views/affected-order-list";

const AffectedOrderListContainer = (props) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAllSelected, setAllSelected] = useState(false);
  const [exempted_orders, setOverrideOrdersExemption] = useState([]);

  const setOrderExemption = (exempted, order, all) => {
    setOrders(
      orders.map((base) => {
        if (base.order_no == order?.order_no || all == true) {
          base.is_override_removal_excluded = exempted;
        }
        return base;
      })
    );
  };

  const select = (order) => {
    const exempted = !order.is_override_removal_excluded;
    requestUpdateOrderToBeExemptedFromOverrideRemoval(order, exempted);
    setOrderExemption(exempted, order);
  };

  const selectAll = (exempted) => {
    requestUpdateOrderToBeExemptedFromOverrideRemovalBatches(orders, exempted);
    setOrderExemption(exempted, null, true);
  };

  const remove = (order) => {
    const exempted = false;
    requestUpdateOrderToBeExemptedFromOverrideRemoval(order, exempted);
    setOrderExemption(exempted, order);
  };

  const removeAll = () => {
    const exempted_orders = orders.filter(
      (o) => o.is_override_removal_excluded == true
    );
    const exempted = false;
    requestUpdateOrderToBeExemptedFromOverrideRemovalBatches(
      exempted_orders,
      exempted
    );
    setOrderExemption(exempted, null, true);
  };

  const requestUpdateOrderToBeExemptedFromOverrideRemoval = (
    order,
    exempted
  ) => {
    props
      .requestUpdateOrderToBeExemptedFromOverrideRemoval(order.order_no, {
        shop: order.shop,
        exempted: exempted,
      })
      .then((response) => {
        const message = response.data.message;
        if (response.data.success) {
          Toast.success(message);
        } else {
          setOrderExemption(!exempted, order);
          Toast.error(message);
        }
      })
      .catch((error) => {
        setOrderExemption(!exempted, order);
        Toast.error(error.toString());
      });
  };

  const requestUpdateOrderToBeExemptedFromOverrideRemovalBatches = (
    orders,
    exempted
  ) => {
    if (orders.length <= 0) return;

    props
      .requestUpdateOrderToBeExemptedFromOverrideRemovalBatches({
        orders: orders.map((order) => {
          return { shop: order.shop, order_no: order.order_no };
        }),
        exempted: exempted,
      })
      .then((response) => {
        const message = response.data.message;
        if (response.data.success) {
          Toast.success(message);
        } else {
          setOrderExemption(!exempted, null, true);
          Toast.error(message);
        }
      })
      .catch((error) => {
        setOrderExemption(!exempted, null, true);
        Toast.error(error.toString());
      });
  };

  // Check for orders update
  useEffect(() => {
    setOverrideOrdersExemption(
      orders.filter((o) => o.is_override_removal_excluded == true)
    );
    setAllSelected(
      orders.filter((o) => o.is_override_removal_excluded == true).length ==
        orders.length
    );
  }, [orders]);

  useEffect(() => {
    props
      .requestListOfOverrideAffectedOrders(props.id)
      .then((response) => {
        setOrders(response.data.data);
      })
      .catch((error) => {
        Toast.error(error.toString());
      });
  }, []);

  return (
    <>
      <AffectedOrderListComponent
        orders={orders}
        loading={loading}
        isAllSelected={isAllSelected}
        exempted_orders={exempted_orders}
        select={select.bind(this)}
        selectAll={selectAll.bind(this)}
        remove={remove.bind(this)}
        removeAll={removeAll.bind(this)}
      />
    </>
  );
};

export default connect(null, {
  ...Http,
})(AffectedOrderListContainer);
