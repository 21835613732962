const CREATE_CATEGORY_MAIN_BOX = "product/product/category/box/main/create";
const CREATE_CATEGORY_BOX = "product/product/category/box/create";
const CONFIRMED_CATEGORY = "product/product/category/confirmed";
const EDIT_CATEGORY = "product/product/category/edit";
const CLEAR_CATEGORY = "product/product/category/clear";
const UPDATE_FULL_CATEGORY = "product/product/category/update/full";

export {
  CREATE_CATEGORY_MAIN_BOX,
  CREATE_CATEGORY_BOX,
  CONFIRMED_CATEGORY,
  EDIT_CATEGORY,
  CLEAR_CATEGORY,
  UPDATE_FULL_CATEGORY,
};
