import React from "react";
import PrivateRoute from "private-route";
import OrderContainer from "../order/container";
import CustomerContainer from "../customer/container";
import ShippingContainer from "../shipping/container";
import PaymentContainer from "../payment/container";

import HeaderComponent from "./header";

class StepsComponent extends React.Component {
  render() {
    return (
      <>
        <HeaderComponent activePage={this.props.match.params.page} />

        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/orders/in-store/create-order/list`}
          component={OrderContainer}
        />

        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/orders/in-store/create-order/customer`}
          component={CustomerContainer}
        />

        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/orders/in-store/create-order/shipping`}
          component={ShippingContainer}
        />
        {
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/orders/in-store/create-order/payment`}
            component={PaymentContainer}
          />
          /*
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/orders/in-store/create-order/complete`}
          component={CreateInStoreOrderContainerV2}
        /> */
        }
      </>
    );
  }
}

export default StepsComponent;
