import GameComponent from "../gameboard/game-component";

let x = 0;
let y = 0;

export default class IsPointer {
  setCoordinates = (x_, y_) => {
    x = x_;
    y = y_;
  };

  getX = () => {
    return x;
  };

  getY = () => {
    return y;
  };
  pointed = (component) => {
    if (component instanceof GameComponent === false) {
      return;
    }

    var objectStartleftStart = component.getX();
    var objectStartLeftEnd = component.getX() + component.getWidth();

    var objectStartTopStart = component.getY();
    var objectStartTopEnd = component.getY() + component.getHeight();

    // Mouse start to drop at certain object
    if (x > objectStartleftStart && x < objectStartLeftEnd) {
      if (y > objectStartTopStart && y < objectStartTopEnd) {
        return true;
      }
    }

    return false;
  };
}
