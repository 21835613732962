import React from "react";
import { Map } from "immutable";
import { empty, isNumber, isNumberPositive } from "common";
import uuid from "react-uuid";

const _VaShootError = (element, message) => {
  const errorDiv = element.parentElement.querySelector("div.error-response");
  element.classList.add("va-invalid-input");
  element.classList.remove("va-valid-input");
  errorDiv.classList.add("va-invalid-input");
  errorDiv.classList.remove("va-valid-input");

  if (message !== undefined) {
    errorDiv.innerHTML = message;
  }
};

const _VaShootSuccess = (element) => {
  const errorDiv = element.parentElement.querySelector("div.error-response");
  errorDiv.classList.remove("va-invalid-input");
  errorDiv.classList.add("va-valid-input");
  element.classList.remove("va-invalid-input");
  element.classList.add("va-valid-input");
};

const _VaText = (element) => {
  if (element.required === true && empty(element.value)) {
    _VaShootError(element);
    return false;
  }
  _VaShootSuccess(element);
  return true;
};

const _VaNumber = (element) => {
  if (!isNumber(element.value)) {
    _VaShootError(element, "This field must be a number");
    return false;
  }
  if (element.required === true && empty(element.value)) {
    _VaShootError(element);
    return false;
  }

  // if (!isNumberPositive(element.value)) {
  //   _VaShootError(element, "This field field must be a number");
  //   return false;
  // }

  if (!empty(element.attributes.getNamedItem("data-min"))) {
    const minValue = element.getAttribute("data-min");
    if (element.value < minValue) {
      _VaShootError(
        element,
        "This field must be equal or greater than to " + minValue
      );
      return false;
    }
  }

  _VaShootSuccess(element);
  return true;
};

const _VaValidator = (element) => {
  let result;

  switch (element.type.toLowerCase()) {
    case "text":
      result = _VaText(element);
      break;

    case "number":
      result = _VaNumber(element);
      break;
    default:
  }

  return result;
};

const VaResponse = (props) => {
  return React.createElement("div", {
    className: "error-response",
    name: "error-response",
    key: "12121",
    dangerouslySetInnerHTML: {
      __html: props?.message ?? "This field is required",
    },
  });
};

const VaCreateField = (type, props) => {
  const _props = Map(props).toJS();

  const handleChange = (event) => {
    _VaValidator(event.target);
  };

  const _ref = (ref) => {
    if (ref !== null) {
      ref.addEventListener("change", handleChange);
    }
  };

  _props.className = (props?.className ?? []).concat("  va-field");

  return React.createElement("input", {
    ..._props,
    type: type,
    ref: _ref,
    key: _props.name,
  });
};

const VaCreateTextArea = (props) => {
  const _props = Map(props).toJS();

  const handleChange = (event) => {
    _VaValidator(event.target);
  };

  const _ref = (ref) => {
    if (ref !== null) {
      ref.addEventListener("change", handleChange);
    }
  };

  _props.className = (props?.className ?? []).concat("  va-field");

  return React.createElement("textarea", {
    ..._props,
    ref: _ref,
    key: _props.name,
  });
};

const VaCreateElement = (type, props) => {
  return React.createElement("div", { className: "va-container" }, [
    VaCreateField(type, props),
    VaResponse(props),
  ]);
};

const VaCreateElementTextArea = (props) => {
  return React.createElement("div", { className: "va-container" }, [
    VaCreateTextArea(props),
    VaResponse(props),
  ]);
};

export const VaField = (props) => {
  return VaCreateElement("text", props);
};

export const VaNumber = (props) => {
  return VaCreateElement("number", props);
};

export const VaTextArea = (props) => {
  return VaCreateElementTextArea(props);
};

export const VaForm = (props) => {
  const _props = Map(props).toJS();
  _props.className = "va-form " + (_props?.className ?? "");

  return React.createElement("form", { ..._props }, props.children);
};

export const VaBasicFormValid = () => {
  return VaFormValid("basic-info");
};

export const VaShippingFormValid = () => {
  return VaFormValid("shipping-info");
};

export const VaSellingFormValid = () => {
  return VaFormValid("selling-info");
};

const VaFormValid = (className) => {
  const form = document.getElementsByClassName(className);

  let isValid = 0;
  for (var f in form) {
    if (typeof form[f] === "object") {
      const field = form[f].getElementsByClassName("va-field");
      for (var fi in field) {
        if (typeof field[fi] === "object") {
          if (_VaValidator(field[fi]) === false) {
            isValid++;
          }
        }
      }
    }
  }

  return isValid <= 0;
};
