import * as type from "../../constants/product";
import Http from "api/http";
import Emitter from "common/event-emitter";
import * as common from "../common";
import * as payload from "./payload";
import {
  setLoading,
  updateForm,
  updateSellingForm,
  updateDetailsForm,
  updateShippingForm,
  updateSEOForm,
} from "../payload";

import { updateFullCategory } from "../category/payload";
import { updateFullVariation } from "../variation/payload";
import { updateFullWholesale } from "../wholesale/payload";
import { updateFullMedia } from "../media/payload";

function refreshProductDataTable() {
  Emitter.emit("PRODUCT_REFRESH_LIST");
}

export const getProductListShowCLearedDuplicateCase = (
  filter,
  success_callback,
  error_callback
) => (dispatch) => {
  return Http.POST("product/product/show-cleared-duplicate-case", {
    payload: {
      criteria: filter.criteria,
      per_page: filter.per_page,
      page: filter.page,
    },
  })
    .then(success_callback)
    .catch(error_callback);
};

export const getProductListWithDuplicateCase = (
  filter,
  success_callback,
  error_callback
) => (dispatch) => {
  return Http.POST("product/product/with-duplicate-case", {
    payload: {
      criteria: filter.criteria,
      per_page: filter.per_page,
      page: filter.page,
    },
  })
    .then(success_callback)
    .catch(error_callback);
};

export const getProductList = (filter, success_callback, error_callback) => (
  dispatch
) => {
  dispatch(setLoading(true));
  console.log(filter);
  return Http.POST("product/product/listing", {
    payload: {
      criteria: filter.criteria,
      per_page: filter.per_page,
      page: filter.page,
    },
  })
    .then(success_callback)
    .catch(error_callback);
};

export const deleteProduct = (id: productId) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.POST("product/product/delete/" + id)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        refreshProductDataTable();
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

export const getProduct = (productId) => (dispatch) => {
  return Http.GET("product/product/get/" + productId)
    .then(function (response) {
      // Update Basic Form
      dispatch(updateForm("title", response.data.data.basic.title));
      dispatch(updateForm("parent_sku", response.data.data.basic.parent_sku));
      dispatch(updateForm("brand", response.data.data.basic.brand));
      dispatch(updateForm("shop", response.data.data.basic.shop));

      // Update selected category
      dispatch(
        updateFullCategory(
          response.data.data.basic.category.data,
          response.data.data.basic.category.confirmed,
          response.data.data.basic.category.box
        )
      );

      // Update Selling Form
      dispatch(
        updateSellingForm("price", response.data.data.basic.selling.price)
      );
      dispatch(
        updateSellingForm("cost", response.data.data.basic.selling.cost)
      );
      dispatch(
        updateSellingForm("stocks", response.data.data.basic.selling.stocks)
      );

      // Update Details Form
      dispatch(
        updateDetailsForm(
          "short_desc",
          response.data.data.basic.details.short_desc
        )
      );
      dispatch(
        updateDetailsForm("detailed", response.data.data.basic.details.detailed)
      );
      // Update Shipping Form
      dispatch(
        updateShippingForm("weight", response.data.data.shipping.weight)
      );
      dispatch(updateShippingForm("width", response.data.data.shipping.width));
      dispatch(
        updateShippingForm("height", response.data.data.shipping.height)
      );
      dispatch(
        updateShippingForm("length", response.data.data.shipping.length)
      );

      // Update SEO Form
      dispatch(updateSEOForm("title", response.data.data.seo.title));
      dispatch(
        updateSEOForm("description", response.data.data.seo.description)
      );

      // Update Main Media
      if (
        response.data.data.media.main !== undefined &&
        response.data.data.media.main.length > 0
      ) {
        dispatch(updateFullMedia(response.data.data.media.main));
      }

      //Update Wholesale
      if (
        response.data.data.wholesale !== undefined &&
        response.data.data.wholesale.length > 0
      ) {
        dispatch(updateFullWholesale(response.data.data.wholesale));
      }

      //Update Variation
      if (
        response.data.data.variation != undefined &&
        response.data.data.variation.variants.length > 0
      ) {
        console.log(response.data.data.variation);
        dispatch(
          updateFullVariation(
            response.data.data.variation.variants,
            response.data.data.variation.compose
          )
        );
      }
    })
    .catch(function (error) {
      console.log(error);
      console.log(error.message);
    });
};

export const deactivateProduct = (productId) => (dispatch) => {
  Http.POST("product/product/deactivate/" + productId)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        refreshProductDataTable();
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

export const activateProduct = (productId) => (dispatch) => {
  Http.POST("product/product/activate/" + productId)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        refreshProductDataTable();
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

export const clearedProductDuplicateCase = (product_id, status) => (
  dispatch
) => {
  Http.GET(
    "product/product/cleared-duplicate-case/" +
      product_id +
      "/" +
      (status ? 1 : 0)
  )
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        refreshProductDataTable();
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};
