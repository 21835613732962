class Tickle {
  constructor(base) {
    this.base = base;
  }

  openPlayerOpponentsListBox = () => {
    this.base.setState({
      tickle: { popupBox: true },
    });
    console.log(this.parent.state.players);
  };

  closePlayerOpponentsListBox = () => {
    this.base.setState({
      tickle: { popupBox: false },
    });
  };
}

export default Tickle;
