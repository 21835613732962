import * as type from "../constants/index";

export const setLoading = (payload) => {
  return { type: type.LOADING, payload: payload };
};

export const callback = (response, callback) => {
  return {
    type: type.CALLBACK,
    payload: { response, callback },
  };
};

export const addBrand = (brand) => {
  return {
    type: type.ADD_BRAND,
    payload: brand,
  };
};

export const addBrandItem = (brand, item) => {
  return {
    type: type.ADD_BRAND_ITEM,
    payload: { brand, item },
  };
};

export const addBrandItemVariant = (brand, item, variant) => {
  return {
    type: type.ADD_BRAND_ITEM_VARIANT,
    payload: { brand, item, variant },
  };
};

export const updateMainStock = (brand_id, item_id, stock) => {
  return {
    type: type.UPDATE_MAIN_STOCK,
    payload: { brand_id, item_id, stock },
  };
};

export const updateVariantStock = (brand_id, item_id, compose_id, stock) => {
  return {
    type: type.UPDATE_VARIANT_STOCK,
    payload: { brand_id, item_id, compose_id, stock },
  };
};

export const destroy = () => {
  return {
    type: type.DESTROY,
  };
};

export const loadStockingTemplate = (info, warehouse, brands) => {
  return {
    type: type.LOAD_STOCKING_TEMPLATE,
    payload: { info, warehouse, brands },
  };
};

export const removeTemplateBrand = (brand_id) => {
  return {
    type: type.REMOVE_TEMPLATE_BRAND,
    payload: brand_id,
  };
};

export const removeTemplateBrandItem = (brand_id, item_id) => {
  return {
    type: type.REMOVE_TEMPLATE_BRAND_ITEM,
    payload: { brand_id, item_id },
  };
};

export const removeTemplateBrandItemVariation = (
  brand_id,
  item_id,
  variant_id
) => {
  return {
    type: type.REMOVE_TEMPLATE_BRAND_ITEM_VARIATION,
    payload: { brand_id, item_id, variant_id },
  };
};

export const resetTemplateBrandItemStock = () => {
  return {
    type: type.RESET_TEMPLATE_BRAND_ITEM_STOCK,
  };
};

export const changeGlobalAllocationType = (action, allocation_type, value) => {
  return {
    type: type.CHANGE_GLOBAL_ALLOCATION_TYPE,
    payload: { action, allocation_type, value },
  };
};
