import React from "react";
import { VaNumber, VaForm } from "../service/va-form";
const ShippingInfoView = ({ handleInputChange, form }) => {
  return (
    <div className="digital-add needs-validation">
      <VaForm className="shipping-info">
        <div className="row">
          <div className="form-group col-sm-3">
            <label className="col-form-label pt-0"> Weight (kg)</label>
            <VaNumber
              className="form-control"
              name="weight"
              required
              value={form.shipping.weight}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group col-sm-3">
            <label className="col-form-label pt-0"> Width (cm)</label>
            <VaNumber
              className="form-control"
              name="width"
              required
              value={form.shipping.width}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group col-sm-3">
            <label className="col-form-label pt-0"> Height (cm)</label>
            <VaNumber
              className="form-control"
              name="height"
              required
              value={form.shipping.height}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group col-sm-3">
            <label className="col-form-label pt-0"> Length (cm)</label>
            <VaNumber
              className="form-control"
              name="length"
              required
              value={form.shipping.length}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
      </VaForm>
    </div>
  );
};

export default ShippingInfoView;
