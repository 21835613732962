import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";

export const requestUserProfile = (user_id) => (dispatch) => {
  return Http.GET("settings/user/profile/get/" + user_id);
};

export const requestGetAllCommon = () => (dispatch) => {
  return Http.GET("settings/user/get-all-common");
};

export const requestUpdateUserNotifications = (user_id, option_id, checked) => (
  dispatch
) => {
  return Http.POST("settings/user/profile/" + user_id + "/settings-update", {
    option_id,
    checked,
  });
};

export const requestUpdateUserProfileInfo = (user_id, payload) => (
  dispatch
) => {
  return Http.POST("settings/user/profile/" + user_id + "/info-update", {
    ...payload,
  });
};
