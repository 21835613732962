import React from "react";
import { Redirect } from "react-router-dom";
import AssociationModalView from "../views/association-modal";
import GameComponent from "./gameboard/game-component";
import LineComponent from "./gameboard/line-component";
import GameBoard from "./gameboard/game-board";
import Opponent from "./gameboard/opponent";
import Player from "./gameboard/player";
import Tickle from "./gameboard/tickle";

import { connect } from "react-redux";
import * as AssociationHttp from "../../redux/actions/association/http";

import uuid from "react-uuid";
import { empty } from "common";
import * as toast from "components/common/toast";

import Emitter from "common/event-emitter";

import GamePlay from "./gameplay";

class AssociationModalContainer extends React.Component {
  state = {
    open: false,
    score: 0,
    start: false,
    opponents: [],
    players: [],
    selectedShop: [],
    productSearch: "",
    selected: null,
    paginations: {
      limit: 4,
      players: {
        page: 0,
        next: 0,
        label: null,
        previous: 0,
      },
      opponents: {
        page: 0,
        next: 0,
        label: null,
        previous: 0,
      },
    },
    tickle: {
      player: {
        popupBox: false,
        player: null,
      },
      opponent: {
        popupBox: false,
        opponent: null,
      },
    },
  };

  constructor() {
    super();

    this.onmousedown = this.onmousedown.bind(this);
    this.onmousemove = this.onmousemove.bind(this);
    this.handleShopOptionChange = this.handleShopOptionChange.bind(this);
    this.handleSearchProduct = this.handleSearchProduct.bind(this);
    this.addNewOpponent = this.addNewOpponent.bind(this);
    this.onTickleClosePlayerModal = this.onTickleClosePlayerModal.bind(this);
    this.onTickleCloseOpponentModal = this.onTickleCloseOpponentModal.bind(
      this
    );
    this.handleDetachOpponent = this.handleDetachOpponent.bind(this);
    this.handleDetachPlayer = this.handleDetachPlayer.bind(this);
    this.handleOpponentChangeNumberOfItem = this.handleOpponentChangeNumberOfItem.bind(
      this
    );
    this.selected_shop_input_ref = React.createRef();
    this.emitter = Emitter;
    this.tickle = new Tickle(this);
    this.gameplay = GamePlay(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.gameboard.open != this.props.gameboard.open) {
      if (this.props.gameboard.open) {
        this.clearEverthing();
        this.setupGameboard();
        this.loadPlayers();
      }
    }
  }

  componentDidMount() {}

  onmousemove(e) {
    const { x, y } = this.getMousePointerCoordinates(e);
    this.gameplay.target(x, y);
  }

  onmousedown(e) {
    const { x, y } = this.getMousePointerCoordinates(e);
    this.gameplay.pointer(x, y);
  }

  getMousePointerCoordinates(e) {
    const extraPixels = 75;
    const containerCoordinates = this.gameboard
      .container()
      .getBoundingClientRect();

    var x = e.clientX - containerCoordinates.left;
    var y = Math.round(e.clientY - containerCoordinates.top) - extraPixels;

    return { x, y };
  }

  gameboardUpdater() {
    setTimeout(() => {
      this.gameboard.clear();

      this.gameplay.opponent.activate();
      this.gameplay.opponent.updateCoordinates();
      this.gameplay.opponent.pagination.activate();

      this.gameplay.player.activate();
      this.gameplay.player.updateCoordinates();
      this.gameplay.player.pagination.activate();
    }, 500);
  }

  setupGameboard() {
    const container = document.getElementsByClassName("gamecontainer")[0];
    const canvas = container.getElementsByClassName("gameboard-canvas")[0];
    this.gameboard = new GameBoard(container, canvas);

    this.gameboard.canvas().height = 500;
    this.gameboard.canvas().width = 700;
  }

  clearEverthing() {
    this.setState((state) => {
      return {
        ...state,
        opponents: [],
        players: [],
        selectedShop: [],
        productSearch: "",
        selected: null,
        paginations: {
          limit: 4,
          players: {
            page: 0,
            next: 0,
            label: null,
            previous: 0,
          },
          opponents: {
            page: 0,
            next: 0,
            label: null,
            previous: 0,
          },
        },
      };
    });
  }

  loadPlayers() {
    this.clearEverthing();
    this.gameplay.player.createPlayers(this.props.gameboard.product);
    this.gameboardUpdater();
  }

  updateBaseState(stateData) {
    if (typeof stateData == "function") {
      this.setState(stateData);
    } else {
      this.setState((state) => {
        return {
          ...state,
          ...stateData,
        };
      });
    }
  }

  storeConnection(player, opponent) {
    this.props
      .requestStoreConnection({
        player: player.getBrief(),
        opponent: opponent.getBrief(),
      })
      .then((response) => {
        console.log(response);
        const message = response.data.message;
        if (response.data.success) {
          toast.success(message);
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  }

  deleteConnection(player, opponent) {
    this.props
      .requestDeleteConnection({
        player: player.getBrief(),
        opponent: opponent.getBrief(),
      })
      .then((response) => {
        const message = response.data.message;
        if (response.data.success) {
          toast.success(message);
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  }

  updateNumberOfItemValue(player, opponent) {
    this.props.requestProductLinkReferenceNumberOfItemValue(
      {
        player: player.getBrief(),
        opponent: opponent.getBrief(),
      },
      (response) => {}
    );
  }

  getShops() {
    const shops = this.props.commonReducer.data?.shops ?? [];
    return shops.map((d) => {
      return {
        label: d.app_name + ", " + d.shop_name,
        value: d.id,
      };
    });
  }

  handleSearchProduct = (value) => {
    this.setState({
      selected_product: value,
    });
  };

  handleShopOptionChange = (value) => {
    if (empty(value)) return;
    this.setState({
      selected_shop: value,
    });

    this.selected_shop_input_ref.current.value = value.value;
  };

  addNewOpponent = () => {
    if (empty(this.state.selected_shop)) return;
    if (empty(this.state.selected_product)) return;

    this.gameplay.opponent.createOpponents([this.state.selected_product]);
    this.gameboardUpdater();
  };

  handleDetachOpponent(player, opponent) {
    this.gameplay.detachPlayerOpponent(player, opponent);
    this.gameboardUpdater();
  }

  handleDetachPlayer(opponent, player) {
    this.gameplay.detachOpponentPlayer(opponent, player);
    this.gameboardUpdater();
  }

  onTickleClosePlayerModal() {
    this.tickle.closePlayer();
  }

  onTickleCloseOpponentModal() {
    this.tickle.closeOpponent();
  }

  handleOpponentChangeNumberOfItem(player, opponent, value) {
    this.gameplay.changeOpponentNumberOfItem(player, opponent, value);
  }

  render() {
    // console.log(this.state);
    return (
      <AssociationModalView
        {...this.state}
        {...this.props}
        shopOptionsList={this.getShops()}
        handleShopOptionChange={this.handleShopOptionChange}
        selected_shop_input_ref={this.selected_shop_input_ref}
        handleSearchProduct={this.handleSearchProduct}
        onTickleClosePlayerModal={this.onTickleClosePlayerModal}
        onTickleCloseOpponentModal={this.onTickleCloseOpponentModal}
        addNewOpponent={this.addNewOpponent}
        handleDetachOpponent={this.handleDetachOpponent}
        handleDetachPlayer={this.handleDetachPlayer}
        onmousedown={this.onmousedown}
        onmousemove={this.onmousemove}
        handleOpponentChangeNumberOfItem={this.handleOpponentChangeNumberOfItem}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      commonReducer: state.commonReducer,
    };
  },
  {
    ...AssociationHttp,
  }
)(AssociationModalContainer);
