import React, { Component } from "react";

import { connect } from "react-redux";
import { updateSEOForm } from "../../redux/actions/payload";
import SEOInfoView from "../views/seo-info";

class SEOInfoContainer extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = (event) => {
    this.props.updateSEOForm(
      event.target.name.replace("seo_", ""),
      event.target.value
    );
  };

  render() {
    return (
      <SEOInfoView
        handleInputChange={this.handleInputChange}
        form={this.props.form}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      form: state.productReducer.form,
    };
  },
  {
    updateSEOForm: updateSEOForm,
  }
)(SEOInfoContainer);
