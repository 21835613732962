import React, { Fragment } from "react";
import "./style/wholesale.scss";
import { Trash2, Trash } from "react-feather";

const WholesaleTableView = ({
  addTier,
  removeTier,
  updateTierColumn,
  addRightSideBarAnchor,
  wholesale: { data, enabled, error },
}) => {
  return (
    <div
      className="card"
      ref={(ref) => {
        addRightSideBarAnchor("Wholesale", "wholesale", ref);
      }}
    >
      <div className="card-header">
        <h5>WHOLESALE</h5>
      </div>
      <div className="card-body">
        <WholesaleTable
          addTier={addTier}
          removeTier={removeTier}
          updateTierColumn={updateTierColumn}
          error={error}
          data={data}
        />
      </div>
    </div>
  );
};

const InputBox = ({ updateTierColumn, id, column, value, max }) => {
  return (
    <input
      type="text"
      onChange={(e) => updateTierColumn(id, column, e.target.value)}
      value={value}
      name={column}
      placeholder="..."
      className="no-border-input form-control"
      disabled={max === true ? "disabled" : ""}
    />
  );
};

const DefaultRow = ({
  s,
  id,
  max,
  price,
  removeTier,
  min_stocks,
  max_stocks,
  updateTierColumn,
}) => {
  return (
    <tr key={id}>
      <td>{s}</td>
      <td>
        <InputBox
          updateTierColumn={updateTierColumn}
          column="price"
          value={price}
          id={id}
        />
      </td>
      <td>
        <InputBox
          updateTierColumn={updateTierColumn}
          column="min_stocks"
          value={min_stocks}
          id={id}
        />
      </td>
      <td>
        <InputBox
          updateTierColumn={updateTierColumn}
          column="max_stocks"
          value={max_stocks}
          id={id}
          max={max}
        />
      </td>
      <td>
        <a
          href="javascript:;"
          onClick={(e) => {
            removeTier(id);
          }}
        >
          <Trash width={20} height={20} />
        </a>
      </td>
    </tr>
  );
};

const WholesaleTable = ({
  data,
  error,
  addTier,
  removeTier,
  updateTierColumn,
}) => {
  return (
    <Fragment>
      <table className="table-wholesale">
        <thead>
          <tr>
            <th>Tier</th>
            <th>PRICE</th>
            <th>MIN. STOCKS</th>
            <th>MAX. STOCKS</th>
            <th>
              <Trash2 width={20} height={20} style={{ marginTop: 2 + "px" }} />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((v, i) => {
            return (
              <DefaultRow
                key={"wholesale-" + i}
                {...v}
                removeTier={removeTier}
                updateTierColumn={updateTierColumn}
              />
            );
          })}
        </tbody>
        <tfoot>
          <tr key={"default"}>
            <td colSpan="6" className="text-center">
              <button
                type="button"
                onClick={(e) => {
                  addTier();
                }}
                className="m-t-10 btn btn-default"
              >
                Add New Tier
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
      <code>
        <ol>
          {error.map((d, i) => {
            return (
              <li key={i}>
                Tier {d.s} {d.column} : {d.message}
              </li>
            );
          })}
        </ol>
      </code>
    </Fragment>
  );
};

export default WholesaleTableView;
