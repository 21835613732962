import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import InventoryDatatableContainer from "../container/datatable";
import AllocationModalContainer from "./allocation-modal";

import Emitter from "common/event-emitter";
import { empty } from "common";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";

class ListAllocationContainer extends Component {
  state = {
    modalStatus: false,
    inventory_id: "",
    selected: null,
  };

  handleAllocationModalOpen(inventory_id, selected) {
    this.setState((state) => {
      return {
        ...state,
        modalStatus: true,
        inventory_id: inventory_id,
        selected: selected,
      };
    });
  }

  updateState(state) {
    this.setState(state);
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb title="Inventory List" parent="Inventory" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="clearfix"></div>
              <div
                id="batchDelete"
                className="category-table user-list order-table coupon-list-delete"
              >
                <AllocationModalContainer
                  {...this.state}
                  updateState={this.updateState.bind(this)}
                />
                <InventoryDatatableContainer
                  handleAllocationModalOpen={this.handleAllocationModalOpen.bind(
                    this
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  allocateStocks: Action.allocateStocks,
  getAllCommon: Action.getAllCommon,
  getShopItemsAvailableStock: Action.getShopItemsAvailableStock,
})(ListAllocationContainer);
