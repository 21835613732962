class Wholesale {
  constructor(
    id,
    uuid,
    s,
    price,
    min_quantity,
    max_quantity,
    max) {
    const _id = id;
    console.log(id);
  }

  static getId = () => {
    // return _id;
  }
}

export default Wholesale;
