import React, { Component, Fragment } from "react";
import ListSalesByProductDatatableComponent from "../views/list-salesbyproduct-datatable";

import { connect } from "react-redux";
import * as SalesActions from "../../redux/actions/index";

export class ListSalesByProductDatatableContainer extends Component {
  render() {
    const { sales_by_product } = this.props.orderExportReducer.transactions;

    return (
      <ListSalesByProductDatatableComponent
        salesByProduct={sales_by_product}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    orderExportReducer: state.orderExportReducer,
  };
}

export default connect(mapStateToProps, {
  getTransactions: (filters, per_page, page) =>
    SalesActions.getTransactionsRequest(filters, per_page, page),
  destroy: SalesActions.destroy,
})(ListSalesByProductDatatableContainer);
