const COLOR = "yellow";
const WIDTH = 200;
const HEIGHT = 70;
const START_TOP = 20;
const START_LEFT = 500;
const DISTANCE = 20;
const CONNTECTED_LINE_COLOR = "green";
const CONNECTED_COLOR = "green";

export default {
  color: COLOR,
  height: HEIGHT,
  width: WIDTH,
  start_top: START_TOP,
  start_left: START_LEFT,
  distance: DISTANCE,
  connected_line_color: CONNTECTED_LINE_COLOR,
  connected_color: CONNECTED_COLOR,
};
