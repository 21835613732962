import { empty } from "common";
import uuid from "react-uuid";

import GameComponent from "../../gameboard/game-component";
import Opponent from "../../gameboard/opponent";
import UpdateOpponentCoordinates from "./update-coordinates";
import OpponentProp from "./opponent-prop";
import TickleDelProp from "./tickle-del-prop";

class CreateNewOpponents {
  constructor(base) {
    this.base = base;
  }

  createComponent(...titles) {
    return new GameComponent(
      this.base.gameboard.canvas(),
      this.base.gameboard.context(),
      OpponentProp.color,
      0,
      0,
      OpponentProp.width,
      OpponentProp.height,
      titles
    );
  }

  createOpponentTickleDelComponent() {
    return new GameComponent(
      this.base.gameboard.canvas(),
      this.base.gameboard.context(),
      TickleDelProp.color,
      0,
      0,
      TickleDelProp.width,
      TickleDelProp.height,
      ""
    );
  }

  createOpponent(product) {
    let component = this.createComponent(
      product.data.item_name,
      "#" + product.data.item_id,
      product.data.shop.name + " " + product.data.shop.app
    );
    let ticle_del_component = this.createOpponentTickleDelComponent();
    const opponentObject = new Opponent(
      uuid(),
      component,
      product,
      product.data,
      [],
      ticle_del_component
    );

    opponentObject.changeNumberOfItem(product.data.item_value);

    return [opponentObject];
  }

  createOpponentWithVariants(product) {
    let opponents = [];

    product.data.variations.forEach((variant) => {
      let component = this.createComponent(
        variant.name,
        product.data.item_name,
        "#" + variant.id,
        product.data.shop.name + " " + product.data.shop.app
      );
      let ticle_del_component = this.createOpponentTickleDelComponent();
      const opponentObject = new Opponent(
        uuid(),
        component,
        product,
        variant,
        [],
        ticle_del_component
      );

      opponentObject.changeNumberOfItem(product.data.item_value);

      opponents.push(opponentObject);
    });

    return opponents;
  }

  handle(product) {
    let opponents = [];
    if (product.data.has_variation === true) {
      opponents = this.createOpponentWithVariants(product);
    } else {
      opponents = this.createOpponent(product);
    }

    return new UpdateOpponentCoordinates(this.base).handle(opponents);
  }
}

export default CreateNewOpponents;
