import React, { Component, Fragment } from "react";

import { connect } from "react-redux";

import * as Permission from "redux/actions/user/permission/index";
import * as Role_permission from "redux/actions/user/role-permission/index";
import * as Action_group_permission from "redux/actions/user/group-permission/index";
import * as Action_permission from "redux/actions/user/action-permission/index";

import Spinner from "components/common/spinner";
import Message_container from "components/common/message-container";

export class List_role extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getAllRole();
  }

  select(e) {
    if (e.target.value != "") {
      this.props.getPermissionRequest(e.target.value);
      this.props.setActionGroupFormStatus(false);
      this.props.setActionFormStatus(false);
      this.props.setRoleFormStatus(false);
    }
  }

  render() {
    const { currentRole } = this.props.permission;
    const { data } = this.props.role;

    return (
      <select
        value={currentRole}
        className="form-control"
        name="role"
        id="role"
        onChange={(e) => {
          this.select(e);
        }}
      >
        <option key="default" value="">
          --SELECT ROLE--
        </option>

        {data.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.name}
            </option>
          );
        })}
      </select>
    );
  }
}

function mapStateToProps(state) {
  return {
    permission: state.permission,
    role: state.rolePermission,
    actionGroup: state.actionGroupPermission,
  };
}

export default connect(mapStateToProps, {
  getAllRole: Role_permission.getAllRole(),
  getPermissionRequest: (d) => Permission.getPermissionRequest(d),
  setActionGroupFormStatus: (d) => Action_group_permission.setFormStatus(d),
  setActionFormStatus: (d) => Action_permission.setFormStatus(d),
  setRoleFormStatus: (d) => Role_permission.setFormStatus(d),
})(List_role);
