import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";

const ReceivedOrderDataTableComponent = (props) => {
  const {
    editPurchaseOrder,
    rmovePurchaseOrder,
    selected,
    selectedId,
    data,
    pages,
    supplierId,
    loading,
    fetchReceivedOrder,
  } = props;

  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"Order No."}</b>,
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) => (
              <Link
                to={"receive/" + row.original.order_id}
                title="Receive Delivery"
                style={{textDecoration: "underline"}}
              >
                {row.original.order_no}
              </Link>
            ),
          },
          {
            Header: <b>{"Creator"}</b>,
            accessor: "creator",
          },
          {
            Header: <b>{"Delivered Date"}</b>,
            accessor: "delivered_date.format",
          },
          {
            Header: <b>{"Warehouse"}</b>,
            accessor: "warehouse.name",
          },
          {
            Header: <b>{"Received By"}</b>,
            accessor: "received_by",
          },
          {
            Header: <b>{"Remarks"}</b>,
            accessor: "remarks",
          },
          {
            Header: <b>{"Total Quantity"}</b>,
            accessor: "total_quantity",
            style: {
              textAlign: "right",
            },
          },
        ]}
        defaultSorted={[
          {
            id: "delivered_date.format",
            desc: true,
          },
        ]}
        SubComponent={(row) => {
          return (
            <div style={{ padding: "20px" }}>
              <ReactTable
                data={row.original.items}
                collapseOnDataChange={false}
                defaultPageSize={row.original.items.length}
                showPagination={false}
                columns={[
                  {
                    Header: <b>{"Product"}</b>,
                    sortable: false,
                    filterable: false,
                    Cell: (row) =>
                      row.original.detail.variation_id ? (
                        <>
                          <strong>{row.original.detail.variation_name}</strong>
                          <br />
                          {row.original.detail.product_name}
                        </>
                      ) : (
                        row.original.detail.product_name
                      ),
                  },
                  {
                    Header: <b>{"Quantity"}</b>,
                    accessor: "quantity",
                    filterable: false,
                    style: {
                      textAlign: "center",
                    },
                  },
                ]}
              />
            </div>
          );
        }}
        collapseOnDataChange={true}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        onFetchData={fetchReceivedOrder} // Request new data when things change
        filterable
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
};

export default ReceivedOrderDataTableComponent;
