import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";

import { empty } from "common";
import matchSorter from "match-sorter";
import _debounce from "lodash.debounce";

const DataTableComponent = (props) => {
  const {
    data,
    pages,
    loading,
    handleAllocationModalOpen,
    fetchInventoryList,
  } = props;

  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"Last Update"}</b>,
            accessor: "updated_at.format",
          },
          {
            Header: <b>{"Warehouse"}</b>,
            accessor: "warehouse.name",
          },
          {
            Header: <b>{"Product"}</b>,
            accessor: "product.basic.title",
            id: "product_name",
            show: false,
          },
          {
            Header: <b>{"Variation"}</b>,
            accessor: "product.variation_name",
            id: "variation_name",
            show: false,
          },
          {
            Header: <b>{"Product"}</b>,
            accessor: "product.basic.title",
            filterable: true,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: ["variation_name", "product_name"],
              }),
            Cell: (row) => (
              <>
                {!empty(row.original.product.variation_name) ? (
                  <>
                    {row.original.product.variation_name}
                    <br />
                    <small>{row.original.product.basic.title}</small>
                  </>
                ) : (
                  <>{row.original.product.basic.title}</>
                )}
              </>
            ),
          },

          {
            Header: <b>{"Stocks"}</b>,
            accessor: "product.available_stock",
            filterable: false,
            style: {
              textAlign: "right",
            },
            Cell: (row) => (
              <div
                className="btn-group"
                role="group"
                aria-label="Button group with nested dropdown"
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "70px" }}
                >
                  {row.original.product.available_stock}
                </button>
                <button
                  onClick={() => {
                    handleAllocationModalOpen(row.original.id, row.original);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  <FontAwesomeIcon icon={faTruckLoading} size="xs" />
                </button>
              </div>
            ),
          },
        ]}
        defaultSorted={[
          {
            id: "product",
            desc: true,
          },
        ]}
        collapseOnDataChange={true}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        onFetchData={_debounce(fetchInventoryList, 300)} // Request new data when things change
        filterable
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
};

export default DataTableComponent;
