import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";

import { empty } from "common";
import Button from "react-bootstrap/Button";
import * as Toast from "components/common/toast";
import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";

import ApprovalComponent from "../views/approval";

const approve = "approve";
const reject = "disapprove";
const resubmit = "resubmit";

class ApprovalContainer extends Component {
  state = {
    id: null,
    template: [],
    loading: false,
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.updateState({
      id: this.props.match.params.id,
    });
  }

  updateState(state) {
    this.setState(state);
  }

  setLoading(status) {
    this.updateState({
      loading: status,
    });
  }

  submitRequest(type) {
    this.setLoading(true);

    return this.props.submitRequest(type, this.state.template);
  }

  reject() {
    this.izitoast(
      (instance, toast) => {
        if (!empty(this.state.template.approval_remarks)) {
          this.submitRequest(reject)
            .then((response) => {
              this.setLoading(false);
              if (response.data.success) {
                Toast.success(response.data.message);
                instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              } else {
                Toast.error(response.data.message);
              }
            })
            .catch((error) => {
              this.setLoading(false);
              Toast.error(error.toString());
            });
        }
      },
      [
        [
          `<textarea placeholder="Enter reason for reject...">${
            this.state.template?.approval_remarks ?? ""
          }</textarea>`,
          "change",
          (instance, toast, input, e) => {
            this.updateState({
              template: {
                ...this.state.template,
                approval_remarks: input.value,
              },
            });
          },
        ],
      ]
    );
  }

  resubmit() {
    this.izitoast(
      (instance, toast) => {
        if (!empty(this.state.template.approval_remarks)) {
          this.submitRequest(resubmit)
            .then((response) => {
              this.setLoading(false);
              if (response.data.success) {
                Toast.success(response.data.message);
                instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              } else {
                Toast.error(response.data.message);
              }
            })
            .catch((error) => {
              this.setLoading(false);
              Toast.error(error.toString());
            });
        }
      },
      [
        [
          `<textarea placeholder="Enter reason for resubmit...">${
            this.state.template?.approval_remarks ?? ""
          }</textarea>`,
          "change",
          (instance, toast, input, e) => {
            this.updateState({
              template: {
                ...this.state.template,
                approval_remarks: input.value,
              },
            });
          },
        ],
      ]
    );
  }

  approve() {
    this.izitoast(
      (instance, toast) => {
        this.updateState({
          template: {
            ...this.state.template,
            approval_remarks: "",
          },
        });
        this.submitRequest(approve)
          .then((response) => {
            this.setLoading(false);
            if (response.data.success) {
              Toast.success(response.data.message);
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
            } else {
              Toast.error(response.data.message);
            }
          })
          .catch((error) => {
            this.setLoading(false);
            Toast.error(error.toString());
          });
      },
      null,
      "Do you wish to proceed to approve?"
    );
  }

  izitoast(yesCallback, remarksInputs, title) {
    const inputs = empty(remarksInputs) ? [] : remarksInputs;

    Toast.instance().question({
      theme: "dark",
      messageColor: "#d9d4d4",
      backgroundColor: "#9a2379",
      titleColor: "#a19f9f",
      zindex: 999,
      message: "",
      position: "center",
      drag: false,
      progressBar: false,
      close: false,
      overlay: true,
      inputs: inputs,
      title: empty(title) ? "" : title,
      buttons: [
        ["<button><b>Confirm</b></button>", yesCallback, true],
        [
          "<button>Cancel</button>",
          function (instance, toast) {
            instance.hide({ transitionOut: "fadeOut" }, toast, "button");
          },
        ],
      ],
      onClosing: function (instance, toast, closedBy) {
        // console.info('Closing | closedBy: ' + closedBy);
      },
      onClosed: function (instance, toast, closedBy) {
        // console.info('Closed | closedBy: ' + closedBy);
      },
    });
  }

  render() {
    console.log(this.state);
    return (
      <Fragment>
        <Breadcrumb
          title="Approval"
          element={
            <>
              <div className="pull-right">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={(e) => this.reject()}
                >
                  REJECT
                </Button>
                <Button
                  variant="default"
                  size="sm"
                  onClick={(e) => this.resubmit()}
                >
                  RE-SUBMIT
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={(e) => this.approve()}
                >
                  APPROVE
                </Button>
              </div>
            </>
          }
        />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="clearfix"></div>
              <ApprovalComponent
                {...this.state}
                updateState={this.updateState.bind(this)}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  ...Http,
})(ApprovalContainer);
