import React, { Component, Fragment, useState } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

import { empty } from "common";
import matchSorter from "match-sorter";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DailySalesDatatableComponent = (props) => {
  const { fetchSales, pages, daily_sales, loading, last_updated_at } = props;
  console.log(loading);
  return (
    <div>
      <Row>
        <Col className="text-left">
          <small>{loading ? "Reloading..." : ""}</small>
        </Col>
        <Col className="text-right">
          Last Updated at: {last_updated_at?.created_at ?? null}
        </Col>
      </Row>

      <ReactTable
        data={daily_sales}
        pages={pages} // Display the total number of pages
        // loading={loading} // Display the loading overlay when we need it
        // onFetchData={fetchSales} // Request new data when things change
        // manual
        filterable
        className="-striped -highlight"
        showPagination={true}
        showPaginationTop={true}
        showPaginationBottom={true}
        defaultPageSize={50}
        pageSizeOptions={[50, 75, 100]}
        collapseOnDataChange={false}
        freezeWhenExpanded={true}
        minRows={0}
        columns={[
          {
            Header: <b>{"S#"}</b>,

            filterable: false,
            style: {
              textAlign: "left",
              width: "3%",
            },
            width: 30,
            Cell: (row) => <div>{row.index + 1}</div>,
          },
          {
            Header: <b>{"Item"}</b>,
            id: "search_title",
            filterable: true,
            accessor: (d) => d.search_title,
            filterAll: true,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: ["search_title", "product", "sku"],
              }),
            style: {
              textAlign: "left",
            },
            width: 400,
            Cell: (row) => (
              <>
                {!empty(row.original.sku) ? (
                  <>
                    {row.original.sku}
                    <br />
                    <small>{row.original.product}</small>
                  </>
                ) : (
                  <>{row.original.product}</>
                )}
              </>
            ),
          },
          {
            Header: <b>{"Item"}</b>,
            id: "product",
            show: false,
            filterable: true,
            accessor: (d) => d.product,
            style: {
              textAlign: "left",
            },
          },
          {
            Header: <b>{"Pending"}</b>,
            accessor: "pending_quantity",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 120,
          },
          {
            Header: <b>{"Processed"}</b>,
            accessor: "proccessed_quantity",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 120,
          },
          {
            Header: <b>{"Total Orders"}</b>,
            sortable: true,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 150,
            Cell: (row) => (
              <>
                {(row.original?.pending_quantity ?? 0) +
                  (row.original?.proccessed_quantity ?? 0)}
              </>
            ),
          },
          {
            Header: <b>{"Current Inventory"}</b>,
            filterable: false,
            style: {
              textAlign: "left",
            },
            width: 300,
            Cell: (row) => (
              <>
                {(row.original?.inventory ?? []).map((inventory, index) => {
                  return (
                    <div key={index}>
                      {inventory.warehouse.name}:{" "}
                      <strong>{inventory.stock}</strong>
                      <br />
                    </div>
                  );
                })}
              </>
            ),
          },
          {
            Header: <b>{"Shopee Stock"}</b>,
            accessor: "live_stock",
            sortable: true,
            filterable: false,
            filterAll: true,
            width: 200,
            style: {
              textAlign: "center",
            },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["order_no"] }),
          },
        ]}
      />
    </div>
  );
};

export default DailySalesDatatableComponent;
