import { empty } from "common";
import uuid from "react-uuid";

import GameComponent from "../../gameboard/game-component";
import Opponent from "../../gameboard/opponent";
import UpdateOpponentCoordinates from "./coordinates";
import OpponentProp from "./prop";

class CreateNewOpponents {
  constructor(base) {
    this.base = base;
  }

  createComponent(title) {
    return new GameComponent(
      this.base.gameboard.canvas(),
      this.base.gameboard.context(),
      OpponentProp.color,
      0,
      0,
      OpponentProp.width,
      OpponentProp.height,
      title
    );
  }

  createOpponents(shops) {
    const opponents = [];

    shops.forEach((shop) => {
      let component = this.createComponent(shop.shop_name);

      opponents.push(
        new Opponent(uuid(), component, shop, shop, null, null, null, null)
      );
    });

    return opponents;
  }

  handle(shops) {
    let opponents = this.createOpponents(shops);

    return new UpdateOpponentCoordinates(this.base).handle(opponents);
  }
}

export default CreateNewOpponents;
