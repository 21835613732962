import * as type from "../../constants/campaign";

export const updateMultipleCampaign = (id, column, value) => {
  return {
    type: type.UPDATE_MULTI_CAMPAIGN,
    payload: { id, column, value },
  };
};

export const updateSingleCampaign = (column, value) => {
  return {
    type: type.UPDATE_SINGLE_CAMPAIGN,
    payload: { column, value },
  };
};

export const addMultipleCampaignError = (id, error) => {
  return {
    type: type.ADD_MULTIPLE_CAMPAIGN_ERROR,
    payload: { id, error },
  };
};

export const addSingleCampaignError = (error) => {
  return {
    type: type.ADD_SINGLE_CAMPAIGN_ERROR,
    payload: { error },
  };
};

export const clear = () => {
  return {
    type: type.CLEAR,
  };
};
