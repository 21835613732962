import React, { Component, Fragment } from 'react'

import { connect } from "react-redux";
import * as BrandAction from "../../redux/actions/index";
import FormComponent from "../views/form";

class CreateBrandContainer extends Component {
    state = {
        clear: false,
        name: '',
        description: '',
    };

    componentDidUpdate = (prevProps) => {
        if (this.state.clear == true) return;
        if (prevProps.brand.response?.message != '') {
            if (prevProps.brand.response?.error == false) {
                this.setState({
                    name: '',
                    description: '',
                    clear: true
                });
            }
        }
    }

    handleSubmit(e) {
        this.setState({
            clear: false
        })
        this.props.createBrand(this.state);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const {loading, response} = this.props.brand;

        return (
            <FormComponent
                handleChange={this.handleChange.bind(this)}
                handleSubmit={this.handleSubmit.bind(this)}
                loading={loading}
                response={response}
                {...this.props}
                {...this.state}
             />
        )
    }
}

function mapStateToProps(state) {
    return {
        brand: state.brand
    };
}

export default connect(
    mapStateToProps,
   {
        createBrand:  BrandAction.createBrand,
        getAll: (filters, per_page, page) => BrandAction.getAllRequest(filters, per_page, page)
    }
)(CreateBrandContainer);
