import React, { Component, Fragment } from 'react';

import DataTableContainer from '../container/datatable';
import CreateCategoryContainer from "../container/create-category";
import Breadcrumb from 'components/common/breadcrumb';

const ListCategoryComponent = (props) => {
    const {
        open,
        onOpenModal,
        onCloseModal
    } = props;
    return (
        <Fragment>
            <Breadcrumb
                title="Category List"
                parent="Product"
                desc={(<a onClick={e=>{onOpenModal(e)}} href='javascript:void(0);'>Create Category</a>)}
            />
            <div className="container-fluid">
                <div className="card">
                    <div className="card-body">
                        <div className="clearfix"></div>
                        <CreateCategoryContainer
                            open={open}
                            onOpenModal={onOpenModal}
                            onCloseModal={onCloseModal}
                        />
                        <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                            <DataTableContainer />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ListCategoryComponent;
