import Http from "api/http";

import { setLoading, setProgress, addOrUpdateImage } from "./media/payload";
import * as toast from "components/common/toast";

const SaveMediaTemp = (next, store, action) => (dispatch) => {
  // Saving form data and images
  const f = new FormData();
  f.append("photo", action.payload.image);
  return Http.POST("product/product/image/temp/save", f, {
    onUploadProgress: (event) => {
      store.dispatch(
        setProgress(action.payload.key, (event.loaded / event.total) * 100)
      );
    },
  })
    .then((response) => {
      if (response.data.success === true) {
        next(
          addOrUpdateImage({
            key: action.payload.key,
            hash: response.data.hash,
            imageUrl: response.data.url,
          })
        );
      } else {
        toast.error(response.data.message);
      }

      store.dispatch(setLoading(action.payload.key, false));
      store.dispatch(setProgress(action.payload.key, 0));
    })
    .catch(function (error) {
      toast.error(error.message);
      store.dispatch(setLoading(action.payload.key, false));
      store.dispatch(setProgress(action.payload.key, 0));
    });
};

export default SaveMediaTemp;
