import { empty } from "common";

class GameComponent {
  CONNECTED = false;
  texts = [];

  constructor(canvas, context, color, x, y, width, height, texts, fontColor) {
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.color = color;
    this.speedX = 0;
    this.speedY = 0;
    this.context = context;
    this.canvas = canvas;

    this.fontColor = fontColor;
    this.locked = false;

    this.texts = typeof texts == "object" ? texts : [texts];
  }

  area = () => {
    return this.canvas;
  };

  changeY = (y) => {
    this.y = y;
  };

  changeX = (x) => {
    this.x = x;
  };

  changeSpeedY = (y) => {
    this.speedY = y;
  };

  changeSpeedX = (x) => {
    this.speedX = x;
  };

  changeWidth = (width) => {
    this.width = width;
  };

  changeHeight = (height) => {
    this.height = height;
  };

  changeTitle = (texts) => {
    texts = typeof texts == "object" ? texts : [texts];
    this.texts = texts;
  };

  changeColor = (color) => {
    this.color = color;
  };

  changeFontColor = (color) => {
    this.fontColor = color;
  };

  lock = () => {
    this.locked = true;
  };

  unlock = () => {
    this.locked = false;
  };

  isLocked = () => {
    return this.locked;
  };

  connected = (connected) => {
    this.CONNECTED = connected;
  };

  isConnected = () => {
    return this.CONNECTED;
  };

  getText = () => {
    return this.texts;
  };

  getWidth = () => {
    return this.width;
  };

  getHeight = () => {
    return this.height;
  };

  getX = () => {
    return this.x;
  };

  getY = () => {
    return this.y;
  };

  getSpeedY = () => {
    return this.speedY;
  };

  getSpeedX = () => {
    return this.speedX;
  };

  update = () => {
    this.drawReact();

    if (this.hasTitle()) {
      this.drawText();
    }
  };

  drawReact = () => {
    this.context.beginPath();
    this.context.fillStyle = this.color;
    this.context.fillRect(
      this.getX(),
      this.getY(),
      this.getWidth(),
      this.getHeight()
    );
  };

  drawText = () => {
    this.context.beginPath();
    this.context.font = "12px Arial";
    this.context.fillStyle = empty(this.fontColor) ? "black" : this.fontColor;

    var start_top = 5;
    var start_left = 5;
    var line_height = 15;
    var breaker_point = line_height;
    var char_length = this.getWidth() / 7;

    this.texts.forEach((text, i) => {
      this.context.fillText(
        `${text}`.substr(0, char_length) +
          (text.length > char_length ? "..." : ""),
        this.getX() + start_left,
        this.getY() + start_top + breaker_point
      );
      breaker_point += line_height;
    });
  };

  stop = () => {
    this.clear();
    this.update();
  };

  hasTitle() {
    if (empty(this.texts)) {
      return false;
    }
    return true;
  }

  gotHit = (otherObject) => {
    const left = this.getX() + this.getWidth();
    const right = this.area().width - this.getX();
    const top = this.getY() + this.getHeight();
    const bottom = this.area().height - this.getY();

    const otherLeft = otherObject.getX();
    const otherRight =
      otherObject.area().width - (otherObject.getX() + otherObject.getWidth());

    const otherTop = otherObject.getY();
    const otherBottom =
      otherObject.area().height -
      (otherObject.getY() + otherObject.getHeight());

    if (left < otherLeft) {
      return false;
    }

    if (otherBottom > bottom) {
      return false;
    }

    if (otherTop > top) {
      return false;
    }

    if (right < otherRight) {
      return false;
    }

    return true;
  };
}

export default GameComponent;
