import React, { Component, Fragment, useRef } from "react";
import Modal from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruckLoading,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { empty } from "common";

import "./style/history-modal.scss";
import Button from "react-bootstrap/Button";

export default class HistoryModalComponent extends Component {
  render() {
    const {
      history_modal,
      handleOnCloseModal,
      orderExportReducer,
      handleGetTransactions,
    } = this.props;

    const style = {
      closeButton: {
        top: "-14px",
        right: "-8px",
      },
      closeIcon: {
        color: "#fff",
        fontSize: "20px",
      },
      modalContainer: {
        width: "400px",
      },
    };

    return (
      <Fragment>
        <Modal
          open={history_modal.modal}
          onClose={handleOnCloseModal}
          closeIcon={FontAwesomeIcon}
          classNames={{
            modal: "sales-transactions-history-modal",
          }}
          styles={style}
          center
        >
          <table
            className="table table-bordered table-hover"
            style={{ marginTop: "20px" }}
          >
            <thead>
              <tr>
                <th>Generated Date</th>
                <th>FILTER</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {(orderExportReducer?.history.data ?? []).map((history) => {
                return (
                  <tr key={history.id}>
                    <td>{history.created_at.format}</td>
                    <td>
                      {history.input.status} <br />
                      <small>
                        {moment(history.input.start_date.date).format(
                          "MMM DD, YYYY"
                        )}
                        {" - "}
                        {moment(history.input.end_date.date).format(
                          "MMM DD, YYYY"
                        )}
                      </small>
                    </td>
                    <td>{history.progress_status}</td>
                    <td className="text-right">
                      <Button
                        disabled={
                          true ==
                            (history.progress_status.toLowerCase() !=
                              "ready") || false
                        }
                        onClick={(e) => {
                          handleGetTransactions(history.id);
                        }}
                        variant="secondary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faTruckLoading} size="sm" />
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal>
      </Fragment>
    );
  }
}
