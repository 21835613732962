import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

export const success = (message, position) => {
  iziToast.success({
    message: message,
    position: position == undefined ? "topRight" : position,
    backgroundColor: "#07bc0c",
    messageColor: "#fff",
  });
};

export const error = (message, position) => {
  iziToast.error({
    title: "Error!",
    message: message,
    position: position == undefined ? "topRight" : position,
  });
};

export const info = (message, position) => {
  iziToast.info({
    title: "Info!",
    message: message,
    position: position == undefined ? "topRight" : position,
  });
};

export const warning = (message) => {
  iziToast.warning({
    title: "Caution",
    message: message,
    position: "topCenter",
    backgroundColor: "#ffe75e",
  });
};

export const question = (title, message, callback) => {
  iziToast.question({
    theme: "light",
    messageColor: "#d9d4d4",
    backgroundColor: "#9a2379",
    titleColor: "#a19f9f",
    title: title,
    message: message,
    position: "center",
    progressBar: false,
    close: false,
    buttons: [
      [
        "<button><b>YES</b></button>",
        function (instance, toast) {
          callback();
          instance.hide({ transitionOut: "fadeOut" }, toast, "button");
        },
        true,
      ],
      [
        "<button>NO</button>",
        function (instance, toast) {
          instance.hide({ transitionOut: "fadeOut" }, toast, "button");
        },
      ],
    ],
    onClosing: function (instance, toast, closedBy) {
      // console.info('Closing | closedBy: ' + closedBy);
    },
    onClosed: function (instance, toast, closedBy) {
      // console.info('Closed | closedBy: ' + closedBy);
    },
  });
};

export function instance() {
  return iziToast;
}
