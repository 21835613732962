import uuid from "react-uuid";

const compose = (state) => {
  const baseVariant = state.variation.data;
  const lastVariant = baseVariant.length - 1;
  const numberOfVariant = baseVariant.length;
  let composed = [];
  let row = 1;
  let variantIndex = 0;
  let variantCollectionsOfSkuIndex = [];

  // Count total number of rows of variants
  const numberOfRows = baseVariant.reduce((carry, variant) => {
    carry *= variant.sku_data.length;
    return carry;
  }, 1);

  // Assign base collections index for each variant
  for (var i = 0; i < baseVariant.length; i++) {
    variantCollectionsOfSkuIndex = variantCollectionsOfSkuIndex.concat({
      variantIndex: i,
      skuIndex: 0,
    });
  }

  // Update current variant sku index
  const updateVariantSkuIndex = (variantIndex, skuIndex) => {
    variantCollectionsOfSkuIndex = variantCollectionsOfSkuIndex.map((c) => {
      if (c.variantIndex === variantIndex) {
        c.skuIndex = skuIndex;
      }
      return c;
    });
  };

  // Get Sku Current Iterate Index
  const getVariantSkuIndex = (variantIndex) => {
    const collection = variantCollectionsOfSkuIndex.filter(
      (index) => index.variantIndex === variantIndex
    );
    return collection[0].skuIndex;
  };

  // Get specific variant by index
  const getVariant = (variantIndex) => {
    return baseVariant[variantIndex];
  };
  // Get Previous Variant by index
  const getPreviousVariantIndex = (currentVariantIndex) => {
    const prevVariantIndex = currentVariantIndex - 1;
    const prevSkuIndex = getVariantSkuIndex(prevVariantIndex);
    const prevVariant = getVariant(prevVariantIndex);

    if (prevSkuIndex === prevVariant.sku_data.length - 1) {
      updateVariantSkuIndex(prevVariantIndex, 0);
      return getPreviousVariantIndex(prevVariantIndex);
    }

    return prevVariantIndex;
  };

  // Create compose rows
  const createCompose = (composed, row, data) => {
    const rowIndex = composed.findIndex((l) => l.row === row);
    if (rowIndex === -1) {
      composed.push({
        id: uuid(),
        row: row,
        data: {
          options: [{ title: data.title, value: data.sku.name, column: 1 }],
          sku: "",
          stocks: 0,
          price: 0,
          image: { url: "", session: "", loading: false, progress: 0 },
          sale_price: 0,
          sale_start_at: "",
          sale_end_at: "",
        },
        errors: { campaign: [], variation: [] },
      });
    } else {
      const rowData = composed[rowIndex].data;
      rowData.options = rowData.options.concat({
        title: data.title,
        value: data.sku.name,
        column: rowData.options.length + 1,
      });
      composed[rowIndex].data = rowData;
    }

    return composed;
  };
  // Iterate all variants until its each sku are composed
  do {
    let currentIndex = variantIndex;
    const variant = getVariant(currentIndex);
    const variantSku = variant.sku_data;
    const skuIndex = getVariantSkuIndex(currentIndex);
    const sku = variantSku[skuIndex];

    // Increment variant index for the next loop
    variantIndex++;
    // Define variant in each loop
    const data = {
      id: variant.id,
      title: variant.title,
      sku: sku,
    };
    // Create compose data
    composed = createCompose(composed, row, data);

    // Determine the last variant from the list
    // In order to compose the variants sku
    if (currentIndex === lastVariant) {
      if (row === numberOfRows) {
        // This will end the loop once the itetation is completed
        variantIndex = baseVariant.length;
      } else {
        if (skuIndex === variantSku.length - 1) {
          // Since the last variant reach its sku index pick
          // We need to retrieve the variant index of the previous position from the last variant
          const previousVariantIndex = getPreviousVariantIndex(currentIndex);
          // Increment the previous variant sku index by 1 as a starting point
          // for the iteration of the next sku
          updateVariantSkuIndex(
            previousVariantIndex,
            getVariantSkuIndex(previousVariantIndex) + 1
          );
          // Reset the current variant sku index to 0
          // to resume the starting point of itration
          updateVariantSkuIndex(currentIndex, 0);
        } else {
          // Increament the variant sku index by 1
          // In order to select the sku for the next iteration
          updateVariantSkuIndex(currentIndex, skuIndex + 1);
        }
        // Increment row only when all variants sku are composed
        row++;
        // Set variant index to default by 0
        // In order to loop back the variants for each iteration
        variantIndex = 0;
      }
    }
  } while (variantIndex < baseVariant.length);

  return composed;
};

const Composer = (state, action) => {
  return {
    ...state,
    variation: {
      ...state.variation,
      compose: compose(state),
    },
  };
};

export default Composer;
