import OpponentClass from "./opponent/index";
import PlayerClass from "./player/index";
import GameComponent from "../gameboard/game-component";
import IsPointerClass from "./pointer";

const GamePlay = (base) => {
  const Player = PlayerClass(base);
  const Opponent = OpponentClass(base);
  const IsPointer = new IsPointerClass();

  const target = (x, y) => {
    Player.updateLine(x, y).then((players) => {
      Player.updatePlayers(players);
    });

    playerTargetOpponent();

    base.gameboardUpdater();
  };

  const pointer = (x, y) => {
    IsPointer.setCoordinates(x, y);
    playerPointer();
    opponentPointer();
    paginationPointer();

    base.gameboardUpdater();
  };

  const playerPointer = () => {
    Player.listPlayers.allActive().then((players) => {
      players.forEach((player) => {
        if (IsPointer.pointed(player.getTickleShowCon())) {
          base.updateBaseState({
            tickle: { player: { popupBox: true, player: player } },
          });
        }
      });
    });

    Player.listPlayers.allActive().then((players) => {
      players.map((player) => {
        if (IsPointer.pointed(player.getTickleShowCon())) {
        } else if (IsPointer.pointed(player.getComponent())) {
          player = Player.createLine(
            player,
            IsPointer.getX(),
            IsPointer.getY()
          );
        }
        return player;
      });

      Player.updatePlayers(players);
    });
  };

  const paginationPointer = () => {
    // Players pagination
    if (IsPointer.pointed(Player.pagination.next())) {
      Player.pagination.increment();
    } else if (IsPointer.pointed(Player.pagination.previous())) {
      Player.pagination.decrement();
    } else if (IsPointer.pointed(Opponent.pagination.next())) {
      Opponent.pagination.increment();
    } else if (IsPointer.pointed(Opponent.pagination.previous())) {
      Opponent.pagination.decrement();
    }
  };

  const opponentPointer = () => {
    Opponent.ListOpponents.allActive().forEach((opponent) => {
      if (IsPointer.pointed(opponent.getTickleDel())) {
        // Opponent.remove([opponent]);
        // // Remove connections from database
        // opponent.players.forEach((player) => {
        //   detachPlayerOpponent(player, opponent);
        // });
        base.updateBaseState({
          tickle: { opponent: { popupBox: true, opponent: opponent } },
        });
      } else if (IsPointer.pointed(opponent.getComponent())) {
      }
    });
  };

  const detachPlayerOpponent = (player, opponent) => {
    Player.detachOpponent(player, opponent);
    Opponent.detachPlayer(opponent, player);
    // delete from db
    base.deleteConnection(player, opponent);
  };

  const detachOpponentPlayer = (opponent, player) => {
    Player.detachOpponent(player, opponent);
    Opponent.detachPlayer(opponent, player);
    // delete from db
    base.deleteConnection(player, opponent);
  };

  const playerTargetOpponent = () => {
    Player.listPlayers.allActive().then((players) => {
      players.map((player) => {
        Opponent.ListOpponents.allActive().map((opponent) => {
          if (player.hasOpenLines()) {
            player.getOpenLines().forEach((line) => {
              if (
                line.gotHit(opponent.getComponent()) &&
                !player.isAttached(opponent)
              ) {
                player = Player.character(player).connectOpponent(
                  line,
                  opponent
                );
                opponent = Opponent.character(opponent).connectPlayer(player);

                // store to db
                base.storeConnection(player, opponent);
              }
            });
          }
          return opponent;
        });
        return player;
      });
      Player.updatePlayers(players);
    });
  };

  const changeOpponentNumberOfItem = (player, opponent, value) => {
    // There must be one item value for all the same opponents
    // Player.listPlayers.all().forEach((player) => {
    //   const opponents = player
    //     .getOpponents()
    //     .filter((f) => f.opponent.sameValueAs(opponent));
    //   if (opponents.length > 0) {
    //     Player.changeOpponentNumberOfItem(player, opponent, value);
    //
    //     setTimeout(() => {
    //       base.updateNumberOfItemValue(player, opponent);
    //     }, 500);
    //   }
    // });

    Player.changeOpponentNumberOfItem(player, opponent, value);
    Opponent.changeNumberOfItem(opponent, value);

    setTimeout(() => {
      base.updateNumberOfItemValue(player, opponent);
    }, 500);
  };

  return {
    opponent: Opponent,
    player: Player,
    detachPlayerOpponent,
    detachOpponentPlayer,
    changeOpponentNumberOfItem,
    pointer,
    target,
  };
};

export default GamePlay;
