const ADD_VARIANT = "product/product/variation/add/variant";
const ADD_SKU = "product/product/variation/add/sku";
const REMOVE_SKU = "product/product/variation/remove/sku";
const REMOVE = "product/product/variation/remove";
const UPDATE_VARIANT_COLUMN = "product/product/variation/update-variant-column";
const UPDATE_SKU_COLUMN = "product/product/variation/update-sku-column";
const CLEAR = "product/product/variation/clear";
const ENABLE = "product/product/variation/enable";
const UPDATE_TITLE = "product/product/variation/update-title";
const SHOW_ERROR_INFO = "product/product/variation/show/error/info";
const ADD_VARIANT_ERROR = "product/product/variation/variant/error/add";
const ADD_SKU_ERROR = "product/product/variation/sku/error/add";
const UPDATE_COMPOSER_CELL_VALUE =
  "product/product/variation/composer/cell/update";
const ADD_COMPOSER_CELL_VALUE_ERROR =
  "product/product/variation/compose/error/add";

const UPDATE_FULL_VARIATION = "product/product/variation/update/full";
const UPDATE_COMPOSER = "product/product/variation/update/composer";

export {
  ADD_VARIANT,
  ADD_SKU,
  REMOVE_SKU,
  ENABLE,
  REMOVE,
  UPDATE_VARIANT_COLUMN,
  UPDATE_SKU_COLUMN,
  CLEAR,
  UPDATE_TITLE,
  SHOW_ERROR_INFO,
  ADD_VARIANT_ERROR,
  ADD_SKU_ERROR,
  UPDATE_COMPOSER_CELL_VALUE,
  ADD_COMPOSER_CELL_VALUE_ERROR,
  UPDATE_FULL_VARIATION,
  UPDATE_COMPOSER,
};
