import React, { Component, Fragment } from "react";
import SummaryModalComponent from "../views/summary-modal";
import { connect } from "react-redux";

const SummaryModalContainer = (props) => {
  const handleSummaryModalClose = () => {
    props.setModalSummaryOpen(false);
  };

  return (
    <SummaryModalComponent
      {...props}
      handleSummaryModalClose={handleSummaryModalClose.bind(this)}
    />
  );
};

export default connect((state) => {
  return {
    inventory_stocking_reducer: state.inventoryStockingReducer,
  };
})(SummaryModalContainer);
