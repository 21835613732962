import { numberOnly, empty, emptyNumeric, isNumberPositive } from "common";
import * as payload from "../actions/campaign/payload";

const dateRangeError = (store, campaign) => {
  const error = [];
  if (emptyNumeric(campaign.sale_price)) {
    return error;
  }

  if (new Date(campaign.sale_start_at) <= new Date()) {
    error.push({
      column: "sale_start_at",
      message: store.getState().productReducer.labels.error.campaign
        .invalid_sale_start_date,
    });
  }

  // if (new Date(campaign.sale_end_at) <= new Date()) {
  //   error.push({
  //     column: "sale_end_at",
  //     message: store.getState().product.labels.error.campaign
  //       .invalid_sale_end_date,
  //   });
  // }

  if (new Date(campaign.sale_end_at) <= new Date(campaign.sale_start_at)) {
    error.push({
      column: "sale_end_at",
      message: store.getState().productReducer.labels.error.campaign
        .invalid_sale_end_date_range,
    });
  }

  return error;
};

const unkownValue = (store, campaign) => {
  const error = [];

  // if (empty(campaign.sale_end_at) && empty(campaign.sale_start_at)) {
  //   return error;
  // }
  console.log(campaign.sale_price);
  if (emptyNumeric(campaign.sale_price)) {
    return error;
  }

  if (!isNumberPositive(campaign.sale_price)) {
    error.push({
      column: "unit_cost",
      message: store.getState().productReducer.labels.error.wholesale
        .uknown_value,
    });
  }

  if (empty(campaign.sale_end_at)) {
    error.push({
      column: "sale_end_at",
      message: store.getState().productReducer.labels.error.campaign
        .uknown_value,
    });
  }

  if (empty(campaign.sale_start_at)) {
    error.push({
      column: "sale_start_at",
      message: store.getState().productReducer.labels.error.campaign
        .uknown_value,
    });
  }

  return error;
};

const dispatchMultiCampaignError = (store) => {
  store.getState().productReducer.variation.compose.map((row) => {
    const error_daterange = dateRangeError(store, row.data);
    const error_unknown = unkownValue(store, row.data);
    const error = error_unknown.concat(error_daterange);

    store.dispatch(payload.addMultipleCampaignError(row.id, error));

    return row;
  });
};

const dispatchSingleCampaignError = (store) => {
  const campaign = store.getState().productReducer.campaign;
  const error_daterange = dateRangeError(store, campaign);
  const error_unknown = unkownValue(store, campaign);
  const error = error_unknown.concat(error_daterange);

  store.dispatch(payload.addSingleCampaignError(error));
};

export const validateMultipleCampaign = (next, store, action) => {
  next(action);

  dispatchMultiCampaignError(store);
};

export const validateSingleCampaign = (next, store, action) => {
  next(action);

  dispatchSingleCampaignError(store);
};
