import React, { Component, Fragment } from "react";

import Message_container from "components/common/message-container";
import ItemsDatatableContainer from "../container/items-datatable";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const TableComponent = (props) => {
  return (
    <Fragment>
      <div className="card">
        <div className="card-body">
          <Message_container message={props.message} error={props.error} />
          <div>
            <ButtonGroup className="text-right">
              <Button
                variant={props.query.out_of_stock ? "secondary" : "default"}
                onClick={(e) => {
                  props.handleQueryOutOfStock();
                }}
              >
                Out of Stock
              </Button>

              <DropdownButton
                as={ButtonGroup}
                title="Sort By"
                id="bg-nested-dropdown"
              >
                <Dropdown.Item
                  eventKey="1"
                  onClick={(e) => {
                    props.handleSortQuery({ id: "sold", desc: true });
                  }}
                >
                  Best Seller
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  disabled={props.query.out_of_stock}
                  onClick={(e) => {
                    props.handleSortQuery({ id: "stocks", desc: true });
                  }}
                >
                  Stocks High
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  disabled={props.query.out_of_stock}
                  onClick={(e) => {
                    props.handleSortQuery({ id: "stocks", desc: false });
                  }}
                >
                  Stocks Low
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </div>
          <div>
            <ItemsDatatableContainer {...props} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TableComponent;
