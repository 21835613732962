const FETCH_ALL = "supplier/load-all";
const FETCH = "supplier/load";
const FORM_STATUS = "supplier/form-status";
const LOADING = "supplier/progress-loading";
const RESPONSE = "supplier/response";
const UPDATE_STATUS = "supplier/update/status/boolean";
const CALLBACK = "supplier/callback";

export {
  FETCH_ALL,
  FETCH,
  FORM_STATUS,
  LOADING,
  RESPONSE,
  UPDATE_STATUS,
  CALLBACK,
};
