import * as type from "../constants/index";
import moment from "moment";

const initialState = {
  data: [],
  shops: [],
  filter: [],
  history: [],
  transactions: {
    sales: {
      updated: null,
      total: 0,
      page: 1,
      data: [],
      loading: false,
    },
    summary: [],
    sales_by_product: [],
    sales_by_product_sku: [],
  },
  loading: false,
};

export default function OrderExportReducer(state = initialState, action) {
  switch (action.type) {
    case type.LOAD_TRANSACTIONS:
      state = {
        ...state,
        transactions: {
          ...state.transactions,
          ...action.payload,
        },
      };
      state = {
        ...state,
        transactions: {
          ...state.transactions,
          sales: {
            ...state.transactions.sales,
            updated: moment(),
          },
        },
      };
      break;
    case type.LOAD_SALES:
      state = {
        ...state,
        transactions: {
          ...state.transactions,
          sales: action.payload,
        },
      };
      break;
    case type.LOAD_HISTORY:
      state = {
        ...state,
        history: action.payload,
      };
      break;
    case type.LOAD_SHOP_LIST:
      state = {
        ...state,
        shops: action.payload,
      };
      break;
    case type.LOADING:
      const loading = action.payload;
      state = { ...state, loading };
      break;
    case type.SALES_LOADING:
      state = {
        ...state,
        transactions: {
          ...state.transactions,
          sales: {
            ...state.transactions.sales,
            loading: action.payload,
          },
        },
      };
      break;
    case type.UPDATE_FILTER:
      state = {
        ...state,
        filter: action.payload,
      };
      break;
    case type.RESPONSE:
      const response = action.payload;
      state = { ...state, response };
      break;
    case type.DESTROY:
      state = initialState;
      break;
    case type.CLEAR_TRANSACTIONS:
      state = {
        ...state,
        transactions: initialState.transactions,
      };
      break;
  }

  return state;
}
