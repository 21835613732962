import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { isNumber } from "common";

import * as Http from "../../redux/actions/http";

import ItemsDatatableComponent from "../views/items-datatable";

const ItemsDatatableContainer = (props) => {
  const [datatable, setDataTable] = useState({
    data: [],
    pages: 0,
    loading: false,
    pageSize: 0,
    total: 0,
    allChecked: false,
  });

  function requestProductList(state, instance) {
    setDataTable({
      ...datatable,
      loading: true,
    });
    props
      .requestProductList({
        criteria: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
        per_page: state.pageSize,
        page: state.page == 0 ? 1 : state.page + 1,
      })
      .then((response) => {
        if (response.data.success) {
          setDataTableProps(
            response.data.data, //initializeReOrderLevel(response.data.data, props.all_reorder_level),
            response.data.total,
            state.pageSize
          );
        }
      });
  }

  function handleItemReOrderLevelChange(cellInfo, value) {
    datatable.data[cellInfo.index].basic.re_order_level = value;

    setDataTable({
      ...datatable,
      data: datatable.data,
    });
  }

  function handleItemVariantReOrderLevelChange(cellInfo, value) {
    // Get item from specified variant
    const item = datatable.data.filter((item) => {
      return item.variation.compose.indexOf(cellInfo.original) !== -1;
    })[0];
    const item_index = datatable.data.indexOf(item);
    // Retrieve variant index
    const variant_index = datatable.data[item_index].variation.compose.indexOf(
      cellInfo.original
    );
    // Change variant re order level
    datatable.data[item_index].variation.compose[
      variant_index
    ].re_order_level = value;

    setDataTable({
      ...datatable,
      data: datatable.data,
    });
  }

  function initializeReOrderLevel(data, re_order_level) {
    return (data ?? []).map((item) => {
      if (item.checked) {
        item.basic.re_order_level = re_order_level;
        item.variation.compose = item.variation.compose.map((variant) => {
          if (variant.checked) {
            variant.re_order_level = re_order_level;
          }
          return variant;
        });
      }

      return item;
    });
  }

  function setDataTableProps(data, total, pageSize) {
    setDataTable({
      data: data,
      pages: Math.ceil(total / pageSize),
      pageSize: pageSize,
      loading: false,
      total: total,
    });
  }

  function handleHeaderIncludeCheckbox(checked) {
    setDataTable({
      ...datatable,
      allChecked: checked,
      data: (datatable?.data ?? []).map((row) => {
        row.checked = checked;
        row.variations = row.variation.compose.map((variant) => {
          variant.checked = checked;
          return variant;
        });
        return row;
      }),
    });
  }

  function handleAllItemsIncludeCheckbox(item, checked) {
    const item_index = datatable.data.indexOf(item);

    datatable.data[item_index].checked = checked;

    // Find with index and change data item variations include check
    if (datatable.data[item_index].variation.compose.length > 0) {
      datatable.data[item_index].variation.compose = datatable.data[
        item_index
      ].variation.compose.map((variant) => {
        variant.checked = checked;
        return variant;
      });
    }

    const items_checked = datatable.data.filter((item) => item.checked == true);

    // All check must remain check if there still items checked
    datatable.allChecked = items_checked.length > 0;

    setDataTable({
      ...datatable,
      data: datatable.data,
    });
  }

  function handleItemVariantIncludeCheckbox(item, variant, checked) {
    const item_index = datatable.data.indexOf(item);

    // Find with index and change data item variation check
    if (datatable.data[item_index].variation.compose.length > 0) {
      const variant_index = datatable.data[
        item_index
      ].variation.compose.indexOf(variant);

      datatable.data[item_index].variation.compose[
        variant_index
      ].checked = checked;
    }

    const variants_checked = datatable.data[
      item_index
    ].variation.compose.filter((variant) => variant.checked == true);
    // Change item checked based on the variants check
    // item check must remain check if there is still variant on checked status
    // otherwise unchecked it
    datatable.data[item_index].checked = variants_checked.length > 0;

    const items_checked = datatable.data.filter((item) => item.checked == true);
    // All check must remain check if there still items checked
    datatable.allChecked = items_checked.length > 0;

    setDataTable({
      ...datatable,
      data: datatable.data,
      allChecked: datatable.allChecked,
    });
  }

  function renderEditableVariant(cellInfo, headerCellInfo) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ced4da",
          borderRadius: "0.25rem",
          padding: "0.375rem 0.75rem",
          fontSize: "1rem",
          lineHeight: "1.5",
        }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          if (!isNumber(e.target.innerHTML)) {
            return;
          }
          handleItemVariantReOrderLevelChange(cellInfo, e.target.innerHTML);
        }}
        dangerouslySetInnerHTML={{
          __html: cellInfo.original.re_order_level,
        }}
      />
    );
  }

  function renderEditable(cellInfo) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ced4da",
          borderRadius: "0.25rem",
          padding: "0.375rem 0.75rem",
          fontSize: "1rem",
          lineHeight: "1.5",
        }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          if (!isNumber(e.target.innerHTML)) {
            return;
          }
          handleItemReOrderLevelChange(cellInfo, e.target.innerHTML);
        }}
        dangerouslySetInnerHTML={{
          __html: datatable.data[cellInfo.index]?.basic?.re_order_level ?? "",
        }}
      />
    );
  }

  // Detect all_reorder_level changes
  // apply to all items levels, the re_order_level
  useEffect(() => {
    setDataTable({
      ...datatable,
      data: initializeReOrderLevel(datatable.data, props.all_reorder_level),
    });
  }, [props.all_reorder_level, props.keep_effect_updated]);

  // Every changes of datatables,
  // let the parent component knows about it
  useEffect(() => {
    props.updateState({
      datatable: datatable,
    });
  }, [datatable]);

  return (
    <ItemsDatatableComponent
      {...datatable}
      renderEditable={renderEditable.bind(this)}
      requestProductList={requestProductList.bind(this)}
      renderEditableVariant={renderEditableVariant.bind(this)}
      handleAllItemsIncludeCheckbox={handleAllItemsIncludeCheckbox.bind(this)}
      handleHeaderIncludeCheckbox={handleHeaderIncludeCheckbox.bind(this)}
      handleItemVariantIncludeCheckbox={handleItemVariantIncludeCheckbox.bind(
        this
      )}
    />
  );
};

export default connect(null, {
  requestProductList: Http.requestProductList,
})(ItemsDatatableContainer);
