import * as typeMedia from "../../modules/product/product/redux/constants/media";
import * as typeVaration from "../../modules/product/product/redux/constants/variation";
import * as typeWholesale from "../../modules/product/product/redux/constants/wholesale";
import * as typeCampaign from "../../modules/product/product/redux/constants/campaign";
import * as typeCategory from "../../modules/product/product/redux/constants/category";
import * as typeProduct from "../../modules/product/product/redux/constants/product";
import * as typeAssociation from "../../modules/product/product/redux/constants/association";
import * as typeSupplier from "../../modules/supplier/redux/constants/index";
import * as typePurchase from "../../modules/purchase/redux/constants/index";
import * as typeInStoreOrder from "../../modules/order/in-store/redux/constants/index";

import * as ProductIndex from "../../modules/product/product/redux/middleware";
import * as MediaMiddleware from "../../modules/product/product/redux/middleware/media";
import * as VariationMiddleware from "../../modules/product/product/redux/middleware/variation";
import * as WholesaleMiddleware from "../../modules/product/product/redux/middleware/wholesale";
import * as CampaignMiddleware from "../../modules/product/product/redux/middleware/campaign";
import * as CategoryMiddleware from "../../modules/product/product/redux/middleware/category";
import * as SubmitMiddleware from "../../modules/product/product/redux/middleware/submit";
import * as DraftMiddleware from "../../modules/product/product/redux/middleware/draft";
import * as PlayerAssociation from "../../modules/product/product/redux/middleware/association";
import * as SupplierMiddleware from "../../modules/supplier/redux/middleware/index";
import * as PurchaseMiddleware from "../../modules/purchase/redux/middleware/index";
import * as InStoreMiddleware from "../../modules/order/in-store/redux/middleware/index";

function actor(next, store, action, handlers) {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](next, store, action);
  }
  return next(action);
}

const middleware = (store) => (next) => (action) => {
  return actor(next, store, action, {
    // Variation
    [typeVaration.UPDATE_COMPOSER_CELL_VALUE]:
      VariationMiddleware.updateComposerCellValue,

    [typeVaration.UPDATE_SKU_COLUMN]:
      VariationMiddleware.updateVariantSkuColumn,

    [typeVaration.UPDATE_VARIANT_COLUMN]:
      VariationMiddleware.updateVariantColumn,
    // Wholesale
    [typeWholesale.ADD_TIER]: WholesaleMiddleware.addTier,
    [typeWholesale.REMOVE_TIER]: WholesaleMiddleware.removeTier,
    [typeWholesale.UPDATE_TIER_COLUMN]: WholesaleMiddleware.updateTierColumn,

    [typeProduct.CALLBACK]: ProductIndex.callback,

    // Media
    [typeMedia.ADD_OR_UPDATE]: MediaMiddleware.imageValidator,

    // Campaign
    [typeCampaign.UPDATE_MULTI_CAMPAIGN]:
      CampaignMiddleware.validateMultipleCampaign,
    [typeCampaign.UPDATE_SINGLE_CAMPAIGN]:
      CampaignMiddleware.validateSingleCampaign,

    // CategoryView
    [typeCategory.CONFIRMED_CATEGORY]:
      CategoryMiddleware.validateConfirmCategory,

    // Product submit
    [typeProduct.SUBMIT]: SubmitMiddleware.submit,
    // Product draft
    [typeProduct.DRAFT]: DraftMiddleware.draft,
    // Product Association
    [typeAssociation.PLAYER_CONNECTING_RESULT]:
      PlayerAssociation.connectingResult,
    [typeAssociation.PLAYER_DISCONNECTING_RESULT]:
      PlayerAssociation.disconnectingResult,
    [typeSupplier.CALLBACK]: SupplierMiddleware.callback,
    [typePurchase.CALLBACK]: PurchaseMiddleware.callback,

    // IN STORE ORDER
    [typeInStoreOrder.ADD_ITEM_TO_BASKET]: InStoreMiddleware.valdateAddBasket,
    [typeInStoreOrder.INCREASE]: InStoreMiddleware.ValidateIncreaseBasket,
  });
};

export default middleware;
