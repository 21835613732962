import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default class DetailedDescriptionView extends Component {
  render() {
    const {
      shortContent,
      detailedContent,
      handleInputChange,
      handleDetailsChange,
      form,
    } = this.props;
    return (
      <div className="digital-add needs-validation">
        <div className="form-group">
          <label className="col-form-label">Short Summary</label>
          <textarea
            rows="4"
            name="short_desc"
            cols="12"
            onChange={(e) => handleInputChange(e)}
            value={form.details.short_desc}
          ></textarea>
        </div>
        <div className="form-group mb-0">
          <label className="col-form-label">Detailed Description</label>
          <div className="description-sm">
            <CKEditor
              editor={ClassicEditor}
              data={form.details.detailed}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={handleDetailsChange}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
