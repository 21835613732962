const ADD_ITEM_TO_BASKET = "commerce/instore/order/basket/item/add";
const REMOVE_ITEM_FROM_BASKET = "commerce/instore/order/basket/item/remove";
const CHANGE_BASKET_ITEM_QUANTITY =
  "commerce/instore/order/basket/item/quantity/change";
const DESTROY_BASKET = "commerce/instore/order/basket/destroy";
const CHANGE_CUSTOMER_DATA = "commerce/instore/order/customer/change-data";
const INCREASE = "commerce/instore/order/basket/increase";
const DECREASE = "commerce/instore/order/basket/decrease";
const CHANGE_SHIPPING_DATA = "commerce/instore/order/shipping/change-data";

export {
  DESTROY_BASKET,
  ADD_ITEM_TO_BASKET,
  REMOVE_ITEM_FROM_BASKET,
  CHANGE_BASKET_ITEM_QUANTITY,
  CHANGE_CUSTOMER_DATA,
  INCREASE,
  DECREASE,
  CHANGE_SHIPPING_DATA,
};
