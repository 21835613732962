const ADD_TIER = "product/product/wholesale/add-tier";
const REMOVE_TIER = "product/product/wholesale/remove-tier";
const UPDATE_TIER_COLUMN = "product/product/wholesale/update-tier";
const CLEAR = "product/product/wholesale/clear";
const ENABLE = "product/product/wholesale/enable";
const SHOW_ERROR_INFO = "product/product/wholesale/show/info/error";
const UPDATE_FULL = "product/product/wholesale/update/full";

export {
  ADD_TIER,
  ENABLE,
  REMOVE_TIER,
  UPDATE_TIER_COLUMN,
  CLEAR,
  SHOW_ERROR_INFO,
  UPDATE_FULL,
};
