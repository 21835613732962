import React, { Component, Fragment } from "react";

import DataTableComponent from "../views/datatable";
import { connect } from "react-redux";
import * as SupplierAction from "../../redux/actions/index";
import * as toast from "components/common/toast";

class DataTableContainer extends Component {
  state = {
    data: [],
    pages: null,
    pageSize: 0,
    selectedId: [],
    open: false,
  };

  constructor() {
    super();
    this.fetchSupplier = this.fetchSupplier.bind(this);
    this.removeSupplier = this.removeSupplier.bind(this);
    this.selected = this.selected.bind(this);
    this.editSupplier = this.editSupplier.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.supplierReducer.data.data) !==
      JSON.stringify(prevProps.supplierReducer.data.data)
    ) {
      this.setState({
        data: this.props.supplierReducer.data.data,
        selectedId: [],
        pages: Math.ceil(
          this.props.supplierReducer.data.total / this.state.pageSize
        ),
      });
    }
  }

  fetchSupplier(state, instance) {
    this.props.getAllSuppliers(
      {
        filtered: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
      },
      state.pageSize,
      state.page == 0 ? 1 : state.page + 1
    );

    this.setState({
      data: this.props.supplierReducer.data.data,
      pages: Math.ceil(this.props.supplierReducer.data.total / state.pageSize),
      pageSize: state.pageSize,
    });
  }

  removeSupplier(e) {
    const props = this.props;
    const state = this.state;
    if (state.selectedId.length <= 0) return;

    toast.question(
      "Delete",
      "Are you sure you want to remove this?",
      function () {
        props.deleteSupplier(state.selectedId);
      }
    );
  }

  editSupplier(e, id) {}

  selected(e, id) {
    if (!e.target.checked) {
      this.setState({
        selectedId: this.state.selectedId.filter((d) => d !== id),
      });
    } else {
      this.setState({
        selectedId: this.state.selectedId.concat(id),
      });
    }
  }

  render() {
    const { loading } = this.props.supplierReducer;

    return (
      <DataTableComponent
        loading={loading}
        editSupplier={this.editSupplier.bind(this)}
        removeSupplier={this.removeSupplier.bind(this)}
        selected={this.selected.bind(this)}
        fetchSupplier={this.fetchSupplier}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierReducer: state.supplierReducer,
  };
}

export default connect(mapStateToProps, {
  getAllSuppliers: (filter, limit, page) =>
    SupplierAction.getAllRequest(filter, limit, page),
  deleteSupplier: (d) => SupplierAction.deleteSupplier(d),
})(DataTableContainer);
