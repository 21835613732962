import React, { Component } from "react";

import { connect } from "react-redux";
import { updateSellingForm } from "../../redux/actions/payload";
import SellCostView from "../views/sell-cost";

class SellCostContainer extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = (event) => {
    this.props.updateSellingForm(event.target.name, event.target.value);
  };

  render() {
    const {
      wholesale: { enabled: wholesaleEnabled },
      variation: { enabled: variationEnabled },
      addRightSideBarAnchor,
    } = this.props;

    const enable = wholesaleEnabled || variationEnabled;

    if (enable === false) {
      return (
        <SellCostView
          addRightSideBarAnchor={addRightSideBarAnchor}
          wholesaleEnabled={wholesaleEnabled}
          variationEnabled={variationEnabled}
          handleInputChange={this.handleInputChange}
          form={this.props.form}
        />
      );
    }
    return "";
  }
}

export default connect(
  (state) => {
    return {
      wholesale: state.productReducer.wholesale,
      variation: state.productReducer.variation,
      form: state.productReducer.form,
    };
  },
  {
    updateSellingForm: updateSellingForm,
  }
)(SellCostContainer);
