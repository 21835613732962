import LineComponent from "../../gameboard/line-component";

const COLOR = "black";
const WIDTH = 5;

class PlayerLine {
  constructor(base) {
    this.base = base;
  }

  createLine(player, x, y) {
    return new LineComponent(
      this.base.gameboard,
      COLOR,
      WIDTH,
      player.getComponent().getX() + player.getComponent().getWidth(),
      player.getComponent().getY() + player.getComponent().getHeight() / 2,
      x,
      y
    );
  }

  handle(player, x, y) {
    return this.createLine(player, x, y);
  }
}

export default PlayerLine;
