import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";
import Emitter from "common/event-emitter";

export const requestCreatesSalesOrder =
  (data, callback, error) => (dispatch) => {
    Http.PUT("orders/in-store/create-order", data).then(callback).catch(error);
  };

export const requestUpdateSalesOrder =
  (order_id, data, callback, error) => (dispatch) => {
    Http.PATCH("orders/in-store/update-order/" + order_id, data)
      .then(callback)
      .catch(error);
  };

export const getAllCommon = (callback, error_callback) => (dispatch) => {
  return Http.GET("orders/in-store/get-all-common")
    .then(callback)
    .catch(error_callback);
};

export const requestExternalShopInventory =
  (data, callback, error_callback) => (dispatch) => {
    return Http.POST("orders/in-store/get-external-shop-inventory", data)
      .then(callback)
      .catch(error_callback);
  };

export const requestRetrieveAllSalesOrders =
  (payload, callback, error_callback) => (dispatch) => {
    return Http.POST("orders/in-store/get-orders", { payload })
      .then(callback)
      .catch(error_callback);
  };

export const requestRetrieveSalesOrder =
  (sales_order_id, callback, error_callback) => (dispatch) => {
    return Http.GET("orders/in-store/get-order/" + sales_order_id)
      .then(callback)
      .catch(error_callback);
  };

export const requestProductList = (payload, config) => (dispatch) => {
  return Http.POST("orders/in-store/get-products", payload, config);
};

export const requestCalculatePayment = (basket) => (dispatch) => {
  return Http.POST("orders/in-store/calculate-payment", { basket });
};
