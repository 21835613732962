const LOAD_TRANSACTIONS = "sales/transactions/load";
const LOADING = "sales/transactions/progress-loading";
const RESPONSE = "sales/transactions/response";
const DESTROY = "sales/transactions/destroy";
const LOAD_SHOP_LIST = "sales/transactions/shoplist";
const LOAD_SALES = "sales/transactions/list";
const SALES_LOADING = "sales/transactions/list/loading";
const UPDATE_FILTER = "sales/transactions/list/filter";
const CLEAR_TRANSACTIONS = "sales/transactions/list/clear";
const LOAD_HISTORY = "sales/transactions/list/history";

export {
  LOAD_HISTORY,
  CLEAR_TRANSACTIONS,
  LOAD_TRANSACTIONS,
  LOADING,
  RESPONSE,
  DESTROY,
  LOAD_SHOP_LIST,
  LOAD_SALES,
  SALES_LOADING,
  UPDATE_FILTER,
};
