import { empty } from "common";
import OpponentProp from "./prop";

class UpdateOpponentCoordinates {
  constructor(base) {
    this.base = base;
  }

  handle(opponents) {
    var OBSTACLE_START_TOP = OpponentProp.start_top;
    opponents = opponents.map((opponent) => {
      opponent
        .getComponent()
        .changeX(
          this.base.gameboard.canvas().width -
            OpponentProp.width -
            OpponentProp.distance
        );
      opponent.getComponent().changeY(OBSTACLE_START_TOP);

      OBSTACLE_START_TOP += OpponentProp.height + OpponentProp.distance;

      return opponent;
    });

    return opponents;
  }
}

export default UpdateOpponentCoordinates;
