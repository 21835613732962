import React, { Component, Fragment } from "react";

import { connect } from "react-redux";

export class MessageContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.error == true) {
      return (
        <div>
          <div className="alert alert-danger" role="alert">
            {this.props.message}
          </div>
        </div>
      );
    }
    return <div></div>;
  }
}

function mapStateToProps(state) {
  return {
    userAuthReducer: state.userAuthReducer,
  };
}

let MessageContainerRedux = connect(mapStateToProps)(MessageContainer);

export default MessageContainerRedux;
