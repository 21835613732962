import { afterState, getDefaultSku } from "./common";
import UpdateComposer from "./update-composer";

const RemoveSku = (state, action) => {
  // find variant index
  const variantIndex = state.variation.data.findIndex(
    (variant) => variant.id === action.payload.id
  );
  const variation = state.variation.data;
  // Retrieve variant index record
  const sku = variation[variantIndex].sku_data.filter(
    (sku) => sku.uuid === action.payload.uuid
  );
  // Remove via array filter of variant index
  variation[variantIndex].sku_data = variation[variantIndex].sku_data.filter(
    (sku) => sku.uuid !== action.payload.uuid
  );

  // Auto add new sku (Last row) if no such remaining sku
  if (variation[variantIndex].sku_data.length <= 0) {
    const skuData = getDefaultSku();
    skuData.last = true;
    variation[variantIndex].sku_data = variation[variantIndex].sku_data.concat(
      skuData
    );
  }

  // Set the last sku field to last = true
  if (sku[0]?.last === true) {
    const lastIndex = variation[variantIndex].sku_data.length - 1;
    variation[variantIndex].sku_data[lastIndex].last = true;
  }

  return UpdateComposer(
    afterState({
      ...state,
      variation: {
        ...state.variation,
        data: variation,
      },
    })
  );
};

export default RemoveSku;
