import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

const Emitter = {
  on: (event, fn, context) => eventEmitter.on(event, fn, context),
  once: (event, fn, context) => eventEmitter.once(event, fn, context),
  off: (event, fn, context) => eventEmitter.removeListener(event, fn, context),
  emit: (event, payload, context) => eventEmitter.emit(event, payload, context),
  eventNames: () => eventEmitter.eventNames(),
};

export default Emitter;
