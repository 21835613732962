import CreateOpponents from "./create-opponent";
import UpdateOpponentCoordinates from "./update-coordinates";
import ListOpponents from "./list-opponents";
import Pagination from "./pagination";
import Character from "./character";
import { empty } from "common";

const Opponent = (base) => {
  const pagination = new Pagination(base);

  const updateCoordinates = () => {
    return new UpdateOpponentCoordinates(base).handle(
      ListOpponents(base).allActive()
    );
  };

  const activate = () => {
    ListOpponents(base)
      .allActive()
      .forEach((opponent) => {
        opponent.getComponent().update();
        opponent.getTickleDel().update();
      });
  };

  const remove = (opponents_) => {
    const opponents = opponents_.length > 0 ? opponents_ : [opponents_];
    base.updateBaseState((state) => {
      return {
        ...state,
        opponents: state.opponents.filter((opponent) => {
          if (opponents.filter((o) => o.sameValueAs(opponent)).length > 0) {
            return false;
          }
          return true;
        }),
      };
    });
    setTimeout(() => {
      pagination.updateCurrentPageIfNeeded();
    }, 300);
  };

  const updateState = (opponents_) => {
    base.updateBaseState((state) => {
      const buttons = new Pagination(base).create();
      let opponents = empty(opponents_) ? [] : opponents_;

      opponents = [].concat(...opponents).filter((opponent) => {
        if (state.opponents.filter((o) => o.sameValueAs(opponent)).length > 0) {
          return false;
        }
        return true;
      });

      return {
        ...state,
        opponents: state.opponents.concat(...opponents),
        paginations: {
          ...state.paginations,
          opponents: {
            ...state.paginations.opponents,
            previous: buttons[0],
            next: buttons[2],
            label: buttons[1],
            page: 0,
          },
        },
      };
    });
  };

  const createOpponents = (products) => {
    const opponents = [];

    if (products.length != undefined && products.length > 0) {
      products.forEach((product) => {
        opponents.push(new CreateOpponents(base).handle(product));
      });
    } else {
      opponents.push(new CreateOpponents(base).handle(products));
    }

    updateState(opponents);
  };

  const character = (opponent) => {
    return new Character(base, opponent);
  };

  return {
    ListOpponents: ListOpponents(base),
    pagination,
    createOpponents,
    updateCoordinates,
    character,
    activate,
    remove,
  };
};

export default Opponent;
