import React from "react";
import { Redirect } from "react-router-dom";
import MigrationModalComponent from "../views/migration-modal";
import GameComponent from "./gameboard/game-component";
import LineComponent from "./gameboard/line-component";
import GameBoard from "./gameboard/game-board";
import Migration from "./migration";

import { connect } from "react-redux";
import * as HttpRedux from "../../redux/actions/http";

import uuid from "react-uuid";
import { empty } from "common";
import Emitter from "common/event-emitter";

import GamePlay from "./gameplay";

import * as toast from "components/common/toast";
import Http from "api/http";
import Echo from "laravel-echo";

class MigrationModalContainer extends React.Component {
  state = {
    open: false,
    loading: false,
    opponents: [],
    players: [],
    selected: null,
    shops: [],
    start_button: null,
    paginations: {
      limit: 4,
      players: {
        page: 0,
        next: 0,
        label: null,
        previous: 0,
      },
      opponents: {
        page: 0,
        next: 0,
        label: null,
        previous: 0,
      },
    },
  };

  constructor() {
    super();

    this.onmousedown = this.onmousedown.bind(this);
    this.onmousemove = this.onmousemove.bind(this);
    this.migration = Migration(this);
    this.emitter = Emitter;
  }

  componentDidMount() {
    this.props.getAllCommon((response) => {
      this.setState({
        ...response,
      });
    });

    window.Pusher = require("pusher-js");
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: true,
      authEndpoint: Http.getBaseUrl() + "/broadcasting/auth",
      host: Http.getBaseUrl(),
      auth: {
        headers: {
          Authorization:
            "Bearer " + this.props.userAuthReducer.session.access_token,
        },
      },
    });

    window.Echo.private(
      "channel-migration-notify." + this.props.userAuthReducer.session.user.id
    )
      .listen("ItemsMigrationFinished", (e) => {
        toast.success(e.message);
        this.unlockMigrationButton();
        console.log(e);
      })
      .listen("ItemsMigrationFailed", (e) => {
        toast.error(e.message);
        this.unlockMigrationButton();
        console.log(e);
      });
  }

  componentWillUnmount() {
    window.Echo = null;
    window.Pusher = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.migration.open != this.props.migration.open) {
      if (this.props.migration.open) {
        this.clearEverthing();
        this.setupGameboard();
        this.loadPlayers();
        this.loadOpponents();
      }
    }
  }

  onmousemove(e) {
    const { x, y } = this.getMousePointerCoordinates(e);
    this.migration.target(x, y);
  }

  onmousedown(e) {
    const { x, y } = this.getMousePointerCoordinates(e);
    this.migration.pointer(x, y);
  }

  getMousePointerCoordinates(e) {
    const extraPixels = 0;
    const containerCoordinates = this.gameboard
      .container()
      .getBoundingClientRect();

    var x = e.clientX - containerCoordinates.left;
    var y = Math.round(e.clientY - containerCoordinates.top) - extraPixels;

    return { x, y };
  }

  gameboardUpdater() {
    setTimeout(() => {
      this.gameboard.clear();

      this.migration.opponent.coordinates();
      this.migration.opponent.activate();

      this.migration.player.coordinates();
      this.migration.player.activate();

      this.migration.buttons.activate();
    }, 500);
  }

  setupGameboard() {
    const container = document.getElementsByClassName("gamecontainer")[0];
    const canvas = container.getElementsByClassName("gameboard-canvas")[0];
    this.gameboard = new GameBoard(container, canvas);

    this.gameboard.canvas().height = 500;
    this.gameboard.canvas().width = 700;
  }

  clearEverthing() {
    this.setState((state) => {
      return {
        ...state,
        opponents: [],
        players: [],
        selectedShop: [],
        productSearch: "",
        selected: null,
        paginations: {
          limit: 4,
          players: {
            page: 0,
            next: 0,
            label: null,
            previous: 0,
          },
          opponents: {
            page: 0,
            next: 0,
            label: null,
            previous: 0,
          },
        },
      };
    });
  }

  loadPlayers() {
    this.migration.player.createPlayers(
      this.state.shops.filter(
        (d) => d.app_key.toLowerCase() != this.state.shop_store.toLowerCase()
      )
    );
    this.gameboardUpdater();
  }

  loadOpponents() {
    this.migration.opponent.createOpponents(
      this.state.shops.filter(
        (d) => d.app_key.toLowerCase() == this.state.shop_store.toLowerCase()
      )
    );
    this.gameboardUpdater();
  }

  updateBaseState(stateData) {
    if (typeof stateData == "function") {
      this.setState(stateData);
    } else {
      this.setState((state) => {
        return {
          ...state,
          ...stateData,
        };
      });
    }
  }

  migrate() {
    if (this.state.loading) return;

    const players = this.state.players
      .filter((player) => player.getComponent().isConnected())
      .map((player) => {
        return {
          info: player.getInfo(),
          opponents: player.opponents.map((opponent) => {
            return {
              info: opponent.opponent.getInfo(),
            };
          }),
        };
      });

    if (players.length <= 0) return;

    this.updateBaseState({
      loading: true,
    });
    this.props.handleLockMigrationModal(true);
    this.migration.buttons.startButton.progress();

    this.props.migrate(players, (response) => {
      if (response.error == false) {
      }
    });
  }

  link() {
    if (this.state.loading) return;

    const players = this.state.players
      .filter((player) => player.getComponent().isConnected())
      .map((player) => {
        return {
          info: player.getInfo(),
          opponents: player.opponents.map((opponent) => {
            return {
              info: opponent.opponent.getInfo(),
            };
          }),
        };
      });

    if (players.length <= 0) return;

    this.updateBaseState({
      loading: true,
    });
    this.props.handleLockMigrationModal(true);
    this.migration.buttons.linkButton.progress();

    this.props.link(players, (response) => {
      if (response.error == false) {
      }
    });
  }

  unlockMigrationButton() {
    this.updateBaseState({
      loading: false,
    });
    this.props.handleLockMigrationModal(false);
    this.migration.buttons.startButton.start();
  }

  unlockLinkButton() {
    this.updateBaseState({
      loading: false,
    });
    this.props.handleLockMigrationModal(false);
    this.migration.buttons.linkButton.start();
  }

  render() {
    return (
      <MigrationModalComponent
        {...this.state}
        {...this.props}
        onmousedown={this.onmousedown}
        onmousemove={this.onmousemove}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      commonReducer: state.commonReducer,
      userAuthReducer: state.userAuthReducer,
    };
  },
  {
    getAllCommon: HttpRedux.getAllCommon,
    migrate: HttpRedux.migrate,
    link: HttpRedux.link,
  }
)(MigrationModalContainer);
