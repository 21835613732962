import LineComponent from "./line-component";
import GameComponent from "./game-component";
import OpponentComponent from "./opponent";

import { empty } from "common";

class Player {
  lines = [];
  opponents = [];

  constructor(
    id,
    component,
    info,
    data,
    stored,
    line,
    opponent,
    tickle_show_con
  ) {
    this.id = id;
    this.component = component;
    this.info = info;
    this.data = data;
    this.stored = stored;
    this.tickle_show_con = tickle_show_con;
    this.active = true;
    if (!empty(line)) {
      this.createLine(line);
    }
    this.attachOpponent(null, opponent);
  }

  get() {
    return {
      id: this.getId(),
      info: this.getInfo(),
      data: this.getData(),
      stored: this.isStored(),
      line: this.getLine(),
      opponent: this.getOpponent(),
      component: this.getComponent(),
    };
  }

  getBrief() {
    return {
      data: this.getData(),
      info: this.getInfo(),
    };
  }

  getId() {
    return this.id;
  }

  getComponent() {
    return this.component;
  }

  getInfo() {
    return this.info;
  }

  getData() {
    return this.data;
  }

  getOpponents() {
    return this.opponents;
  }

  getLines() {
    return this.lines;
  }

  sameValueAs(player) {
    return player.getData().id === this.getData().id;
  }

  getTickleShowCon() {
    return this.tickle_show_con;
  }

  isStored() {
    return this.stored == true;
  }

  changeComponent(component) {
    if (component instanceof GameComponent === false) {
      throw "Not a valid component!";
    }
    this.component = component;
  }

  changeLines(lines) {
    this.lines = lines;
  }

  changeOpponents(opponents) {
    this.opponents = opponents;
  }

  changeInfo(info) {
    this.info = info;
  }

  changeData(data) {
    this.data = data;
  }

  addLine(line) {
    if (line instanceof LineComponent === false) {
      throw "Not a valid line component!";
    }
    // Do not add new line when there is an open line
    if (this.lines.filter((line) => line.isOpen()).length <= 0) {
      this.lines = this.lines.concat(line);
    }
  }

  changeIsStored(stored) {
    this.stored = stored;
  }

  detachOpponent(base_opponent) {
    if (base_opponent instanceof OpponentComponent === false) {
      throw "Not a valid opponent component";
    }
    this.changeOpponents(
      this.getOpponents().filter(
        (file) => !file.opponent.sameValueAs(base_opponent)
      )
    );
  }

  attachOpponent(line, opponent) {
    opponent = [opponent].filter((o) => {
      if (
        this.opponents.filter((o_) => o_.opponent.sameValueAs(o)).length > 0
      ) {
        return false;
      }
      return true;
    })[0];

    if (!empty(opponent)) {
      // Change line coordinates
      line = this.changeLineCooridnates(line, opponent);

      this.opponents = this.opponents.concat({
        line,
        opponent,
      });
    }
  }

  changeLineCooridnates(line, opponent) {
    line.changeLineToX(opponent.getComponent().getX());
    line.changeLineToY(
      opponent.getComponent().getY() + opponent.getComponent().getHeight() / 2
    );

    line.changeMoveToX(
      this.getComponent().getX() + this.getComponent().getWidth()
    );
    line.changeMoveToY(
      this.getComponent().getY() + this.getComponent().getHeight() / 2
    );

    line.open(false);

    return line;
  }

  isAttached(opponent) {
    return (
      this.getOpponents().filter((file) => file.opponent.sameValueAs(opponent))
        .length > 0
    );
  }

  isActive() {
    return this.active;
  }

  changeActive(active) {
    this.active = active;
  }

  isOpponentActive() {
    return this.getOpponent().isActive();
  }

  isConnectedWithActiveOpponent() {
    return this.getComponent().isConnected() && this.isOpponentActive();
  }

  isNotConnectedButWithLine() {
    return !this.getComponent().isConnected() && !empty(this.getLines());
  }

  isConnected() {
    return this.getComponent().isConnected();
  }

  hasOpenLines() {
    return this.getLines().filter((line) => line.isOpen()).length > 0;
  }

  getOpenLines() {
    return this.getLines().filter((line) => line.isOpen());
  }

  removeOpenLines() {
    this.lines = this.lines.filter((line) => !line.isOpen());
  }
}

export default Player;
