import React, { Component, Fragment } from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const FilterComponent = (props) => {
  return (
    <Fragment>
      <ButtonGroup className="text-right">
        <DropdownButton
          as={ButtonGroup}
          title={"Filter By " + (props?.filter ?? "")}
          id="bg-nested-dropdown"
          variant="default"
        >
          <Dropdown.Item
            eventKey="1"
            onClick={(e) => {
              props.handleFilter("ALL_TO_SHIP");
            }}
            // disabled={props.request}
          >
            ALL To Ship
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            onClick={(e) => {
              props.handleFilter("TO_SHIP_AND_SHIPPED_TODAY");
            }}
            // disabled={props.request}
          >
            To Ship & Shipped Today
          </Dropdown.Item>
        </DropdownButton>
      </ButtonGroup>
    </Fragment>
  );
};

export default FilterComponent;
