const FETCH_SETTINGS = "product/product/fetch-settings";
const LOADING = "product/progress-loading";
const RESPONSE = "product/response";
const SUCCESS = "product/success";
const DESTROY = "product/destroy";
const SELECT_ANCHOR = "product/achor/selected";
const ADD_ANCHOR = "product/achor/add";
const SUBMIT = "product/submit";
const DRAFT = "product/draft";
const CANCEL = "product/cancel";
const UPDATE_FORM = "product/form/update";
const UPDATE_SELLING_FORM = "product/form/selling/update";
const UPDATE_DETAILS_FORM = "product/form/details/update";
const UPDATE_SHIPPING_FORM = "product/form/shipping/update";
const UPDATE_SEO_FORM = "product/form/seo/update";
const LOAD_PRODUCTS = "product/listing";
const PRODUCT_ID = "product/product-id";
const UPDATE_WH_FORM = "product/form/update";
const UPDATE_SELLING = "product/selling/update";
const UPDATE_DETAILS = "product/details/update";
const UPDATE_SHIPPING = "product/shipping/update";
const UPDATE_SEO = "product/seo/update";
const CALLBACK = "product/calllback";

export {
  LOADING,
  SUCCESS,
  RESPONSE,
  FETCH_SETTINGS,
  DESTROY,
  SELECT_ANCHOR,
  ADD_ANCHOR,
  SUBMIT,
  DRAFT,
  CANCEL,
  UPDATE_FORM,
  UPDATE_SELLING_FORM,
  UPDATE_DETAILS_FORM,
  UPDATE_SHIPPING_FORM,
  UPDATE_SEO_FORM,
  LOAD_PRODUCTS,
  PRODUCT_ID,
  UPDATE_WH_FORM,
  UPDATE_SELLING,
  UPDATE_SHIPPING,
  UPDATE_SEO,
  CALLBACK,
};
