export const updateMultipleCampaign = (state, action) => {
  return {
    ...state,
    variation: {
      ...state.variation,
      compose: state.variation.compose.map((row) => {
        if (row.id === action.payload.id) {
          row.data[action.payload.column] = action.payload.value;
        }
        return row;
      }),
    },
  };
};

export const updateSingleCampaign = (state, action) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      [action.payload.column]: action.payload.value,
    },
  };
};

export const addMultipleCampaignError = (state, action) => {
  return {
    ...state,
    variant: {
      ...state.variation,
      compose: state.variation.compose.map((row) => {
        if (row.id === action.payload.id) {
          row.errors.campaign = [];
          row.errors.campaign = action.payload.error;
        }
        return row;
      }),
    },
  };
};

export const addSingleCampaignError = (state, action) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      error: action.payload.error,
    },
  };
};

export const clear = (state, action) => {
  return {
    ...state,
    campaign: {
      sale_price: 0,
      sale_start_at: "",
      sale_end_at: "",
      error: [],
    },
  };
};
