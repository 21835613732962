import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import Emitter from "common/event-emitter";
import * as Http from "../../redux/actions/http";
import * as Action from "../../redux/actions/payload";

import TopLeftComponent from "../views/top-left";

const TopLeftContainer = (props) => {
  const [stocking_saving_loading, setStockingSaveLoading] = useState(false);
  const [
    stocking_for_approval_loading,
    setStockingForApprovalLoading,
  ] = useState(false);
  const _requestSubmitForApproval = () => {
    setStockingForApprovalLoading(true);
    props
      .requestSubmitForApproval({
        template: props.inventory_stocking_reducer.template_selected,
        template_name: props.template_name,
        warehouse: props.warehouse_selected,
      })
      .then((response) => {
        setStockingForApprovalLoading(false);
        props.reloadTemplateList();
        props.changeMessage(response.data.message, !response.data.success);

        setTimeout(() => {
          props.handleTemplateChange(
            props.inventory_stocking_reducer.template_selected.id
          );
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        props.changeMessage(error.toString(), true);
        setStockingForApprovalLoading(false);
      });
  };

  const _requestSaveStockingTemplate = () => {
    if (stocking_saving_loading) return;

    setStockingSaveLoading(true);
    props
      .requestSaveStockingTemplate({
        template: props.inventory_stocking_reducer.template_selected,
        template_name: props.template_name,
        warehouse: props.warehouse_selected,
      })
      .then((response) => {
        setStockingSaveLoading(false);
        props.reloadTemplateList();
        props.changeMessage(response.data.message, !response.data.success);

        setTimeout(() => {
          props.handleTemplateChange(
            props.inventory_stocking_reducer.template_selected.id
          );
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        props.changeMessage(error.toString(), true);
        setStockingSaveLoading(false);
      });
  };

  const requestSaveStockingTemplate = () => {
    try {
      requestSubmissionValidation();
      _requestSaveStockingTemplate();
    } catch (e) {
      console.log(e);
      setStockingSaveLoading(false);
      props.changeMessage(e.toString(), true);
    }
  };

  const requestSubmitForApproval = () => {
    try {
      requestSubmissionValidation();
      _requestSubmitForApproval();
    } catch (e) {
      console.log(e);
      setStockingForApprovalLoading(false);
      props.changeMessage(e.toString(), true);
    }
  };

  const handleChangeWarehouse = (id) => {
    const index = props.warehouse_list.findIndex((f) => f.id == id);
    props.updateState({
      warehouse_selected: props.warehouse_list[index],
    });
  };

  const handleInputChangeTemplate = (name) => {
    props.updateState({
      template_name: name,
    });
  };

  const resetSelectedTemplate = () => {
    props.destroy();
    props.reloadTemplateList();
  };

  const requestSubmissionValidation = () => {
    if (props.isSelectedTemplatePendingApproval()) {
      throw "The selected template is pending approval. You may click refresh button to be updated.";
    }
  };

  useEffect(() => {
    props
      .requestStockingTemplateList()
      .then((response) => {
        props.updateState({
          template_list: response.data.data,
        });
      })
      .catch((error) => {});
  }, [props.reload_template_list]);

  return (
    <TopLeftComponent
      {...props}
      stocking_saving_loading={stocking_saving_loading}
      stocking_for_approval_loading={stocking_for_approval_loading}
      requestSubmitForApproval={requestSubmitForApproval.bind(this)}
      requestSaveStockingTemplate={requestSaveStockingTemplate.bind(this)}
      handleChangeWarehouse={handleChangeWarehouse.bind(this)}
      handleInputChangeTemplate={handleInputChangeTemplate.bind(this)}
      resetSelectedTemplate={resetSelectedTemplate.bind(this)}
    />
  );
};

export default connect(
  (state) => {
    return {
      inventory_stocking_reducer: state.inventoryStockingReducer,
    };
  },
  {
    requestSaveStockingTemplate: Http.requestSaveStockingTemplate,
    requestSubmitForApproval: Http.requestSubmitForApproval,
    requestStockingTemplateList: Http.requestStockingTemplateList,
    changeSelectedStockingTemplate: Action.changeSelectedStockingTemplate,
    destroy: Action.destroy,
  }
)(TopLeftContainer);
