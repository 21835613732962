import React, { Component, Fragment, useRef } from "react";
import Modal from "react-responsive-modal";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./style/canvas.scss";

import AsyncSelect from "react-select/async";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import SelectProductService from "../service/select-product";
import { debounce } from "underscore";
import "./style/gameboard.scss";
import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";

import ReactModal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

export default class SyncModalComponent extends Component {
  render() {
    const {
      sync,
      handleCloseSyncModal,
      handleChangeSyncStatus,
      handleSubmit,
      shops,
      loading,
      shop_store,
    } = this.props;

    const customSelectStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        // color: state.isSelected ? "red" : "blue",
        padding: 10,
      }),
      menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
        borderBottom: "1px dotted pink",
        color: state.selectProps.menuColor,
        padding: 10,
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "none transparent",
      }),
    };

    const closeButton = {
      closeButton: {
        top: "-14px",
        right: "-8px",
      },
      closeIcon: {
        color: "#fff",
        fontSize: "20px",
      },
      modalContainer: {
        width: "400px",
      },
    };

    return (
      <Fragment>
        <Modal
          open={sync.open}
          onClose={handleCloseSyncModal}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          classNames={{
            modal: "sync-style",
          }}
          closeIcon={faTruckLoading}
          styles={closeButton}
          center
        >
          <div style={{ padding: "20px 0 0 0" }} className="modal-body">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th width="3%"></th>
                  <th>Shop</th>
                  <th>Platform</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {shops
                  .filter((shop) => shop.app_key != shop_store)
                  .map((shop) => {
                    return (
                      <tr key={shop.id}>
                        <td>
                          <Form.Check
                            name="check"
                            type="checkbox"
                            checked={shop?.sync ?? false}
                            onChange={(e) => {
                              handleChangeSyncStatus(shop.id, e);
                            }}
                          />
                        </td>
                        <td>{shop.shop_name}</td>
                        <td>{shop.app_name}</td>
                        <td>
                          {shop.loading
                            ? "Sync..."
                            : shop?.failed
                            ? "Failed!"
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <ReactModal.Footer>
              <Button
                disabled={loading}
                variant="primary"
                onClick={(e) => {
                  handleSubmit();
                }}
              >
                {loading ? "Sync..." : "Submit"}
              </Button>
            </ReactModal.Footer>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
