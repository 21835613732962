import * as type from "../constants/index";

export const setLoading = (payload) => {
  return { type: type.LOADING, payload: payload };
};

export const setResponse = (payload) => {
  return { type: type.RESPONSE, payload: payload };
};

export const fetchAll = (payload) => {
  return {
    type: type.FETCH_ALL,
    payload: payload,
  };
};

export const fetch = (payload) => {
  return {
    type: type.FETCH,
    payload: payload,
  };
};

export const choosenCategory = (payload) => {
  return {
    type: type.CHOOSEN,
    payload: payload,
  };
};

export const setUpdateStatus = (payload) => {
  return {
    type: type.UPDATE_STATUS,
    payload: payload,
  };
};
