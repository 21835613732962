import React, { Component, Fragment } from "react";

import Modal from "react-responsive-modal";
import Spinner from "components/common/spinner";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Message_container from "components/common/message-container";
import AsyncSelect from "react-select/async";
import SelectCategoryService from "../../services/select-category";

export default class CategoryFormComponent extends Component {
  render() {
    const {
      loading,
      response,
      open,
      name,
      parent,
      description,
      onCloseModal,
      handleSubmit,
      handleChange,
      handleOptionChange,
      handleInputChange,
    } = this.props;

    const { id: categoryId, name: categoryName } = parent;

    return (
      <Fragment>
        <Modal open={open} onClose={onCloseModal} center>
          <AvForm
            className="needs-validation category-form"
            onValidSubmit={(e) => handleSubmit(e)}
            onInvalidSubmit={this.handleInvalidSubmit}
            style={{ minWidth: 500 + "px" }}
          >
            <div className="modal-header">
              <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                Create Category
              </h5>
            </div>
            <div className="modal-body">
              <Message_container
                message={response?.message ?? ""}
                error={response?.error ?? false}
              />
              <div className="form-group row">
                <div className="col-xl-6 col-md-6">
                  <AvField
                    value={name}
                    label="Category Name"
                    onChange={handleChange}
                    className="form-control"
                    name="name"
                    id="name"
                    type="text"
                    required
                  />
                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="form-group">
                    <label>Parent Category</label>
                    <AsyncSelect
                      value={{ value: categoryId, label: categoryName }}
                      isClearable={true}
                      onChange={handleOptionChange}
                      cacheOptions
                      loadOptions={SelectCategoryService}
                      defaultOptions
                      onInputChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xl-12 col-md-12">
                  <AvField
                    style={{ minHeight: 200 + "px" }}
                    value={description}
                    label="Category Description"
                    onChange={handleChange}
                    className="form-control"
                    name="description"
                    id="description"
                    type="textarea"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => onCloseModal()}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                {loading === true ? <Spinner loading={true} /> : ""}Save
              </button>
            </div>
          </AvForm>
        </Modal>
      </Fragment>
    );
  }
}
