const FETCH_ALL = "user/permission/role/fetch-all";
const FORM_STATUS = 'user/permission/role/form-status';
const LOADING = 'user/permission/role/progress-loading';
const RESPONSE = 'user/permission/role/response';

export {
	FORM_STATUS,
	LOADING,
	RESPONSE,
	FETCH_ALL	
};
