import React, { Component, Fragment } from 'react'

import { AvField, AvForm } from 'availity-reactstrap-validation';

import { connect } from "react-redux";
import * as Action_group_permission from "redux/actions/user/group-permission/index";

import Spinner from "components/common/spinner";
import Message_container from "components/common/message-container";

export class Create_action_group extends Component {

    state = {
        actions: [],
        description: '',
        clear: false
    }

    constructor(props) {
        super(props);
        this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate = (prevProps) => {
        if (this.state.clear == true) return;
        if (prevProps.actionGroup.response?.message != '') {
            if (prevProps.actionGroup.response?.error == false) {
                this.setState({
                    name: '',
                    description: '',
                    actions: [],
                    clear: true
                });
            }
        }
    }
    
    handleSubmit(e) {
        this.setState({
            clear: false
        })
        this.props.createActionGroup(this.state);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                       <AvForm className="needs-validation action-group-form" onValidSubmit={(e) => this.handleSubmit(e)} onInvalidSubmit={this.handleInvalidSubmit}>
                            <Message_container 
                                message = {this.props.actionGroup.response?.message ?? ''} 
                                error = {this.props.actionGroup.response?.error ?? false}
                            />
                            <div className="form-group row">
                                <div className="col-xl-12 col-md-12">
                                    <AvField value={this.state.name} label="Action Group Name" onChange={this.handleChange} className="form-control" name="name" id="name" type="text" required />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-xl-12 col-md-12">
                                    <AvField value={this.state.description} label="Action Group Description" onChange={this.handleChange} className="form-control" name="description" id="description" type="textarea" />
                                </div>
                            </div>
                            <div className="pull-right">
                                <button type="submit" className="btn btn-primary">
                                    <Spinner loading={this.props.actionGroup.loading} />Save
                                </button>
                            </div>
                        </AvForm>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        actionGroup: state.actionGroupPermission
    };
}


export default connect(
    mapStateToProps,
    {
        createActionGroup:  Action_group_permission.createActionGroup
    }
)(Create_action_group);
