import React, { Component, Fragment, useState } from "react";

import {
  Navigation,
  Box,
  MessageSquare,
  Users,
  Briefcase,
  CreditCard,
  ShoppingCart,
  Calendar,
} from "react-feather";
import CountUp from "react-countup";

const SummaryWidgetComponent = (props) => {
  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-xl-3 col-md-6 xl-50">
            <div className="card o-hidden widget-cards">
              <div className="bg-warning card-body">
                <div className="media static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <Navigation className="font-warning" />
                    </div>
                  </div>
                  <div className="media-body col-8">
                    <span className="m-0">Sales</span>
                    <h4 className="mb-0">
                      {props.daily_sales_summary?.sales?.currency ?? ""}{" "}
                      <CountUp
                        className="counter"
                        end={props.daily_sales_summary?.sales?.value ?? 0}
                        separator=","
                      />
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 xl-50">
            <div className="card o-hidden  widget-cards">
              <div className="bg-secondary card-body">
                <div className="media static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <Box className="font-secondary" />
                    </div>
                  </div>
                  <div className="media-body col-8">
                    <span className="m-0">Revenue</span>
                    <h4 className="mb-0">
                      {props.daily_sales_summary?.revenue?.currency ?? ""}{" "}
                      <CountUp
                        className="counter"
                        end={props.daily_sales_summary?.revenue?.value ?? 0}
                        separator=","
                      />
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 xl-50">
            <div className="card o-hidden  widget-cards">
              <div className="bg-info card-body">
                <div className="media static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <Box className="font-secondary" />
                    </div>
                  </div>
                  <div className="media-body col-8">
                    <span className="m-0">Cost</span>
                    <h4 className="mb-0">
                      {props.daily_sales_summary?.cost?.currency ?? ""}{" "}
                      <CountUp
                        className="counter"
                        end={props.daily_sales_summary?.cost?.value ?? 0}
                        separator=","
                      />
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 xl-50">
            <div className="card o-hidden widget-cards">
              <div className="bg-primary card-body">
                <div className="media static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <MessageSquare className="font-primary" />
                    </div>
                  </div>
                  <div className="media-body col-8">
                    <span className="m-0">Orders / Units</span>
                    <h4 className="mb-0">
                      <CountUp
                        className="counter"
                        end={props.daily_sales_summary?.orders ?? 0}
                        separator=","
                      />{" "}
                      /{" "}
                      <CountUp
                        className="counter"
                        end={props.daily_sales_summary?.units ?? 0}
                        separator=","
                      />
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SummaryWidgetComponent;
