import React, { Component, Fragment } from "react";

import Emitter from "common/event-emitter";
import { empty, isNumber } from "common";

import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import * as Payload from "../../redux/actions/payload";

import ModifyStockTableComponent from "../views/modify-stock-table";

class ModifyStockTableContainer extends Component {
  state = {
    modalStatus: false,
    inventory_id: "",
    selected: null,
    templates: [],
  };

  constructor() {
    super();
    this.renderMainEditable = this.renderMainEditable.bind(this);
    this.renderVariantEditable = this.renderVariantEditable.bind(this);
    this.handleTemplateSelect = this.handleTemplateSelect.bind(this);
    this.handleRemoveTemplateBrand = this.handleRemoveTemplateBrand.bind(this);
    this.handleRemoveTemplateBrandItem = this.handleRemoveTemplateBrandItem.bind(
      this
    );
    this.handleRemoveTemplateBrandItemVariation = this.handleRemoveTemplateBrandItemVariation.bind(
      this
    );
    this.handleAllocationTypeChange = this.handleAllocationTypeChange.bind(
      this
    );
  }

  componentWillUnmount() {
    this.props.destroy();
    Emitter.off("REFRESH_STOCKING_TEMPLATE_LIST");
  }

  componentDidMount() {
    this.loadTemplateList();
    Emitter.on("REFRESH_STOCKING_TEMPLATE_LIST", (state) => {
      this.loadTemplateList(state.template_id);
    });
  }

  loadTemplateList(template_id) {
    this.props.requestAllStockingTemplateList(
      (response) => {
        this.setState({
          templates: response.data.data,
        });
        setTimeout(() => {
          // this.handleTemplateSelect(template_id);
        }, 2000);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  componentDidUpdate() {}

  handleTemplateSelect(template_id) {
    const template = this.state.templates.reduce((c, template) => {
      if (template.id == template_id) {
        c = template;
      }
      return c;
    }, []);

    this.props.loadStockingTemplate(
      { id: template.id, name: template.name },
      template.warehouse,
      template.brands
    );
  }

  handleRemoveTemplateBrand(brand_id) {
    this.props.removeTemplateBrand(brand_id);
  }

  handleRemoveTemplateBrandItem(brand_id, item_id) {
    this.props.removeTemplateBrandItem(brand_id, item_id);
  }

  handleRemoveTemplateBrandItemVariation(brand_id, item_id, variant_id) {
    this.props.removeTemplateBrandItemVariation(brand_id, item_id, variant_id);
  }

  renderMainEditable(cellInfo) {
    if (cellInfo.original.has_variation) {
      return <></>;
    }

    return (
      <div
        style={{ backgroundColor: "#F0ECE3" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          if (!isNumber(e.target.innerHTML)) {
            return;
          }
          this.props.updateMainStock(
            cellInfo.column.brand_id,
            cellInfo.original.id,
            parseInt(e.target.innerHTML)
          );
        }}
        dangerouslySetInnerHTML={{
          __html: (this.props.inventoryReducer.template.brands ?? []).reduce(
            (c, brand) => {
              if (brand.id == cellInfo.column.brand_id) {
                c = brand.items.reduce((c, item) => {
                  if (item.id == cellInfo.original.id) {
                    c = item.stock;
                  }
                  return c;
                }, 0);
              }

              return c;
            },
            0
          ),
        }}
      />
    );
  }

  renderVariantEditable(cellInfo) {
    return (
      <div
        style={{ backgroundColor: "#F0ECE3" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          if (!isNumber(e.target.innerHTML)) {
            return;
          }
          this.props.updateVariantStock(
            cellInfo.column.brand_id,
            cellInfo.column.item_id,
            cellInfo.original.id,
            parseInt(e.target.innerHTML)
          );
        }}
        dangerouslySetInnerHTML={{
          __html: (this.props.inventoryReducer.template?.brands ?? []).reduce(
            (c, brand) => {
              if (brand.id == cellInfo.column.brand_id) {
                c = brand.items.reduce((c, item) => {
                  if (item.id == cellInfo.column.item_id) {
                    c = item.variation.compose.reduce((c, compose) => {
                      if (compose.id == cellInfo.original.id) {
                        c = compose.stock;
                      }
                      return c;
                    }, 0);
                  }
                  return c;
                }, 0);
              }

              return c;
            },
            0
          ),
        }}
      />
    );
  }

  handleAllocationTypeChange(action, allocation_type, value) {
    this.props.changeGlobalAllocationType(action, allocation_type, value);
  }

  render() {
    return (
      <Fragment>
        <ModifyStockTableComponent
          {...this.state}
          {...this.props}
          handleTemplateSelect={this.handleTemplateSelect}
          handleRemoveTemplateBrand={this.handleRemoveTemplateBrand}
          handleRemoveTemplateBrandItem={this.handleRemoveTemplateBrandItem}
          handleRemoveTemplateBrandItemVariation={
            this.handleRemoveTemplateBrandItemVariation
          }
          inventory_reducer={this.props.inventoryReducer}
          renderMainEditable={this.renderMainEditable}
          renderVariantEditable={this.renderVariantEditable}
          handleAllocationTypeChange={this.handleAllocationTypeChange}
        />
      </Fragment>
    );
  }
}

export default connect(
  (state) => {
    return {
      inventoryReducer: state.inventoryReducer,
    };
  },
  {
    requestBrandItemList: Http.requestBrandItemList,

    destroy: Payload.destroy,
    updateMainStock: Payload.updateMainStock,
    updateVariantStock: Payload.updateVariantStock,
    requestAllStockingTemplateList: Http.requestAllStockingTemplateList,
    loadStockingTemplate: Payload.loadStockingTemplate,
    removeTemplateBrand: Payload.removeTemplateBrand,
    removeTemplateBrandItem: Payload.removeTemplateBrandItem,
    removeTemplateBrandItemVariation: Payload.removeTemplateBrandItemVariation,
    changeGlobalAllocationType: Payload.changeGlobalAllocationType,
  }
)(ModifyStockTableContainer);
