import React from "react";
import PrivateRoute from "private-route";
import CreateInStoreOrderContainerV2 from "modules/order/in-store/componentsv2/container/create-order";

import OrderHeaderContainer from "../container/checkout/header";

class Container extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <OrderHeaderContainer />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/orders/in-store/create-order/:page`}
          component={CreateInStoreOrderContainerV2}
        />
      </>
    );
  }
}

export default Container;
