import React, { Component, Fragment } from "react";
import Modal from "react-responsive-modal";
import Spinner from "components/common/spinner";
import { AvField, AvForm } from "availity-reactstrap-validation";

export default class FormComponent extends Component {
  render() {
    const {
      open,
      onCloseModal,
      handleSubmit,
      handleInvalidSubmit,
      handleChange,
      loading,
      response,
      name,
      address,
      website,
      contact_name,
      contact_no,
      viber,
      messenger,
    } = this.props;

    return (
      <Fragment>
        <AvForm
          className="needs-validation category-form"
          onValidSubmit={handleSubmit.bind(this)}
          onInvalidSubmit={this.handleInvalidSubmit}
          style={{ minWidth: 400 + "px" }}
        >
          <div className="form-group row">
            <div className="col-xl-12 col-md-12">
              <AvField
                value={name}
                label="Name"
                onChange={handleChange}
                className="form-control"
                name="name"
                id="name"
                type="text"
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xl-12 col-md-12">
              <AvField
                style={{ minHeight: 100 + "px" }}
                value={address}
                label="Address"
                onChange={handleChange}
                className="form-control"
                name="address"
                type="textarea"
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xl-12 col-md-12">
              <AvField
                value={contact_name}
                label="Contact Name"
                onChange={handleChange}
                className="form-control"
                name="contact_name"
                type="text"
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xl-12 col-md-12">
              <AvField
                value={contact_no}
                label="Contact No."
                onChange={handleChange}
                className="form-control"
                name="contact_no"
                type="text"
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xl-12 col-md-12">
              <AvField
                style={{ minHeight: 100 + "px" }}
                value={website}
                label="Website"
                onChange={handleChange}
                className="form-control"
                name="website"
                type="textarea"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-xl-12 col-md-12">
              <AvField
                value={viber}
                label="Viber"
                onChange={handleChange}
                className="form-control"
                name="viber"
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xl-12 col-md-12">
              <AvField
                value={messenger}
                label="Messenger"
                onChange={handleChange}
                className="form-control"
                name="messenger"
                type="text"
              />
            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              {loading === true ? <Spinner loading={true} /> : ""}Save
            </button>
          </div>
        </AvForm>
      </Fragment>
    );
  }
}
