import React, { Component } from "react";
import ButtonWholesaleVariationsView from "../views/button-wholesale-variations";
import { connect } from "react-redux";
import * as Product from "../../redux/actions";

class ButtonWholesaleVariationsContainer extends Component {
  handleEnableVariation = () => {
    this.props.clearVariation();
    this.props.enableVariation(
      this.props.variation.enabled === true ? false : true
    );
  };

  handleEnableWholesale = () => {
    this.props.clearWholesale();
    this.props.enableWholesale(
      this.props.wholesale.enabled === true ? false : true
    );
  };

  render() {
    const {
      variation: { enabled: variationEnabled },
      wholesale: { enabled: wholesaleEnabled },
    } = this.props;
    return (
      <ButtonWholesaleVariationsView
        handleEnableVariation={this.handleEnableVariation}
        handleEnableWholesale={this.handleEnableWholesale}
        variationEnabled={variationEnabled}
        wholesaleEnabled={wholesaleEnabled}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      variation: state.productReducer.variation,
      wholesale: state.productReducer.wholesale,
    };
  },
  {
    enableVariation: Product.variation.enableVariation,
    enableWholesale: Product.wholesale.enableWholesale,
    clearVariation: Product.variation.clear,
    clearWholesale: Product.wholesale.clear,
  }
)(ButtonWholesaleVariationsContainer);
