import React, { Component, Fragment } from "react";
import StockingSummaryModalComponent from "../views/stocking-summary-modal";
import Emitter from "common/event-emitter";
import * as toast from "components/common/toast";
import { empty } from "common";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";

class StockingSummaryModalContainer extends Component {
  constructor() {
    super();
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose() {
    this.props.handleSummaryModalClose();
  }

  render() {
    return (
      <StockingSummaryModalComponent
        {...this.state}
        {...this.props}
        template={this.props.inventoryReducer.template}
        handleModalClose={this.handleModalClose}
      />
    );
  }
}

export default connect((state) => {
  return {
    inventoryReducer: state.inventoryReducer,
  };
})(StockingSummaryModalContainer);
