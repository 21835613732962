import Http from "api/http";
import * as payload from "redux/actions/user/group-permission/payload";
import * as common from "redux/actions/user/group-permission/common";

export const getAllActionGroupRequest = () => (dispatch) => {
  return Http.GET("permission/action-group/getAllActionGroup")
    .then(function (response) {
      if (response.data.success === false) {
        return;
      }
      dispatch(payload.loadActionGroup(response.data.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getAllActionGroup = () => (dispatch) => {
  return function (dispatch) {
    return dispatch(getAllActionGroupRequest());
  };
};

export const updateActionGroup = (data: actionGroup) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PATCH("permission/action-group/update", { payload: data })
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllActionGroupRequest());
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error) {
      dispatch(common.setError(error.message));
    });
};

export const createActionGroup = (data: actionGroup) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PUT("permission/action-group/create", data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllActionGroupRequest());
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error) {
      dispatch(common.setError(error.message));
    });
};
