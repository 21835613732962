import React, { useState, useEffect } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import UpdateShopContainer from "../container/update-shop";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDumpster,
  faDumpsterFire,
  faBoxes,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";

const LoadingContainer = () => {
  return (
    <>
      <Spinner
        animation="border"
        variant="primary"
        size="lg"
        role="status"
        style={{
          width: "0.8rem",
          height: "0.8rem",
        }}
      ></Spinner>{" "}
    </>
  );
};

const SuccessContainer = () => {
  return (
    <>
      <FontAwesomeIcon icon={faCheck} size={"lg"} />{" "}
    </>
  );
};

const FailedContainer = () => {
  return (
    <>
      <FontAwesomeIcon icon={faTimes} size={"lg"} />{" "}
    </>
  );
};

const DataTableComponent = (props) => {
  return (
    <div>
      <UpdateShopContainer
        shopId={props.shopId}
        open={props.modal}
        onOpenModal={(e) => {
          props.onOpenModal(e);
        }}
        onCloseModal={(e) => {
          props.onCloseModal(e);
        }}
        {...props.edit}
      />
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"Actions"}</b>,
            sortable: false,
            filterable: false,
            width: 300,
            style: {
              textAlign: "center",
              width: "100%",
            },
            Cell: (row) => (
              <div>
                <span
                  onClick={(e) => {
                    props.syncShopItems(row.original.id);
                  }}
                  className="btn btn-sm btn-success mr-1"
                  title="Sync Items"
                >
                  {row.original?.progress?.sync_item == true && (
                    <LoadingContainer />
                  )}
                  {row.original?.progress?.sync_item == "s" && (
                    <SuccessContainer />
                  )}
                  {row.original?.progress?.sync_item == "f" && (
                    <FailedContainer />
                  )}
                  <FontAwesomeIcon icon={faBoxes} size={"lg"} />
                </span>

                <span
                  onClick={(e) => {
                    props.generateDailyOrders(row.original.id);
                  }}
                  className="btn btn-sm btn-primary mr-1"
                  title="Generate Orders"
                >
                  {row.original?.progress?.generate_daily_orders == true && (
                    <LoadingContainer />
                  )}
                  {row.original?.progress?.generate_daily_orders == "s" && (
                    <SuccessContainer />
                  )}
                  {row.original?.progress?.generate_daily_orders == "f" && (
                    <FailedContainer />
                  )}
                  <FontAwesomeIcon icon={faDumpster} size={"lg"} />
                </span>

                <span
                  onClick={(e) => {
                    props.updateDailyOrderStatus(row.original.id);
                  }}
                  className="btn btn-sm btn-warning mr-1"
                  title="Updates Orders Status"
                >
                  {row.original?.progress?.update_daily_orders_status ==
                    true && <LoadingContainer />}
                  {row.original?.progress?.update_daily_orders_status ==
                    "s" && <SuccessContainer />}
                  {row.original?.progress?.update_daily_orders_status ==
                    "f" && <FailedContainer />}
                  <FontAwesomeIcon icon={faDumpsterFire} size={"lg"} />
                </span>
              </div>
            ),
          },
          {
            Header: <b>{"Name"}</b>,
            accessor: "shop_name",
            Cell: (row) => (
              <>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    props.editShop(e, row.original.id);
                  }}
                >
                  {row.original.shop_name}{" "}
                </a>
              </>
            ),
          },
          {
            Header: <b>{"App"}</b>,
            accessor: "app_name",
            filterable: false,
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Shop id"}</b>,
            accessor: "shop_id",
            filterable: false,
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Connect"}</b>,
            accessor: "connect",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) => (
              <div>
                <a
                  href={row.original.authorization_url}
                  className={row.original.connected_at ? "text-success" : ""}
                >
                  <i className="fa fa-connectdevelop"></i>
                </a>
              </div>
            ),
          },
          {
            Header: (
              <button
                onClick={(e) => {
                  props.removeShop(e);
                }}
                className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
              >
                Delete
              </button>
            ),
            accessor: "delete",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) => (
              <div>
                <span>
                  <input
                    defaultChecked={props.selectedId.includes(row.original.id)}
                    onChange={(e) => {
                      props.selected(e, row.original.id);
                    }}
                    type="checkbox"
                    className="checkbox_animated"
                  />
                </span>
              </div>
            ),
          },
        ]}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={props.datatable.data}
        pages={props.datatable.pages} // Display the total number of pages
        loading={props.loading} // Display the loading overlay when we need it
        onFetchData={props.fetchShop} // Request new data when things change
        filterable
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
};

export default DataTableComponent;
