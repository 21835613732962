import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import * as CategoryAction from "../../redux/actions/index";
import CategoryFormComponent from "../views/form-category";

class CreateCategoryContainer extends Component {
  state = {
    clear: false,
    name: "",
    description: "",
    parent: { id: 0, name: "" },
    inputValue: "",
  };

  constructor(props) {
    super(props);
    this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    if (this.state.clear == true) return;
    if (prevProps.category.response?.message != "") {
      if (prevProps.category.response?.error == false) {
        this.setState({
          name: "",
          description: "",
          inputValue: "",
          parent: [],
          clear: true,
        });
      }
    }
  };

  handleSubmit(e) {
    this.setState({
      clear: false,
    });
    this.props.createCategory(this.state);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    return inputValue;
  };

  handleOptionChange = (value) => {
    if (value === null || value === undefined) {
      this.setState({
        parent: { id: "", name: "" },
      });
      return;
    }

    this.setState({
      parent: { id: value.value, name: value.label },
    });
  };

  render() {
    const { loading, response } = this.props.category;
    const { open, onCloseModal } = this.props;

    return (
      <CategoryFormComponent
        loading={loading}
        response={response}
        open={open}
        onCloseModal={onCloseModal}
        handleOptionChange={this.handleOptionChange.bind(this)}
        handleInputChange={this.handleInputChange.bind(this)}
        handleSubmit={this.handleSubmit.bind(this)}
        handleChange={this.handleChange.bind(this)}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    category: state.category,
  };
}

export default connect(mapStateToProps, {
  createCategory: CategoryAction.createCategory,
  getAll: (criteria, per_page, page) =>
    CategoryAction.getAllRequest(criteria, per_page, page),
  choosenCategory: (d) => CategoryAction.chooseCategoryRequest(d),
})(CreateCategoryContainer);
