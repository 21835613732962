import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import _debounce from "lodash.debounce";
import { empty } from "common";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Navigation } from "react-feather";

const ApprovalListDatatableComponent = (props) => {
  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"View"}</b>,
            sortable: false,
            filterable: false,
            width: 150,
            Cell: (row) => (
              <Link
                to={"approval/" + row.original.id}
                className="btn btn-xs btn-secondary mr-1"
                title="Approval Action"
              >
                <Navigation />
              </Link>
            ),
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Approver"}</b>,
            accessor: "approval.approver_user.name",
            sortable: false,
            filterable: false,

            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Template"}</b>,
            accessor: "name",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "left",
            },
          },
          {
            Header: <b>{"Warehouse"}</b>,
            accessor: "warehouse.name",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "left",
            },
          },
          {
            Header: <b>{"Total Brand Count"}</b>,
            accessor: "brand_count",
            sortable: false,
            filterable: false,
            Cell: (row) => row.original.brands.length,
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Status"}</b>,
            accessor: "approval_status.name",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
          },
        ]}
        manual
        data={props.data}
        pages={props.pages} // Display the total number of pages
        loading={props.loading} // Display the loading overlay when we need it
        onFetchData={_debounce(props.fetchList, 300)} // Request new data when things change
        pageSizeOptions={[20, 50]}
        freezeWhenExpanded={true}
        filterable
        filterable
        defaultPageSize={20}
        collapseOnDataChange={false}
        collapseOnPageChange={false}
        className="-striped -highlight"
      />
    </div>
  );
};

export default ApprovalListDatatableComponent;
