import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "redux/actions/user/user-auth";
//images import
import man from "../../../assets/images/dashboard/man.png";
export class User_menu extends Component {
  constructor(props) {
    super(props);
    this.logout.bind(this);
  }
  logout() {
    this.props.logout();
  }
  render() {
    return (
      <Fragment>
        <li className="onhover-dropdown">
          <div className="media align-items-center">
            <img
              className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
              src={man}
              alt="header-user"
            />
            <div className="dotted-animation">
              <span className="animate-circle"></span>
              <span className="main-circle"></span>
            </div>
          </div>
          <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <li>
              <Link to={`${process.env.PUBLIC_URL}/settings/profile`}>
                <i data-feather="user"></i>Edit Profile
              </Link>
            </li>
            <li>
              <a href="#">
                <i data-feather="mail"></i>Inbox
              </a>
            </li>
            <li>
              <a href="#">
                <i data-feather="lock"></i>Lock Screen
              </a>
            </li>
            <li>
              <a href="#">
                <i data-feather="settings"></i>Settings
              </a>
            </li>
            <li>
              <a href="#" onClick={(e) => this.logout(e)}>
                <i data-feather="log-out"></i>Logout
              </a>
            </li>
          </ul>
        </li>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userAuth: state.userAuth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logout,
    },
    dispatch
  );
}

let User_menuRedux = connect(mapStateToProps, mapDispatchToProps)(User_menu);

export default User_menuRedux;
