import * as type from "redux/constants/user-auth";
import Auth from "common/auth";

const initialState = {
  session: {
    access_token: "",
    token_type: "",
    expires_in: "",
  },
  credentials: {
    error: false,
    message: "",
  },
  loading: false,
  is_loggedin: false,
};

export default function User_auth(state = initialState, action) {
  switch (action.type) {
    case type.UPDATE_SESSION:
      const login_at = new Date();
      const current_date = new Date();
      current_date.setSeconds(
        current_date.getSeconds() + parseInt(action.payload.expires_in)
      );

      const session = {
        access_token: action.payload.access_token,
        // token_type: action.payload.token_type,
        // expires_in: action.payload.expires_in,
        login_at: login_at,
        // expiration_due: current_date,
        user: action.payload.user,
        sid: action.payload.sid,
      };
      const is_loggedin = true;
      Auth.setSession(session);

      return { ...state, session, is_loggedin };
      break;
    case type.CHANGE_USER_SESSION:
      return {
        ...state,
        session: {
          ...state.session,
          user: action.payload,
        },
      };
      break;
    case type.LOGOUT:
      console.log(initialState);
      return (state = initialState);
      break;
    case type.INVALID_CREDENTIALS:
      var credentials = {
        error: true,
        message: action.payload,
      };
      return { ...state, credentials };
      break;
    case type.VALID_CREDENTIALS:
      var credentials = {
        error: false,
        message: "",
      };
      return { ...state, credentials };
      break;
    case type.LOADING:
      const loading = action.payload;
      return { ...state, loading };
      break;
  }

  return state;
}
