import React, { Fragment } from "react";

import Table from "react-bootstrap/Table";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { empty } from "common";
import ReactTabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AffectedOrderListTabComponent from "./affected-order-list-tab";
import ExemptedOrderListTabComponent from "./exempted-order-list-tab";

export default function AffectedOrderListComponent(props) {
  return (
    <>
      <ReactTabs
        defaultActiveKey="order-list-all"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="order-list-all" title="Order List All">
          <AffectedOrderListTabComponent
            orders={props.orders}
            select={props.select}
            selectAll={props.selectAll}
            isAllSelected={props.isAllSelected}
          />
        </Tab>
        <Tab eventKey="exempted-orders-list" title="Exempted Order List">
          <ExemptedOrderListTabComponent
            orders={props.exempted_orders}
            remove={props.remove}
            removeAll={props.removeAll}
          />
        </Tab>
      </ReactTabs>
    </>
  );
}
