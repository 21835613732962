import * as type from "../constants";

export const destroy = () => {
  return {
    type: type.DESTROY,
  };
};

export const changeSelectedStockingTemplate = (template) => {
  return {
    type: type.CHANGE_SEL_STOCKING_TEMPLATE,
    payload: template,
  };
};

export const changeHeaderAllocationType = (allocation_type) => {
  return {
    type: type.CHANGE_HEADER_ALLOCATION_TYPE,
    payload: allocation_type,
  };
};

export const changeItemAllocationType = (allocation_type, item) => {
  return {
    type: type.CHANGE_ITEM_ALLOCATION_TYPE,
    payload: { allocation_type, item },
  };
};

export const changeItemVariantAllocationType = (
  allocation_type,
  item,
  variant
) => {
  return {
    type: type.CHANGE_ITEM_VARIANT_ALLOCATION_TYPE,
    payload: { allocation_type, item, variant },
  };
};

export const removeSelectedTemplateBrand = (brand_id) => {
  return {
    type: type.REMOVE_SELECTED_TEMPLATE_BRAND,
    payload: brand_id,
  };
};

export const removeSelectedTemplateBrandItem = (brand_id, item_id) => {
  return {
    type: type.REMOVE_SELECTED_TEMPLATE_BRAND_ITEM,
    payload: { brand_id, item_id },
  };
};

export const removeSelectedTemplateBrandItemVariation = (
  brand_id,
  item_id,
  variant_id
) => {
  return {
    type: type.REMOVE_SELECTED_TEMPLATE_BRAND_ITEM_VARIATION,
    payload: { brand_id, item_id, variant_id },
  };
};

export const addBrandToSelectedTemplate = (brand) => {
  return {
    type: type.ADD_BRAND_SELECTED_TEMPLATE,
    payload: brand,
  };
};

export const addBrandItemToSelectedTemplate = (brand, item) => {
  return {
    type: type.ADD_BRAND_ITEM_SELECTED_TEMPLATE,
    payload: { brand, item },
  };
};

export const addBrandItemVariantToSelectedTemplate = (brand, item, variant) => {
  return {
    type: type.ADD_BRAND_ITEM_VARIANT_SELECTED_TEMPLATE,
    payload: { brand, item, variant },
  };
};

export const changeItemStock = (brand_id, item_id, stock) => {
  return {
    type: type.CHANGE_ITEM_STOCK,
    payload: { brand_id, item_id, stock },
  };
};

export const changeItemVariantStock = (
  brand_id,
  item_id,
  compose_id,
  stock
) => {
  return {
    type: type.CHANGE_ITEM_VARIANT_STOCK,
    payload: { brand_id, item_id, compose_id, stock },
  };
};

export const resetSelectedTemplateItemVariantStock = () => {
  return {
    type: type.RESET_SELECTED_TEMPLATE_ITEM_VARIANT_STOCK,
  };
};
