import React, { Component, Fragment, Suspense } from "react";

import { empty } from "common";
import moment from "moment";
import * as toast from "components/common/toast";
import { Map } from "immutable";

export default function PurchaseOrder(ParentComponent) {
  return class extends React.Component {
    state = {
      supplier: "",
      payment_term: [],
      payment_mode: [],
      status: "Pending",
      remarks: "",
      title: "",
      error: false,
      message: "",
      statuses: [],
      items: [],
      payment_terms: [{ id: "", name: "Select" }],
      payment_modes: [{ id: "", name: "Select" }],
      delivery_statuses: [{ id: "", name: "Select" }],
      delivery_status: [],
      payment_statuses: [{ id: "", name: "Select" }],
      payment_status: [],
      categories: [{ id: "", name: "Select" }],
      brands: [{ id: "", name: "Select" }],
      save_loading: false,
      units: [{ id: "", name: "Select" }],
      supplier_invalid: false,
      order_date: moment()._d,
      modal: {
        editable_item_form: {
          open: false,
          loading: false,
          data: [],
        },
      },
    };

    constructor(props) {
      super(props);

      this.form_ref = React.createRef();
    }

    updateState(callback) {
      this.setState(callback);
    }

    clearState() {
      this.setState({
        supplier: "",
        payment_term: "",
        order_date: "",
        status: "Pending",
        remarks: "",
        title: "",
        error: false,
        message: "",
        payment_mode: [],
        payment_status: [],
        delivery_status: [],
        items: [],
      });
    }

    getStateData() {
      const data = Map(this.state).toJS();

      delete data.brands;
      delete data.categories;
      delete data.delivery_statuses;
      delete data.payment_modes;
      delete data.payment_terms;

      return {
        ...data,
      };
    }

    validateComplete() {
      if (this.isDeliveryCompleted()) {
        throw "Purchase order is already completed.";
      }
    }

    async validation() {
      var error = false;
      var supplier_invalid = false;
      var order_date_invald = false;
      const error_message = "This is required.";

      this.updateState({
        supplier_invalid: false,
        order_date_invald: false,
      });

      return new Promise((resolve, reject) => {
        this.updateState((state) => {
          // Form validation
          if (empty(state.supplier)) {
            supplier_invalid = true;
          }
          if (empty(state.order_date)) {
            order_date_invald = true;
          }

          if (order_date_invald || supplier_invalid) {
            reject({
              order_date_invald,
              supplier_invalid,
              message: "Please complete all form fields.",
            });
          }

          if (state.items.length <= 0) {
            reject({ message: "Please add atleast 1 item." });
          }

          const items = state.items.map((row) => {
            row.item_error_message = null;
            row.unit_error_message = null;
            row.quantity_error_message = null;
            row.price_error_message = null;

            if (empty(row.item)) {
              error = true;
              row.item_error_message = error_message;
            }
            if (empty(row.unit) || (row.unit?.id ?? "") == "") {
              error = true;
              row.unit_error_message = error_message;
            }
            if (empty(row.quantity)) {
              error = true;
              row.quantity_error_message = error_message;
            }

            if ((empty(row.price) && !row?.is_free) || row.price <= 0) {
              error = true;
              row.price_error_message = error_message;
            }

            return row;
          });

          if (error) {
            reject({
              items,
              message:
                "Please correct item fields." +
                state.items
                  .reduce((c, row) => {
                    if (empty(row.item)) {
                      c.push("item");
                    }
                    if (empty(row.unit)) {
                      c.push("unit");
                    }
                    if (empty(row.quantity)) {
                      c.push("quantity");
                    }
                    if ((empty(row.price) && !row?.is_free) || row.price <= 0) {
                      c.push("price");
                    }
                    return c;
                  }, [])
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .join(", "),
            });
          }

          resolve(true);
        });
      });
    }

    componentDidMount() {
      // Retrieve common data if the parent component doesn't use this method
    }

    retrieveAllCommonData() {
      this.updateState({
        order_data_loading: true,
      });
      return new Promise((resolve, reject) => {
        this.props.getAllCommon(
          (response) => {
            const data = response.data;
            if (data.success === false) {
              return;
            }
            resolve({
              payment_terms: this.state.payment_terms.concat(
                data.payment_terms.data
              ),
              payment_statuses: this.state.payment_statuses.concat(
                data.payment_statuses.data
              ),
              delivery_statuses: this.state.delivery_statuses.concat(
                data.delivery_statuses.data
              ),
              payment_modes: this.state.payment_modes.concat(
                data.payment_modes.data
              ),
              // categories: this.state.categories.concat(data.categories.data),
              statuses: data.statuses,
              units: this.state.units.concat(data.units.data),
              // brands: this.state.brands.concat(data.brands.data),
            });
          },
          (error) => {
            console.log(error);
          }
        );
      });
    }

    beforeSubmitValidation(event) {}

    handleSubmitOrder(e) {
      e.preventDefault();
      try {
        this.validation()
          .then((e) => {
            this.form_ref.current.submit();
          })
          .catch((error) => {
            console.log(error.message);
            toast.error(error.message);
            delete error.message;
            if (error)
              this.updateState({
                ...error,
              });
          });
      } catch (error) {
        console.log(error);
        toast.error(error);
      }
    }

    handleInputChange(name, value) {
      this.setState({
        [name]: value,
      });
    }

    handleSupplierSearchOptionChange(value) {
      this.setState({
        supplier: value,
      });
    }

    isDeliveryCompleted() {
      return (
        this.state.delivery_statuses.filter(
          (status) => status.name.toLowerCase() == "completed"
        )[0]?.id == this.state.original_delivery_status?.id
      );
    }

    openEditableItemFormModal(product) {
      this.setState((state) => {
        return {
          modal: {
            ...state.modal,
            editable_item_form: {
              ...state.modal.editable_item_form,
              open: true,
              data: product,
            },
          },
        };
      });
    }

    closeEditableItemFormModal() {
      this.setState((state) => {
        return {
          modal: {
            ...state.modal,
            editable_item_form: {
              ...state.modal.editable_item_form,
              open: false,
            },
          },
        };
      });
    }

    changeEditableItemFormLoading(status) {
      this.updateState((state) => {
        return {
          ...state,
          modal: {
            ...state.modal,
            editable_item_form: {
              ...state.modal.editable_item_form,
              loading: status,
            },
          },
        };
      });
    }

    render() {
      return (
        <ParentComponent
          {...this.state}
          {...this.props}
          form_ref={this.form_ref}
          clearState={this.clearState.bind(this)}
          getStateData={this.getStateData.bind(this)}
          updateState={this.updateState.bind(this)}
          validation={this.validation.bind(this)}
          isDeliveryCompleted={this.isDeliveryCompleted.bind(this)}
          handleInputChange={this.handleInputChange.bind(this)}
          handleSubmitOrder={this.handleSubmitOrder.bind(this)}
          validateComplete={this.validateComplete.bind(this)}
          retrieveAllCommonData={this.retrieveAllCommonData.bind(this)}
          openEditableItemFormModal={this.openEditableItemFormModal.bind(this)}
          closeEditableItemFormModal={this.closeEditableItemFormModal.bind(
            this
          )}
          beforeSubmitValidation={this.beforeSubmitValidation.bind(this)}
          changeEditableItemFormLoading={this.changeEditableItemFormLoading.bind(
            this
          )}
          handleSupplierSearchOptionChange={this.handleSupplierSearchOptionChange.bind(
            this
          )}
        />
      );
    }
  };
}
