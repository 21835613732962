import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";

import Emitter from "common/event-emitter";
import { empty } from "common";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";

import ApprovalModalContainer from "./approval-modal";
import ApprovalListDatatableContainer from "./approval-list-datatable";

class ApprovalListContainer extends Component {
  state = {
    modal: {
      approval: false,
    },
    template: [],
  };

  constructor() {
    super();
  }

  updateState(state) {
    this.setState(state);
  }

  showApprovalModal(template) {
    this.updateState({
      modal: {
        ...this.state.modal,
        approval: true,
      },
      template,
    });
  }

  hideApprovalModal() {
    this.updateState({
      modal: {
        ...this.state.modal,
        approval: false,
      },
    });
  }

  changeApprovalRemarks(remarks) {
    this.updateState({
      template: {
        ...this.state.template,
        approval_remarks: remarks,
      },
    });
  }

  changeApprovalCheck(item, checked) {
    const brands = this.state.template.brands.map((brand) => {
      const item_index = brand.items.indexOf(item);
      if (item_index !== -1) {
        brand.items[item_index].checked = checked;
      }

      return brand;
    });

    this.updateState({
      template: {
        ...this.state.template,
        brands: brands,
      },
    });
  }

  changeApprovalVariantCheck(item, compose, checked) {
    const brands = this.state.template.brands.map((brand) => {
      const item_index = brand.items.indexOf(item);
      if (item_index !== -1) {
        const compose_index = brand.items[item_index].variation.compose.indexOf(
          compose
        );
        if (compose_index !== -1) {
          brand.items[item_index].variation.compose[
            compose_index
          ].checked = checked;
        }
      }

      return brand;
    });

    this.updateState({
      template: {
        ...this.state.template,
        brands: brands,
      },
    });
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb title="Approval List" parent="Inventory" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="clearfix"></div>
              <div className="">
                <ApprovalListDatatableContainer
                  {...this.state}
                  showApprovalModal={this.showApprovalModal.bind(this)}
                />
                <ApprovalModalContainer
                  {...this.state}
                  changeApprovalRemarks={this.changeApprovalRemarks.bind(this)}
                  changeApprovalCheck={this.changeApprovalCheck.bind(this)}
                  changeApprovalVariantCheck={this.changeApprovalVariantCheck.bind(
                    this
                  )}
                  hideApprovalModal={this.hideApprovalModal.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {})(ApprovalListContainer);
