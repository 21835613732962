import React, { Component, Fragment } from "react";
import AllocationModalComponent from "../views/allocation-modal";
import Modal from "react-responsive-modal";
import Emitter from "common/event-emitter";
import * as toast from "components/common/toast";
import { empty } from "common";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";
import * as Http from "../../redux/actions/http";

class AllocationModalContainer extends Component {
  state = {
    allocation_type: "",
    allocation_types: [],
    stocks: 0,
    response: [],
    loading: false,
    shop: "",
    shops: [],
    allocation_platform: "",
    allocation_platforms: [],
  };

  componentDidMount() {
    this.props.requestAllCommonList((response) => {
      if (response.data.success === false) {
        return;
      }

      this.setState({
        shops: response.data.shops.map((shop) => {
          shop.disabled = true;
          return shop;
        }),
        allocation_platforms: response.data.allocation_platforms,
        allocation_types: response.data.allocation_types,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.modalStatus == false)
      if (this.props.modalStatus == true) {
        this.setState({
          response: "",
          erro: null,
          loading: true,
        });
        setTimeout(() => {
          this.getShopItemsAvailableStock();
        }, 2000);
      }
  }

  clearShopSel() {
    this.setState((state) => {
      return {
        ...state,
        shop: "",
      };
    });
  }

  handleShopItemAllocationInputChange(e) {
    e.persist();
    console.log(e);
    const index = e.target.name.split("_")[0];
    const name = e.target.name.split("_")[1];
    const value = e.target.value;

    this.props.updateState((state) => {
      state.selected.shop_links_reference = state.selected.shop_links_reference.map(
        (link, i) => {
          if (i == index) {
            link[name.replace("stocks", "allocated_stocks")] = value;
          }

          return link;
        }
      );
      return {
        ...state,
        selected: state.selected,
      };
    });
  }

  handleStoreItemAllocationInputChange(e) {
    e.persist();

    const index = e.target.name.split("_")[0];
    const name = e.target.name.split("_")[1];
    const value = e.target.value;

    this.props.updateState((state) => {
      if (state.selected.product.has_variation) {
        const variations = state.selected.product.variation.compose.map(
          (compose, i) => {
            if (i == index) {
              compose[name.replace("stocks", "allocated_stocks")] = value;
            }

            return compose;
          }
        );

        state.selected.product.variation.compose = variations;
      } else {
        state.selected.product[
          name.replace("stocks", "allocated_stocks")
        ] = value;
      }

      return {
        ...state,
        selected: state.selected,
      };
    });
  }

  validations() {
    if (this.state.stocks > this.props.selected.available_stock) {
      throw "Not enough available stocks.";
    }

    let bool = false;
    let total_stocks_allocated = 0;
    this.props.selected.shop_links_reference.forEach((link) => {
      total_stocks_allocated += parseFloat(link?.allocated_stocks ?? 0);
      if (!empty(link.allocated_stocks) && link.allocated_stocks > 0) {
        if (empty(link.type)) {
          throw "Allocation type is required.";
        } else {
          bool = true;
        }
      }
    });

    if (this.props.selected.product.has_variation) {
      this.props.selected.product.variation.compose.forEach((compose) => {
        total_stocks_allocated += parseFloat(compose?.allocated_stocks ?? 0);
        if (!empty(compose.allocated_stocks) && compose.allocated_stocks > 0) {
          if (empty(compose.type)) {
            throw "Allocation type is required.";
          } else {
            bool = true;
          }
        }
      });
    } else {
      if (!empty(this.props.selected.product?.allocated_stocks)) {
        if (empty(this.props.selected.product?.type)) {
          throw "Allocation type is required.";
        } else {
          bool = true;
        }
      }
    }

    if (total_stocks_allocated > this.props.selected.product.available_stock) {
      throw "Not enought available stocks.";
    }

    if (!bool) {
      throw "Choose atleast 1 product for allocation.";
    }
  }

  getShopItemsAvailableStock() {
    const details = this.props.selected.product;
    this.props.selected.product.variation.compose = this.props.selected.product.variation.compose.filter(
      (compose) => compose.id == this.props.selected.product.variation_id
    );
    this.props.getShopItemsAvailableStock(
      {
        link_allocation: this.props.selected.shop_links_reference,
        store_product_allocation: details,
      },
      (response) => {
        this.setState({
          loading: false,
        });
        this.props.updateState((state) => {
          // Get available stock on product links
          state.selected.shop_links_reference = state.selected.shop_links_reference.map(
            (link) => {
              link.available_stock = response.data.reduce((stock, item) => {
                if (item.shop_id == link.shop.id) {
                  if (item.item_id == link.shop_item_id) {
                    if (empty(link.shop_item_variation_id)) {
                      stock = item.stock;
                    } else {
                      if (item.item_variant_id == link.shop_item_variation_id) {
                        stock = item.stock;
                      }
                    }
                  }
                }

                return stock;
              }, 0);

              return link;
            }
          );
          // Get available stock on main product product variants
          state.selected.product.variation.compose = state.selected.product.variation.compose.map(
            (variant) => {
              variant.available_stock = response.data.reduce((stock, item) => {
                if (item.shop_id == state.selected.product.basic.shop.id) {
                  if (item.item_variant_id == variant.id) {
                    stock = item.stock;
                  }
                }

                return stock;
              }, 0);

              return variant;
            }
          );

          // Change available stock for main product
          response.data.map((item) => {
            if (item.shop_id == state.selected.product.basic.shop.id)
              if ((item.item_id = state.selected.product.id)) {
                if (empty(item.item_variant_id)) {
                  state.selected.stocks = item.stock;
                }
              }
            return item;
          });

          return {
            ...state,
            selected: state.selected,
          };
        });
      }
    );
  }

  handleSubmitAllocation() {
    try {
      this.validations();

      if (this.state.loading) return;

      this.setState({
        loading: true,
      });
      this.props.requestAllocateStocks(
        this.props.inventory_id,
        {
          link_allocation: this.props.selected.shop_links_reference,
          store_product_variant_allocation: this.props.selected.product
            .variation,
          store_main_product_allocation: this.props.selected.product,
        },
        (response) => {
          if (response.data.success) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }

          setTimeout(() => {
            Emitter.emit("INVENTORY_REFRESH_DATATABLE", {});
            this.setState({
              loading: false,
            });
            this.props.updateState({
              modalStatus: false,
            });
          }, 1000);
        },
        (error) => {
          console.log(error);
          this.setState({
            response: {
              message: error?.message ?? "Error occured!",
              error: true,
            },
            loading: false,
          });
        }
      );
    } catch (e) {
      this.setState({
        response: {
          message: e?.message ?? e,
          error: true,
        },
        loading: false,
      });
    }
  }

  handleAllocationModalClose() {
    this.props.updateState((state) => {
      return {
        ...state,
        modalStatus: false,
        inventory_id: null,
        selected: null,
      };
    });
    Emitter.emit("INVENTORY_REFRESH_DATATABLE");
  }

  render() {
    return (
      <AllocationModalComponent
        {...this.props}
        {...this.state}
        handleAllocationModalClose={this.handleAllocationModalClose.bind(this)}
        handleShopItemAllocationInputChange={this.handleShopItemAllocationInputChange.bind(
          this
        )}
        handleStoreItemAllocationInputChange={this.handleStoreItemAllocationInputChange.bind(
          this
        )}
        handleSubmitAllocation={this.handleSubmitAllocation.bind(this)}
        isShopeeStore={this.isShopeeStore}
      />
    );
  }
}

export default connect(null, {
  requestAllocateStocks: Http.requestAllocateStocks,
  requestAllCommonList: Http.requestAllCommonList,
  getShopItemsAvailableStock: Http.requestShopItemsAvailableStock,
})(AllocationModalContainer);
