import { empty } from "common";

import SharedClass from "../shared";

class CreateLinkOpponents {
  constructor(base) {
    this.base = base;
    this.shared = new SharedClass(base);
  }

  getSpecificVariant(link) {
    if (link.shop_item.data.has_variation) {
      link.shop_item.data.variations = link.shop_item.data.variations.filter(
        (variant) => variant.id == link.shop_item_variation_id
      );
    }

    return link;
  }

  createOpponents(product) {
    if (product.has_variation === true) {
      product.variation.compose.forEach((compose) => {
        if (!empty(compose.links_reference)) {
          compose.links_reference.forEach((link) => {
            this.shared.createOpponents([
              this.getSpecificVariant(link).shop_item,
            ]);
          });
        }
      });
    } else {
      product.links_reference.forEach((link) => {
        this.shared.createOpponents([this.getSpecificVariant(link).shop_item]);
      });
    }
  }

  getOpponents(player, link) {
    return this.shared.getAllOpponents().reduce((c, o) => {
      if (player.getInfo().has_variation) {
        if (player.getData().id == link.product_variation_compose_id) {
          if (o.getInfo().data.has_variation) {
            if (o.getData().id == link.shop_item_variation_id) {
              c.push(o);
            }
          } else {
            if (o.getData().id == link.shop_item.data.id) {
              c.push(o);
            }
          }
        }
      } else {
        if (o.getInfo().data.has_variation) {
          if (o.getData().id == link.shop_item_variation_id) {
            c.push(o);
          }
        } else {
          if (o.getData().id == link.shop_item.data.id) {
            c.push(o);
          }
        }
      }

      return c;
    }, []);
  }

  connect() {
    return this.shared.getAllPlayers().map((player) => {
      player.getData().links_reference.forEach((link) => {
        let opponents = this.getOpponents(player, link);
        if (!empty(opponents)) {
          opponents.forEach((opponent) => {
            if (!empty(opponent)) {
              const connect = this.shared.connectPlayerOpponent(
                player,
                opponent
              );
              player = connect.player;
            }
          });
        }
      });

      return player;
    });
  }

  async handle(product) {
    this.createOpponents(product);
    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.connect());
      }, 300);
    });
  }
}

export default CreateLinkOpponents;
