import { setDefault } from "./common";
const Enable = (state, action) => {
  return setDefault({
    ...state,
    variation: {
      ...state.variation,
      enabled: action.payload,
    },
  });
};

export default Enable;
