import React, { Fragment } from "react";

import AsyncSelect from "react-select/async";
import SelectBrand from "../service/select-brand";
import SelectShop from "../service/select-shop";
import CategoryContainer from "../container/category";
import { VaField, VaForm } from "../service/va-form";

const FormBasicView = ({
  handleCategoryOptionChange,
  handleCategoryInputChange,
  handleBrandOptionChange,
  handleBrandInputChange,
  handleShopOptionChange,
  handleShopInputChange,
  handleInputChange,
  validation,
  form,
}) => {
  return (
    <Fragment>
      <div className="needs-validation col-sm-12">
        <VaForm className="basic-info">
          <div className="form-group  col-sm-6 va-container">
            <label className="col-form-label">
              <span>*</span> Shop
            </label>
            <AsyncSelect
              isClearable={true}
              onChange={handleShopOptionChange}
              cacheOptions
              loadOptions={SelectShop}
              defaultOptions
              allowCreateWhileLoading={false}
              onInputChange={handleShopInputChange}
              value={{ value: form.shop?.id, label: form.shop?.name }}
            />
            <div className="error-response" className="error-response">
              Shop is required
            </div>
          </div>
          <div className="form-group col-sm-6">
            <label className="col-form-label pt-0">
              <span>*</span> Title
            </label>
            <VaField
              name="title"
              key={"title"}
              value={form.title}
              className="form-control"
              message={validation.title.message}
              required={validation.title.required}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group  col-sm-6">
            <label className="col-form-label pt-0">
              <span>*</span> Parent Sku
            </label>
            <VaField
              name="parent_sku"
              key="parent_sku"
              className="form-control"
              value={form.parent_sku}
              message={validation.parent_sku.message}
              required={validation.parent_sku.required}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group  col-sm-6 va-container">
            <label className="col-form-label">
              <span>*</span> Brand
            </label>
            <AsyncSelect
              isClearable={true}
              onChange={handleBrandOptionChange}
              cacheOptions
              loadOptions={SelectBrand}
              defaultOptions
              allowCreateWhileLoading={false}
              onInputChange={handleBrandInputChange}
              value={{ value: form.brand?.id, label: form.brand?.name }}
            />
            <div className="error-response" className="error-response">
              Brand is required
            </div>
          </div>
          <div className="form-group">
            <label className="col-form-label col-sm-12">
              <span>*</span> Category
            </label>
            <CategoryContainer />
          </div>
        </VaForm>
      </div>
    </Fragment>
  );
};

export default FormBasicView;
