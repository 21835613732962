import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import Emitter from "common/event-emitter";
import * as Http from "../../redux/actions/http";
import * as Action from "../../redux/actions/payload";

import StockingComponent from "../views/component";

class StockingContainer extends Component {
  state = {
    warehouse_list: [],
    warehouse_selected: [],
    template_list: [],
    template: [],
    allocation_types: [],
    template_name: "",
    message: { description: "", error: false },
    reload_template_list: null,
    modal_summary_open: false,
  };

  componentDidMount() {
    // this.props.destroy();
    this.props.requestAllCommonList().then((response) => {
      this.updateState({
        warehouse_list: response.data.warehouse_list.data,
        allocation_types: response.data.allocation_types,
      });
    });
  }

  updateState(state) {
    this.setState(state);
  }

  findTemplate(template_id) {
    const index = this.state.template_list.findIndex(
      (f) => f.id == template_id
    );
    return this.state.template_list[index];
  }

  changeMessage(description, error) {
    this.updateState({
      message: {
        description: description,
        error: error,
      },
    });
  }

  reloadTemplateList() {
    this.updateState({
      reload_template_list: !this.state.reload_template_list,
    });
  }

  handleTemplateChange(template_id) {
    this.props.changeSelectedStockingTemplate(this.findTemplate(template_id));
  }

  isSelectedTemplatePendingApproval() {
    return (
      this.props.inventory_stocking_reducer.template_selected?.approval_status
        ?.id == "pending-approval"
    );
  }

  setModalSummaryOpen(open) {
    this.updateState({
      modal_summary_open: open,
    });
  }

  render() {
    return (
      <StockingComponent
        {...this.state}
        {...this.props}
        updateState={this.updateState.bind(this)}
        findTemplate={this.findTemplate.bind(this)}
        changeMessage={this.changeMessage.bind(this)}
        setModalSummaryOpen={this.setModalSummaryOpen.bind(this)}
        reloadTemplateList={this.reloadTemplateList.bind(this)}
        handleTemplateChange={this.handleTemplateChange.bind(this)}
        isSelectedTemplatePendingApproval={this.isSelectedTemplatePendingApproval.bind(
          this
        )}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      inventory_stocking_reducer: state.inventoryStockingReducer,
    };
  },
  {
    requestAllCommonList: Http.requestAllCommonList,
    destroy: Action.destroy,
    changeSelectedStockingTemplate: Action.changeSelectedStockingTemplate,
  }
)(StockingContainer);
