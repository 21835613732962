import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import _debounce from "lodash.debounce";
import { empty } from "common";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Badge from "react-bootstrap/Badge";

const ItemsDatatableComponent = (props) => {
  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"Warehouse"}</b>,
            accessor: "warehouse.name",
            sortable: false,
            style: {
              textAlign: "right",
            },
            width: 100,
          },
          {
            Header: <b>{"Item"}</b>,
            id: "title",
            width: 300,
            sortable: false,
            style: {
              textAlign: "left",
            },
            Cell: (row) => {
              if (empty(row.original.product.variation_id)) {
                return row.original.product.basic.title;
              }
              return (
                <>
                  {row.original.product.variation_name} <br />
                  <small>{row.original.product.basic.title}</small>
                </>
              );
            },
          },
          {
            Header: <b>{"Current Stock"}</b>,
            id: "stocks",
            accessor: "product.available_stock",
            sortable: true,
            style: {
              textAlign: "right",
            },
            width: 150,
            Cell: (row) => {
              return (
                <>
                  {row.original.product.available_stock}
                  <Badge
                    bg="primary"
                    className="ml-1 cursor-pointer"
                    onClick={(e) => {
                      props.handleAllocationModalOpen(row.original);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </Badge>
                </>
              );
            },
          },

          {
            Header: <b>{"Total Sold"}</b>,
            id: "sold",
            accessor: "product.overall_sold_count",
            sortable: true,
            style: {
              textAlign: "center",
            },
            width: 150,
          },
          {
            Header: <b>{"Daily Sold"}</b>,
            accessor: "product.extra_fields.total_daily_sold_count",
            sortable: false,
            style: {
              textAlign: "center",
            },
            width: 150,
          },
          {
            Header: <b>{"Pending Orders"}</b>,
            sortable: false,
            style: {
              textAlign: "center",
            },
            width: 150,
            Cell: (row) => {
              return (
                <>
                  <>
                    {row.original.product.extra_fields.pending_orders
                      .unprocess ?? 0}
                  </>
                </>
              );
            },
          },
          {
            Header: <b>{"Processed Orders"}</b>,
            sortable: false,
            style: {
              textAlign: "center",
            },
            width: 150,
            Cell: (row) => {
              return (
                <>
                  <>
                    {row.original.product.extra_fields.pending_orders
                      .processed ?? 0}
                  </>
                </>
              );
            },
          },
          {
            Header: <b>{"Total Orders"}</b>,
            sortable: false,
            style: {
              textAlign: "center",
            },
            width: 150,
            Cell: (row) => {
              return (
                <>
                  <>
                    {row.original.product.extra_fields.pending_orders.total ??
                      0}
                  </>
                </>
              );
            },
          },

          {
            Header: <b>{"Re-order Level"}</b>,
            id: "re_order_level",
            accessor: "product.re_order_level",
            sortable: true,
            style: {
              textAlign: "center",
            },
            width: 200,
          },
        ]}
        SubComponent={(row) => {
          return (
            <ReactTable
              data={row.original.shop_links_reference}
              collapseOnDataChange={false}
              showPagination={false}
              defaultPageSize={row.original.shop_links_reference.length}
              columns={[
                {
                  Header: <b>{"Shop"}</b>,
                  filterable: false,
                  style: {
                    textAlign: "center",
                  },
                  width: 200,
                  Cell: (row) => {
                    return (
                      row.original.shop.name +
                      " (" +
                      row.original.shop.platform.name +
                      ")"
                    );
                  },
                },
                {
                  Header: <b>{"Title"}</b>,
                  accessor: "name",
                  filterable: false,
                  style: {
                    textAlign: "left",
                  },
                  Cell: (row) => {
                    if (empty(row.original.shop_item_variation_id)) {
                      return row.original.shop_item_name;
                    }
                    return (
                      <>
                        {row.original.shop_item_variation.name} <br />
                        <small>{row.original.shop_item_name}</small>
                      </>
                    );
                  },
                },
              ]}
            />
          );
        }}
        manual
        data={props.data}
        pages={props.pages} // Display the total number of pages
        loading={props.loading} // Display the loading overlay when we need it
        onFetchData={_debounce(props.fetchInventoryList, 300)} // Request new data when things change
        pageSizeOptions={[20, 50]}
        freezeWhenExpanded={true}
        filterable
        filterable
        defaultPageSize={20}
        collapseOnDataChange={false}
        collapseOnPageChange={false}
        className="-striped -highlight"
      />
    </div>
  );
};

export default ItemsDatatableComponent;
