import * as type from "../constants/index";

export const loadShopList = (payload) => {
  return {
    type: type.LOAD_SHOP_LIST,
    payload: payload,
  };
};

export const changeShop = (payload) => {
  return {
    type: type.SHOP_SELECTED,
    payload: payload,
  };
};

export const loadTransactions = (payload) => {
  return {
    type: type.LOAD_TRANSACTIONS,
    payload: payload,
  };
};

export const changeItemLiveStock = (id, variation_id, live_stock) => {
  return {
    type: type.CHANGE_LIVE_STOCK,
    payload: { id, variation_id, live_stock },
  };
};

export const destroy = () => {
  return {
    type: type.DESTROY,
  };
};
