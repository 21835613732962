import Http from "api/http";
import * as payload from "redux/actions/common/payload";

export const getGlobalConfigRequest = () => (dispatch) => {
  return Http.GET("common/global-config")
    .then(function (response) {
      dispatch(payload.updateGlobalConfig(response.data));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getGlobalConfig = () => (dispatch) => {
  return function (dispatch) {
    return dispatch(getGlobalConfigRequest());
  };
};
