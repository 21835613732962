const DESTROY = "inventory/stocking/destroy";
const ADD_BRAND_SELECTED_TEMPLATE =
  "inventory/stocking/add-brand-selected-template";
const ADD_BRAND_ITEM_SELECTED_TEMPLATE =
  "inventory/stocking/add-brand-item-selected-template";
const ADD_BRAND_ITEM_VARIANT_SELECTED_TEMPLATE =
  "inventory/stocking/add-brand-item-variant-selected-template";
const CHANGE_ITEM_VARIANT_STOCK =
  "inventory/stocking/change-item-variant-stock";
const CHANGE_ITEM_STOCK = "inventory/stocking/change-item-stock";
const CHANGE_SEL_STOCKING_TEMPLATE =
  "inventory/stocking/selected-stocking-template";
const REMOVE_SELECTED_TEMPLATE_BRAND =
  "inventory/stocking/remove-selected-template-brand";
const REMOVE_SELECTED_TEMPLATE_BRAND_ITEM =
  "inventory/stocking/remove-selected-template-brand-item";
const RESET_SELECTED_TEMPLATE_ITEM_VARIANT_STOCK =
  "inventory/stocking/reset-selected-template-item-variant-stock";
const REMOVE_SELECTED_TEMPLATE_BRAND_ITEM_VARIATION =
  "inventory/stocking/remove-selected-template-brand-item-variation";
const CHANGE_HEADER_ALLOCATION_TYPE =
  "inventory/stocking/change-header-allocation-type";

const CHANGE_ITEM_ALLOCATION_TYPE =
  "inventory/stocking/change-item-allocation-type";

const CHANGE_ITEM_VARIANT_ALLOCATION_TYPE =
  "inventory/stocking/change-item-variant-allocation-type";

export {
  DESTROY,
  ADD_BRAND_SELECTED_TEMPLATE,
  ADD_BRAND_ITEM_SELECTED_TEMPLATE,
  ADD_BRAND_ITEM_VARIANT_SELECTED_TEMPLATE,
  CHANGE_ITEM_VARIANT_STOCK,
  CHANGE_ITEM_STOCK,
  CHANGE_SEL_STOCKING_TEMPLATE,
  REMOVE_SELECTED_TEMPLATE_BRAND,
  REMOVE_SELECTED_TEMPLATE_BRAND_ITEM,
  REMOVE_SELECTED_TEMPLATE_BRAND_ITEM_VARIATION,
  RESET_SELECTED_TEMPLATE_ITEM_VARIANT_STOCK,
  CHANGE_HEADER_ALLOCATION_TYPE,
  CHANGE_ITEM_ALLOCATION_TYPE,
  CHANGE_ITEM_VARIANT_ALLOCATION_TYPE,
};
