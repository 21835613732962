import React, { Component } from 'react';


export class Spinner extends Component {

    render() {
        return (
            (this.props.loading == true) ?
            (<i className="fa fa-refresh fa-spin" style={{marginRight: 10+'px'}} />) : ''
        )
    }
}

export default Spinner
