import * as payload from "./payload";
import * as toast from "components/common/toast";

export const defaultErrorMessage =
  "There was an error occured. Please try again!";

export const setSuccess = (message) => (dispatch) => {
  toast.success(message);
  dispatch(payload.setLoading(false));
  dispatch(
    payload.setResponse({
      error: false,
      message: message,
    })
  );
  setTimeout(() => {
    dispatch(
      payload.setResponse({
        error: false,
        message: "",
      })
    );
  }, 2000);
};

export const setError = (error) => (dispatch) => {
  toast.error(error);
  dispatch(payload.setLoading(false));
  dispatch(
    payload.setResponse({
      error: true,
      message: error,
    })
  );
  setTimeout(() => {
    dispatch(
      payload.setResponse({
        error: false,
        message: "",
      })
    );
  }, 2000);
};
