import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruckLoading,
  faArrowRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import _debounce from "lodash.debounce";

import Button from "react-bootstrap/Button";

const ListBrandTableComponent = (props) => {
  const {
    data,
    pages,
    loading,
    brand_items,
    fetchBrandList,
    fetchBrandItems,
    handleAddBrand,
    handleAddBrandItem,
    handleAddBrandItemVariant,
  } = props;

  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"Brand"}</b>,
            accessor: "name",
            Cell: (row) =>
              row.original.name + " (" + row.original.items.total + ")",
          },
          {
            Header: <b>{"Action"}</b>,
            filterable: false,
            sortable: false,
            style: {
              textAlign: "right",
            },
            Cell: (row) => (
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  handleAddBrand(row.original);
                }}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" />
              </Button>
            ),
          },
        ]}
        defaultSorted={[
          {
            id: "product",
            desc: true,
          },
        ]}
        SubComponent={(brand) => {
          return (
            <div style={{ padding: "20px" }}>
              <ReactTable
                data={
                  brand_items[brand.original.id]?.data ??
                  brand.original.items.data
                }
                pages={
                  brand_items[brand.original.id]?.pages ??
                  Math.ceil(brand.original.items.total / 20)
                }
                brandId={brand.original.id}
                onFetchData={_debounce(fetchBrandItems, 300)} // Request new data when things change
                showPaginationTop={true}
                collapseOnDataChange={false}
                showPagination={true}
                defaultPageSize={
                  brand_items[brand.original.id]?.data?.length ??
                  brand.original.items.data.length
                }
                filterable
                manual
                columns={[
                  {
                    Header: <b>{"Product"}</b>,
                    accessor: "basic.title",
                    filterable: true,
                    style: {
                      textAlign: "left",
                    },
                    Cell: (row) =>
                      "(" +
                      row.original.variation.compose.length +
                      ") " +
                      row.original.basic.title,
                  },
                  {
                    Header: <b>{"Action"}</b>,
                    filterable: false,
                    sortable: false,
                    style: {
                      textAlign: "right",
                    },
                    Cell: (row) => (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          handleAddBrandItem(brand.original, row.original);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} size="sm" />
                      </Button>
                    ),
                  },
                ]}
                SubComponent={(item) => {
                  return (
                    item.original.has_variation && (
                      <div style={{ padding: "20px" }}>
                        <ReactTable
                          data={item.original.variation.compose}
                          collapseOnDataChange={false}
                          showPagination={false}
                          defaultPageSize={
                            item.original.variation.compose.length
                          }
                          columns={[
                            {
                              Header: <b>{"Variant"}</b>,
                              accessor: "sku",
                              filterable: false,
                              style: {
                                textAlign: "left",
                              },
                            },
                            {
                              Header: <b>{"Action"}</b>,
                              filterable: false,
                              sortable: false,
                              style: {
                                textAlign: "right",
                              },
                              Cell: (row) => (
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    handleAddBrandItemVariant(
                                      brand.original,
                                      item.original,
                                      row.original
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlus} size="sm" />
                                </Button>
                              ),
                            },
                          ]}
                        />
                      </div>
                    )
                  );
                }}
              />
            </div>
          );
        }}
        collapseOnDataChange={false}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        filterable
        onFetchData={_debounce(fetchBrandList, 300)} // Request new data when things change
        defaultPageSize={20}
        className="-striped -highlight"
      />
    </div>
  );
};

export default ListBrandTableComponent;
