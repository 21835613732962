import React, { Fragment } from "react";

import Table from "react-bootstrap/Table";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { empty } from "common";
import PropTypes from "prop-types";

const AffectedOrderListTabComponent = (props) => {
  return (
    <>
      <ReactTable
        // comment
        columns={[
          {
            Header: (
              <input
                type="checkbox"
                name="header-checkbox"
                className=""
                checked={props.isAllSelected}
                onChange={(e) => props.selectAll(e.target.checked)}
              />
            ),
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 50,
            Cell: (row) => (
              <input
                type="checkbox"
                className=""
                name={`order-checkbox_${row.original.order_no}`}
                checked={row.original?.is_override_removal_excluded ?? false}
                onChange={(e) => props.select(row.original)}
              />
            ),
          },
          {
            Header: <b>{"Order No."}</b>,
            accessor: "order_no",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "left",
            },
          },
          {
            Header: <b>{"Status"}</b>,
            accessor: "status.name",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "left",
            },
          },
          {
            Header: <b>{"Recipient"}</b>,
            accessor: "recipient.name",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "left",
            },
          },
          {
            Header: <b>{"Order Date"}</b>,
            accessor: "order_date",
            sortable: false,
            filterable: false,
          },
        ]}
        SubComponent={(order) => {
          return (
            <ReactTable
              // comment
              columns={[
                {
                  Header: <b>{"Item Name"}</b>,
                  accessor: "name",
                  sortable: false,
                  filterable: false,
                  style: {
                    textAlign: "left",
                  },
                  Cell: (row) => {
                    if (empty(row.original.variation_id)) {
                      return row.original.name;
                    }
                    return (
                      <>
                        {row.original.variation_name} <br />
                        {row.original.name}
                      </>
                    );
                  },
                },
                {
                  Header: <b>{"Quantity"}</b>,
                  accessor: "quantity",
                  sortable: false,
                  filterable: false,
                  style: {
                    textAlign: "right",
                  },
                },
              ]}
              data={order.original.items}
              defaultPageSize={order.original.items.length}
            />
          );
        }}
        data={props.orders}
        loading={props.loading} // Display the loading overlay when we need it
        pageSizeOptions={[50, 100]}
        freezeWhenExpanded={true}
        filterable
        filterable
        defaultPageSize={20}
        collapseOnDataChange={false}
        collapseOnPageChange={false}
        className="-striped -highlight"
      />
    </>
  );
};

AffectedOrderListTabComponent.propTypes = {
  orders: PropTypes.array,
  select: PropTypes.func,
  selectAll: PropTypes.func,
  isAllSelected: PropTypes.bool,
};

export default AffectedOrderListTabComponent;
