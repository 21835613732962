import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";

export const requestProductList = (filter) => (dispatch) => {
  return Http.POST("product/re-order-level/get-product-list", {
    payload: {
      ...filter,
    },
  });
};

export const requestApplyReOrderLevelChanges = (items) => (dispatch) => {
  return Http.POST("product/re-order-level/apply-re-order-level-changes", {
    items,
  });
};
