import CreateNewLine from "./create-line";
import LineComponent from "../../gameboard/line-component";

export default class Character {
  constructor(base, player) {
    this.base = base;
    this.player = player;
  }

  /*
   * Player need to remove an open line if it is pointed again
   */
  createLine(x, y) {
    if (this.player.hasOpenLines()) {
      this.player.removeOpenLines();
    } else {
      this.player.addLine(
        new CreateNewLine(this.base).handle(this.player, x, y)
      );
    }

    return this.player;
  }

  changeOpenLineCooridnates(x, y) {
    const lines = this.player.getLines().map((line) => {
      if (line.isOpen()) {
        line.changeMoveToX(
          this.player.getComponent().getX() +
            this.player.getComponent().getWidth()
        );
        line.changeMoveToY(
          this.player.getComponent().getY() +
            this.player.getComponent().getHeight() / 2
        );

        line.changeLineToX(x);
        line.changeLineToY(y);
      }
      return line;
    });

    this.player.changeLines(lines);
  }

  dettachLine(baseline, opponent) {
    const transfer_line = this.player
      .getLines()
      .filter((line) => line.sameValueAs(baseline));

    const lines = this.player
      .getLines()
      .filter((line) => !line.sameValueAs(baseline));

    this.player.changeLines(lines);

    return transfer_line[0];
  }

  attachOpponent(baseline, opponent) {
    this.player.attachOpponent(baseline, opponent);
    this.player.getComponent().connected(true);

    return this.player;
  }

  detachOpponent(opponent) {
    this.player.detachOpponent(opponent);

    return this.player;
  }

  connectOpponent(line, opponent) {
    this.attachOpponent(this.dettachLine(line, opponent), opponent);

    return this.player;
  }

  updateLine(x, y) {
    this.changeOpenLineCooridnates(x, y);

    return this.player;
  }
}
