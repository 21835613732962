import { empty } from "common";

const ListPlayers = (base) => {
  const all = () => {
    return base.state.players;
  };

  const activePlayersByPage = (players) => {
    let players_ = empty(players) ? all() : players;

    players_ = players_.slice(
      base.state.paginations.players.page * base.state.paginations.limit,
      base.state.paginations.limit * (base.state.paginations.players.page + 1)
    );

    return players_;
  };

  const updateActiveStatus = () => {
    const activePlayers = activePlayersByPage();

    return all().map((player) => {
      player.changeActive(false);
      const active = activePlayers.filter((o) => o.sameValueAs(player));
      if (active.length > 0) {
        player.changeActive(true);
      }
      return player;
    });
  };

  const allActive = async () => {
    const players = updateActiveStatus();
    // base.updateBaseState((state) => {
    //   return {
    //     ...state,
    //     players: players,
    //   };
    // });

    return await new Promise((resolve, reject) => {
      resolve(players.filter((player) => player.isActive() === true));
    });
  };

  return {
    all,
    allActive,
  };
};

export default ListPlayers;
