import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";

import StockingListDatatableContainer from "../container/stocking-list-datatable";
import SummaryModalContainer from "../container/summary-modal";
import TopLeftContainer from "../container/top-left";

import BrandItemsListDatatableContainer from "../container/brand-items-list-datatable";
import TopRightContainer from "../container/top-right";

import Message_container from "components/common/message-container";

class StockingComponent extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Stocking" parent="Inventory" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <Message_container
                message={this.props.message?.description ?? ""}
                error={this.props.message?.error ?? false}
              />
              <div className="clearfix"></div>
              <div className="">
                <Row>
                  <Col lg={7}>
                    <TopLeftContainer {...this.props} />
                    <StockingListDatatableContainer {...this.props} />
                  </Col>
                  <Col lg={5}>
                    <TopRightContainer {...this.props} />
                    <BrandItemsListDatatableContainer {...this.props} />
                  </Col>
                </Row>
                <SummaryModalContainer {...this.props} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StockingComponent;
