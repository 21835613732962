import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const ListShopeeProductDataTableComponent = (props) => {
  const {
    open,
    data,
    pages,
    loading,
    selected,
    selectedId,
    editProduct,
    fetchProduct,
    updateUnitCost,
    syncShopeeProduct,
    updateUnitCostStatus,
  } = props;

  return (
    <div>
      <ReactTable
        collapseOnDataChange={false}
        columns={[
          {
            Header: <b>{"Image"}</b>,
            accessor: "Image",
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) =>
              row.original.images.map((image, i) => {
                return <img key={i} src={image} width="50" />;
              }),
          },
          {
            Header: <b>{"Name"}</b>,
            accessor: "name",
            Cell: (row) => (
              <a
                href="javascript:;"
                onClick={(e) => {
                  editProduct(e, row.original.item_name);
                }}
              >
                {row.original.item_name}
              </a>
            ),
          },
          {
            Header: <b>{"Parent Sku"}</b>,
            accessor: "sku",
            Cell: (row) => <div>{row.original.item_sku}</div>,
          },
          {
            Header: <b>{"Category"}</b>,
            accessor: "category",
            Cell: (row) => <div>{row.original.category_id}</div>,
          },
          {
            Header: <b>{"Created Date"}</b>,
            accessor: "created_date",
          },
          {
            Header: <b>{"Unit Cost"}</b>,
            sortable: false,
            filterable: false,
            style: {
              textAlign: "right",
            },
            Cell: (row) => (
              <div>
                {row.original.variations.length <= 0 ? (
                  row.original.unit_cost.update ? (
                    <div className="input-group mb-3 unit-cost-container">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Unit Cost"
                        aria-label="Unit Cost"
                        defaultValue={row.original.unit_cost.value}
                      />
                      <div
                        className="input-group-append"
                        style={{ padding: 0 + "px" }}
                      >
                        <button
                          className="btn btn-outline-secondary btn-sm"
                          style={{ padding: 50 + "%" }}
                          type="button"
                          onClick={(e) => {
                            updateUnitCost(
                              row.original.id,
                              row.original.item_id,
                              0,
                              e.target
                                .closest(".unit-cost-container")
                                .querySelector("input").value,
                              row.original.currency
                            );
                          }}
                        >
                          {row.original.unit_cost.loading ? (
                            <i className="fa fa-refresh fa-spin"></i>
                          ) : (
                            <i className="fa fa-check"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {row.original.unit_cost.value}{" "}
                      <span
                        className="cursor-pointer"
                        onClick={(e) => {
                          updateUnitCostStatus(
                            row.original.id,
                            row.original.item_id,
                            0
                          );
                        }}
                      >
                        <i className="fa fa-edit"></i>
                      </span>
                    </div>
                  )
                ) : (
                  0
                )}
              </div>
            ),
          },
          {
            Header: <b>{"Price"}</b>,
            sortable: false,
            filterable: false,
            style: {
              textAlign: "right",
            },
            Cell: (row) => <div>{row.original.original_price.text}</div>,
          },
          {
            Header: <b>{"Stocks"}</b>,
            accessor: "stock",
            filterable: false,
            sortable: true,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: <b>{"Status"}</b>,
            accessor: "status",
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) => (
              <span className="badge badge-primary">
                {row.original.status.toUpperCase()}
              </span>
            ),
          },
          {
            Header: (
              <Button
                onClick={(e) => {
                  syncShopeeProduct();
                }}
                variant="primary"
                size="sm"
                className="text-rights"
                style={{
                  textAlign: "right",
                  fontSize: "0.5em",
                }}
              >
                Sync
              </Button>
            ),
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) => (
              <div>
                <Link
                  to={"edit-product/" + row.original.id}
                  click="editProduct(row.original.id)"
                  className="btn btn-xs btn-secondary mr-1"
                  title="Edit"
                >
                  <i className="fa fa-edit"></i>
                </Link>
              </div>
            ),
          },
        ]}
        SubComponent={(item) => {
          return (
            <div style={{ padding: "20px" }}>
              <em>Product Variations</em>
              <br />
              <br />
              <ReactTable
                data={item.original.variations}
                collapseOnDataChange={false}
                defaultPageSize={5}
                showPagination={false}
                columns={[
                  {
                    Header: <b>{"Name"}</b>,
                    accessor: "name",
                    filterable: false,
                    style: {
                      textAlign: "left",
                    },
                  },
                  {
                    Header: <b>{"Sku"}</b>,
                    accessor: "sku",
                    filterable: false,
                    style: {
                      textAlign: "left",
                    },
                  },
                  {
                    Header: <b>{"Stock"}</b>,
                    accessor: "stock",
                    filterable: false,
                    style: {
                      textAlign: "center",
                    },
                  },
                  {
                    Header: <b>{"Unit Cost"}</b>,
                    filterable: false,
                    style: {
                      textAlign: "center",
                    },
                    Cell: (row) => (
                      <div>
                        {row.original.unit_cost.update ? (
                          <div className="input-group mb-3 unit-cost-container">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Unit Cost"
                              aria-label="Unit Cost"
                              defaultValue={row.original.unit_cost.value}
                            />
                            <div
                              className="input-group-append"
                              style={{ padding: 0 + "px" }}
                            >
                              <button
                                className="btn btn-outline-secondary btn-sm"
                                style={{ padding: 50 + "%" }}
                                type="button"
                                onClick={(e) => {
                                  updateUnitCost(
                                    item.original.id,
                                    item.original.item_id,
                                    row.original.id,
                                    e.target
                                      .closest(".unit-cost-container")
                                      .querySelector("input").value,
                                    row.original.unit_cost.currency
                                  );
                                }}
                              >
                                {row.original.unit_cost.loading ? (
                                  <i className="fa fa-refresh fa-spin"></i>
                                ) : (
                                  <i className="fa fa-check"></i>
                                )}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {row.original.unit_cost.value}{" "}
                            <span
                              className="cursor-pointer"
                              onClick={(e) => {
                                updateUnitCostStatus(
                                  item.original.id,
                                  item.original.item_id,
                                  row.original.id
                                );
                              }}
                            >
                              <i className="fa fa-edit"></i>
                            </span>
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    Header: <b>{"Price"}</b>,
                    accessor: "price.text",
                    filterable: false,
                    style: {
                      textAlign: "right",
                    },
                  },
                ]}
              />
            </div>
          );
        }}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        onFetchData={fetchProduct} // Request new data when things change
        filterable
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
};

export default ListShopeeProductDataTableComponent;
