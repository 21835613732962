import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

import Message_container from "components/common/message-container";
import Bus from "common/event-emitter";
import Form from "react-bootstrap/Form";

const CustomerComponent = (props) => {
  const [message, setMessage] = useState({
    error: false,
    description: "",
  });

  useEffect(() => {
    Bus.on("message", ({ error, message: description }) => {
      setMessage({ error, description });

      // Clear out display after few seconds
      if (error) {
        setTimeout(() => {
          setMessage(false, "");
        }, 10000);
      }
    });
  }, []);

  return (
    <>
      <Container fluid>
        <Card>
          <Card.Body>
            <Message_container
              message={message.description}
              error={message.error}
            />
            <Row>
              <Col xs={12} md={12} lg={6}>
                <Form className="">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="name"
                      value={props.data?.name ?? ""}
                      onChange={(e) => {
                        props.changeCustomerData(e.target.name, e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder=""
                      name="email"
                      value={props.data?.email ?? ""}
                      onChange={(e) => {
                        props.changeCustomerData(e.target.name, e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="mobileno"
                      value={props.data?.mobileno ?? ""}
                      onChange={(e) => {
                        props.changeCustomerData(e.target.name, e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="address"
                      value={props.data?.address ?? ""}
                      onChange={(e) => {
                        props.changeCustomerData(e.target.name, e.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default CustomerComponent;
