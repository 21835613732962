import React, { useState, useEffect } from "react";

import CheckoutHeaderComponent from "../../views/checkout/header";

import EventEmitter from "common/event-emitter";

const Menus = [
  {
    name: "basket",
    title: "Basket",
    active: true,
    disabled: false,
  },
  {
    name: "customer",
    title: "Customer",
    active: false,
    disabled: false,
  },
  {
    name: "shipping",
    title: "Shipping",
    active: false,
    disabled: false,
  },
  {
    name: "checkout",
    title: "Checkout",
    active: false,
    disabled: false,
  },
];
const CheckoutHeaderContainer = (props) => {
  const [menus, setMenu] = useState(Menus);

  function togglePage() {
    EventEmitter.emit("TOGGLE-PAGE");
  }

  function getMenu(name) {
    return menus.find((menu) => menu.name == name);
  }

  function navClickHandler(menu) {
    const item = getMenu(menu.name);

    setMenu(
      menus.map((menu) => {
        if (menu.name == item.name) {
          menu.active = true;
        }
        return menu;
      })
    );
  }

  useEffect(() => {
    console.log(menus);
  }, [menus]);
  console.log(menus);
  return (
    <>
      <CheckoutHeaderComponent
        menus={menus}
        togglePage={togglePage.bind(this)}
        navClickHandler={navClickHandler.bind(this)}
      />
    </>
  );
};

export default CheckoutHeaderContainer;
