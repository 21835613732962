import React, { Component, Fragment, Suspense } from "react";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";

import * as toast from "components/common/toast";

import { empty } from "common";
import moment from "moment";

import { Link } from "react-router-dom";

import PurchaseOrder from "./purchase-order";
const PuchaseOrderComponent = React.lazy(() =>
  import("../views/purchase-order")
);

class UpdatePuchaseOrderContainer extends Component {
  constructor(props) {
    super(props);
  }

  loadCommonData() {
    this.props.updateState({
      order_data_loading: true,
    });
    setTimeout(() => {
      this.props.retrieveAllCommonData().then((data) => {
        this.props.updateState({
          ...data,
        });
        this.props.getPurchaseOrder(
          this.props.match.params.id,
          (response) => {
            const data = response.data;
            this.props.updateState({
              ...data,
              original_delivery_status: data.delivery_status,
              supplier: { value: data.supplier.id, label: data.supplier.name },
              order_date: Date.parse(data.order_at.value),
              items: data.items.reduce((item, row) => {
                item.push({
                  is_db: true,
                  ...row,
                  item: {
                    option: {
                      label:
                        row.details.variation_name +
                        ", " +
                        row.details.product_name,
                      value: row.details.product_id,
                    },
                    details: {
                      ...row.details,
                    },
                  },
                });

                return item;
              }, []),
              order_data_loading: false,
            });
          },
          (error) => {
            console.log(error);
            this.props.updateState({
              order_data_loading: false,
            });
          }
        );
      });
    }, 500);
  }

  componentDidMount() {
    this.loadCommonData();
  }

  submission() {
    this.props.updateState({
      save_loading: true,
    });

    setTimeout(() => {
      const date = new Date(this.props.order_date);
      this.props.updatePurchaseOrder(
        this.props.match.params.id,
        {
          ...this.props.getStateData(),
          supplier: this.props.supplier.value,
          order_date: date.toDateString(),
        },
        (response) => {
          this.props.updateState({
            save_loading: false,
          });
          if (response.data.success === true) {
            // setTimeout(() => {
            //   this.props.history.push("/purchase/list-purchase-orders");
            // }, 2000);
          }
          if (response.data.success === true) {
            // this.props.clearState();
            response.data.message =
              response.data.message +
              '<br /><a href="/purchase/list-purchase-orders">Go to purchases list.</a>';
            toast.success(response.data.message);
          }

          this.props.updateState({
            message: response.data.message,
            error: !response.data.success,
          });
        },
        (error) => {
          console.log(error);
          this.props.updateState({
            message: error?.message,
            error: true,
            save_loading: false,
          });
        }
      );
    }, 1000);
  }

  handleValidSubmit(event, values) {
    try {
      this.props.validateComplete();
      this.submission();
    } catch (e) {
      console.log(e);
      toast.error(e);
    }
  }

  render() {
    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          {!this.props.order_data_loading ? (
            <PuchaseOrderComponent
              {...this.state}
              {...this.props}
              edit={true}
              handleValidSubmit={this.handleValidSubmit.bind(this)}
            />
          ) : (
            "Loading..."
          )}
        </Suspense>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierReducer: state.supplierReducer,
  };
}

const PurchaseOrderBase = PurchaseOrder(UpdatePuchaseOrderContainer);

export default connect(mapStateToProps, {
  updatePurchaseOrder: Action.updatePurchaseOrder,
  getPurchaseOrder: Action.getPurchaseOrder,
  getAllCommon: Action.getAllCommon,
})(PurchaseOrderBase);
