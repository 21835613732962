import { empty } from "common";
import PlayerProp from "./prop";

class UpdatePlayerCoordinates {
  constructor(base) {
    this.base = base;
  }

  handle(players) {
    var PLAYER_START_TOP = PlayerProp.start_top;
    players = players.map((player) => {
      player.getComponent().changeX(PlayerProp.start_left);
      player.getComponent().changeY(PLAYER_START_TOP);

      PLAYER_START_TOP += PlayerProp.height + PlayerProp.distance;

      return player;
    });

    return players;
  }
}

export default UpdatePlayerCoordinates;
