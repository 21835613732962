import React from "react";

import { connect } from "react-redux";
import * as SalesActions from "../../redux/actions/index";
import Emitter from "common/event-emitter";
import { empty } from "common";

import HistoryModalComponent from "../views/history-modal";

class HistoryModalContainer extends React.Component {
  state = {};

  constructor() {
    super();
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  handleGetTransactions(job_id) {
    this.props.handleOnCloseModal();
    this.props.getTransactionsHistory(job_id);

    Emitter.emit(
      "SALES_FILTERED_BY",
      this.props.orderExportReducer.history.data.reduce((c, history) => {
        if (history.id === job_id) {
          c = history;
        }
        return c;
      })
    );
  }

  render() {
    return (
      <HistoryModalComponent
        {...this.state}
        {...this.props}
        handleGetTransactions={this.handleGetTransactions.bind(this)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    orderExportReducer: state.orderExportReducer,
  };
}

export default connect(mapStateToProps, {
  getTransactionsHistory: SalesActions.getTransactionsHistory,
})(HistoryModalContainer);
