import React, { Component, Fragment } from "react";

import ResponsiveModal from "react-responsive-modal";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "components/common/spinner";

const ApprovalModalComponent = (props) => {
  const style = {
    closeButton: {
      top: "-14px",
      right: "-8px",
    },
    closeIcon: {
      color: "#fff",
      fontSize: "20px",
    },
    modalContainer: {
      width: "400px",
    },
  };

  return (
    <Fragment>
      <Modal
        show={props.modal.approval}
        onHide={props.hideApprovalModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Template: {props.template?.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            height: "500px",
            overflowX: "auto",
          }}
        >
          {ApprovalInfo(props)}
          {TableContent(props)}
          {ApprovalRemarks(props)}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            disabled={props.loading.loading}
            onClick={(e) => {
              props.submitDisapproveRequest();
            }}
          >
            {props.loading.disapprove && <Spinner loading={true} />}
            Dis-approve
          </Button>
          <Button
            variant="success"
            disabled={props.loading.loading}
            onClick={(e) => {
              props.submitResubmitRequest();
            }}
          >
            {" "}
            {props.loading.resubmit && <Spinner loading={true} />}Re-submit
          </Button>
          <Button
            variant="primary"
            disabled={props.loading.loading}
            onClick={(e) => {
              props.submitApproveRequest();
            }}
          >
            {" "}
            {props.loading.approve && <Spinner loading={true} />}Approve
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const ApprovalInfo = (props) => {
  return (
    <>
      <p>
        <b>Warehouse:</b> {props.template?.warehouse?.name}
      </p>
      <p>
        <b>Submitted By:</b> {props.template?.approval?.approver_user?.name}
      </p>
      <p>
        <b>Submitted Date:</b> {props.template?.approval?.submitted_at}
      </p>
    </>
  );
};

const ApprovalRemarks = (props) => {
  return (
    <textarea
      className="form-control"
      rows="5"
      placeholder="Enter remarks here"
      onChange={(e) => {
        props.changeApprovalRemarks(e.target.value);
      }}
      value={props.template?.approval_remarks}
    ></textarea>
  );
};

const FooterContent = () => {};

const TableContent = (props) => {
  return (
    <Table bordered className="m-t-20">
      <tbody>
        {(props.template?.brands ?? []).map((brand) => {
          return (
            <tr key={brand.id}>
              <td>
                <Table>
                  <thead>
                    <tr>
                      <th className="text-center">***{brand.name}***</th>
                      <th>Allocation Type</th>
                      <th>Stock</th>
                      <th
                        style={{
                          display: "none",
                        }}
                      >
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {brand.items.data.map((item) => {
                      return (
                        <Fragment key={item.id}>
                          <tr key={item.id}>
                            <td>{item.basic.title}</td>
                            <td>
                              {item.has_variation
                                ? "-"
                                : item.allocation_type.name}
                            </td>
                            <td
                              className="text-right"
                              style={{
                                backgroundColor: "#fff",
                                color: "#ff8084",
                                fontWeight: "bold",
                              }}
                            >
                              {item.has_variation ? "-" : item.stock}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                display: "none",
                              }}
                            >
                              {item.has_variation ? (
                                "-"
                              ) : (
                                <input
                                  type="checkbox"
                                  name={"item_" + item.id}
                                  onClick={(e) =>
                                    props.changeApprovalCheck(
                                      item,
                                      e.target.checked
                                    )
                                  }
                                />
                              )}
                            </td>
                          </tr>
                          {item.variation.compose.length > 0 && (
                            <tr key={"compose_" + item.id}>
                              <td colSpan="4">
                                <Table>
                                  <thead>
                                    <tr>
                                      <th>Variant</th>
                                      <th>Allocation Type</th>
                                      <th className="text-center">Stock</th>
                                      <th
                                        style={{
                                          display: "none",
                                        }}
                                      >
                                        &nbsp;
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item.variation.compose.map((compose) => {
                                      return (
                                        <tr key={compose.id}>
                                          <td>{compose.sku}</td>
                                          <td>
                                            {compose.allocation_type.name}
                                          </td>
                                          <td
                                            className="text-right"
                                            style={{
                                              backgroundColor: "#fff",
                                              color: "#ff8084",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {compose.stock}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "center",
                                              display: "none",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              name={
                                                "item_variant" +
                                                item.id +
                                                "_" +
                                                compose.id
                                              }
                                              onClick={(e) =>
                                                props.changeApprovalVariantCheck(
                                                  item,
                                                  compose,
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ApprovalModalComponent;
