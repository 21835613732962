import React, { Component, Fragment, useState, forwardRef } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";

const FilterComponent = (props) => {
  const {
    handleSearch,
    handleSubmit,
    handleChangeDate,
    handleChangeShop,
    handleCalendarOpen,
    handleCalendarClose,
    handleChangeShopStatus,
    handleOnHistoryOpenModal,
    startDate,
    endDate,
    loading,
    shop,
    shops,
    order_status,
  } = props;

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <Form.Control placeholder="" value={value} onClick={onClick} ref={ref} />
  ));

  return (
    <Fragment>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="row m-b-40 justify-content-center no-gutters-ok">
            <div className="col-auto">
              <select
                className="form-control"
                onChange={(e) => handleChangeShop(e)}
                value={shop?.value ?? ""}
              >
                <option value="">--CHOOSE SHOP--</option>
                {shops.map((d) => {
                  return (
                    <option key={d.value} value={d.value}>
                      {d.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-auto">
              <select
                className="form-control"
                onChange={(e) => handleChangeShopStatus(e)}
                value={order_status?.id ?? ""}
              >
                <option>--CHOOSE STATUS--</option>
                {(shop?.order_statuses ?? []).map((d) => {
                  return (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-sm-5">
              <DatePicker
                showTimeSelect
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleChangeDate}
                selectsRange
                shouldCloseOnSelect={false}
                selectsEnd={true}
                selectsStart={true}
                onCalendarOpen={handleCalendarOpen}
                onCalendarClose={handleCalendarClose}
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={<DatePickerInput />}
              />
            </div>

            <div className="col-auto">
              <Dropdown as={ButtonGroup}>
                <Button variant="primary" type="submit">
                  {loading ? (
                    <div>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        style={{ width: "1em", height: "1em" }}
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "GENERATE"
                  )}
                </Button>

                <Dropdown.Toggle
                  split
                  variant="primary"
                  id="dropdown-split-basic"
                />

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleOnHistoryOpenModal}>
                    View History
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default FilterComponent;
