import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import UpdateBrandContainer from "../container/update-brand";

const DataTableComponent = (props) => {
  const {
    open,
    edit,
    editBrand,
    removeBrand,
    selected,
    selectedId,
    data,
    pages,
    brandId,
    loading,
    fetchBrand,
    onOpenModal,
    onCloseModal,
  } = props;

  return (
    <div>
      <UpdateBrandContainer
        brandId={brandId}
        open={open}
        onOpenModal={(e) => {
          onOpenModal(e);
        }}
        onCloseModal={(e) => {
          onCloseModal(e);
        }}
        {...edit}
      />
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"ID"}</b>,
            accessor: "id",
            filterable: false,
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Name"}</b>,
            accessor: "name",
            Cell: (row) => (
              <a
                href="javascript:;"
                onClick={(e) => {
                  editBrand(e, row.original.id);
                }}
              >
                {row.original.name}
              </a>
            ),
          },
          {
            Header: <b>{"Description"}</b>,
            accessor: "description",
          },
          {
            Header: (
              <button
                onClick={(e) => {
                  removeBrand(e);
                }}
                className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
              >
                Delete
              </button>
            ),
            accessor: "delete",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) => (
              <div>
                <span>
                  <input
                    defaultChecked={selectedId.includes(row.original.id)}
                    onChange={(e) => {
                      selected(e, row.original.id);
                    }}
                    type="checkbox"
                    className="checkbox_animated"
                  />
                </span>
              </div>
            ),
          },
        ]}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        onFetchData={fetchBrand} // Request new data when things change
        filterable
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
};

export default DataTableComponent;
