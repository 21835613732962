import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import Message_container from "components/common/message-container";

import RightSidebarContainer from "../container/right-sidebar";
import TabsetProfileContainer from "../container/tabset-profile";

export default class ProfileComponent extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Profile" parent="settings" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4">
              <div className="card profile-card">
                <div className="card-body">
                  <RightSidebarContainer {...this.props} />
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="card profile-card">
                <div className="card-body">
                  <TabsetProfileContainer {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
