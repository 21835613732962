import React, { Component, Fragment } from "react";
import SummaryWidgetComponent from "../views/summary-widget";

import { connect } from "react-redux";
// import * as SalesActions from "../../redux/actions/index";

export class SummaryWidgetContainer extends Component {
  render() {
    return <SummaryWidgetComponent {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    dashboardReducer: state.dashboardReducer,
  };
}

export default connect(mapStateToProps, null)(SummaryWidgetContainer);
