import React, { Component, Fragment, useState, useEffect } from "react";
import DailySalesDatatableComponent from "../views/daily-sales-datatable";
import Emitter from "common/event-emitter";
import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import * as Payload from "../../redux/actions/payload";
import { empty } from "common";

import ApiHttp from "api/http";
import axios from "axios";

import _ from "lodash";

var INTERVAL_FETCH = null;
var INTERVAL_LIVE_STOCK = null;
var DAILY_SALES_REQUEST = [];

const ListSalesDatatableContainer = (props) => {
  const [datatable, setDataTable] = useState({
    data: [],
    pages: null,
    pageSize: 0,
    shop: 0,
    shops: [],
    loading_live_stock: null,
    daily_sales: [],
    last_updated_at: null,
    live_stocks: [],
    daily_sales_summary: [],
  });

  const [loading, setLoading] = useState(false);

  function handleAutoRefresher() {
    INTERVAL_FETCH = setInterval(() => {
      if (!loading) {
        fetch();
      }
    }, 60000); // refresh every 1 minute

    INTERVAL_LIVE_STOCK = setInterval(() => {
      if (!datatable.loading_live_stock) {
        // handleLiveStockReteriever();
      }
    }, 60000);
  }

  function cleanUpAutoRefresher() {
    clearInterval(INTERVAL_FETCH);
    clearInterval(INTERVAL_LIVE_STOCK);
  }

  function handleLiveStockReteriever() {
    var request_interval = 3000;
    _.chunk(datatable.daily_sales, 20).forEach((chunk) => {
      setTimeout(() => {
        loadingLiveStockOn(true);
        props.requestLiveStock(
          props.dashboardReducer.shop_selected.id,
          chunk.map((c) => {
            return { id: c.id, variation_id: c.variation_id };
          }),
          (response) => {
            if (response.data.success) {
              setDataTable({
                ...datatable,
                loading_live_stock: false,
                live_stocks: datatable.live_stocks.concat(response.data.data),
              });
            }
          },
          (error) => {
            loadingLiveStockOn(false);
          }
        );
      }, request_interval);
      request_interval += 2000;
    });
  }

  function handleUpdateItemLiveStocksState() {
    datatable.live_stocks.forEach((stock, i) => {
      var sale_index = datatable.daily_sales.findIndex(
        (i) => i.id == stock.id && i.variation_id == stock.variation_id
      );

      if (sale_index !== -1) {
        datatable.daily_sales[sale_index].live_stock = stock.live_stock;
      }
    });

    setDataTable({
      ...datatable,
      daily_sales: datatable.daily_sales,
    });
  }

  function loadingLiveStockOn(status) {
    setDataTable({
      ...datatable,
      loading_live_stock: status,
    });
  }

  function fetch() {
    setLoading(true);

    const shop_id = props.dashboardReducer?.shop_selected?.id ?? 0;

    if (empty(shop_id)) return;

    ApiHttp.POST("dashboard/get-daily-sales", {
      code: props.filter,
      shop_id: shop_id,
    })
      .then((response) => {
        setDataTable({
          ...datatable,
          daily_sales: response.data.daily_sales.items,
          last_updated_at: response.data.daily_sales.last_updated_at,
        });

        props.updateState({
          daily_sales_summary: response.data.daily_sales_summary,
        });

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function cancelPreviousRequest() {
    DAILY_SALES_REQUEST.forEach((request, i) => {
      request();
      delete request[i];
    });
  }

  // Check request to fetch daily sales
  useEffect(() => {
    fetch();
    console.log(props.request);
  }, [props.request]);

  useEffect(() => {
    // handleUpdateItemLiveStocksState();
  }, [datatable.live_stocks]);

  useEffect(() => {
    handleAutoRefresher();

    return () => {
      cleanUpAutoRefresher();
    };
  }, []);

  return (
    <DailySalesDatatableComponent {...datatable} {...props} loading={loading} />
  );
};

function mapStateToProps(state) {
  return {
    dashboardReducer: state.dashboardReducer,
  };
}

export default connect(mapStateToProps, {
  requestDailySales: Http.requestDailySales,
  requestAllCommon: Http.requestAllCommon,
  requestLiveStock: Http.requestLiveStock,
  changeItemLiveStock: Payload.changeItemLiveStock,
})(ListSalesDatatableContainer);
