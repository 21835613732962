import React, { Component,Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import {List} from "immutable";
import { connect } from "react-redux";

import  * as Role_permission from "redux/actions/user/role-permission/index";
import * as Action_group_permission from "redux/actions/user/group-permission/index";
import * as Action_permission from "redux/actions/user/action-permission/index";
import * as PermissionAction  from "redux/actions/user/permission/index";

import Message_container from "components/common/message-container";
import Create_role from "components/users/create-role";
import Create_action from "components/users/create-action";
import Create_action_group from "components/users/create-action-group";
import List_role from "components/users/list-role";
import List_action from "components/users/list-action";
import List_action_group from "components/users/list-action-group";
import List_permission from "components/users/list-permission";

import { ToastContainer } from "react-toastify";
import Spinner from "components/common/spinner";

import Auth from "common/auth";

import "components/users/style-action.css"

export class Permission extends Component {

    constructor(props) {
        super(props);
        console.log(Auth.isExpiredToken());
    }   

    handleUpdateActionGroup(e) {
        this.props.updateActionGroup(this.props.actionGroup.data.filter( d => {
            d.actions = d.actions.filter( d => {
                return d.checked === true;
            });
            return d;
        }));
    }

    handleUpdatePermission(e) {
        this.props.updatePermission(
            this.props.permission.currentRole,
            this.props.permission.data
        );

    }

    handleCancelPermissionUpdate() {
        this.props.changeUpdateStatus(false);
        this.props.clearCurrentRole();
    }

    setActionGroupFormStatus() {
        this.props.setActionGroupFormStatus(true);
        this.props.setActionFormStatus(false); 
        this.props.setRoleFormStatus(false);            
    }

    setActionFormStatus() {
        this.props.setActionFormStatus(true);
        this.props.setActionGroupFormStatus(false);  
        this.props.setRoleFormStatus(false);      
    }

    setRoleFormStatus() {
        this.props.setRoleFormStatus(true);
        this.props.setActionFormStatus(false); 
        this.props.setActionGroupFormStatus(false);        
    }

    render() {
        const {
            permission,
            actionGroup,
            action,
            role
        } = this.props;
        
        return (
            <Fragment>
                <Breadcrumb title="Permission" parent="Users" desc="Roles / Actions assigning" />
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <List_role />
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <div className="row">
                                                <div className="col-sm-12 m-b-10">
                                                    {
                                                        permission.isUpdate === true ?
                                                            (   
                                                                <Fragment>
                                                                    <button type="button" onClick={d =>{this.handleUpdatePermission(d)}} className="btn m-r-10 btn-primary">
                                                                    <Spinner loading={permission.loading} />Update Permission
                                                                    </button>
                                                                    <button type="button" onClick={ e => {this.handleCancelPermissionUpdate(e)}} className="btn btn-secondary">
                                                                        CANCEL
                                                                    </button>
                                                                </Fragment>
                                                            ) : 
                                                            (
                                                                <button type="button" onClick={d =>{this.handleUpdateActionGroup(d)}} className="btn btn-primary">
                                                                <Spinner loading={action.loading} />Update Group Actions
                                                                </button>
                                                            )    
                                                    
                                                    }
                                                </div>
                                                <div className="col-sm-12">
                                                    {
                                                        permission.isUpdate === true ? ('') :
                                                        (
                                                            <Fragment>
                                                                <button type="button" onClick={e=>{this.setRoleFormStatus(e)}} className="btn btn-xs btn-secondary m-r-10">
                                                                   Add New Role
                                                                </button>
                                                                <button type="button" onClick={e=>{this.setActionFormStatus(e)}} className="btn btn-xs btn-secondary m-r-10">
                                                                   Add New Action
                                                                </button>
                                                                <button type="button" onClick={e=>{this.setActionGroupFormStatus()}} className="btn btn-xs btn-secondary m-r-10">
                                                                   Add New Group
                                                                </button>
                                                            </Fragment>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4 border-right">
                                         {permission.isUpdate === true ? 
                                            <List_permission /> : <List_action_group /> } 
                                        </div>
                                        <div className="col-sm-4 border-right">
                                            { permission.isUpdate === true ? ('')  : <List_action />}
                                        </div>
                                        <div className="col-sm-4">
                                             {this.props.action.formStatus === true ? <Create_action /> : ''}
                                             {actionGroup.formStatus === true ? <Create_action_group /> : ''}
                                             {role.formStatus === true ? <Create_role /> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.rolePermission,
        action: state.actionPermission,
        actionGroup: state.actionGroupPermission,
        permission: state.permission
    };
}

let PermissionRedux = connect(
    mapStateToProps,
    {
        updateActionGroup: d => Action_group_permission.updateActionGroup(d),
        setActionGroupFormStatus: d => Action_group_permission.setFormStatus(d),
        setActionFormStatus: d => Action_permission.setFormStatus(d),
        setRoleFormStatus: d => Role_permission.setFormStatus(d),
        changeUpdateStatus: d => PermissionAction.changeUpdateStatus(d),
        updatePermission: (i, d) => PermissionAction.updatePermission(i, d),
        clearCurrentRole: PermissionAction.clearCurrentRole
    }
)(Permission);

export default PermissionRedux;
