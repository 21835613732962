import React, { Component, Fragment } from "react";

import { User, Settings } from "react-feather";
import designer from "assets/images/dashboard/designer.jpg";

const RightSidebarComponent = (props) => {
  return (
    <>
      <div className="profile-details text-center">
        <img
          src={designer}
          alt=""
          className="img-fluid img-90 rounded-circle blur-up lazyloaded"
        />
        <h5 className="f-w-600 f-16 mb-0">
          {props.userAuthReducer.session.user.name}
        </h5>
        <span> {props.userAuthReducer.session.user.email}</span>
        <div className="social">
          <div className="form-group btn-showcase">
            <button className="btn social-btn btn-fb d-inline-block">
              {" "}
              <i className="fa fa-facebook"></i>
            </button>
            <button className="btn social-btn btn-twitter d-inline-block">
              <i className="fa fa-google"></i>
            </button>
            <button className="btn social-btn btn-google d-inline-block mr-0">
              <i className="fa fa-twitter"></i>
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div className="project-status">
        <h5 className="f-w-600 f-16">Activity Logs</h5>
        <div className="media">
          <div className="media-body">
            <h6>Notifications</h6>
          </div>
        </div>
        <div className="media">
          <div className="media-body">
            <h6>Transactions</h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightSidebarComponent;
