import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";
import Emitter from "common/event-emitter";

export const requestInventoryList = (filters, per_page, page) => (dispatch) => {
  return Http.POST("inventory/list-all-inventory", {
    payload: filters,
    per_page,
    page,
  });
};

interface allocation {
  allocation_type: string;
  stocks: integer;
}

export const requestAllocateStocks = (
  id,
  data: allocation,
  successCallback,
  errorCallback
) => (dispatch) => {
  Http.PUT("inventory/allocation/create/" + id, data)
    .then(successCallback)
    .catch(errorCallback);
};

export const requestAllCommonList = (callback) => (dispatch) => {
  return Http.GET("inventory/get-all-common")
    .then(callback)
    .catch(function (error) {
      console.log(error.message);
    });
};

export const requestShopItemsAvailableStock = (
  data,
  successCallback,
  errorCallback
) => (dispatch) => {
  Http.POST("inventory/allocation/get-items-available-stock", data)
    .then(successCallback)
    .catch(errorCallback);
};

export const requestBrandItemList = (
  filter,
  successCallback,
  errorCallback
) => (dispatch) => {
  Http.POST("inventory/modify-stock/get-brand-items-list", filter)
    .then(successCallback)
    .catch(errorCallback);
};

export const requestBrandItemsList = (
  brand_id,
  filter,
  successCallback,
  errorCallback
) => (dispatch) => {
  Http.POST("inventory/modify-stock/get-brand-items-list/" + brand_id, filter)
    .then(successCallback)
    .catch(errorCallback);
};

export const requestWarehouseList = (successCallback, errorCallback) => (
  dispatch
) => {
  Http.GET("inventory/warehouse/get-warehouse-list")
    .then(successCallback)
    .catch(errorCallback);
};

export const requestSaveStockingTemplate = (
  data,
  successCallback,
  errorCallback
) => (dispatch) => {
  Http.POST("inventory/modify-stock/save-template", data)
    .then(successCallback)
    .catch(errorCallback);
};

export const requestSaveStockChanges = (
  data,
  successCallback,
  errorCallback
) => (dispatch) => {
  Http.POST("inventory/save-stock-changes", data)
    .then(successCallback)
    .catch(errorCallback);
};

export const requestAllStockingTemplateList = (
  successCallback,
  errorCallback
) => (dispatch) => {
  Http.GET("inventory/modify-stock/get-all-stocking-template")
    .then(successCallback)
    .catch(errorCallback);
};
