import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";

import Emitter from "common/event-emitter";
import { empty } from "common";
import * as toast from "components/common/toast";
import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";

import ModifyStockTableContainer from "./modify-stock-table";
import ListBrandTableContainer from "./list-brand-table";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";

class ModifyStockContainer extends Component {
  state = {
    template_id: null,
    template_name: "",
    warehouse: null,
    warehouses: [],
    shops: [],
    allocation_platforms: [],
    allocation_type: [],
    allocation_types: [{ id: "", name: "SELECT ALLOCATION TYPE" }],
  };

  constructor() {
    super();
    this.handleSaveTemplate = this.handleSaveTemplate.bind(this);
    this.handleSaveStockChanges = this.handleSaveStockChanges.bind(this);
    this.handleChangeTemplateName = this.handleChangeTemplateName.bind(this);
    this.handleChangeWarehouse = this.handleChangeWarehouse.bind(this);
  }

  componentDidMount() {
    this.props.requestWarehouseList((response) => {
      this.setState({
        warehouses: response.data.data,
      });
    });
    this.props.requestAllCommonList((response) => {
      this.setState({
        allocation_types: this.state.allocation_types.concat(
          response.data.allocation_types
        ),
      });
    });
    if (!empty(this.props.inventoryReducer.template.info.id)) {
      this.handleChangeTemplateName(
        this.props.inventoryReducer.template.info.name
      );
    }
    if (!empty(this.props.inventoryReducer.template.warehouse?.id)) {
      this.handleChangeWarehouse(
        this.props.inventoryReducer.template.warehouse?.id
      );
    }
  }

  handleValidation() {
    if (
      empty(this.props.inventoryReducer.template.info.id) &&
      empty(this.state.template_name)
    ) {
      throw "Template name is required!";
    }

    if (
      empty(this.props.inventoryReducer.template.warehouse?.id) &&
      empty(this.state.warehouse)
    ) {
      throw "Warehouse is required!";
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.inventoryReducer.template.info.name !=
      prevProps.inventoryReducer.template.info.name
    ) {
      this.handleChangeTemplateName(
        this.props.inventoryReducer.template.info.name
      );
    }

    if (
      this.props.inventoryReducer.template.warehouse?.id !=
      prevProps.inventoryReducer.template.warehouse?.id
    ) {
      this.handleChangeWarehouse(
        this.props.inventoryReducer.template.warehouse?.id
      );
    }
  }

  handleChangeTemplateName(value) {
    this.setState({
      template_name: value,
    });
  }

  handleChangeWarehouse(value) {
    this.setState({
      warehouse: value,
    });
  }

  getSelectedWarehouse(warehouse_id) {
    return this.state.warehouses.filter(
      (warehouse) => warehouse.id == warehouse_id
    )[0];
  }

  handleSaveTemplate() {
    try {
      this.handleValidation();
      this.props.requestSaveStockingTemplate(
        {
          template_id: this.props.inventoryReducer.template.info.id ?? 0,
          template_name: this.state.template_name,
          template: this.props.inventoryReducer.template.brands ?? [],
          warehouse: this.getSelectedWarehouse(this.state.warehouse),
        },
        (response) => {
          if (response.data.success) {
            toast.success(response.data.message, "topRight");
            Emitter.emit("REFRESH_STOCKING_TEMPLATE_LIST", {
              template_id: response.data.template_id,
            });
          } else {
            toast.error(response.data.message, "topRight");
          }
        },
        (error) => {
          toast.error(error.message, "topRight");
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(error, "topRight");
    }
  }
  handleSaveStockChanges() {
    try {
      this.handleValidation();
      this.props.requestSaveStockChanges(
        {
          template_id: this.props.inventoryReducer.template.info.id ?? 0,
          template_name: this.state.template_name,
          template: this.props.inventoryReducer.template.brands ?? [],
          warehouse: this.getSelectedWarehouse(this.state.warehouse),
        },
        (response) => {
          if (response.data.success) {
            toast.success(response.data.message, "topRight");
            Emitter.emit("REFRESH_STOCKING_TEMPLATE_LIST", {
              template_id: response.data.template_id,
            });
          } else {
            toast.error(response.data.message, "topRight");
          }
        },
        (error) => {
          toast.error(error.message, "topRight");
        }
      );
    } catch (error) {
      toast.error(error, "topRight");
    }
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb title="Modify Stock" parent="Inventory" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="clearfix"></div>
              <div
                id="batchDelete"
                className="category-table user-list order-table coupon-list-delete"
              >
                <Row>
                  <Col xs={7}>
                    <ButtonToolbar
                      className="mb-3"
                      aria-label="Toolbar with Button groups"
                    >
                      <ButtonGroup aria-label="Buttons">
                        <Button
                          variant="default"
                          size="sm"
                          className="text-right"
                          onClick={(e) => {
                            this.handleSaveStockChanges();
                          }}
                        >
                          SUBMIT FOR APPROVAL
                        </Button>
                        <Button
                          variant="secondary"
                          size="sm"
                          className="text-right"
                          onClick={(e) => {
                            this.handleSaveTemplate();
                          }}
                        >
                          SAVE TEMPLATE
                        </Button>
                      </ButtonGroup>
                      <InputGroup className="m-l-10">
                        <FormControl
                          type="text"
                          placeholder="Template Name"
                          aria-label="Template Name"
                          aria-describedby="btnGroupAddon"
                          onChange={(e) => {
                            this.handleChangeTemplateName(e.target.value);
                          }}
                          value={this.state.template_name}
                          style={{ height: "40px" }}
                        />
                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            this.handleChangeWarehouse(e.target.value);
                          }}
                          style={{ height: "40px" }}
                          value={this.state.warehouse}
                        >
                          <option value="">SELECT WAREHOUSE</option>
                          {this.state.warehouses.map((warehouse) => {
                            return (
                              <option value={warehouse.id}>
                                {warehouse.name}
                              </option>
                            );
                          })}
                          })}
                        </Form.Control>
                      </InputGroup>
                    </ButtonToolbar>
                    <ModifyStockTableContainer {...this.state} />
                  </Col>
                  <Col xs={5}>
                    <ListBrandTableContainer {...this.state} />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  (state) => {
    return {
      inventoryReducer: state.inventoryReducer,
    };
  },
  {
    requestSaveStockingTemplate: Http.requestSaveStockingTemplate,
    requestSaveStockChanges: Http.requestSaveStockChanges,
    requestAllStockingTemplateList: Http.requestAllStockingTemplateList,
    requestWarehouseList: Http.requestWarehouseList,
    requestAllCommonList: Http.requestAllCommonList,
  }
)(ModifyStockContainer);
