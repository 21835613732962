const StartButtonProp = {
  color: "BLACK",
  width: 100,
  height: 50,
};

const LinkButtonProp = {
  color: "BLACK",
  width: 100,
  height: 50,
};

export { StartButtonProp, LinkButtonProp };
