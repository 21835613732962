import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import { AvField, AvForm } from "availity-reactstrap-validation";
import MessageContainer from "components/auth/message-container";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import uuid from "react-uuid";
import Emitter from "common/event-emitter";

import {
  updateSession,
  reValidateLogin,
  logout,
} from "redux/actions/user/user-auth";

import "./lock-modal-style.scss";

class LockModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: null,
      error: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleEmitClearPendingRequest() {
    Emitter.emit("VERIFY_USER_CHECKER");
  }

  handleLogin = (event) => {
    event.preventDefault();

    if (this.state.loading) return;

    this.setState({
      loading: true,
    });
    this.props.reValidateLogin(
      {
        username: this.state.username,
        password: this.state.password,
        verify_username:
          this.props.userAuthReducer.session?.user?.username ?? 0,
      },
      (response) => {
        this.setState({
          loading: false,
          message: response.data.message,
          error: !response.data.success,
        });
        if (response.data.success == true) {
          this.props.updateSession({
            access_token: response.data.access_token,
            token_type: response.data.token_type,
            expires_in: response.data.expires_in,
            user: response.data.data,
            sid: uuid(),
          });
          this.handleEmitClearPendingRequest();
          this.props.handleLockModalClose();
        }
      },
      (error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    );
  };

  render() {
    const { modal_status, handleLockModalClose, template } = this.props;

    const style = {
      closeButton: {
        top: "-14px",
        right: "-8px",
      },
      closeIcon: {
        color: "#fff",
        fontSize: "20px",
      },
      modalContainer: {
        // width: "400px",
        background: "#000",
      },
    };

    return (
      <Fragment>
        <Modal
          open={modal_status}
          onClose={handleLockModalClose}
          showCloseIcon={false}
          center
          closeOnEsc={false}
          closeOnOverlayClick={false}
          styles={style}
          classNames={{
            modal: "lock-modal",
          }}
        >
          <Card
            style={{
              width: "30rem",
            }}
            className="m-t-20 m-l-20 m-r-20"
          >
            <Card.Body>
              <Card.Title className="text-center">
                Hey{" "}
                {
                  (this.props.userAuthReducer?.session?.user?.name ?? "").split(
                    " "
                  )[0]
                }
                , please verify your account.
              </Card.Title>
              <MessageContainer
                error={this.state.error}
                message={this.state.message}
              />
              <AvForm
                className="form-horizontal needs-validation auth-form"
                onValidSubmit={(e) => this.handleLogin(e)}
                onInvalidSubmit={this.handleInvalidSubmit}
              >
                <MessageContainer />
                <div className="form-group">
                  <AvField
                    required
                    onChange={this.handleChange}
                    name="username"
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    id="exampleInputEmail1"
                  />
                </div>
                <div className="form-group">
                  <AvField
                    required
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                  />
                </div>
                <div className="form-terms">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <label className="d-block">
                      <span className="pull-left">
                        {" "}
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => {
                            this.props.logout();
                          }}
                          className="btn btn-default forgot-pass p-0"
                        >
                          Logout
                        </a>
                      </span>
                    </label>
                  </div>
                </div>
                <div className="form-button pull-right">
                  <button className="btn btn-primary" type="submit">
                    {this.state.loading == true ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: 10 + "px" }}
                      />
                    ) : (
                      ""
                    )}
                    Login
                  </button>
                </div>
              </AvForm>
            </Card.Body>
          </Card>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userAuthReducer: state.userAuthReducer,
  };
}

export default connect(mapStateToProps, {
  updateSession: updateSession,
  reValidateLogin: reValidateLogin,
  logout: logout,
})(LockModalComponent);
