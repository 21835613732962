import React, { Component, Fragment, Suspense } from "react";

import { connect } from "react-redux";
import * as Http from "modules/order/in-store/redux/actions/http";
import * as Payload from "modules/order/in-store/redux/actions/payload";

const OrderComponent = React.lazy(() => import("../../views/order/order"));

class OrderContainer extends Component {
  state = {
    modal: {
      toggle: false,
    },
    error: false,
    message: "",
  };

  onSaveLoading() {
    this.changeSaveLoading(true);
  }

  offSaveLoading() {
    this.changeSaveLoading(false);
  }

  updateState(state) {
    this.setState(state);
  }

  setResponseMessage(error, description) {
    this.updateState({
      error,
      description,
    });
  }

  changeSaveLoading(status) {
    this.props.updateState({
      save_loading: status,
    });
  }

  openVariantModalHandler(product) {
    this.updateState({
      modal: {
        toggle: !this.state.modal.toggle,
        product: product,
      },
    });
  }

  handleValidSubmit(event, values) {}

  componentDidMount() {
    this.props.destroy();
  }

  componentWillUnmount() {
    this.props.destroy();
  } 
  
  render() {
    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          {!this.props.order_data_loading && (
            <OrderComponent
              {...this.state}
              {...this.props}
              updateState={this.updateState.bind(this)}
              handleValidSubmit={this.handleValidSubmit.bind(this)}
              openVariantModalHandler={this.openVariantModalHandler.bind(this)}
            />
          )}
        </Suspense>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    inStoreOrderReducer: state.inStoreOrderReducer,
  };
}

export default connect(mapStateToProps, {
  ...Http,
  ...Payload,
})(OrderContainer);
