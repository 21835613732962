import React, { Component, Fragment, Suspense } from "react";

import { empty } from "common";
import moment from "moment";
import { Map } from "immutable";

import * as toast from "components/common/toast";

export default function SalesOrderContainer(ParentComponent) {
  return class extends React.Component {
    state = {
      remarks: "",
      items: [],
      is_create_customer: false,
      order_date: moment()._d,
      save_loading: false,
      order_data_loading: false,
      payment_statuses: [{ id: "", name: "Select" }],
      payment_status: [{ id: "unpaid", name: "Unpaid" }],
      units: [{ id: "", name: "Select" }],
      payment_mode: [],
      payment_modes: [{ id: "", name: "Select" }],
      message: {
        error: false,
        description: "",
      },
    };

    constructor(props) {
      super(props);

      this.form_ref = React.createRef();
    }

    updateState(callback) {
      this.setState(callback);
    }

    clearState() {
      this.setState({
        remarks: "",
        items: [],
        is_create_customer: false,
        order_date: moment()._d,
        save_loading: false,
        order_data_loading: false,
        payment_status: [],
        payment_mode: [],
      });
    }

    getStateData() {
      const data = Map(this.state).toJS();
      delete data.units;
      delete data.save_loading;

      return {
        ...data,
      };
    }

    requestAllCommonData() {
      this.updateState({
        order_data_loading: true,
      });
      return new Promise((resolve, reject) => {
        this.props.getAllCommon((response) => {
          const data = response.data;
          if (data.success === false) {
            return;
          }
          resolve({
            payment_statuses: this.state.payment_statuses.concat(
              data.sale_order_statuses
            ),
            payment_modes: this.state.payment_modes.concat(
              data.payment_modes.data
            ),
            units: this.state.units.concat(data.units.data),
          });
        });
      });
    }

    componentDidMount() {}

    componentWillUnmount() {}

    validation() {
      // Valdate custom field
      if (!this.state.is_create_customer) {
        if (empty(this.state.customer?.value)) {
          throw "Please choose customer.";
        }
      }
      if (empty(this.state.order_date)) {
        throw "Please choose date";
      } else {
        if (!moment(this.state.order_date).isValid()) {
          throw "Date is invalid.";
        }
      }
      // Validate table required inputs
      var error = false;
      const error_message = "This is required.";
      this.updateState((state) => {
        return {
          ...state,
          customer_id: state.customer?.customer_id ?? null,
          items: state.items.map((row) => {
            row.item_error_message = null;
            row.unit_error_message = null;
            row.quantity_error_message = null;
            row.price_error_message = null;
            row.inventory_error_message = null;

            if (empty(row.item)) {
              error = true;
              row.item_error_message = error_message;
            }
            if (empty(row.unit)) {
              error = true;
              row.unit_error_message = error_message;
            }
            if (empty(row.quantity)) {
              error = true;
              row.quantity_error_message = error_message;
            }

            if (empty(row.price) && !row?.is_free) {
              error = true;
              row.price_error_message = error_message;
            }

            const selected = !row.item?.details?.item.has_variation
              ? (row.item?.details?.item.inventory ?? []).filter(
                  (inv) => inv.selected
                )
              : (row.item?.details?.item.variation.compose ?? []).filter(
                  (variant) =>
                    variant.inventory.filter((inv) => inv.selected).length > 0
                );

            if (selected.length <= 0) {
              error = true;
              row.inventory_error_message = "No selected inventory";
            }

            return row;
          }),
        };
      });
      // Throw error validation message
      if (this.state.items.length <= 0) {
        throw "Please add atleast 1 item.";
      }
      if (error) {
        throw (
          "Please correct item fields." +
          this.state.items
            .reduce((c, row) => {
              if (empty(row.item)) {
                c.push("item");
              }
              if (empty(row.unit)) {
                c.push("unit");
              }
              if (empty(row.quantity)) {
                c.push("quantity");
              }
              if (empty(row.price) && !row?.is_free) {
                c.push("price");
              }

              if (row.inventory_error_message) {
                c.push("No selected inventory");
              }

              return c;
            }, [])
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(", ")
        );
      }
    }

    handleSalesCreateOrder(e) {
      e.preventDefault();
      this.form_ref.current.submit();
    }

    handleInputChange(name, value) {
      this.setState({
        [name]: value,
      });
    }

    handleSupplierSearchOptionChange(value) {
      this.setState({
        supplier: value,
      });
    }

    handleSelectedCustomer(value) {
      this.setState({
        customer: value,
      });
    }

    handleCustomerCheckbox(checked) {
      this.setState({
        is_create_customer: checked,
      });
    }

    isStatusPaid() {
      return (
        this.state.payment_statuses.filter(
          (status) => status.name.toLowerCase() == "paid"
        )[0]?.id == this.state.original_payment_status?.id
      );
    }

    changeMessage(description, error) {
      this.setState({
        message: {
          description,
          error,
        },
      });
    }

    render() {
      console.log(this.state);
      return (
        <ParentComponent
          {...this.state}
          {...this.props}
          form_ref={this.form_ref}
          clearState={this.clearState.bind(this)}
          updateState={this.updateState.bind(this)}
          isStatusPaid={this.isStatusPaid.bind(this)}
          validation={this.validation.bind(this)}
          getStateData={this.getStateData.bind(this)}
          handleInputChange={this.handleInputChange.bind(this)}
          requestAllCommonData={this.requestAllCommonData.bind(this)}
          handleSalesCreateOrder={this.handleSalesCreateOrder.bind(this)}
          handleCustomerCheckbox={this.handleCustomerCheckbox.bind(this)}
          handleSelectedCustomer={this.handleSelectedCustomer.bind(this)}
          changeMessage={this.changeMessage.bind(this)}
        />
      );
    }
  };
}
