import { Map, List } from "immutable";

export const addBrandToSelectedTemplate = (state, action) => {
  // Do not re-add existing brand
  if (
    (state.template_selected.brands ?? []).filter(
      (d) => d.id == action.payload.id
    ).length > 0
  ) {
    return state;
  }

  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: (state.template_selected.brands ?? []).concat(action.payload),
    },
  };
};

export const addBrandItemToSelectedTemplate = (state, action) => {
  // do not add new brand items to avoid conflict on adding specifically a new brand
  const action_clone = Map(action).toJS();

  action_clone.payload.brand.items.data = [];

  state = addBrandToSelectedTemplate(state, {
    payload: action_clone.payload.brand,
  });

  // Add item
  state.template_selected.brands = state.template_selected.brands.map(
    (brand) => {
      if (brand.id == action.payload.brand.id) {
        if (
          brand.items.data.filter((item) => item.id == action.payload.item.id)
            .length <= 0
        ) {
          brand.items.data = brand.items.data.concat(action.payload.item);
        }
      }
      return brand;
    }
  );

  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: state.template_selected.brands,
    },
  };
};

export const addBrandItemVariantToSelectedTemplate = (state, action) => {
  const action_clone = Map(action).toJS();

  action_clone.payload.item.variation.compose = [];

  state = addBrandItemToSelectedTemplate(state, action_clone);

  const brand_index = state.template_selected.brands.findIndex(
    (f) => f.id == action.payload.brand.id
  );

  const item_index = state.template_selected.brands[
    brand_index
  ].items.data.findIndex((f) => f.id == action.payload.item.id);

  const brands = List(state.template_selected.brands).toJS();

  // const item_search = brands[brand_index].items.data[item_index];

  const compose = brands[brand_index].items.data[
    item_index
  ].variation.compose.filter((f) => f.id == action.payload.variant.id);

  if (compose.length <= 0) {
    brands[brand_index].items.data[item_index].variation.compose = brands[
      brand_index
    ].items.data[item_index].variation.compose.concat(action.payload.variant);
  }

  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: brands,
    },
  };

  return state;
};
