const LOAD_PRODUCT_LIST = "shopee/product/load-all";
const LOAD_PRODUCT = "shopee/product/load";
const LOADING = "shopee/product/progress-loading";
const RESPONSE = "shopee/product/response";
const UPDATE_STATUS = "shopee/product/status/update";
const DESTROY = "shopee/product/destroy";
const SUCCESS = "shopee/product/success";
const UPDATE_UNIT_COST = "shopee/product/unitcost/update";
const UPDATE_UNIT_COST_STATUS = "shopee/product/unitcost/status/update";
const UPDATE_TABLE_ROW_CELL_LOADING =
  "shopee/product/tablerowcell/status/update";

export {
  LOAD_PRODUCT_LIST,
  LOAD_PRODUCT,
  LOADING,
  RESPONSE,
  UPDATE_STATUS,
  DESTROY,
  SUCCESS,
  UPDATE_UNIT_COST,
  UPDATE_UNIT_COST_STATUS,
  UPDATE_TABLE_ROW_CELL_LOADING,
};
