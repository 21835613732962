import React, { Component, Fragment } from "react";

import "./style/variation.scss";
import { empty } from "common";
import { Trash2, Trash, Plus } from "react-feather";

export default class VariationTableView extends Component {
  render() {
    const {
      addVariant,
      removeVariant,
      addVariantSku,
      removeVariantSku,
      _updateVariantColumn: updateVariantColumn,
      _updateVariantSkuColumn: updateVariantSkuColumn,
      updateVariationTitle,
      updateComposerCellValue,
      addRightSideBarAnchor,
      variation: { data, compose, header, enabled, title, error, max },
    } = this.props;

    return (
      <div className="card">
        <div className="card-header">
          <div className="row">
            <h5>Variation&nbsp;</h5>
          </div>
        </div>
        <div className="card-body">
          <VariationDefineTable
            data={data}
            addVariant={addVariant}
            addVariantSku={addVariantSku}
            removeVariant={removeVariant}
            removeVariantSku={removeVariantSku}
            updateVariantColumn={updateVariantColumn}
            updateVariantSkuColumn={updateVariantSkuColumn}
          />
          {data.length < max && (
            <AddVariationButton
              addVariant={addVariant}
              updateVariantColumn={updateVariantColumn}
              updateVariationTitle={updateVariationTitle}
            />
          )}
          <ComposerTable
            error={error}
            data={data}
            compose={compose}
            header={header}
            removeVariant={removeVariant}
            updateVariantSkuColumn={updateVariantSkuColumn}
            updateComposerCellValue={updateComposerCellValue}
          />
        </div>
      </div>
    );
  }
}

const ComposerTable = ({
  data,
  compose,
  header,
  error,
  removeVariant,
  updateVariantSkuColumn,
  updateComposerCellValue,
}) => {
  console.log(compose);
  return (
    <Fragment>
      <table className="table-variation">
        <thead>
          <tr>
            <th colSpan="6" className="variation-title"></th>
          </tr>
          <tr>
            {header.map((h, i) => {
              return <th key={i}>{h.name}</th>;
            })}
            <th>SKU</th>
            <th>PRICE</th>
            <th>STOCKS</th>
            <th width="10px">PUBLISH</th>
          </tr>
        </thead>
        <tbody>
          {compose.map((sku, composeIndex) => {
            return (
              <tr key={sku.row}>
                {header.map((h, i) => {
                  if (sku.data.options[i].first === true) {
                    return (
                      <td rowSpan={sku.data.options[i].rowSpan} key={i}>
                        {sku.data.options[i].value}
                      </td>
                    );
                  }
                })}
                <td>
                  {sku.id}
                  <ComposeInputBox
                    id={sku.id}
                    updateHandler={updateComposerCellValue}
                    column="sku"
                    value={sku.data.sku}
                  />
                </td>
                <td>
                  <ComposeInputBox
                    id={sku.id}
                    updateHandler={updateComposerCellValue}
                    column="price"
                    value={sku.data.price === 0 ? "" : sku.data.price}
                    placeholder={0}
                  />
                </td>
                <td>
                  <ComposeInputBox
                    id={sku.id}
                    updateHandler={updateComposerCellValue}
                    column="stocks"
                    value={sku.data.stocks === 0 ? "" : sku.data.stocks}
                    placeholder={0}
                  />
                </td>
                <td>
                  <ComposeCheckbox
                    id={sku.id}
                    updateHandler={updateComposerCellValue}
                    column="status"
                    value={sku.data.status === "publish" ? true : false}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <code>
        <ol>
          {compose.map((row) => {
            return row.errors.variation.map((error, i) => {
              return (
                <li key={i}>
                  <b>SKU {row.data.sku}</b>: {error.message}
                </li>
              );
            });
          })}
        </ol>
      </code>
    </Fragment>
  );
};

const VariationDefineTable = ({
  data,
  updateVariantColumn,
  updateVariantSkuColumn,
  updateVariationTitle,
  addVariant,
  addVariantSku,
  removeVariant,
  removeVariantSku,
}) => {
  return (
    <Fragment>
      <table>
        <tbody>
          {data.map((variant, i) => {
            return (
              <Fragment key={variant.id}>
                <tr>
                  <td>Name</td>
                  <td>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="..."
                        className="form-control"
                        onChange={(e) =>
                          updateVariantColumn(
                            variant.id,
                            "title",
                            e.target.value
                          )
                        }
                        value={variant.title}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          onClick={(e) => removeVariant(variant.id)}
                          className="btn btn-sku remove"
                        >
                          <Trash />
                        </button>
                      </div>
                    </div>
                    {variant.error !== undefined && variant.error != "" && (
                      <small className="form-text text-danger">
                        {variant.error.message}
                      </small>
                    )}
                  </td>
                </tr>
                {variant.sku_data.map((sku, i) => {
                  return (
                    <tr key={sku.uuid}>
                      <td>({sku.s}) Option&nbsp;&nbsp;&nbsp;</td>
                      <td>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control clean-inputbox"
                            placeholder="..."
                            onChange={(e) =>
                              updateVariantSkuColumn(
                                variant.id,
                                sku.uuid,
                                "name",
                                e.target.value
                              )
                            }
                            value={sku.name}
                          />
                          {sku.last === true ? (
                            <div className="input-group-append">
                              <button
                                type="button"
                                onClick={(e) => addVariantSku(variant.id)}
                                className="btn btn-sku add"
                              >
                                <Plus />
                              </button>
                              <button
                                type="button"
                                onClick={(e) =>
                                  removeVariantSku(variant.id, sku.uuid)
                                }
                                className="btn btn-sku remove"
                              >
                                <Trash />
                              </button>
                            </div>
                          ) : (
                            <div className="input-group-append">
                              <button
                                type="button"
                                onClick={(e) =>
                                  removeVariantSku(variant.id, sku.uuid)
                                }
                                className="btn btn-sku remove"
                              >
                                <Trash />
                              </button>
                            </div>
                          )}
                        </div>
                        {sku.error !== undefined && sku.error != "" && (
                          <small className="form-text text-danger">
                            {sku.error.message}
                          </small>
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan="2">&nbsp;</td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

const DefaultRow = ({
  id,
  uuid,
  s,
  sku,
  name,
  price,
  stocks,
  status,
  removeVariant,
  updateVariantSkuColumn,
}) => {
  return (
    <tr>
      <td>{sku}</td>
      <td>
        <InputBox
          updateVariantSkuColumn={updateVariantSkuColumn}
          value={name}
          column="name"
          id={id}
          uuid={uuid}
        />
      </td>
      <td>
        <InputBox
          updateVariantSkuColumn={updateVariantSkuColumn}
          value={price}
          column="price"
          id={id}
          uuid={uuid}
        />
      </td>
      <td>
        <InputBox
          updateVariantSkuColumn={updateVariantSkuColumn}
          value={stocks}
          column="stocks"
          id={id}
          uuid={uuid}
        />
      </td>
      <td className="text-center">
        <Checkbox
          updateVariantSkuColumn={updateVariantSkuColumn}
          value={status}
          column="status"
          id={id}
          uuid={uuid}
        />
      </td>
    </tr>
  );
};

const Checkbox = ({ updateVariantSkuColumn, id, uuid, column, value }) => {
  return (
    <input
      type="checkbox"
      className="custom-control-input"
      checked={value === true ? "checked" : ""}
      onChange={(e) =>
        updateVariantSkuColumn(id, uuid, column, e.target.checked)
      }
      className="checkbox_animated"
      id={"status-" + id}
    />
  );
};

const InputBox = ({ updateHandler, id, uuid, column, value, disabled }) => {
  return (
    <input
      type="text"
      onChange={(e) => updateHandler(id, uuid, column, e.target.value)}
      value={value}
      name={column}
      placeholder="..."
      className="no-border-input form-control"
      disabled={disabled === true ? true : ""}
    />
  );
};

const ComposeInputBox = ({
  updateHandler,
  id,
  column,
  value,
  disabled,
  placeholder,
}) => {
  return (
    <input
      type="text"
      onChange={(e) => updateHandler(id, column, e.target.value)}
      value={value}
      name={column}
      placeholder={placeholder}
      className="no-border-input form-control"
      disabled={disabled === true ? true : ""}
    />
  );
};

const ComposeCheckbox = ({ updateHandler, id, column, value }) => {
  return (
    <input
      type="checkbox"
      className="custom-control-input"
      checked={value === true ? "checked" : ""}
      onChange={(e) => updateHandler(id, column, e.target.checked)}
      className="checkbox_animated"
    />
  );
};

const AddVariationButton = ({ addVariant }) => {
  return (
    <div className="row m-t-50">
      <div className="col-sm-12">
        <button
          className="btn btn-lg"
          onClick={(e) => {
            addVariant();
          }}
        >
          ADD VARIATION
        </button>
      </div>
    </div>
  );
};
