import React, { Component, Fragment } from "react";
import Modal from "react-responsive-modal";
import Spinner from "components/common/spinner";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Message_container from "components/common/message-container";

export default class FormComponent extends Component {
  render() {
    const {
      open,
      onCloseModal,
      handleSubmit,
      handleInvalidSubmit,
      handleChange,
      loading,
      response,
      name,
      description,
    } = this.props;

    return (
      <Fragment>
        <Modal open={open} onClose={onCloseModal} center>
          <AvForm
            className="needs-validation category-form"
            onValidSubmit={handleSubmit.bind(this)}
            onInvalidSubmit={this.handleInvalidSubmit}
            style={{ minWidth: 400 + "px" }}
          >
            <div className="modal-header">
              <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                Create Brand
              </h5>
            </div>
            <div className="modal-body">
              <Message_container
                message={response?.message ?? ""}
                error={response?.error ?? false}
              />
              <div className="form-group row">
                <div className="col-xl-12 col-md-12">
                  <AvField
                    value={name}
                    label="Brand Name"
                    onChange={handleChange}
                    className="form-control"
                    name="name"
                    id="name"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xl-12 col-md-12">
                  <AvField
                    style={{ minHeight: 100 + "px" }}
                    value={description}
                    label="Brand Description"
                    onChange={handleChange}
                    className="form-control"
                    name="description"
                    id="description"
                    type="textarea"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => onCloseModal()}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                {loading === true ? <Spinner loading={true} /> : ""}Save
              </button>
            </div>
          </AvForm>
        </Modal>
      </Fragment>
    );
  }
}
