import React, { useState } from "react";
import uuid from "react-uuid";
import { empty, emptyNumeric, isNumber } from "common";
import { List } from "immutable";
import Wholesale from "interfaces/product/wholesale";

const defaultTier = [
  {
    id: uuid(),
    s: 1,
    price: "",
    min_stocks: "",
    max_stocks: "",
    max: false,
  },
];

const getDefaultLine = () => {
  return List(defaultTier).toJS();
};

const sequenceUpdater = (state) => {
  return {
    ...state,
    wholesale: {
      ...state.wholesale,
      data: state.wholesale.data.map((tier, i) => {
        tier.s = i + 1;
        return tier;
      }),
    },
  };
};

const createNewTier = (state) => {
  const newTier = getDefaultLine()[0];
  newTier.id = uuid();
  newTier.max = true;
  newTier.max_stocks = state.fields.wholesale.max;

  return newTier;
};

export const addTier = (state, action) => {
  const newTier = createNewTier(state);
  const previousMaxTierIndex = state.wholesale.data.findIndex(
    (tier) => tier.max_stocks === state.fields.wholesale.max
  );
  if (previousMaxTierIndex !== -1) {
    const previousMaxTier = state.wholesale.data[previousMaxTierIndex];
    previousMaxTier.max = false;
    previousMaxTier.max_stocks = parseInt(previousMaxTier.min_stocks) + 1;
    newTier.min_stocks = parseInt(previousMaxTier.min_stocks ?? 0) + 2;
  }
  state = {
    ...state,
    wholesale: {
      ...state.wholesale,
      data: state.wholesale.data.concat(newTier),
    },
  };

  return sequenceUpdater(state);
};

export const removeTier = (state, action) => {
  state = {
    ...state,
    wholesale: {
      ...state.wholesale,
      data: state.wholesale.data.filter(
        (tier) => tier.id !== action.payload.id
      ),
    },
  };
  // Update last row to max if needed
  if (state.wholesale.data.filter((tier) => tier.max === true).length <= 0) {
    const lastTier = state.wholesale.data[state.wholesale.data.length - 1];
    lastTier.max = true;
    lastTier.max_stocks = state.fields.wholesale.max;
  }
  // Atleast 2 rows available by default
  if (state.wholesale.data.length <= 1) {
    const newTier = createNewTier(state);
    const previousMaxTierIndex = state.wholesale.data.findIndex(
      (tier) => tier.max_stocks === state.fields.wholesale.max
    );
    if (previousMaxTierIndex !== -1) {
      const previousMaxTier = state.wholesale.data[previousMaxTierIndex];
      previousMaxTier.max = false;
      previousMaxTier.max_stocks = "";
    }

    state = {
      ...state,
      wholesale: {
        ...state.wholesale,
        data: state.wholesale.data.concat(newTier),
      },
    };
  }

  return sequenceUpdater(state);
};
/*
    TODO: Set automatically the new tier min_stocks
    formula: min_stocks = prev_max_stocks + 1
    Completed: April 20, 2020 1:03pm / Vic Tagupa
*/
export const updateTierColumn = (state, action) => {
  const minimumStocksInitialValue = () => {
    if (action.payload.column === state.fields.wholesale.max_stocks) {
      state.wholesale.data[state.wholesale.data.length - 1].min_stocks =
        parseInt(action.payload.value) + 1;
    }

    return state;
  };

  state = {
    ...state,
    wholesale: {
      ...state.wholesale,
      data: state.wholesale.data.map((d) => {
        if (d.id == action.payload.id) {
          d[action.payload.column] = action.payload.value;
        }
        return d;
      }),
    },
  };

  state = minimumStocksInitialValue();

  return state;
};

export const clear = (state, action) => {
  const tier1 = createNewTier(state);
  const tier2 = createNewTier(state);
  tier1.max = false;
  tier1.max_stocks = "";
  return {
    ...state,
    wholesale: {
      ...state.wholesale,
      data: [tier1, tier2],
      enabled: false,
      error: [],
    },
  };
};

export const enableWholesale = (state, action) => {
  state = clear(state, action);
  return sequenceUpdater({
    ...state,
    wholesale: {
      ...state.wholesale,
      enabled: action.payload,
    },
  });
};

export const showErrorInfo = (state, action) => {
  return {
    ...state,
    wholesale: {
      ...state.wholesale,
      error: action.payload.message,
    },
  };
};

export const updateFullWholesale = (state, action) => {
  return {
    ...state,
    wholesale: {
      data: action.payload.data,
      enabled: true,
      error: [],
    },
  };
};
