import React, { Component, Fragment } from "react";

import "./style/product.scss";
import "./style/modal.scss";
import Breadcrumb from "../../../../../components/common/breadcrumb";
import Modal from "react-responsive-modal";
import FormBasicContainer from "../container/form-basic";
import ButtonWholesaleVariationsContainer from "../container/button-wholesale-variation";
import SellCostContainer from "../container/sell-cost";
import DetailedDescriptionContainer from "../container/detailed-description";
import ShippingInfoContainer from "../container/shipping-info";
import MediaContainer from "../container/media";
import SEOInfoContainer from "../container/seo-info";
import CampaignContainer from "../container/campaign";
import SidebarContainer from "../container/sidebar";
import ButtonContainer from "../container/button";
import { VaField, VaForm } from "../service/va-form";
import Spinner from "react-bootstrap/Spinner";

export default class AddProductView extends Component {
  render() {
    const {
      addRightSideBarAnchor,
      wholesaleVariationContainer,
      enableSellCostContainer,
      rightSidebarAnchor,
      loading,
      response,
    } = this.props;

    return (
      <Fragment>
        <Breadcrumb title="Add Products" parent="Product" />
        <div className="container-fluid">
          <SidebarContainer data={rightSidebarAnchor} />
          <div className="row product-add">
            {response?.error === true && (
              <div className="col-xl-12">
                <div className="alert alert-danger page-error">
                  <p>Oops! There are errors that must be fixed.</p>
                  <p>&nbsp;</p>
                  <ul>
                    {response.message.map((m, i) => {
                      return (
                        <li key={i}>
                          <p>
                            {i + 1}. {m}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}

            <div className="col-xl-12">
              <div
                className="card"
                ref={(ref) => {
                  addRightSideBarAnchor("Basic Information", "basic-info", ref);
                }}
              >
                <div className="card-header">
                  <h5>Basic Information</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <FormBasicContainer />
                  </div>
                </div>
              </div>

              <SellCostContainer
                addRightSideBarAnchor={addRightSideBarAnchor}
              />

              <div className="card card-without-bg">
                <div className="card-body">
                  <ButtonWholesaleVariationsContainer />
                </div>
              </div>
              <div>{wholesaleVariationContainer}</div>
              <div
                className="card"
                ref={(ref) => {
                  addRightSideBarAnchor(
                    "Detailed Description",
                    "detailed-desc",
                    ref
                  );
                }}
              >
                <div className="card-header">
                  <h5>Detailed Description</h5>
                </div>
                <div className="card-body">
                  <DetailedDescriptionContainer />
                </div>
              </div>

              <div
                className="card"
                ref={(ref) => {
                  addRightSideBarAnchor("Media", "media", ref);
                }}
              >
                <div className="card-header">
                  <h5>Media</h5>
                </div>
                <div className="card-body">
                  <MediaContainer />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div
                className="card"
                ref={(ref) => {
                  addRightSideBarAnchor(
                    "Shipping Information",
                    "shipping-info",
                    ref
                  );
                }}
              >
                <div className="card-header">
                  <h5>Shipping Information</h5>
                </div>
                <div className="card-body">
                  <ShippingInfoContainer />
                </div>
              </div>
              <div
                className="card"
                ref={(ref) => {
                  addRightSideBarAnchor(
                    "Campaign Information",
                    "campaign-info",
                    ref
                  );
                }}
              >
                <div className="card-header">
                  <h5>Campaign</h5>
                </div>
                <div className="card-body">
                  <CampaignContainer />
                </div>
              </div>
              <div
                className="card"
                ref={(ref) => {
                  addRightSideBarAnchor("SEO Information", "seo-info", ref);
                }}
              >
                <div className="card-header">
                  <h5>SEO Information</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <SEOInfoContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <ButtonContainer />
            </div>
          </div>
        </div>
        <Modal
          open={false}
          showCloseIcon={false}
          focusTrapped={false}
          onClose={() => {}}
          closeOnEsc={false}
          center
          classNames={{
            modal: "modalLoading",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </Modal>
      </Fragment>
    );
  }
}
