import { empty } from "common";

const ListPlayers = (base) => {
  const all = () => {
    return base.state.players;
  };

  const activePlayersByPage = (players) => {
    let players_ = empty(players) ? all() : players;
    return players_.slice(
      base.state.paginations.players.page * base.state.paginations.limit,
      base.state.paginations.limit * (base.state.paginations.players.page + 1)
    );
  };

  const updateActiveStatus = () => {
    const activePlayers = activePlayersByPage();

    return all().map((player) => {
      player.changeActive(false);
      const active = activePlayers.filter((o) => o.getId() === player.getId());
      if (active.length > 0) {
        player.changeActive(true);
      }
      return player;
    });
  };

  const allActive = () => {
    updateActiveStatus();
    return base.state.players.filter((player) => player.isActive() === true);
  };

  return {
    all,
    allActive,
  };
};

export default ListPlayers;
