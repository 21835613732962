import React, { Component, Fragment, useRef } from "react";
import Modal from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruckLoading,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { empty } from "common";

import Message_container from "components/common/message-container";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default class ImportModalComponent extends Component {
  render() {
    const {
      handleCloseImportModal,
      handleFileChange,
      handleSetFileRef,
      handleSubmit,
      import_modal,
      upload_progress,
      error,
      message,
    } = this.props;

    const style = {
      closeButton: {
        top: "-14px",
        right: "-8px",
      },
      closeIcon: {
        color: "#fff",
        fontSize: "20px",
      },
      modalContainer: {
        width: "400px",
      },
    };

    return (
      <Fragment>
        <Modal
          open={import_modal.open}
          onClose={handleCloseImportModal}
          closeIcon={FontAwesomeIcon}
          classNames={{
            modal: "tickle_modal",
          }}
          styles={style}
          center
        >
          <Row className="m-t-20">
            <Col>
              <Message_container error={error} message={message} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit} className="m-t-20 m-l-20 m-b-20">
                <Form.Group>
                  <Form.File
                    name="file"
                    label="Upload excel file"
                    onChange={handleFileChange}
                    ref={(ref) => {
                      handleSetFileRef(ref);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Button type="submit" className="center" variant="primary">
                    {upload_progress ? "Uploading..." : "Upload"}
                  </Button>{" "}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Modal>
      </Fragment>
    );
  }
}
