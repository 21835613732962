import OpponentClass from "./opponent/index";
import PlayerClass from "./player/index";
import GameComponent from "../gameboard/game-component";
import IsPointerClass from "./pointer";
import StartButtonClass from "./start-button";
import LinkButtonClass from "./link-button";

const Migration = (base) => {
  const Player = PlayerClass(base);
  const Opponent = OpponentClass(base);
  const IsPointer = new IsPointerClass();
  const StartButton = new StartButtonClass(base);
  const LinkButton = new LinkButtonClass(base);

  const target = (x, y) => {
    if (base.props.migration.lock) return;

    Player.updatePlayers(Player.updateLine(x, y));
    playerTargetOpponent();

    base.gameboardUpdater();
  };

  const pointer = (x, y) => {
    if (base.props.migration.lock) return;

    IsPointer.setCoordinates(x, y);
    playerPointer();
    opponentPointer();
    buttonPointer();

    base.gameboardUpdater();
  };

  const buttonPointer = () => {
    if (base.props.migration.lock) return;

    if (IsPointer.pointed(base.state.start_button)) {
      if (!base.state.start_button.isLocked()) {
        base.migrate();
      }
    }

    if (IsPointer.pointed(base.state.link_button)) {
      if (!base.state.link_button.isLocked()) {
        base.link();
      }
    }
  };

  const playerPointer = () => {
    const players = Player.listPlayers.allActive().map((player) => {
      if (IsPointer.pointed(player.getComponent())) {
        player = Player.createLine(player, IsPointer.getX(), IsPointer.getY());
      }
      return player;
    });

    Player.updatePlayers(players);
  };

  const opponentPointer = () => {
    Opponent.ListOpponents.allActive().forEach((opponent) => {
      if (IsPointer.pointed(opponent.getComponent())) {
        Player.listPlayers.allActive().map((player) => {
          let opponent_ = player.getOpponents().reduce((c, o) => {
            if (o.opponent.sameValueAs(opponent)) {
              c = o;
            }
            return c;
          }, null);
          if (opponent_) {
            detachPlayerOpponent(player, opponent);
          }
        });
      }
    });
  };

  const detachPlayerOpponent = (player, opponent) => {
    Player.detachOpponent(player, opponent);

    // delete from db
    // base.deleteConnection(player, opponent);
  };

  const playerTargetOpponent = () => {
    const players = Player.listPlayers.allActive().map((player) => {
      Opponent.ListOpponents.allActive().map((opponent) => {
        if (player.hasOpenLines()) {
          player.getOpenLines().forEach((line) => {
            if (
              line.gotHit(opponent.getComponent()) &&
              !player.isAttached(opponent)
            ) {
              player = Player.character(player).connectOpponent(line, opponent);
              opponent = Opponent.character(opponent).connectPlayer(player);

              // store to db
              // base.storeConnection(player, opponent);
            }
          });
        }
        return opponent;
      });
      return player;
    });

    Player.updatePlayers(players);
  };

  const buttons = {
    activate() {
      StartButton.activate();
      // LinkButton.activate();
    },
    startButton: StartButton,
    linkButton: LinkButton,
  };

  return {
    opponent: Opponent,
    player: Player,
    buttons: buttons,
    pointer,
    target,
  };
};

export default Migration;
