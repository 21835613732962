import * as type from "../../constants/variation";

export const addVariant = () => {
  return {
    type: type.ADD_VARIANT,
  };
};

export const addVariantSku = (id) => {
  return {
    type: type.ADD_SKU,
    payload: { id },
  };
};

export const removeVariantSku = (id, uuid) => {
  return {
    type: type.REMOVE_SKU,
    payload: { id, uuid },
  };
};
export const removeVariant = (id) => {
  return {
    type: type.REMOVE,
    payload: { id },
  };
};

export const updateVariantColumn = (id, column, value) => {
  return {
    type: type.UPDATE_VARIANT_COLUMN,
    payload: { id, column, value },
  };
};

export const updateVariantSkuColumn = (id, uuid, column, value) => {
  return {
    type: type.UPDATE_SKU_COLUMN,
    payload: { id, uuid, column, value },
  };
};

export const updateVariationTitle = (payload) => {
  return {
    type: type.UPDATE_TITLE,
    payload: payload,
  };
};

export const clear = () => {
  return {
    type: type.CLEAR,
  };
};

export const enableVariation = (payload) => {
  return {
    type: type.ENABLE,
    payload: payload,
  };
};

export const addSkuError = (error) => {
  return {
    type: type.ADD_SKU_ERROR,
    payload: error,
  };
};

export const addVariantError = (error) => {
  return {
    type: type.ADD_VARIANT_ERROR,
    payload: error,
  };
};

export const updateComposerCellValue = (id, column, value) => {
  return {
    type: type.UPDATE_COMPOSER_CELL_VALUE,
    payload: { id, column, value },
  };
};

export const addComposerCellValueError = (id, error) => {
  return {
    type: type.ADD_COMPOSER_CELL_VALUE_ERROR,
    payload: { id, error },
  };
};

export const updateFullVariation = (data, compose) => {
  return {
    type: type.UPDATE_FULL_VARIATION,
    payload: { data, compose },
  };
};

export const updateComposer = () => {
  return {
    type: type.UPDATE_COMPOSER,
  };
};
