import Http from "api/http";
import * as typeCategory from "../constants/category";
import * as typeProduct from "../constants/product";
import * as categoryPayload from "../actions/category/payload";
import { setLoading, setResponse, callback } from "./payload";

export const getProductSettingsRequest = () => (dispatch) => {
  return Http.GET("product/product/settings")
    .then(function (response) {
      dispatch({
        type: typeProduct.FETCH_SETTINGS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getProductSettings = () => (dispatch) => {
  return function (dispatch) {
    return dispatch(getProductSettingsRequest());
  };
};

export const getCategoryTree = () => (dispatch) => {
  return Http.POST("product/product/category/getCategoryTree")
    .then(function (response) {
      dispatch(
        categoryPayload.createCategoryMainBox({
          categories: response.data,
        })
      );
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getAllCommon = (_callback) => (dispatch) => {
  return Http.GET("product/product/get-all-common")
    .then(function (response) {
      dispatch(callback(_callback(response.data)));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const migrate = (players, _callback) => (dispatch) => {
  return Http.POST("product/product/migrate", { players })
    .then(function (response) {
      dispatch(callback(_callback(response.data)));
    })
    .catch(function (error) {
      console.log(error);
      console.log(error.message);
    });
};

export const sync = (shops, _callback) => (dispatch) => {
  return Http.POST("product/product/sync", { shops })
    .then(function (response) {
      dispatch(callback(_callback(response.data)));
    })
    .catch(function (error) {
      console.log(error);
      console.log(error.message);
    });
};

export const link = (shops, _callback) => (dispatch) => {
  return Http.POST("product/product/link", { shops })
    .then(function (response) {
      dispatch(callback(_callback(response.data)));
    })
    .catch(function (error) {
      console.log(error);
      console.log(error.message);
    });
};
