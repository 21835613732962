import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";

const BatchUpdateComponent = (props) => {
  return (
    <>
      <form>
        <div className="form-group row">
          <div className="col-xl-6 col-md-6">
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Re-order level"
                aria-label="Re-order level"
                aria-describedby="Re-order level"
                onChange={(e) => {
                  props.setAllReOrderLevel(e.target.value);
                }}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={(e) => {
                    props.applyToAllItemsAndVariants();
                  }}
                >
                  Apply to All
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
      </form>
    </>
  );
};

export default BatchUpdateComponent;
