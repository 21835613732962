import React, { Component, Fragment } from "react";

import { connect } from "react-redux";

import * as Action_group_permission from "redux/actions/user/group-permission/index";
import * as Permission from "redux/actions/user/permission/index";

import Spinner from "components/common/spinner";
import Message_container from "components/common/message-container";

export class List_permission extends Component {
  constructor(props) {
    super(props);
    this.setActionSelected = this.setActionSelected.bind(this);
  }

  setSelected(id) {
    this.props.updateGroupCheckBox(id);
  }

  setActionSelected(actionGroupId, actionId) {
    this.props.updateGroupActionCheckbox(actionGroupId, actionId);
  }

  render() {
    const { data } = this.props.permission;

    return (
      <Fragment>
        <div className="row">
          <div className="col-sm-12">
            <h5>Permission List</h5>
            <ol key="list-permission">
              {data.map((group, i) => {
                return (
                  <li key={"y" + i}>
                    <label>
                      <input
                        type="checkbox"
                        checked={group.checked === true ? "checked" : ""}
                        onChange={(d) => {
                          this.setSelected(group.id);
                        }}
                        className="checkbox_animated"
                        name={"group-check" + i}
                      />
                      {group.name}
                    </label>

                    <ol key={"x" + i}>
                      {group.actions.map((action, i) => {
                        return (
                          <li key={"xy" + i}>
                            <label>
                              <input
                                type="checkbox"
                                checked={
                                  action.checked === true ? "checked" : ""
                                }
                                data-group-id={group.id}
                                data-action-id={action.id}
                                onChange={(e) =>
                                  this.setActionSelected(group.id, action.id)
                                }
                                className="checkbox_animated"
                                name={"group-action-check" + i}
                              />
                              {action.name}
                            </label>
                          </li>
                        );
                      })}
                    </ol>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    actionGroup: state.actionGroupPermission,
    action: state.actionPermission,
    permission: state.permission,
  };
}

export default connect(mapStateToProps, {
  updateGroupCheckBox: (d) => Permission.updateGroupCheckBox(d),
  updateGroupActionCheckbox: (g, a) =>
    Permission.updateGroupActionCheckbox(g, a),
})(List_permission);
