import Http from "api/http";
import { empty } from "common";

export const connectingResult = (next, store, action) => {
  if (typeof action.payload.callback == "function") {
    action.payload.callback(action.payload.response);
  }
};

export const disconnectingResult = (next, store, action) => {
  if (typeof action.payload.callback == "function") {
    action.payload.callback(action.payload.response);
  }
};
