import React, { Component, Fragment, useState } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

import matchSorter from "match-sorter";

const ListSalesDatatableComponent = (props) => {
  const {
    fetchSales,
    pages,
    transactions: { data: data, loading: loading },
  } = props;

  return (
    <div>
      <ReactTable
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        // onFetchData={fetchSales} // Request new data when things change
        // manual
        filterable
        className="-striped -highlight"
        showPagination={true}
        howPaginationTop={true}
        showPaginationBottom={true}
        defaultPageSize={50}
        pageSizeOptions={[20, 50, 100]}
        collapseOnDataChange={false}
        freezeWhenExpanded={true}
        minRows={0}
        columns={[
          {
            Header: <b>{"S#"}</b>,
            filterable: false,
            width: 50,
            style: {
              textAlign: "left",
              width: "3%",
            },
            Cell: (row) => <div>{row.index + 1}</div>,
          },
          {
            Header: <b>{"Order Date"}</b>,
            accessor: "order_date",
            filterable: false,
            style: {
              textAlign: "left",
            },
          },
          {
            Header: <b>{"Order No."}</b>,
            accessor: "order_no",
            sortable: true,
            filterable: true,
            filterAll: true,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["order_no"] }),
          },
          {
            Header: <b>{"Total Quantity"}</b>,
            accessor: "income.total_quantity",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Total Sales"}</b>,
            accessor: "income.total_sales.text",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: <b>{"Total Cost"}</b>,
            accessor: "income.total_cost.text",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: <b>{"SF"}</b>,
            accessor: "income.total_sf.text",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: <b>{"SF Rebates"}</b>,
            accessor: "income.total_sfrebates.text",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: <b>{"T.S.Rebates"}</b>,
            accessor: "income.total_seller_rebates.text",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: <b>{"T.Rebates"}</b>,
            accessor: "income.total_rebates.text",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: <b>{"Total Charges"}</b>,
            accessor: "income.total_charges.text",
            filterable: false,
            sortable: true,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: <b>{"Net"}</b>,
            accessor: "income.net.text",
            filterable: false,
            sortable: true,
            style: {
              textAlign: "right",
            },
          },
        ]}
        SubComponent={(order) => {
          return (
            <div style={{ padding: "20px" }}>
              <em>Order Item Details</em>
              <br />
              <ReactTable
                data={order.original.items}
                collapseOnDataChange={false}
                defaultPageSize={5}
                showPagination={true}
                columns={[
                  {
                    Header: <b>{"Product"}</b>,
                    accessor: "item_name",
                    filterable: false,
                    style: {
                      textAlign: "left",
                    },
                    Cell: (row) => (
                      <div>
                        {row.original.item_name}
                        <br />
                        <small>
                          <em>{row.original.variation_name}</em>
                        </small>
                      </div>
                    ),
                  },
                  {
                    Header: <b>{"Quantity"}</b>,
                    accessor: "quantity",
                    filterable: false,
                    style: {
                      textAlign: "center",
                    },
                  },
                  {
                    Header: <b>{"Retail Price"}</b>,
                    accessor: "original_price.text",
                    filterable: false,
                    style: {
                      textAlign: "center",
                    },
                  },
                  {
                    Header: <b>{"Sale Price"}</b>,
                    accessor: "deal_price.text",
                    filterable: false,
                    style: {
                      textAlign: "right",
                    },
                  },
                  {
                    Header: <b>{"Unit Cost"}</b>,
                    accessor: "unit_cost.text",
                    filterable: false,
                    style: {
                      textAlign: "right",
                    },
                  },
                  {
                    Header: <b>{"Gross Sales"}</b>,
                    accessor: "gross_sales.text",
                    filterable: false,
                    style: {
                      textAlign: "right",
                    },
                  },
                  {
                    Header: <b>{"Charges"}</b>,
                    accessor: "charge_fees.text",
                    filterable: false,
                    style: {
                      textAlign: "right",
                    },
                  },
                  {
                    Header: <b>{"Transaction Fee"}</b>,
                    accessor: "transaction_fee.text",
                    filterable: false,
                    style: {
                      textAlign: "right",
                    },
                  },
                  {
                    Header: <b>{"Net"}</b>,
                    accessor: "net.text",
                    filterable: false,
                    style: {
                      textAlign: "right",
                    },
                  },
                ]}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default ListSalesDatatableComponent;
