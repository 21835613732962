import * as type from "../constants/index";
import uuid from "react-uuid";

const initialState = {
  basket: [],
  customer: {},
  shipping: {},
};

function getId(state, id) {
  const find = state.basket.find((basket) => basket.id == id);
  if (!find) {
    return id;
  }

  return getId(state, uuid());
}

export default function InStoreOrderReducer(state = initialState, action) {
  switch (action.type) {
    case type.DESTROY_BASKET:
      return {
        ...state,
        basket: [],
      };

    case type.ADD_ITEM_TO_BASKET:
      const find = state.basket.find(
        (item) =>
          action.payload.product.id == item.product.id &&
          (action.payload?.variant?.id ?? null) == (item?.variant?.id ?? null)
      );

      if (find) {
        find.quantity += action.payload.quantity;
      } else {
        state.basket = state.basket.concat({
          id: getId(state, uuid()),
          product: action.payload.product,
          variant: action.payload?.variant ?? null,
          quantity: action.payload.quantity,
          tag: action.payload.tag,
        });
      }

      return {
        ...state,
        basket: [...state.basket],
      };
    case type.REMOVE_ITEM_FROM_BASKET:
      return {
        ...state,
        basket: state.basket.filter(
          (item) =>
            action.payload.product.id != item.product.id &&
            (action.payload?.variant?.id ?? null) != (item?.variant?.id ?? null)
        ),
      };

    case type.CHANGE_BASKET_ITEM_QUANTITY:
      return {
        ...state,
        basket: state.basket.map((item) => {
          if (action.payload.product.id == item.id) {
            if (
              (action.action.payload?.variant?.id ?? null) ==
              (item?.variant?.id ?? null)
            ) {
              item.quantity = action.payload.quantity;
            }
          }
          return item;
        }),
      };

    case type.INCREASE:
      var basketIndex = state.basket.findIndex(
        (basket) => basket.id == action.payload.id
      );
      ++state.basket[basketIndex].quantity;
      return {
        ...state,
      };

    case type.DECREASE:
      var basketIndex = state.basket.findIndex(
        (basket) => basket.id == action.payload.id
      );
      --state.basket[basketIndex].quantity;
      // Remove when quantity is 0
      if (state.basket[basketIndex].quantity < 1) {
        delete state.basket[basketIndex];
      }
      state.basket = state.basket.filter((n) => n);
      return {
        ...state,
      };
    case type.CHANGE_CUSTOMER_DATA:
      return {
        ...state,
        customer: {
          ...state.customer,
          [action.payload.name]: action.payload.value,
        },
      };
      break;
    case type.CHANGE_SHIPPING_DATA:
      return {
        ...state,
        shipping: {
          ...state.shipping,
          [action.payload.name]: action.payload.value,
        },
      };
      break;
  }

  return state;
}
