import Http from "api/http";

const SelectShop = (inputValue, callback) => {
  const defaultOptions = (label) => [
    {
      value: "",
      label: label,
    },
  ];

  Http.POST("product/product/shop/search", {
    payload: { criteria: { name: inputValue } },
  })
    .then(function (response) {
      if (response.data?.success === false) {
        callback(defaultOptions(response.data.message));
      } else {
        callback(response.data);
      }
    })
    .catch(function (error) {
      callback(defaultOptions(error?.response?.data?.message));
    });
};

export default SelectShop;
