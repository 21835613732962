import { getDefaultSku, afterState } from "./common";

const AddSku = (state, action) => {
  const variantIndex = state.variation.data.findIndex(
    (variant) => variant.id === action.payload.id
  );
  const variation = state.variation.data;
  const skuData = getDefaultSku();

  skuData.last = true;
  variation[variantIndex].sku_data = variation[variantIndex].sku_data.map(
    (variant) => {
      variant.last = false;
      return variant;
    }
  );
  variation[variantIndex].sku_data = variation[variantIndex].sku_data.concat(
    skuData
  );

  return afterState({
    ...state,
    variation: {
      ...state.variation,
      data: variation,
    },
  });
};

export default AddSku;
