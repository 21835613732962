import React, { useState, useEffect } from "react";

const Message_container = (props) => {
  const [message, setMessage] = useState({
    error: false,
    description: "",
  });

  useEffect(() => {
    setMessage({
      error: props.error,
      description: props.message,
    }); 
  }, [props.error, props.message]);

  if (message.error == true) {
    return (
      <div
        className="alert alert-danger"
        role="alert"
        dangerouslySetInnerHTML={{
          __html: message.description,
        }}
      ></div>
    );
  }
  
  return (message.error == false && message.description != "") && (
    <div
      className="alert alert-success"
      role="alert"
      dangerouslySetInnerHTML={{
        __html: message.description,
      }}
    ></div>
  ) 
};

export default Message_container;
