import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import Message_container from "components/common/message-container";

import "./style/gameboard.scss";

export default class LinkReferenceComponent extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Link Reference" parent="product" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <Message_container
                message={this.props.message}
                error={this.props.error}
              />
              <div className="linkref-gamecontainer">
                <canvas
                  className="linkref-gameboard-canvas"
                  onMouseDown={(e) => {
                    this.props.onmousedown(e);
                  }}
                  onMouseMove={(e) => {
                    this.props.onmousemove(e);
                  }}
                ></canvas>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
