import * as type from "../constants/index";

export const addItemToBasket = (product, quantity, variant, tag) => {
  return {
    type: type.ADD_ITEM_TO_BASKET,
    payload: { product, variant, quantity, tag },
  };
};

export const removeItemFromBasket = (product, variant) => {
  return {
    type: type.REMOVE_ITEM_FROM_BASKET,
    payload: { product, variant },
  };
};

export const changeItemQuantityFromBasket = (product, variant, quantity) => {
  return {
    type: type.CHANGE_BASKET_ITEM_QUANTITY,
    payload: { product, variant, quantity },
  };
};

export const destroy = () => {
  return {
    type: type.DESTROY_BASKET,
  };
};

export const increase = (id) => {
  return {
    type: type.INCREASE,
    payload: { id },
  };
};

export const decrease = (id) => {
  return {
    type: type.DECREASE,
    payload: { id },
  };
};

export const changeCustomerData = (name, value) => {
  console.log({ name, value });
  return {
    type: type.CHANGE_CUSTOMER_DATA,
    payload: { name, value },
  };
};

export const changeShippingData = (name, value) => {
  return {
    type: type.CHANGE_SHIPPING_DATA,
    payload: { name, value },
  };
};
