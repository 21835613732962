import {
  responseSuccess,
  responseFailed,
  setLoading,
  addOrUpdateImage,
} from "../actions/media/payload";
import SaveMediaTemp from "../actions/save-media-temp";
import * as toast from "components/common/toast";

export const imageValidator = (next, store, action) => {
  if (
    !store
      .getState()
      .productReducer.settings.image_accepted_types.includes(
        action.payload.image.type
      )
  ) {
    toast.error(
      store.getState().productReducer.labels.error.media.image_accepted_types
    );
    return responseFailed(
      store.getState().productReducer.labels.error.media.image_accepted_types
    );
  }
  if (
    action.payload.image.size / 1000000 >
    store.getState().productReducer.settings.max_upload_image_size
  ) {
    toast.error(
      store.getState().productReducer.labels.error.media
        .upload_image_maximum_file_size
    );
    return responseFailed(
      store.getState().productReducer.labels.error.media
        .upload_image_maximum_file_size
    );
  }
  console.log(action.payload.image);
  next(setLoading(action.payload.key, true));
  store.dispatch(
    SaveMediaTemp(next, store, {
      payload: { key: action.payload.key, image: action.payload.image },
    })
  );
  // let reader = new FileReader();
  // reader.onload = () => {
  //   next(setLoading(action.payload.key, true));
  //   store.dispatch(
  //     SaveMediaTemp(next, store, {
  //       payload: { key: action.payload.key, image: reader.result },
  //     })
  //   );
  //   // next(addOrUpdateImage(action.payload.key, reader.result));
  // };
  // reader.readAsDataURL(action.payload.image);
};
