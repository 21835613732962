import React from "react";
import { VaNumber, VaForm } from "../service/va-form";

const SellCostView = ({ addRightSideBarAnchor, handleInputChange, form }) => {
  return (
    <div
      className="card"
      ref={(ref) => {
        addRightSideBarAnchor("Selling Information", "sell-cost-info", ref);
      }}
    >
      <div className="card-header">
        <h5>Selling & Cost Information</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="digital-add needs-validation col-sm-6">
            <VaForm className="selling-info">
              <div className="row">
                <div className="form-group col-sm-6">
                  <label className="col-form-label">
                    <span>*</span> Price
                  </label>
                  <VaNumber
                    name="price"
                    className="form-control"
                    required={true}
                    value={form.selling.price}
                    data-min="1"
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="form-group col-sm-6">
                  <label className="col-form-label">
                    <span>*</span> Stocks
                  </label>
                  <VaNumber
                    name="stocks"
                    className="form-control"
                    required
                    data-min="1"
                    value={form.selling.stocks}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
            </VaForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellCostView;
