import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";
import axios from "axios";
import moment from "moment";

const formatDate = (date) => {
  return moment(date).format("Y/M/D HH:mm");
};

export const requestDailySales = (filter, success_callback, error_callback) => (
  dispatch
) => {
  return Http.POST("dashboard/get-daily-sales", {
    ...filter,
    payload: {
      criteria: filter.filtered,
      sorted: filter.sorted,
      per_page: filter.per_page,
      page: filter.page,
    },
  })
    .then(success_callback)
    .catch(error_callback);
};

export const requestAllCommon = () => (dispatch) => {
  return Http.GET("dashboard/get-all-common");
};

export const requestLiveStock = (
  shop_id,
  data,
  success_callback,
  error_callback
) => (dispatch) => {
  return Http.POST("dashboard/get-live-stock/" + shop_id, { data })
    .then(success_callback)
    .catch(error_callback);
};
