import * as type from "../../constants/category";

export const createCategoryBox = ({
  categoryParentId,
  categoryId,
  checked,
}) => {
  return {
    type: type.CREATE_CATEGORY_BOX,
    payload: { categoryParentId, categoryId, checked },
  };
};

export const createCategoryMainBox = ({ categories }) => {
  return {
    type: type.CREATE_CATEGORY_MAIN_BOX,
    payload: { categories },
  };
};

export const confirmCategory = () => {
  return {
    type: type.CONFIRMED_CATEGORY,
  };
};

export const editCategory = () => {
  return {
    type: type.EDIT_CATEGORY,
  };
};

export const cancelCategory = () => {
  return {
    type: type.CLEAR_CATEGORY,
  };
};

export const updateFullCategory = (data, confirmed, box) => {
  return {
    type: type.UPDATE_FULL_CATEGORY,
    payload: { data, confirmed, box },
  };
};
