import { empty } from "common";
import OpponentProp from "./opponent-prop";
import PlayerProp from "../player/player-prop";

class UpdateOpponentCoordinates {
  constructor(base) {
    this.base = base;
  }

  handle(opponents) {
    var OBSTACLE_START_TOP = OpponentProp.start_top;
    opponents = opponents.map((opponent) => {
      var width_canvas_half = this.base.gameboard.canvas().width / 2;
      opponent
        .getComponent()
        .changeX(
          width_canvas_half +
            (width_canvas_half - OpponentProp.width) -
            PlayerProp.start_left
        );
      opponent.getComponent().changeY(OBSTACLE_START_TOP);

      OBSTACLE_START_TOP += OpponentProp.height + OpponentProp.distance;

      opponent
        .getTickleDel()
        .changeY(
          opponent.getComponent().getY() -
            opponent.getTickleDel().getHeight() / 2
        );

      opponent
        .getTickleDel()
        .changeX(
          opponent.getComponent().getX() +
            opponent.getComponent().getWidth() -
            opponent.getTickleDel().getWidth() / 2
        );

      return opponent;
    });

    return opponents;
  }
}

export default UpdateOpponentCoordinates;
