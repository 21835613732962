import React from "react";
import { Route, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const LoginRoute = ({ component: Component, ...main_props }) => {
  return (
    <Route
      {...main_props}
      render={(props) =>
        main_props.userAuthReducer.is_loggedin == false ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={"/dashboard" + "?sid=" + main_props.userAuthReducer.session.sid}
          />
        )
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    userAuthReducer: state.userAuthReducer,
  };
}

let LoginRouteRedux = connect(mapStateToProps)(LoginRoute);

export default LoginRouteRedux;
