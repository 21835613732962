import * as variation from "./variation/payload";
import * as wholesale from "./wholesale/payload";
import * as media from "./media/payload";
import * as campaign from "./campaign/payload";
import * as category from "./category/payload";
import * as product from "./product/payload";
import * as http from "./http";
import * as typeProduct from "../constants/product";

const destroy = () => {
  return {
    type: typeProduct.DESTROY,
  };
};

const selectAnchor = (selector) => {
  return {
    type: typeProduct.SELECT_ANCHOR,
    payload: { selector },
  };
};

const addAnchor = (title, selector, ref) => {
  return {
    type: typeProduct.ADD_ANCHOR,
    payload: { title, selector, ref },
  };
};

const submit = (data) => {
  return {
    type: typeProduct.SUBMIT,
    payload: data,
  };
};

const draft = (data) => {
  return {
    type: typeProduct.DRAFT,
    payload: data,
  };
};

export {
  variation,
  wholesale,
  media,
  campaign,
  category,
  http,
  destroy,
  selectAnchor,
  addAnchor,
  submit,
  draft,
  product,
};
