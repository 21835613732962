import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";

export const getAllRequest = (filters, per_page, page) => (dispatch) => {
  dispatch(payload.setLoading(true));
  return Http.POST("settings/shop/getAll", {
    payload: filters,
    per_page,
    page,
  });
};

export const getAll = (filters, per_page, page) => (dispatch) => {
  return getAllRequest(filters, per_page, page);
};

export const getShopRequest = (shopId) => (dispatch) => {
  return Http.GET("settings/shop/get/" + shopId)
    .then(function (response) {
      dispatch(payload.fetch(response.data));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getShop = (shopId) => (dispatch) => {
  return function (dispatch) {
    return dispatch(getShopRequest(shopId));
  };
};

interface api {
  name: string;
  description: string;
}

export const createShop = (data: api) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PUT("settings/shop/create", data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

export const updateShop = (shopId, data: api) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PATCH("settings/shop/update/" + shopId, data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(payload.setUpdateStatus(true));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

interface shopId {
  id: int; //brand id
}
export const deleteShop = (data: shopId) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.POST("settings/shop/delete", { payload: data })
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

export const connect = (data: shopId) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.POST("settings/shop/connect", { payload: data })
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

export const requestSyncShopItems = (shop_id) => (dispatch) => {
  return Http.POST("settings/shop/item/sync/" + shop_id);
};

export const requestGenerateDailyOrders = (shop_id) => (dispatch) => {
  return Http.POST("settings/shop/order/daily-generate/" + shop_id);
};

export const requestUpdateDailyOrdersStatus = (shop_id) => (dispatch) => {
  return Http.POST(
    "settings/shop/order/daily-generate/update/status/" + shop_id
  );
};
