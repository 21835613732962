import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";
import Emitter from "common/event-emitter";

export const getAllReceivedOrdersRequest = (
  filters,
  per_page,
  page,
  callback
) => (dispatch) => {
  dispatch(payload.setLoading(true));
  return Http.POST("purchase-order/receive/list-all-deliveries", {
    payload: filters,
    per_page,
    page,
  })
    .then(function (response) {
      dispatch(payload.setLoading(false));
      dispatch(payload.callback(response.data, callback));
    })
    .catch(function (error) {
      dispatch(payload.setLoading(false));
      console.log(error);
    });
};

export const getAllRequest = (filters, per_page, page, callback) => (
  dispatch
) => {
  dispatch(payload.setLoading(true));
  return Http.POST("purchase-order/getAll", {
    payload: filters,
    per_page,
    page,
  })
    .then(function (response) {
      dispatch(payload.setLoading(false));
      dispatch(payload.callback(response.data, callback));
    })
    .catch(function (error) {
      dispatch(payload.setLoading(false));
      console.log(error);
    });
};

export const getAll = (filters, per_page, page) => (dispatch) => {
  return function (dispatch) {
    return dispatch(getAllRequest(filters, per_page, page));
  };
};

export const getPurchaseOrder = (id, success_callback, error_callback) => (
  dispatch
) => {
  return Http.GET("purchase-order/get/" + id)
    .then(success_callback)
    .catch(error_callback);
};

interface purchaseOrder {
  supplier: integer;
  order_date: date;
  status: string;
  remarks: string;
  items: Object;
}

interface receiveOrder {
  courier: string;
  delivered_by: string;
  received_by: string;
  status: string;
  received_remarks: string;
  items: Object;
}

export const receivePurchaseOrder = (
  id,
  data: receiveOrder,
  success_callback,
  error_callback
) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PUT("purchase-order/receive/create/" + id, data)
    .then(success_callback)
    .catch(error_callback);
};

export const updateItemDescription = (product_id, data, callback, error) => (
  dispatch
) => {
  Http.PATCH("purchase-order/update-item-description/" + product_id, data)
    .then(callback)
    .catch(error);
};

export const createPurchaseOrder = (data: purchaseOrder, callback, error) => (
  dispatch
) => {
  Http.PUT("purchase-order/create", data).then(callback).catch(error);
};

export const updatePurchaseOrder = (
  id,
  data: purchaseOrder,
  callback,
  error
) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PATCH("purchase-order/update/" + id, data)
    .then(callback)
    .catch(error);
};

interface brandId {
  id: int; //id
}
export const deletePurchaseOrder = (data: id, callback) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.POST("purchase-order/delete", { payload: data })
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        Emitter.emit("REFRESH_DATATABLE", response.data);
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

export const searchProduct = (criteria, callback) => (dispatch) => {
  return Http.POST("purchase-order/search-product", {
    payload: { criteria, per_page: 10, page: 1 },
  })
    .then(function (response) {
      dispatch(payload.callback(response.data, callback));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getPurchaseStatuses = (callback) => (dispatch) => {
  return Http.GET("purchase-order/statuses")
    .then(function (response) {
      dispatch(payload.callback(response.data, callback));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getAllPaymentTerms = (callback) => (dispatch) => {
  return Http.GET("purchase-order/get-all-payment-terms")
    .then(function (response) {
      dispatch(payload.callback(response.data, callback));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getAllPaymentModes = (callback) => (dispatch) => {
  return Http.GET("purchase-order/get-all-payment-modes")
    .then(function (response) {
      dispatch(payload.callback(response.data, callback));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getAllCommon = (callback, error_callback) => (dispatch) => {
  return Http.GET("purchase-order/get-all-common")
    .then(callback)
    .catch(error_callback);
};
