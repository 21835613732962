import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";

const ItemsDatatableComponent = (props) => {
  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: (
              <input
                type="checkbox"
                className="form-control"
                checked={props.allChecked ? "checked" : ""}
                onChange={(e) => {
                  props.handleHeaderIncludeCheckbox(e.target.checked);
                }}
              />
            ),
            accessor: "checked",
            sortable: false,
            filterable: false,
            width: 70,
            Cell: (row) => {
              return (
                <input
                  onChange={(e) => {
                    props.handleAllItemsIncludeCheckbox(
                      row.original,
                      e.target.checked
                    );
                  }}
                  checked={row.original?.checked ? "checked" : ""}
                  type="checkbox"
                  className="form-control"
                />
              );
            },
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Id#"}</b>,
            accessor: "id",
            sortable: false,
            filterable: false,
            width: 70,
            maxWidth: 70,
            Cell: (row) => row.index + 1,
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Item"}</b>,
            id: "title",
            width: 700,
            sortable: false,
            style: {
              textAlign: "left",
            },
            Cell: (row) =>
              row.original.basic.title +
              "(" +
              (row.original?.variation?.compose?.length ?? 0) +
              ")",
          },
          {
            Header: <b>{"Sold"}</b>,
            id: "overall_sold_count",
            accessor: "overall_sold_count",
            sortable: true,
            style: {
              textAlign: "center",
            },
            width: 100,
          },
          {
            Header: <b>{"Re-order Level"}</b>,
            id: "re-order-level",
            accessor: "re_order_level",
            sortable: false,
            style: {
              textAlign: "center",
            },
            width: 200,
            Cell: (row) => {
              if (row.original?.variation?.compose?.length <= 0)
                return props.renderEditable(row);

              return null;
            },
          },
        ]}
        SubComponent={(row) => {
          if (row.original?.variation?.compose?.length <= 0) {
            return null;
          }

          return (
            <ReactTable
              data={row.original?.variation?.compose ?? []}
              collapseOnDataChange={false}
              showPagination={false}
              defaultPageSize={row.original?.variation?.compose?.length ?? 0}
              columns={[
                {
                  Header: (
                    <input
                      onChange={(e) => {
                        props.handleAllItemsIncludeCheckbox(
                          row.original,
                          e.target.checked
                        );
                      }}
                      checked={row.original?.checked ? "checked" : ""}
                      type="checkbox"
                      className="form-control"
                    />
                  ),
                  accessor: "checked",
                  sortable: false,
                  filterable: false,
                  width: 70,
                  Cell: (variant) => {
                    return (
                      <input
                        onChange={(e) => {
                          props.handleItemVariantIncludeCheckbox(
                            row.original,
                            variant.original,
                            e.target.checked
                          );
                        }}
                        checked={variant.original?.checked ? "checked" : ""}
                        type="checkbox"
                        className="form-control"
                      />
                    );
                  },
                  style: {
                    textAlign: "center",
                  },
                },
                {
                  Header: <b>{"Title"}</b>,
                  accessor: "sku",
                  filterable: false,
                  style: {
                    textAlign: "left",
                  },
                },
                {
                  Header: <b>{"Sold"}</b>,
                  id: "overall_sold_count",
                  accessor: "overall_sold_count",
                  sortable: true,
                  style: {
                    textAlign: "center",
                  },
                  width: 100,
                },
                {
                  Header: <b>{"Re-order Level"}</b>,
                  accessor: "re_order_level",
                  filterable: false,
                  style: {
                    textAlign: "center",
                  },
                  width: 200,
                  Cell: props.renderEditableVariant,
                },
              ]}
            />
          );
        }}
        manual
        data={props.data}
        pages={props.pages} // Display the total number of pages
        loading={props.loading} // Display the loading overlay when we need it
        onFetchData={props.requestProductList} // Request new data when things change
        pageSizeOptions={[20, 50]}
        freezeWhenExpanded={true}
        filterable
        filterable
        defaultPageSize={20}
        collapseOnDataChange={false}
        collapseOnPageChange={false}
        className="-striped -highlight"
      />
    </div>
  );
};

export default ItemsDatatableComponent;
