import React, { Component, Fragment } from "react";
import FilterComponent from "../views/filter";

import { empty } from "common";

import moment from "moment";
import { connect } from "react-redux";
import * as SalesActions from "../../redux/actions/index";

export class FilterContainer extends Component {
  state = {
    startDate: moment().startOf("month")._d,
    endDate: moment().endOf("month")._d,
    shop: { value: "", label: "" },
    order_status: { id: "", name: "" },
  };

  constructor(props) {
    super(props);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeShop = this.handleChangeShop.bind(this);
  }

  componentDidMount() {
    this.props.getAllCommon();

    let startDate = moment().startOf("month")._d;
    let endDate = moment().endOf("month")._d;

    if (!empty(this.props.orderExportReducer.filter)) {
      startDate = moment(this.props.orderExportReducer.filter.start_date)._d;
      endDate = moment(this.props.orderExportReducer.filter.end_date)._d;
    }

    this.setState({
      startDate,
      endDate,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { startDate, endDate } = this.state;

    this.props.getTransactions({
      start_date: startDate,
      end_date: endDate,
      shop: this.state.shop.value,
      order_status: this.state.order_status.id,
    });
  }

  handlChangeEndDate(date) {
    console.log(date);
    const s = moment(this.state.startDate);
    const e = moment(date[0]);

    if (e.diff(s, "days") > 30 || e.diff(s, "days") < 0) {
      this.setState({
        startDate: e.subtract("days", 30)._d,
      });
    }
    this.setState({
      endDate: date[0],
      endTimeSel: true,
    });
  }

  handleChangeStartDate(date) {
    console.log(date);
    const s = moment(date[0]);
    const e = moment(this.state.endDate);

    if (e.diff(s, "days") > 30 || e.diff(s, "days") < 0) {
      this.setState({
        endDate: s.add("days", 30)._d,
      });
    }

    this.setState({
      startDate: date[0],
      startDateSel: false,
      startTimeSel: true,
    });
  }

  handleChangeStartTime(time) {
    console.log(time);
    const t = moment(time);
    const s = moment(this.state.startDate);
    const d = moment(s.format("YYYY-MM-DD") + " " + t.format("HH:mm"));

    this.setState({
      startDate: d._d,
      startTimeSel: false,
    });
  }

  handleChangeEndTime(time) {
    const t = moment(time);
    const s = moment(this.state.endDate);
    const d = moment(s.format("YYYY-MM-DD") + " " + t.format("HH:mm"));

    this.setState({
      endDate: d._d,
      startTimeSel: false,
    });
  }

  handleChangeDate(date) {
    if (this.state.startTimeSel) {
      this.handleChangeStartTime(date);
    } else if (this.state.endTimeSel) {
      this.handleChangeEndTime(date);
    } else {
      if (this.state.startDateSel) {
        this.handleChangeStartDate(date);
      } else {
        this.handlChangeEndDate(date);
      }
    }

    // const _date = moment(date);
  }

  handleCalendarClose() {
    this.setState({
      startDateSel: false,
    });
  }

  handleCalendarOpen() {
    this.setState({
      startDateSel: true,
      startTimeSel: false,
      endTimeSel: false,
    });
  }

  getSelectedShop(shop) {
    return (this.props.orderExportReducer?.shops ?? []).reduce((c, d) => {
      if (d.id == shop) {
        c = {
          label: d.name,
          value: d.id,
          order_statuses: d.order_statuses,
        };
      }
      return c;
    }, []);
  }

  handleChangeShop(e) {
    this.setState({
      shop: this.getSelectedShop(e.target.value),
    });
  }

  handleChangeShopStatus(e) {
    this.setState({
      order_status: this.state.shop.order_statuses.reduce((status, i) => {
        if (i.id === e.target.value) {
          status = i;
        }
        return status;
      }, []),
    });
  }

  getShops() {
    return this.props.orderExportReducer.shops.map((d) => {
      return {
        label: d.name,
        value: d.id,
      };
    });
  }

  render() {
    const { loading } = this.props.orderExportReducer;

    return (
      <FilterComponent
        handleChangeDate={this.handleChangeDate}
        handleSubmit={this.handleSubmit}
        handleChangeShop={this.handleChangeShop}
        handleCalendarOpen={this.handleCalendarOpen.bind(this)}
        handleCalendarClose={this.handleCalendarClose.bind(this)}
        handleChangeShopStatus={this.handleChangeShopStatus.bind(this)}
        loading={loading}
        shops={this.getShops()}
        {...this.state}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    orderExportReducer: state.orderExportReducer,
  };
}

export default connect(mapStateToProps, {
  getTransactions: (coveredDate, filters, per_page, page) =>
    SalesActions.getTransactionsRequest(coveredDate, filters, per_page, page),
  destroy: SalesActions.destroy,
  getAllCommon: SalesActions.getAllCommon,
})(FilterContainer);
