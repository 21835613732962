import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import DatatableContainer from "../container/datatable";
import CreateShopContainer from "../container/create-shop";

export default class ListShopComponent extends Component {
  render() {
    const { open, onOpenModal, onCloseModal } = this.props;
    return (
      <Fragment>
        <Breadcrumb
          title="Shop List"
          parent="Settings"
          desc={
            <a
              onClick={(e) => {
                onOpenModal(e);
              }}
              href="javascript:void(0);"
            >
              Create Shop
            </a>
          }
        />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="clearfix"></div>
              <CreateShopContainer
                open={open}
                onOpenModal={(e) => {
                  onOpenModal(e);
                }}
                onCloseModal={(e) => {
                  onCloseModal(e);
                }}
              />
              <div id="batchDelete" className="">
                <DatatableContainer {...this.props} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
