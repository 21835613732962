import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";
import axios from "axios";
import moment from "moment";

let jobTransactionInterval;

const formatDate = (date) => {
  return moment(date).format("Y/M/D HH:mm");
};

export const getSales = (filter, criteria, per_page, page) => (dispatch) => {
  dispatch(payload.changeSalesLoading(true));
  return Http.POST("orders/export/getAll", {
    start_date: formatDate(filter.start_date),
    end_date: formatDate(filter.end_date),
    order_status: filter.order_status,
    shop_id: filter.shop,
    payload: {
      criteria: criteria.filtered,
      sorted: criteria.sorted,
      per_page,
      page,
    },
  })
    .then(function (response) {
      dispatch(payload.loadSales(response.data));
      dispatch(payload.changeSalesLoading(false));
    })
    .catch(function (error) {
      dispatch(common.setError(error.message));
      dispatch(payload.setLoading(false));
    });
};

export const getTransactionsRequest = (filter, criteria, per_page, page) => (
  dispatch
) => {
  dispatch(payload.setLoading(true));
  return Http.POST("orders/export/create", {
    start_date: formatDate(filter.start_date),
    end_date: formatDate(filter.end_date),
    order_status: filter.order_status,
    shop_id: filter.shop,
    shop_id: filter.shop,
    payload: criteria,
    per_page,
    page,
  })
    .then(function (response) {
      dispatch(payload.setLoading(false));
      if (response.data.job_status_id != null) {
        // jobTransactionInterval = setInterval(() => {
        // dispatch(payload.clearTransactions());
        // dispatch(getJobTransactions(response.data.job_status_id, filter));
        // }, 5000);
      }
    })
    .catch(function (error) {
      dispatch(common.setError(error.message));
      dispatch(payload.setLoading(false));
    });
};

const cancelRequest = [];
const getJobTransactions = (job_id, filter) => (dispatch) => {
  return Http.POST(
    "orders/export/get-sales/import/" + job_id,
    {},
    {
      cancelToken: new axios.CancelToken(function executor(c) {
        cancelRequest.push(c);
      }),
    }
  )
    .then(function (response) {
      if (response.data.has_content) {
        dispatch(payload.loadTransactions(response.data));
        dispatch(payload.changeFilter(filter));
        if (!response.data.is_finished) {
          setTimeout(() => {
            dispatch(getJobTransactions(job_id, filter));
          }, 5000);
        }
      }
      if (
        response.data?.is_failed == true ||
        response.data?.is_finished == true
      ) {
        // clearInterval(jobTransactionInterval);
        dispatch(payload.setLoading(false));
        cancelRequest.forEach(function (c) {
          c();
        });
      }
    })
    .catch(function (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled");
      } else {
        setTimeout(() => {
          dispatch(getJobTransactions(job_id, filter));
          dispatch(common.setError(error.message));
        }, 5000);
      }
      dispatch(payload.setLoading(false));
    });
};

export const getTransactions = (coveredDate, filters, per_page, page) => (
  dispatch
) => {
  return function (dispatch) {
    return dispatch(
      getTransactionsRequest(coveredDate, filters, per_page, page)
    );
  };
};

export const getAllCommon = () => (dispatch) => {
  return Http.GET("orders/export/get-all-common")
    .then(function (response) {
      dispatch(payload.loadShopList(response.data.shops));
    })
    .catch(function (error) {});
};

export const getHistoryList = () => (dispatch) => {
  return Http.GET("orders/export/history")
    .then(function (response) {
      dispatch(payload.loadHistory(response.data));
    })
    .catch(function (error) {});
};

export const getTransactionsHistory = (job_id) => (dispatch) => {
  dispatch(getJobTransactions(job_id));
};
