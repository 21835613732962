import * as type from "../constants/index";

const initialState = {
  data: [],
  loading: false,
  formStatus: false,
};

export default function PurchaseReducer(state = initialState, action) {
  switch (action.type) {
    case type.LOADING:
      const loading = action.payload;
      return { ...state, loading };
      break;
  }

  return state;
}
