import UpdateComposer from "./update-composer";

const UpdateSku = (state, action) => {
  return {
    ...state,
    variation: {
      ...state.variation,
      data: state.variation.data.map((variant) => {
        if (variant.id == action.payload.id) {
          variant.sku_data.map((sku) => {
            if (sku.uuid === action.payload.uuid) {
              if (sku[action.payload.column] !== undefined) {
                sku[action.payload.column] = action.payload.value;
              }
            }
            return sku;
          });
        }
        return variant;
      }),
    },
  };
};

export default UpdateSku;
