import * as type  from "redux/constants/user/permission/action";

const initialState = {
   data: [],
   formStatus: false
};

export default function Action_permission(state = initialState, action) {
    
    switch (action.type) {
        case type.LOAD_ACTION:
            state = Object.assign({}, state, {
	            data: action.payload
	        });
        break;    
        case type.FORM_STATUS:
        	state = Object.assign({}, state, {
	            formStatus: action.payload
	        });
        break;
        case type.LOADING:
            const loading = action.payload;
            return {...state, loading}
        break;
        case type.RESPONSE:
            const response = action.payload;
            return {...state, response}
        break;
        case type.UPDATE_ACTION_CHECKBOX:   
            state = {...state, data: state.data.map(d => {

                if(d.id == action.payload) {
                   d.checked = d.checked === true ? false : true;
                }
                return d;
            })}
        break;
    }
    
    return state;
}
