const UPDATE_MULTI_CAMPAIGN = 'product/product/campaign/update/multi';
const UPDATE_SINGLE_CAMPAIGN = 'product/product/campaign/update/single';
const ADD_MULTIPLE_CAMPAIGN_ERROR = 'product/product/campaign/multiple/error/add';
const ADD_SINGLE_CAMPAIGN_ERROR = 'product/product/campaign/single/error/add';
const CLEAR = 'product/product/campaign/clear';

export {
	UPDATE_MULTI_CAMPAIGN,
	UPDATE_SINGLE_CAMPAIGN,
	ADD_MULTIPLE_CAMPAIGN_ERROR,
	ADD_SINGLE_CAMPAIGN_ERROR,
	CLEAR
}
