import React, { Component, Fragment } from "react";

import * as toast from "components/common/toast";

import "../views/style/validator.scss";

function ProductService(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.rightSidebarAnchor = [];
      this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillUnmount() {
      this.props.destroy();
    }

    handleInputChange = (event) => {
      this.setState({
        [event.target.name]: event.target.value,
      });
    };

    addRightSideBarAnchor = (title, selector, ref) => {
      if (
        this.rightSidebarAnchor.filter((d) => d.selector === selector).length <=
        0
      ) {
        this.rightSidebarAnchor.push({
          title,
          selector,
          ref,
          visible: true,
        });
      }
    };

    componentDidMount() {
      this.props.getProductSettings();
    }

    render() {
      return (
        <WrappedComponent
          rightSidebarAnchor={this.rightSidebarAnchor}
          addRightSideBarAnchor={this.addRightSideBarAnchor}
          handleInputChange={this.handleInputChange}
          {...this.props}
        />
      );
    }
  };
}

export default ProductService;
