const clear = (state, action) => {
  return {
    ...state,
    variation: {
      ...state.variation,
      data: [],
      compose: [],
      header: [],
      title: "",
      enabled: false,
      error: [],
    },
  };
};

export default clear;
