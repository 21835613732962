import React, { Component, Fragment, Suspense } from "react";

import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import * as Payload from "../../redux/actions/payload";
const EcommerceComponent = React.lazy(() => import("../views/ecommerce"));

class EcommerceContainer extends Component {
  state = {
    shops: [],
  };

  updateState(state) {
    this.setState(state);
  }

  componentDidMount() {
    this.props.requestAllCommon().then((response) => {
      this.updateState({
        shops: response.data.shops,
      });
    });
  }

  render() {
    console.log(this.props);
    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          <EcommerceComponent
            {...this.state}
            setShop={this.props.setShop.bind(this)}
          />
        </Suspense>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      ecommerceReducer: state.ecommerceReducer,
    };
  },
  {
    requestAllCommon: Http.requestAllCommon,
    setShop: Payload.setShop,
  }
)(EcommerceContainer);
