import React, { Component, Fragment } from "react";

import DataTableComponent from "../views/datatable";
import { connect } from "react-redux";
import * as BrandAction from "../../redux/actions/index";
import * as toast from "components/common/toast";

class DataTableContainer extends Component {
  state = {
    data: [],
    pages: null,

    pageSize: 0,
    selectedId: [],
    open: false,
  };

  constructor() {
    super();
    this.fetchBrand = this.fetchBrand.bind(this);
    this.removeBrand = this.removeBrand.bind(this);
    this.selected = this.selected.bind(this);
    this.editBrand = this.editBrand.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.brand.data.data) !==
      JSON.stringify(prevProps.brand.data.data)
    ) {
      this.setState({
        data: this.props.brand.data.data,
        selectedId: [],
        pages: Math.ceil(this.props.brand.data.total / this.state.pageSize),
      });
    }
  }

  fetchBrand(state, instance) {
    this.props.getAllBrand(
      {
        filtered: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
      },
      state.pageSize,
      state.page == 0 ? 1 : state.page + 1
    );

    this.setState({
      data: this.props.brand.data.data,
      pages: Math.ceil(this.props.brand.data.total / state.pageSize),
      pageSize: state.pageSize,
    });
  }

  removeBrand(e) {
    const props = this.props;
    const state = this.state;
    if (state.selectedId.length <= 0) return;

    toast.question(
      "Delete",
      "Are you sure you want to remove this?",
      function () {
        props.deleteBrand(state.selectedId);
      }
    );
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  editBrand(e, brandId) {
    this.setState({ brandId: brandId });
    this.onOpenModal();
    this.props.getBrand(brandId);
    this.props.setUpdateStatus(false);
  }

  selected(e, brandId) {
    if (!e.target.checked) {
      this.setState({
        selectedId: this.state.selectedId.filter((d) => d !== brandId),
      });
    } else {
      this.setState({
        selectedId: this.state.selectedId.concat(brandId),
      });
    }
  }

  render() {
    const { data, pages, brandId, open, selectedId } = this.state;
    const { loading, edit } = this.props.brand;

    return (
      <DataTableComponent
        edit={edit}
        loading={loading}
        editBrand={this.editBrand.bind(this)}
        removeBrand={this.removeBrand.bind(this)}
        onOpenModal={this.onOpenModal.bind(this)}
        onCloseModal={this.onCloseModal.bind(this)}
        selected={this.selected.bind(this)}
        fetchBrand={this.fetchBrand}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    brand: state.brand,
  };
}

export default connect(mapStateToProps, {
  getAllBrand: (filter, limit, page) =>
    BrandAction.getAllRequest(filter, limit, page),
  deleteBrand: (d) => BrandAction.deleteBrand(d),
  getBrand: (d) => BrandAction.getBrandRequest(d),
  setUpdateStatus: (d) => BrandAction.setUpdateStatus(d),
})(DataTableContainer);
