import React from "react";
import { VaField, VaTextArea, VaForm } from "../service/va-form";

const SEOInfoView = ({ handleInputChange, form }) => {
  return (
    <div className="digital-add needs-validation col-sm-6">
      <VaForm>
        <div className="form-group">
          <label className="col-form-label pt-0"> SEO Title</label>
          <VaField
            className="form-control"
            name="seo_title"
            required={false}
            value={form.seo.title}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="form-group">
          <label className="col-form-label">SEO Description</label>
          <VaTextArea
            rows="4"
            cols="12"
            name="seo_description"
            onChange={(e) => handleInputChange(e)}
            value={form.seo.description}
          ></VaTextArea>
        </div>
      </VaForm>
    </div>
  );
};

export default SEOInfoView;
