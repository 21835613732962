import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import * as Action from "../../redux/actions/payload";
import * as toast from "components/common/toast";

import TopRightComponent from "../views/top-right";

const TopRightContainer = (props) => {
  const handleResetSelectedTemplateItemVariantStock = () => {
    props.resetSelectedTemplateItemVariantStock();
    toast.success("Successfully ressetted template stock.", "topRight");
  };
  return (
    <TopRightComponent
      {...props}
      handleResetSelectedTemplateItemVariantStock={handleResetSelectedTemplateItemVariantStock.bind(
        this
      )}
    />
  );
};

export default connect(
  (state) => {
    return {
      inventory_stocking_reducer: state.inventoryStockingReducer,
    };
  },
  {
    resetSelectedTemplateItemVariantStock:
      Action.resetSelectedTemplateItemVariantStock,
  }
)(TopRightContainer);
