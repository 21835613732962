import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import OrderForeCastComponent from "../views/component";

class OrderForeCastContainer extends Component {
  state = {
    request_update: null,
    show_selected_only: null,
    previous_week_dates: [],
    selected_date: "today",
  };

  updateState(state) {
    this.setState(state);
  }

  requestSaveUpdate() {
    this.updateState({
      request_update: !this.state.request_update,
    });
  }

  showSelectedOnly() {
    this.updateState({
      show_selected_only: !this.state.show_selected_only,
    });
  }

  changeDate(date) {
    this.updateState({
      selected_date: date,
    });
  }

  updateMessage(message, error) {
    this.updateState({
      message,
      error,
    });

    setTimeout(() => {
      this.updateState({
        messag: null,
        error: null,
      });
    }, 2000);
  }

  componentDidMount() {
    this.props.requestListPreviousWeekdates().then((response) => {
      this.updateState({
        previous_week_dates: response.data,
      });
    });
  }

  render() {
    return (
      <>
        <OrderForeCastComponent
          {...this.state}
          updateState={this.updateState.bind(this)}
          updateMessage={this.updateMessage.bind(this)}
          requestSaveUpdate={this.requestSaveUpdate.bind(this)}
          showSelectedOnly={this.showSelectedOnly.bind(this)}
          changeDate={this.changeDate.bind(this)}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    shop: state.shop,
  };
}

export default connect(mapStateToProps, {
  requestListPreviousWeekdates: Http.requestListPreviousWeekdates,
})(OrderForeCastContainer);
