import { combineReducers } from "redux";

// User
import User_auth from "redux/reducers/user/user-auth";
import User from "redux/reducers/user/user";

// Permission
import Action_permission from "redux/reducers/user/permission/action";
import Role_permission from "redux/reducers/user/permission/role";
import Action_group_permission from "redux/reducers/user/permission/group";
import Permission from "redux/reducers/user/permission/permission";

// Product
import ProductReducer from "../../modules/product/product/redux/reducers/index";
import Category from "../../modules/product/category/redux/reducers/index";
import Brand from "../../modules/product/brand/redux/reducers/index";

// Settings
import ShopReducer from "../../modules/settings/shop/redux/reducers/index";
import ShopeeReducer from "../../modules/shopee/product/product/redux/reducers/index";
import SupplierReducer from "../../modules/supplier/redux/reducers/index";
import PurchaseReducer from "../../modules/purchase/redux/reducers/index";
import InventoryReducer from "../../modules/inventory/allocation-and-stocking/redux/reducers/index";
import InventoryStockingReducer from "../../modules/inventory/stocking/redux/reducers/index";

import DashboardReducer from "../../modules/home/redux/reducers/index";

// Order
import OrderExportReducer from "../../modules/order/export/redux/reducers/index";
import EcommerceReducer from "../../modules/order/ecommerce/redux/reducers/index";
import InStoreOrderReducer from "../../modules/order/in-store/redux/reducers/index";

// common
import CommonReducer from "redux/reducers/common";

const rootReducer = combineReducers({
  userAuthReducer: User_auth,
  user: User,
  actionPermission: Action_permission,
  rolePermission: Role_permission,
  actionGroupPermission: Action_group_permission,
  permission: Permission,
  category: Category,
  brand: Brand,
  productReducer: ProductReducer,
  shopReducer: ShopReducer,
  orderExportReducer: OrderExportReducer,
  shopeeReducer: ShopeeReducer,
  commonReducer: CommonReducer,
  supplierReducer: SupplierReducer,
  purchaseReducer: PurchaseReducer,
  inventoryReducer: InventoryReducer,
  dashboardReducer: DashboardReducer,
  ecommerceReducer: EcommerceReducer,
  inStoreOrderReducer: InStoreOrderReducer,
  inventoryStockingReducer: InventoryStockingReducer,
});

export default rootReducer;
