import React, { Component,Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from "react-redux";

import  * as Role_permission from "redux/actions/user/role-permission/index";
import Spinner from "components/common/spinner";
import Message_container from "components/common/message-container";

export class Create_role extends Component {

    state = {
        clear: false,
        description: ''
    }
    
    constructor(props) {
        super(props);
        this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate = (prevProps) => {
        if (this.state.clear == true) return;
        if (prevProps.role.response?.message != '') {
            if (prevProps.role.response?.error == false) {
                console.log(prevProps.role);
                this.setState({
                    name: '',
                    description: '',
                    clear: true
                });
            }
        }
    }
    
    handleSubmit(e) {
        this.setState({
            clear: false
        })
        this.props.createRole(this.state);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const {loading, response} = this.props.role;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                       <AvForm className="needs-validation role-form" onValidSubmit={(e) => this.handleSubmit(e)} onInvalidSubmit={this.handleInvalidSubmit}>
                            <Message_container 
                                message = {response?.message ?? ''} 
                                error = {response?.error ?? false}
                            />
                            <div className="form-group row">
                                <div className="col-xl-12 col-md-12">
                                    <AvField label="Role Name" value={this.state.name} onChange={this.handleChange} className="form-control" name="name" id="name" type="text" required />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-xl-12 col-md-12">
                                    <AvField label="Role Description" value={this.state.description} onChange={this.handleChange} className="form-control" name="description" id="description" type="textarea" />
                                </div>
                            </div>
                            <div className="pull-right">
                                <button type="submit" className="btn btn-primary">
                                    <Spinner loading={loading} />Save
                                </button>
                            </div>
                        </AvForm>
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.rolePermission
    };
}


export default connect(
    mapStateToProps,
   {
        createRole:  Role_permission.createRole
    }
)(Create_role);