import React, { Fragment } from "react";

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";

const TopRightComponent = (props) => {
  return (
    <Fragment>
      <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
        <ButtonGroup aria-label="Buttons">
          <Button
            variant="secondary"
            size="sm"
            className="text-right"
            onClick={(e) => {
              props.setModalSummaryOpen(true);
            }}
          >
            SUMMARY
          </Button>
          <Button
            variant="default"
            size="sm"
            className="text-right"
            onClick={(e) => {
              props.handleResetSelectedTemplateItemVariantStock();
            }}
          >
            RESET
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Fragment>
  );
};

export default TopRightComponent;
