import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { isNumber } from "common";
import { List } from "immutable";

import Combinator from "./combinator";
import * as Http from "../../redux/actions/http";

import ItemsDatatableComponent from "../views/items-datatable";

const ItemsDatatableContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [datatable, setDataTable] = useState({
    data: [],
    pages: 0,
    loading: false,
    pageSize: 0,
    total: 0,
    request_update: false,
  });
  const [backup_data, setBackupData] = useState([]);

  const combinator = new Combinator(datatable.data);

  const requestProductListOrderForeCastByDate = () => {
    props
      .requestProductListOrderForeCastByDate(props.selected_date)
      .then((response) => {
        setLoading(false);

        if (response.data.success) {
          const new_data = calculateTotalInventory(
            combinator.append(response.data.data)
          );

          const total = response.data.total + datatable.total;
          setDataTable({
            ...datatable,
            data: new_data,
            total: total,
            pages: Math.ceil(total / new_data.length),
          });

          combinator.update(new_data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const requestProductList = (state, instance) => {
    setLoading(true);
    props
      .requestProductList({
        criteria: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
        per_page: state.pageSize,
        page: state.page == 0 ? 1 : state.page + 1,
      })
      .then((response) => {
        setLoading(false);

        if (response.data.success) {
          combinator.update(datatable.data);
          const new_data = calculateTotalInventory(
            combinator.merge(response.data.data)
          );

          setDataTable({
            ...datatable,
            data: new_data,
            total: response.data.total,
            pages: Math.ceil(response.data.total / new_data.length),
          });

          combinator.update(new_data);

          setTimeout(() => {
            requestProductListOrderForeCastByDate();
          }, 2000);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const changeReOrderQuantity = (cellInfo, quantity) => {
    const index = datatable.data.findIndex(
      (f) =>
        f.id == cellInfo.original.id &&
        f.variant_id == cellInfo.original.variant_id
    );
    const data = List(datatable.data).toJS();

    if ((data[index]?.order_quantity ?? 0) != 0 || quantity > 0) {
      data[index].order_quantity = quantity;
      if (quantity > 0) {
        data[index].checked = true;
      }

      // setTimeout(() => {
      setDataTable({
        ...datatable,
        request_update: !datatable.request_update,
        data: combinator.update(data),
      });
      // }, 200);
    }
  };

  const changeExtraOrder = (cellInfo, quantity) => {
    const index = datatable.data.findIndex(
      (f) =>
        f.id == cellInfo.original.id &&
        f.variant_id == cellInfo.original.variant_id
    );
    const data = List(datatable.data).toJS();

    if ((data[index]?.extra_order ?? 0) != 0 || quantity > 0) {
      data[index].extra_order = quantity;
      if (quantity > 0) {
        data[index].checked = true;
      }

      // setTimeout(() => {
      setDataTable({
        ...datatable,
        request_update: !datatable.request_update,
        data: combinator.update(data),
      });
      // }, 200);
    }
  };

  const calculateTotalInventory = (data) => {
    return data.map((item) => {
      const inventory = !item.has_variation
        ? item.inventory
        : item.variant_inventory;
      item.total_inventory = inventory.reduce((total, inventory) => {
        total += inventory.stock;
        return total;
      }, 0);

      return item;
    });
  };

  const checkbox = (cellInfo) => {
    const index = datatable.data.findIndex(
      (f) =>
        f.id == cellInfo.original.id &&
        f.variant_id == cellInfo.original.variant_id
    );

    datatable.data[index].checked = !datatable.data[index]?.checked ?? false;

    setDataTable({
      ...datatable,
      request_update: !datatable.request_update,
    });

    setTimeout(() => {
      setDataTable({
        ...datatable,
        data: combinator.update(datatable.data),
      });
    }, 1000);
  };

  const retrieveSelectedItems = (data) => {
    return data.filter((f) => f.checked == true);
  };

  const requestSaveUpdate = (data) => {
    props.requestSaveUpdate(retrieveSelectedItems(data)).then((response) => {
      if (response.data.success) {
        props.updateMessage(response.data.message, false);
      }
    });
  };

  const renderEditableExtraOrder = (cellInfo) => {
    return (
      <div
        style={{
          backgroundColor: isTodaySelectedDate() ? "#fff" : "#ccc",
          border: "1px solid #ced4da",
          borderRadius: "0.25rem",
          padding: "0.375rem 0.75rem",
          fontSize: "1rem",
          lineHeight: "1.5",
        }}
        contentEditable={isTodaySelectedDate() ? true : false}
        suppressContentEditableWarning
        onBlur={(e) => {
          if (!isNumber(e.target.innerHTML)) {
            return;
          }

          changeExtraOrder(cellInfo, e.target.innerHTML);
        }}
        dangerouslySetInnerHTML={{
          __html: datatable.data[cellInfo.index]?.extra_order ?? 0,
        }}
      />
    );
  };

  const renderEditable = (cellInfo) => {
    return (
      <div
        style={{
          backgroundColor: isTodaySelectedDate() ? "#fff" : "#ccc",
          border: "1px solid #ced4da",
          borderRadius: "0.25rem",
          padding: "0.375rem 0.75rem",
          fontSize: "1rem",
          lineHeight: "1.5",
        }}
        contentEditable={isTodaySelectedDate() ? true : false}
        suppressContentEditableWarning
        onBlur={(e) => {
          if (!isNumber(e.target.innerHTML)) {
            return;
          }

          changeReOrderQuantity(cellInfo, e.target.innerHTML);
        }}
        dangerouslySetInnerHTML={{
          __html: datatable.data[cellInfo.index]?.order_quantity ?? 0,
        }}
      />
    );
  };

  const isTodaySelectedDate = () => {
    return props.selected_date.toLowerCase() == "today" ? true : false;
  };

  useEffect(() => {
    setTimeout(() => {
      combinator.update(datatable.data);
      // requestSaveUpdate(datatable.data);
    }, 1000);
  }, [datatable.data]);

  useEffect(() => {
    if (props.request_update != null) {
      requestSaveUpdate(datatable.data);
    }
  }, [props.request_update]);

  useEffect(() => {
    requestProductListOrderForeCastByDate(props.selected_date);
  }, [props.selected_date]);

  useEffect(() => {
    if (props.show_selected_only == null) {
      return;
    }

    let data = [];
    if (props.show_selected_only) {
      setBackupData(datatable.data);
      data = combinator.showChecked(datatable.data);
    } else {
      combinator.update(backup_data);
      data = combinator.mergeUpdate(datatable.data);
    }

    setDataTable({
      ...datatable,
      data: combinator.update(data),
    });
  }, [props.show_selected_only]);

  return (
    <ItemsDatatableComponent
      {...datatable}
      loading={loading}
      requestProductList={requestProductList.bind(this)}
      renderEditable={renderEditable.bind(this)}
      renderEditableExtraOrder={renderEditableExtraOrder.bind(this)}
      checkbox={checkbox.bind(this)}
    />
  );
};

export default connect(null, {
  requestProductList: Http.requestProductList,
  requestProductListOrderForeCastByDate:
    Http.requestProductListOrderForeCastByDate,
  requestSaveUpdate: Http.requestSaveUpdate,
})(ItemsDatatableContainer);
