import { Map } from "immutable";
import uuid from "react-uuid";

export const defaultVariant = {
  id: "",
  title: "",
  error: { variant: [], sku: [], campaign: [] },
  sku_data: [],
};

export const defaultSku = {
  uuid: "",
  s: 1,
  sku: "",
  name: "",
  last: false,
};

export const getVariant = (state) => {
  let variant = Map(defaultVariant).toJS();
  variant.id = uuid();

  return variant;
};

export const getDefaultSku = () => {
  const sku = Map(defaultSku).toJS();
  sku.uuid = uuid();

  return sku;
};

export const getDefaultVariant = () => {
  const variant = getVariant();
  const skuData = getDefaultSku();
  skuData.last = true;
  variant.sku_data.push(skuData);

  return variant;
};

export const setDefault = (state) => {
  return {
    ...state,
    variation: {
      ...state.variation,
      data: [getDefaultVariant()],
    },
  };
};

export const sequenceUpdater = (skuData) => {
  return skuData.map((sku, i) => {
    sku.s = 1 + i;
    return sku;
  });
};

export const afterState = (state) => {
  state = {
    ...state,
    variation: {
      ...state.variation,
      data: state.variation.data.map((variant, i) => {
        variant.sku_data = sequenceUpdater(variant.sku_data);
        return variant;
      }),
    },
  };
  return state;
};
