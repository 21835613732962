import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import Message_container from "components/common/message-container";
import ItemsDatatableContainer from "../container/items-datatable";
import Spinner from "components/common/spinner";
import Form from "react-bootstrap/Form";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class OrderForeCastComponent extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Order forecast" parent="product" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-right m-b-20">
              <button
                onClick={(e) => {
                  this.props.requestSaveUpdate();
                }}
                type="button"
                className="btn btn-lg btn-primary"
              >
                SAVE
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12" style={{ height: "50px" }}>
              <Message_container
                message={this.props.message}
                error={this.props.error}
              />
            </div>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <Row>
                      <Col sm={"auto"}>
                        <Form.Group>
                          <select
                            className="form-control"
                            aria-label="Previous Week days"
                            onChange={(e) => {
                              this.props.changeDate(e.target.value);
                            }}
                          >
                            {this.props.previous_week_dates.map((date, i) => {
                              return <option key={i}>{date}</option>;
                            })}
                          </select>
                        </Form.Group>
                      </Col>
                      <Col sm={"auto"}>
                        <Form.Group>
                          <input
                            type="checkbox"
                            id="show_selected_only"
                            className="custom-control-input"
                            className="checkbox_animated"
                            onClick={(e) => {
                              this.props.showSelectedOnly();
                            }}
                          />
                          <Form.Label htmlFor="show_selected_only">
                            Show selected only
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <ItemsDatatableContainer {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
