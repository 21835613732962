import React, { Component, Fragment } from "react";

import DataTableComponent from "../views/datatable";
import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import * as toast from "components/common/toast";
import Emitter from "common/event-emitter";

class DataTableContainer extends Component {
  state = {
    data: [],
    pages: null,
    pageSize: 0,
    selectedId: [],
    open: false,
    loading: false,
    filtered: [],
    sorted: [],
  };

  constructor() {
    super();
    this.fetchInventoryList = this.fetchInventoryList.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
      this.setState({
        data: this.state.data,
        selectedId: [],
        pages: Math.ceil(this.state.total / this.state.pageSize),
      });
    }
  }

  componentDidMount() {
    Emitter.on(
      "INVENTORY_REFRESH_DATATABLE",
      function (response) {
        this.getDaTableRecords(this.state);
      },
      this
    );
  }

  fetchInventoryList(state, instance) {
    this.getDaTableRecords(state);
  }

  getDaTableRecords(state) {
    this.setState({
      loading: true,
    });
    this.props
      .requestInventoryList(
        {
          filtered: state.filtered.reduce((c, i) => {
            c.push({ [i.id]: i.value });
            return c;
          }, []),
          sorted: state.sorted.reduce((c, i) => {
            c.push({ [i.id]: { desc: i.desc } });
            return c;
          }, []),
        },
        state.pageSize,
        state.page == 0 ? 1 : state.page + 1
      )
      .then((response) => {
        console.log(response);
        this.setDataTable(
          response.data.data,
          response.data.total,
          state.pageSize
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  }

  setDataTable(data, total, pageSize) {
    this.setState({
      data: data,
      pages: Math.ceil(total / pageSize),
      pageSize: pageSize,
      loading: false,
      total: total,
    });
  }

  render() {
    const { handleAllocationModalOpen } = this.props;
    return (
      <DataTableComponent
        fetchInventoryList={this.fetchInventoryList}
        handleAllocationModalOpen={handleAllocationModalOpen}
        {...this.state}
      />
    );
  }
}

export default connect(null, {
  requestInventoryList: (filter, limit, page, callback) =>
    Http.requestInventoryList(filter, limit, page, callback),
})(DataTableContainer);
