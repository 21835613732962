import CreatePlayers from "./create-player";
import UpdatePlayerCoordinates from "./coordinates";
import ListPlayers from "./list-players";
import Character from "./character";
import IsPointerClass from "../pointer";
import SharedClass from "../shared";

const Player = (base) => {
  const coordinates = () => {
    return new UpdatePlayerCoordinates(base).handle(
      ListPlayers(base).allActive()
    );
  };

  const activate = () => {
    ListPlayers(base)
      .allActive()
      .forEach((player) => {
        player.getComponent().update();
        player.getLines().forEach((line) => {
          line.update();
        });
        player.getOpponents().forEach((file) => {
          const Shared = new SharedClass(base);
          Shared.updatePlayerLineCoordinates(player, file.line, file.opponent);
        });
      });
  };

  const updateState = (players) => {
    base.updateBaseState((state) => {
      return {
        ...state,
        players: players,
      };
    });
  };

  const updatePlayers = (players) => {
    base.updateBaseState((state) => {
      return {
        ...state,
        players: state.players.map((player) => {
          player = players.reduce((c, p) => {
            if (p.sameValueAs(player)) {
              c = p;
            }
            return c;
          }, player);

          return player;
        }),
      };
    });
  };

  const updateLine = (x, y) => {
    const IsPointer = new IsPointerClass();
    IsPointer.setCoordinates(x, y);
    return ListPlayers(base)
      .allActive()
      .map((player) => {
        if (player.hasOpenLines()) {
          player = character(player).updateLine(
            IsPointer.getX(),
            IsPointer.getY()
          );
        }

        return player;
      });
  };

  const createLine = (player, x, y) => {
    return character(player).createLine(x, y);
  };

  const detachOpponent = (player, opponent) => {
    player = character(player).detachOpponent(opponent);

    updatePlayers([player]);
  };

  const createPlayers = (shops) => {
    updateState(new CreatePlayers(base).handle(shops));
  };

  const character = (player) => {
    return new Character(base, player);
  };

  return {
    listPlayers: ListPlayers(base),
    detachOpponent,
    character,
    createPlayers,
    updatePlayers,
    updateLine,
    createLine,
    coordinates,
    activate,
  };
};

export default Player;
