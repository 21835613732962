import Http from "api/http";
import { setLoading, setResponse, setSuccess } from "./payload";
import * as toast from "components/common/toast";
import { empty } from "common";

const SaveProduct = (store, data) => (dispatch) => {
  // show loading bar
  dispatch(setLoading(true));
  if (!empty(store.getState().productReducer.product_id)) {
    // Update form data and images
    update(dispatch, data, store.getState().productReducer.product_id);
  } else {
    // Save form data and images
    save(dispatch, data);
  }
};

const update = (dispatch, data, productId) => {
  return Http.POST(
    "product/product/update/" + productId,
    { data: data },
    {
      onUploadProgress: (event) => {},
    }
  )
    .then((response) => {
      dispatch(setLoading(false));
      // Show error response if failed
      if (response.data.success === false) {
        return errorResponse(dispatch, response.data.message);
      }

      toast.success(response.data.message);

      return dispatch(
        setSuccess({ success: true, message: response.data.message })
      );
    })
    .catch(function (error) {
      errorResponse(dispatch, error.message);
    });
};

const save = (dispatch, data) => {
  return Http.POST(
    "product/product/save",
    { data: data },
    {
      onUploadProgress: (event) => {},
    }
  )
    .then((response) => {
      dispatch(setLoading(false));
      // Show error response if failed
      if (response.data.success === false) {
        return errorResponse(dispatch, response.data.message);
      }

      toast.success(response.data.message);

      return dispatch(
        setSuccess({ success: true, message: response.data.message })
      );
    })
    .catch(function (error) {
      errorResponse(dispatch, error.message);
    });
};

const errorResponse = (dispatch, message) => {
  toast.error(message);
  dispatch(setLoading(false));
  dispatch(setResponse({ error: true, message: [message] }));
};

export default SaveProduct;
