import Http from "api/http";
import * as payload from "redux/actions/user/action-permission/payload";
import * as common from "redux/actions/user/action-permission/common";

export const getAllActionRequest = () => (dispatch) => {
  return Http.GET("permission/action/getAllAction")
    .then(function (response) {
      if (response.data.success === false) {
        return;
      }
      dispatch(payload.loadAction(response.data.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getAllAction = () => (dispatch) => {
  return function (dispatch) {
    return dispatch(getAllActionRequest());
  };
};

export const createAction = (data: action) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PUT("permission/action/create", data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllActionRequest());
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};
