import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { isNumber } from "common";

import Emitter from "common/event-emitter";
import * as Http from "../../redux/actions/http";

import ItemsDatatableComponent from "../views/items-datatable";

const ItemsDatatableContainer = (props) => {
  const [datatable, setDataTable] = useState({
    data: [],
    pages: 0,
    loading: false,
    pageSize: 20,
    total: 0,
    allChecked: false,
    query: [],
    filtered: [],
    sorted: [],
  });

  function callbackResponse(response) {
    setDataTableProps(
      response.data.data,
      response.data.total,
      datatable.pageSize
    );
  }

  function callbackCatch() {
    setDataTable({
      ...datatable,
      loading: false,
    });
  }

  function createCritera(state) {
    return {
      criteria: state.filtered.reduce((c, i) => {
        c.push({ [i.id]: i.value });
        return c;
      }, []),
      sorted: state.sorted.reduce((c, i) => {
        c.push({ [i.id]: { desc: i.desc } });
        return c;
      }, []),
      per_page: state.pageSize,
      page: state.page == 0 ? 1 : state.page + 1,
    };
  }

  function requestInstance(state) {
    setDataTable({
      ...datatable,
      loading: true,
    });
    if (props.query.out_of_stock) {
      return props.requestOutOfStockInventoryList(createCritera(state));
    }

    return props.requestLiveInventoryList(createCritera(state));
  }

  function fetchInventoryList(state, instance) {
    return requestInstance(state).then(callbackResponse).catch(callbackCatch);
  }

  function setDataTableProps(data, total, pageSize) {
    setDataTable({
      ...datatable,
      data,
      pageSize,
      total,
      loading: false,
      pages: Math.ceil(total / pageSize),
    });
  }

  useEffect(() => {
    fetchInventoryList(datatable);
  }, [props.query.out_of_stock]);

  useEffect(() => {
    fetchInventoryList({
      ...datatable,
      sorted: [props.query.sorted],
    });
  }, [props.query.sorted]);

  return (
    <ItemsDatatableComponent
      {...props}
      {...datatable}
      fetchInventoryList={fetchInventoryList.bind(this)}
    />
  );
};

export default connect(null, {
  requestLiveInventoryList: Http.requestLiveInventoryList,
  requestOutOfStockInventoryList: Http.requestOutOfStockInventoryList,
})(ItemsDatatableContainer);
