import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ApprovalItemListContainer from "../container/approval-item-list";
import AffectedOrderListContainer from "../container/affected-order-list";

export default function ApprovalComponent(props) {
  return (
    <>
      <Row>
        <Col>
          <ApprovalItemListContainer {...props} />
        </Col>
        <Col>
          <AffectedOrderListContainer {...props} />
        </Col>
      </Row>
    </>
  );
}
