import React from "react";
import { excerpt } from "common";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Badge } from "react-bootstrap";
import { Minus, Plus } from "react-feather";
import DefaultImage from "assets/images/dashboard/user1.jpg";

const DatatableComponent = (props) => {
  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"Image"}</b>,
            filterable: false,
            sortable: false,
            style: {
              textAlign: "center",
            },
            width: 100,
            Cell: (row) => <img src={DefaultImage} width={50} />,
          },
          {
            Header: <b>{"Product"}</b>,
            filterable: false,
            sortable: false,
            style: {
              textAlign: "left",
            },
            Cell: (row) => {
              return (
                <>
                  {row.original.product.has_variation && (
                    <span>
                      <strong>{row.original.variant.sku}</strong>
                    </span>
                  )}
                  <span>{excerpt(row.original.product.basic.title, 50)}</span>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "green",
                      float: "right",
                    }}
                  >
                    {row.original.product.basic.is_rent ? "R" : ""}
                  </span>
                </>
              );
            },
          },
          {
            Header: <b>{""}</b>,
            accessor: "quantity",
            sortable: false,
            filterable: false,
            width: 100,
            style: {
              textAlign: "center",
            },
            Cell: (row) => {
              return (
                <>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        size: "20px",
                        marginRight: "10px",
                      }}
                    >
                      x{row.original.quantity}
                    </span>
                    <Badge
                      variant="secondary"
                      className="cursor-pointer"
                      onClick={(e) => {
                        props.increase(row.original.id);
                      }}
                      style={{
                        position: "absolute",
                        top: "-13px",
                        right: 0,
                        fontSize: "20px",
                        borderRadius: 0,
                      }}
                    >
                      <Plus size={20} />
                    </Badge>
                    <Badge
                      variant="primary"
                      className="cursor-pointer"
                      onClick={(e) => {
                        props.decrease(row.original.id);
                      }}
                      style={{
                        position: "absolute",
                        bottom: "-15px",
                        right: 0,
                        fontSize: "20px",
                        borderRadius: 0,
                      }}
                    >
                      <Minus size={20} />
                    </Badge>
                  </div>
                </>
              );
            },
          },
          {
            Header: <b>{"Total"}</b>,
            sortable: false,
            filterable: false,
            width: 200,
            style: {
              textAlign: "center",
            },
            Cell: (row) => {
              return (
                <>
                  {(
                    row.original.quantity *
                    (row.original.product.has_variation
                      ? row.original.variant.price
                      : row.original.product.basic.selling.price)
                  ).toFixed(2)}
                </>
              );
            },
          },
        ]}
        data={props.data}
        pages={props.pages} // Display the total number of pages
        freezeWhenExpanded={true}
        filterable
        defaultPageSize={props.data.length}
        collapseOnDataChange={false}
        collapseOnPageChange={false}
        showPagination={false}
        className="-striped -highlight"
      />
    </div>
  );
};

export default DatatableComponent;
