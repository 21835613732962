import * as typeProduct from "../constants/index";

import * as Product from "./product";

const initialState = {
  products: {
    data: [],
    loading: false,
  },
  form: {
    title: "",
    parent_sku: "",
    selling: { price: "", cost: "", stocks: "" },
    details: { short_desc: "", detailed: "" },
    shipping: { weight: "", width: "", height: "", length: "" },
    seo: { title: "", description: "" },
  },
  variation: {
    data: [],
    compose: [],
    header: [],
    enabled: false,
    error: [],
    max: 2,
  },
  wholesale: {
    data: [],
    enabled: false,
    error: [],
  },
  media: {
    data: [
      {
        key: "primary",
        label: "Primary",
        url: "",
        session: "",
        loading: false,
        progress: 0,
      },
      {
        key: "image1",
        label: "Image 1",
        url: "",
        session: "",
        loading: false,
        progress: 0,
      },
      {
        key: "image2",
        label: "Image 2",
        url: "",
        session: "",
        loading: false,
        progress: 0,
      },
    ],
    primaryKey: "primary",
  },
  category: { data: [], box: [] },
  campaign: { sale_price: 0, sale_start_at: "", sale_end_at: "", error: [] },
  anchor: [],
  settings: {
    default_keys: [],
  },
  fields: {
    wholesale: {
      max: "max",
      max_stocks: "max_stocks",
    },
  },
  validation: {
    basic: {
      title: {
        required: true,
        message: "Title is required",
      },

      parent_sku: {
        required: true,
        message: "Parent Sku is required",
      },

      brand_id: {
        required: true,
        message: "Brand is required",
      },

      category_id: {
        required: true,
        message: "Category is required",
      },
    },

    price: {
      required: true,
      message: "Price is required",
    },

    stocks: {
      required: true,
      message: "Stocks is required",
    },

    variation: {
      required: false,
      message: "Variation is required",
    },

    wholesale: {
      required: false,
      message: "Wholesale is required",
    },

    short_summary: {
      required: true,
      message: "Short summary is required",
    },

    detailed_description: {
      required: true,
      message: "Detailde Description is required",
    },

    media: {
      required: true,
      message: "Media is required",
    },

    weight: {
      required: true,
      message: "Weight is required",
    },

    width: {
      required: true,
      message: "Width is required",
    },

    height: {
      required: true,
      message: "Height is required",
    },

    length: {
      required: true,
      message: "Length is required",
    },

    campaign: {
      required: false,
      message: "Campaign is required",
    },
  },
  labels: {
    error: {
      media: {
        upload_image_maximum_file_size:
          "Maximum image size to upload is 2mb only.",
        image_accepted_types: "Invalid image type.",
      },
      variation: {
        invalid_sku: "Sku Invalid value.",
        invalid_price: "Price Invalid value.",
        invalid_stocks: "Stocks Invalid value.",
        invalid_sku_name: "Invalid value.",
        invalid_name: "Invalid value.",
        duplicate_option: "Duplicate option.",
        duplicate_name: "Duplicate name.",
      },
      wholesale: {
        invalid_range: "Invalid quantity range.",
        invalid_stocks_value: "Invalid quantity value.",
        invalid_price: "Invalid price value",
        invalid_unit_cost: "Invalid unit cost value",
        tier_price_cheaper_invalid:
          "Price must be cheaper than the previous tier price",
      },
      campaign: {
        invalid_sale_end_date_range:
          "The Sale end date must be later than sale start date.",
        invalid_sale_start_date_range:
          "The Sale start date must be earlier than sale end date.",
        invalid_sale_end_date: "The Sale end date must be later than now.",
        invalid_sale_start_date: "The Sale start date must be later than now.",
        uknown_value: "Invalid Value",
      },
      category: {
        invalid_confirmed: "Please finish the category selection.",
      },
      validation: {
        invalid_basic: "Fill out required fields of Basic Information",
        invalid_brand: "Brand is required",
        invalid_category: "Category is required",
        invalid_variation_empty: "Variation is required",
        invalid_variation: "Some of variation fields are invalid",
        invalid_variation_title: "Some of variation title are invalid",
        invalid_variation_options: "Some of variation options are invalid",
        invalid_wholesale_empty: "Wholesale is required",
        invalid_wholesale: "Some of wholesale tier fields are invalid",
        invalid_details_short: "Short description is required",
        invalid_details_detailed: "Detailed description is required",
        invalid_media_empty: "Media is required",
        invalid_media: "Media is invalid",
        invalid_media_primary: "Media primary image is required",
        invalid_campaign: "Campaign is invalid",
        invalid_shipping: "Shipping fields are invalid",
        invalid_selling: "Selling fields are invalid",
        invalid_media_save:
          "There was an error occured upon uploading of images",
      },
      response: {
        message: "There was an error occured while saving. Please try again!",
      },
    },
    success: {
      message: "Successfully Added New Product",
    },
  },
  loading: false,
};

const setResponse = (state, action) => {
  return { ...state, response: action.payload };
};

const setSuccess = (state, action) => {
  return { ...state, success: action.payload };
};

const setLoading = (state, action) => {
  return { ...state, loading: action.payload };
};

const clearProductState = (state, action) => {
  return (state = initialState);
};

const updateVariantColumn = (state, action) => {};

const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
};

const ShopeeReducer = createReducer(initialState, {
  // Product
  [typeProduct.DESTROY]: clearProductState,
  [typeProduct.SUCCESS]: setSuccess,
  [typeProduct.RESPONSE]: setResponse,
  [typeProduct.LOADING]: setLoading,
  [typeProduct.UPDATE_UNIT_COST]: Product.updateUnitCost,
  [typeProduct.UPDATE_UNIT_COST_STATUS]: Product.updateUnitCostStatus,
  [typeProduct.LOAD_PRODUCT_LIST]: Product.loadProductListing,
  [typeProduct.UPDATE_TABLE_ROW_CELL_LOADING]:
    Product.updateTableRowCellLoading,
});

export default ShopeeReducer;
