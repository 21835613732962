import React, { Component, Fragment, useState } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";

import matchSorter from "match-sorter";

const ListSalesByProductDatatableComponent = (props) => {
  const { fetchProduct, pages, loading, salesByProduct } = props;
  return (
    <div>
      <ReactTable
        showPagination={true}
        howPaginationTop={true}
        showPaginationBottom={true}
        defaultPageSize={15}
        pageSizeOptions={[15, 25, 50, 100]}
        collapseOnDataChange={false}
        freezeWhenExpanded={true}
        minRows={0}
        columns={[
          {
            Header: <b>{"S#"}</b>,
            filterable: false,
            width: 50,
            style: {
              textAlign: "left",
              width: "3%",
            },
            Cell: (row) => <div>{row.index + 1}</div>,
          },
          {
            Header: <b>{"Product"}</b>,
            accessor: "product",
            filterable: true,
            sortable: true,
            filterAll: true,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["product"] }),
            style: {
              textAlign: "left",
            },
          },
          {
            Header: <b>{"Quantity"}</b>,
            accessor: "quantity_purchased",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Sales"}</b>,
            accessor: "sales.value_formated",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: <b>{"Cost"}</b>,
            accessor: "cost.value_formated",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: <b>{"Net"}</b>,
            accessor: "net.value_formated",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "right",
            },
          },
        ]}
        //manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={salesByProduct}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        filterable
        className="-striped -highlight"
      />
    </div>
  );
};

export default ListSalesByProductDatatableComponent;
