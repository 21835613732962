import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import * as ShopAction from "../../redux/actions/index";
import FormComponent from "../views/form";

class CreateShopContainer extends Component {
  state = {
    clear: false,
    shop_name: "",
    type: "",
    api_key: "",
    api_secret: "",
    is_main_store: false,
  };

  componentDidUpdate = (prevProps) => {
    if (this.state.clear == true) return;
    if (prevProps.shopReducer.response?.message != "") {
      if (prevProps.shopReducer.response?.error == false) {
        this.setState({
          shop_name: "",
          type: "",
          api_key: "",
          api_secret: "",
          clear: true,
          is_main_store: false,
        });
      }
    }
  };

  handleSubmit(event, values) {
    this.setState({
      clear: false,
    });
    this.props.createShop(values);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleCheckboxChange(e) {
    e.persist();

    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  render() {
    const { loading, response } = this.props.shopReducer;

    return (
      <FormComponent
        handleChange={this.handleChange.bind(this)}
        handleSubmit={this.handleSubmit.bind(this)}
        handleCheckboxChange={this.handleCheckboxChange.bind(this)}
        loading={loading}
        response={response}
        {...this.props}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    shopReducer: state.shopReducer,
  };
}

export default connect(mapStateToProps, {
  createShop: ShopAction.createShop,
  getAll: (filters, per_page, page) =>
    ShopAction.getAllRequest(filters, per_page, page),
})(CreateShopContainer);
