import * as type from "../constants/index";

const initialState = {
  data: [],
  loading: false,
  shop_selected: false,
};

export default function EcommerceReducer(state = initialState, action) {
  switch (action.type) {
    case type.LOADING:
      const loading = action.payload;
      return { ...state, loading };
      break;
    case type.SHOP_SELECTED:
      const shop_selected = action.payload;
      return { ...state, shop_selected };
      break;
  }

  return state;
}
