import { empty } from "common";
import uuid from "react-uuid";

class LineComponent {
  OPEN = true;

  constructor(gameboard, color, width, moveToX, moveToY, lineToX, lineToY) {
    this.width = width;
    this.moveToX = moveToX;
    this.moveToY = moveToY;
    this.lineToX = lineToX;
    this.lineToY = lineToY;
    this.color = color;
    this.gameboard_ = gameboard;

    this.moveToXStart = moveToX;
    this.moveToYStart = moveToY;
    this.lineToXStart = lineToX;
    this.lineToYStart = lineToY;

    this.id = uuid();
  }

  gameboard = () => {
    return this.gameboard_;
  };

  reset = () => {
    this.moveToX = this.moveToXStart;
    this.moveToY = this.moveToYStart;
    this.lineToX = this.lineToXStart;
    this.lineToY = this.lineToYStart;
  };

  changeColor = (color) => {
    this.color = color;
  };

  changeMoveToX = (x) => {
    this.moveToX = x;
  };

  changeMoveToY = (y) => {
    this.moveToY = y;
  };

  changeLineToX = (x) => {
    this.lineToX = x;
  };

  changeLineToY = (y) => {
    this.lineToY = y;
  };

  changeWidth = (width) => {
    this.width = width;
  };

  open = (open) => {
    this.OPEN = open;
  };

  isOpen = () => {
    return this.OPEN;
  };

  getWidth = () => {
    return this.width;
  };

  getMoveToX = () => {
    return this.moveToX;
  };

  getMoveToY = () => {
    return this.moveToY;
  };

  getLineToX = () => {
    return this.lineToX;
  };

  getLineToY = () => {
    return this.lineToY;
  };

  getColor = () => {
    return this.color;
  };

  getId = () => {
    return this.id;
  };

  sameValueAs = (line) => {
    return line.getId() === this.getId();
  };

  update = () => {
    this.gameboard().context().beginPath();
    this.gameboard().context().strokeStyle = this.getColor();
    this.gameboard().context().lineWidth = this.getWidth();
    this.gameboard().context().moveTo(this.getMoveToX(), this.getMoveToY());
    this.gameboard().context().lineTo(this.getLineToX(), this.getLineToY());
    this.gameboard().context().stroke();
  };

  gotHit = (object) => {
    const left = this.getLineToX();
    const right = this.gameboard().canvas().width - left;
    const top = this.getLineToY();
    const bottom = this.gameboard().canvas().height - top;

    const objectLeft = object.getX();
    const objectRight =
      object.area().width - (object.getX() + object.getWidth());

    const objectTop = object.getY();
    const objectBottom =
      object.area().height - (object.getY() + object.getHeight());

    if (left < objectLeft) {
      return false;
    }

    if (objectBottom > bottom) {
      return false;
    }

    if (objectTop > top) {
      return false;
    }

    if (right < objectRight) {
      return false;
    }

    return true;
  };
}

export default LineComponent;
