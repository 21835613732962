import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";

export const requestProductList = (filter) => (dispatch) => {
  return Http.POST("product/order-forecast/get-product-list", {
    payload: {
      ...filter,
    },
  });
};

export const requestProductListTodayOrderForeCast = (filter) => (dispatch) => {
  return Http.POST("product/order-forecast/get-product-list-today-forcast", {
    payload: {
      ...filter,
    },
  });
};

export const requestProductListOrderForeCastByDate = (date) => (dispatch) => {
  return Http.POST("product/order-forecast/get-product-list-forcast-by-date", {
    payload: {
      date,
    },
  });
};

export const requestSaveUpdate = (data) => (dispatch) => {
  return Http.PATCH("product/order-forecast/update", {
    payload: {
      data,
    },
  });
};

export const requestListPreviousWeekdates = (filter) => (dispatch) => {
  return Http.GET("product/order-forecast/list-previous-week-dates");
};
