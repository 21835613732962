import React, { Component, Fragment, useRef } from "react";
import Modal from "react-responsive-modal";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./style/canvas.scss";

import AsyncSelect from "react-select/async";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import SelectProductService from "../service/select-product";
import { debounce } from "underscore";
import "./style/gameboard.scss";
import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";

export default class MigrationModalComponent extends Component {
  render() {
    const {
      migration,
      onmousedown,
      onmousemove,
      handleCloseMigrationModal,
    } = this.props;

    const customSelectStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        // color: state.isSelected ? "red" : "blue",
        padding: 10,
      }),
      menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
        borderBottom: "1px dotted pink",
        color: state.selectProps.menuColor,
        padding: 10,
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "none transparent",
      }),
    };

    const closeButton = {
      closeButton: {
        top: "-14px",
        right: "-8px",
      },
      closeIcon: {
        color: "#fff",
        fontSize: "20px",
      },
      modalContainer: {
        width: "400px",
      },
    };

    return (
      <Fragment>
        <Modal
          open={migration.open}
          onClose={handleCloseMigrationModal}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          classNames={{
            modal: "gameboard-style",
          }}
          closeIcon={faTruckLoading}
          styles={closeButton}
          center
        >
          <div style={{ padding: 0 }} className="modal-body gamecontainer">
            <canvas
              className="gameboard-canvas"
              onMouseDown={(e) => {
                onmousedown(e);
              }}
              onMouseMove={(e) => {
                onmousemove(e);
              }}
            ></canvas>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
