import React, { Component, Fragment, Suspense } from "react";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";

import Message_container from "components/common/message-container";
import Emitter from "common/event-emitter";
import { empty } from "common";
import * as toast from "components/common/toast";
import moment from "moment";

import ManualOrderContainer from "./order";
const OrderComponent = React.lazy(() => import("../views/order"));

class ManualUpdateOrderContainer extends Component {
  onSaveLoading() {
    this.changeSaveLoading(true);
  }

  offSaveLoading() {
    this.changeSaveLoading(false);
  }

  changeSaveLoading(status) {
    this.props.updateState({
      save_loading: status,
    });
  }

  componentDidMount() {
    this.props.requestAllCommonData().then((common_data) => {
      this.props.requestRetrieveSalesOrder(
        this.props.match.params.id,
        (response) => {
          const order_data = response.data;
          this.props.updateState({
            ...common_data,
            ...order_data,
            order_data_loading: false,
          });
        },
        (error) => {}
      );
    });
  }

  handleValidSubmit(event, values) {
    if (this.props.isStatusPaid()) return;

    try {
      if (this.props.save_loading) return;

      this.props.validation();
      this.setState({
        ...values,
      });
      this.onSaveLoading();
      setTimeout(() => {
        const state = this.props.getStateData();
        this.props.requestUpdateSalesOrder(
          this.props.match.params.id,
          {
            ...this.state,
            ...state,
            items: state.items.map((row) => {
              if (!row.item.details.item.has_variation) {
                row.item.details.item.inventory = row.item.details.item.inventory.filter(
                  (inv) => inv.selected
                );
              } else {
                row.item.details.compose.inventory = row.item.details.compose.inventory.filter(
                  (inv) => inv.selected
                );
              }

              return row;
            }),
          },
          (response) => {
            this.offSaveLoading();
            if (response.data.success) {
              response.data.message =
                response.data.message +
                '<br /><a href="/orders/in-store/list">Go to order list.</a>';
              toast.success(response.data.message);
              this.props.clearState();
            } else {
              toast.error(response.data.message);
            }
            this.props.changeMessage(
              response.data.message,
              !response.data.success
            );
          },
          (error) => {
            this.offSaveLoading();
            toast.error(error?.message ?? "");
          }
        );
      }, 1000);
    } catch (error) {
      this.offSaveLoading();
      console.log(error);
      toast.error(error);
    }
  }

  render() {
    console.log(this.state);
    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          {!this.props.order_data_loading && (
            <OrderComponent
              {...this.state}
              {...this.props}
              edit={true}
              handleValidSubmit={this.handleValidSubmit.bind(this)}
            />
          )}
        </Suspense>
      </>
    );
  }
}

const ComponentContainer = ManualOrderContainer(ManualUpdateOrderContainer);

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  requestRetrieveSalesOrder: Action.requestRetrieveSalesOrder,
  requestUpdateSalesOrder: Action.requestUpdateSalesOrder,
  getAllCommon: Action.getAllCommon,
})(ComponentContainer);
