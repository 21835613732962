import * as type from "../constants/index";

const initialState = {
  data: [],
  loading: false,
  formStatus: false,
  template: { info: [], brands: [], warehouse: [] },
};

export default function InventoryReducer(state = initialState, action) {
  switch (action.type) {
    case type.LOADING:
      const loading = action.payload;
      return { ...state, loading };
      break;
    case type.DESTROY:
      state = initialState;
      break;

    case type.LOAD_STOCKING_TEMPLATE:
      return {
        ...state,
        template: {
          info: action.payload.info,
          warehouse: action.payload.warehouse,
          brands: action.payload.brands,
        },
      };
      break;

    case type.CHANGE_GLOBAL_ALLOCATION_TYPE:
      if (action.payload.action == "global") {
        return {
          ...state,
          template: {
            ...state.template,
            global_allocation_type: action.payload.allocation_type,
            brands: state.template.brands.map((brand) => {
              brand.items = brand.items.map((item) => {
                item.allocation_type = action.payload.allocation_type;
                item.variation.compose = item.variation.compose.map(
                  (compose) => {
                    compose.allocation_type = action.payload.allocation_type;
                    return compose;
                  }
                );
                return item;
              });

              return brand;
            }),
          },
        };
      } else if (action.payload.action == "item") {
        const brands = state.template.brands.map((brand) => {
          const item_index = brand.items.indexOf(action.payload.value);
          if (item_index !== -1) {
            brand.items[item_index].allocation_type =
              action.payload.allocation_type;

            brand.items[item_index].variation.compose = brand.items[
              item_index
            ].variation.compose.map((compose) => {
              compose.allocation_type = action.payload.allocation_type;
              return compose;
            });
          }

          return brand;
        });
        return {
          ...state,
          template: {
            ...state.template,
            brands: brands,
          },
        };
      } else if (action.payload.action == "item_variant") {
        const brands = state.template.brands.map((brand) => {
          const item_index = brand.items.indexOf(action.payload.value.item);
          if (item_index !== -1) {
            const compose_index = brand.items[
              item_index
            ].variation.compose.indexOf(action.payload.value.variant);

            if (compose_index !== -1) {
              brand.items[item_index].variation.compose[
                compose_index
              ].allocation_type = action.payload.allocation_type;
            }
          }

          return brand;
        });
        return {
          ...state,
          template: {
            ...state.template,
            brands: brands,
          },
        };
      }

      break;

    case type.REMOVE_TEMPLATE_BRAND:
      return {
        ...state,
        template: {
          ...state.template,
          brands: state.template.brands.filter(
            (brand) => brand.id != action.payload
          ),
        },
      };
      break;
    case type.REMOVE_TEMPLATE_BRAND_ITEM:
      return {
        ...state,
        template: {
          ...state.template,
          brands: state.template.brands.map((brand) => {
            if (brand.id == action.payload.brand_id) {
              brand.items = brand.items.filter(
                (item) => item.id != action.payload.item_id
              );
            }
            return brand;
          }),
        },
      };
      break;
    case type.REMOVE_TEMPLATE_BRAND_ITEM_VARIATION:
      return {
        ...state,
        template: {
          ...state.template,
          brands: state.template.brands.map((brand) => {
            if (brand.id == action.payload.brand_id) {
              brand.items = brand.items.map((item) => {
                if (item.id == action.payload.item_id) {
                  item.variation.compose = item.variation.compose.filter(
                    (compose) => compose.id != action.payload.variant_id
                  );
                }
                return item;
              });
            }
            return brand;
          }),
        },
      };
      break;
    case type.RESET_TEMPLATE_BRAND_ITEM_STOCK:
      return {
        ...state,
        template: {
          ...state.template,
          brands: state.template.brands.map((brand) => {
            brand.items = brand.items.map((item) => {
              item.stock = 0;
              item.variation.compose = item.variation.compose.map((compose) => {
                compose.stock = 0;
                return compose;
              });
              return item;
            });

            return brand;
          }),
        },
      };
      break;
    case type.UPDATE_MAIN_STOCK:
      return {
        ...state,
        template: {
          ...state.template,
          brands: state.template.brands.map((brand) => {
            if (brand.id == action.payload.brand_id) {
              brand.items = brand.items.map((item) => {
                if (item.id == action.payload.item_id) {
                  item.stock = action.payload.stock;
                }
                return item;
              });
            }
            return brand;
          }),
        },
      };
      break;
    case type.UPDATE_VARIANT_STOCK:
      return {
        ...state,
        template: {
          ...state.template,
          brands: state.template.brands.map((brand) => {
            if (brand.id == action.payload.brand_id) {
              brand.items = brand.items.map((item) => {
                if (item.id == action.payload.item_id) {
                  item.variation.compose = item.variation.compose.map(
                    (compose) => {
                      if (compose.id == action.payload.compose_id) {
                        compose.stock = action.payload.stock;
                      }
                      return compose;
                    }
                  );
                }
                return item;
              });
            }
            return brand;
          }),
        },
      };
      break;
    case type.ADD_BRAND:
      let data = action.payload;
      if (
        (state.template.brands ?? []).filter(
          (stock) => stock.id == action.payload.id
        ).length > 0
      ) {
        return state;
      }

      return {
        ...state,
        template: {
          ...state.template,
          brands: (state.template.brands ?? []).concat({
            id: data.id,
            name: data.name,
            items: data.items.data.map((item) => {
              return {
                id: item.id,
                basic: { title: item.basic.title },
                has_variation: item.has_variation,
                variation: {
                  compose: item.variation.compose.map((compose) => {
                    return {
                      id: compose.id,
                      sku: compose.sku,
                    };
                  }),
                },
              };
            }),
          }),
        },
      };
      break;
    case type.ADD_BRAND_ITEM:
      if (
        (state.template.brands ?? []).filter(
          (brand) =>
            brand.id == action.payload.brand.id &&
            brand.items.filter((item) => item.id == action.payload.item.id)
              .length > 0
        ).length > 0
      ) {
        return state;
      }

      var item = {
        id: action.payload.item.id,
        basic: { title: action.payload.item.basic.title },
        has_variation: action.payload.item.has_variation,
        variation: {
          compose: action.payload.item.variation.compose.map((compose) => {
            return {
              id: compose.id,
              sku: compose.sku,
            };
          }),
        },
      };

      var brand = {
        id: action.payload.brand.id,
        name: action.payload.brand.name,
        items: [item],
      };

      // Add brand if not exist
      // otherwise add item
      if (
        (state.template.brands ?? []).filter(
          (brand) => brand.id == action.payload.brand.id
        ) <= 0
      ) {
        state.template.brands = (state.template.brands ?? []).concat(brand);
      } else {
        state.template.brands = state.template.brands.map((brand) => {
          if (brand.id == action.payload.brand.id) {
            if (
              brand.items.filter((item) => item.id == action.payload.item.id)
                .length <= 0
            ) {
              brand.items = brand.items.concat(item);
            }
          }
          return brand;
        });
      }

      return {
        ...state,
        template: {
          ...state.template,
          brands: state.template.brands,
        },
      };
      break;

    case type.ADD_BRAND_ITEM_VARIANT:
      if (
        (state.template.brands ?? []).filter(
          (brand) =>
            brand.id == action.payload.brand.id &&
            brand.items.filter(
              (item) =>
                item.id == action.payload.item.id &&
                item.variation.compose.filter(
                  (compose) => compose.id == action.payload.variant.id
                ).length > 0
            ).length > 0
        ).length > 0
      ) {
        return state;
      }
      var compose = {
        id: action.payload.variant.id,
        sku: action.payload.variant.sku,
      };

      var item = {
        id: action.payload.item.id,
        basic: { title: action.payload.item.basic.title },
        has_variation: action.payload.item.has_variation,
        variation: {
          compose: [compose],
        },
      };

      var brand = {
        id: action.payload.brand.id,
        name: action.payload.brand.name,
        items: [item],
      };
      // Add brand if not exist
      // otherwise add item
      if (
        (state.template.brands ?? []).filter(
          (brand) => brand.id == action.payload.brand.id
        ) <= 0
      ) {
        state.template.brands = (state.template.brands ?? []).concat(brand);
      } else {
        state.template.brands = state.template.brands.map((brand) => {
          if (brand.id == action.payload.brand.id) {
            if (
              brand.items.filter((item) => item.id == action.payload.item.id)
                .length <= 0
            ) {
              brand.items = brand.items.concat(item);
            } else {
              brand.items = brand.items.map((item) => {
                if (item.id == action.payload.item.id) {
                  if (
                    item.variation.compose.filter(
                      (compose) => compose.id == action.payload.variant.id
                    ).length <= 0
                  ) {
                    item.variation.compose = item.variation.compose.concat(
                      compose
                    );
                  }
                }
                return item;
              });
            }
          }
          return brand;
        });
      }

      return {
        ...state,
        template: {
          ...state.template,
          brands: state.template.brands,
        },
      };
      break;
  }

  return state;
}
