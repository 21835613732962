import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruckLoading,
  faTrashAlt,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const ModifyStockTableComponent = (props) => {
  console.log(props);
  return (
    <div>
      <ReactTable
        columns={[
          {
            Header: <b>{"Brand"}</b>,
            Cell: (row) =>
              row.original.name + " (" + row.original.items.length + ")",
          },
          {
            Header: (
              <>
                <InputGroup className="m-l-10">
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      props.handleTemplateSelect(e.target.value);
                    }}
                    value={props.inventory_reducer.template.info.id}
                  >
                    <option value="">SELECT TEMPLATE</option>
                    {props.templates.map((template) => {
                      return (
                        <option
                          key={template.id}
                          value={template.id}
                          title={template.approval?.remarks}
                        >
                          {" "}
                          {template.name} - {template.warehouse.name}{" "}
                          {template.approval?.status?.name ?? ""}
                        </option>
                      );
                    })}
                  </Form.Control>
                </InputGroup>
              </>
            ),
            sortable: false,
            style: {
              textAlign: "right",
            },
            Filter: ({ filter, onChange }) => (
              <div>
                <span>
                  <select
                    name="unit_filter"
                    className="form-control"
                    onChange={(event) =>
                      props.handleAllocationTypeChange(
                        "global",
                        props.allocation_types.filter(
                          (type) => event.target.value == type.id
                        )[0] ?? []
                      )
                    }
                    value={
                      props?.inventoryReducer.template.global_allocation_type
                        ?.id ?? ""
                    }
                    style={{ textAlign: "left" }}
                  >
                    {props.allocation_types.map((type) => {
                      return (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
            ),
            Cell: (row) => (
              <Button
                variant="primary"
                size="sm"
                onClick={(e) => {
                  props.handleRemoveTemplateBrand(row.original.id);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            ),
          },
        ]}
        defaultSorted={[
          {
            id: "product",
            desc: true,
          },
        ]}
        SubComponent={(brand) => {
          return (
            <div style={{ padding: "20px" }}>
              <ReactTable
                data={brand.original.items}
                collapseOnDataChange={false}
                showPagination={false}
                defaultPageSize={brand.original.items.length}
                columns={[
                  {
                    Header: <b>{"Product"}</b>,
                    accessor: "basic.title",
                    filterable: false,
                    style: {
                      textAlign: "left",
                    },
                    maxWidth: "80%",
                    width: "80%",
                    Cell: (row) =>
                      "(" +
                      row.original.variation.compose.length +
                      ") " +
                      row.original.basic.title,
                  },
                  {
                    Header: <b>{"Stock"}</b>,
                    id: "stock",
                    sortable: false,
                    filterable: false,
                    style: {
                      textAlign: "right",
                    },
                    maxWidth: "10%",
                    width: "10%",
                    brand_id: brand.original.id,
                    Cell: props.renderMainEditable,
                  },
                  {
                    Header: <b>{"Type"}</b>,
                    id: "allocation_type",
                    sortable: false,
                    filterable: false,
                    style: {
                      textAlign: "right",
                    },
                    maxWidth: "10%",
                    width: "10%",
                    Cell: (row) => {
                      return (
                        <select
                          style={{
                            backgroundColor: "#F0ECE3",
                            textAlign: "left",
                            fontSize: "0.7rem",
                          }}
                          name="allocation_typer"
                          className="form-control"
                          onChange={(event) =>
                            props.handleAllocationTypeChange(
                              "item",
                              props.allocation_types.filter(
                                (type) => event.target.value == type.id
                              )[0] ?? [],
                              row.original
                            )
                          }
                          value={row.original?.allocation_type?.id ?? ""}
                        >
                          {props.allocation_types.map((type) => {
                            return (
                              <option key={type.id} value={type.id}>
                                {type.name}
                              </option>
                            );
                          })}
                        </select>
                      );
                    },
                  },
                  {
                    Header: <b>{"Action"}</b>,
                    filterable: false,
                    sortable: false,
                    style: {
                      textAlign: "right",
                    },
                    maxWidth: "10%",
                    width: "10%",
                    Cell: (row) => (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={(e) => {
                          props.handleRemoveTemplateBrandItem(
                            brand.original.id,
                            row.original.id
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    ),
                  },
                ]}
                SubComponent={(item) => {
                  return (
                    item.original.has_variation && (
                      <div style={{ padding: "20px" }}>
                        <ReactTable
                          data={item.original.variation.compose}
                          collapseOnDataChange={false}
                          showPagination={false}
                          defaultPageSize={
                            item.original.variation.compose.length
                          }
                          columns={[
                            {
                              Header: <b>{"Variant"}</b>,
                              accessor: "sku",
                              filterable: false,
                              style: {
                                textAlign: "left",
                              },
                              maxWidth: "80%",
                              width: "80%",
                            },
                            {
                              Header: <b>{"Stock"}</b>,
                              sortable: false,
                              filterable: false,
                              style: {
                                textAlign: "right",
                              },
                              maxWidth: "10%",
                              width: "10%",
                              brand_id: brand.original.id,
                              item_id: item.original.id,
                              Cell: props.renderVariantEditable,
                            },
                            {
                              Header: <b>{"Type"}</b>,
                              id: "allocation_type",
                              sortable: false,
                              filterable: false,
                              style: {
                                textAlign: "right",
                              },
                              maxWidth: "10%",
                              width: "10%",
                              Cell: (row) => {
                                return (
                                  <select
                                    name="allocation_typer"
                                    className="form-control"
                                    onChange={(event) =>
                                      props.handleAllocationTypeChange(
                                        "item_variant",
                                        props.allocation_types.filter(
                                          (type) =>
                                            event.target.value == type.id
                                        )[0] ?? [],
                                        {
                                          item: item.original,
                                          variant: row.original,
                                        }
                                      )
                                    }
                                    value={
                                      row.original?.allocation_type?.id ?? ""
                                    }
                                    style={{
                                      backgroundColor: "#F0ECE3",
                                      textAlign: "left",
                                      fontSize: "0.7rem",
                                    }}
                                  >
                                    {props.allocation_types.map((type) => {
                                      return (
                                        <option key={type.id} value={type.id}>
                                          {type.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                );
                              },
                            },
                            {
                              Header: <b>{"Action"}</b>,
                              filterable: false,
                              sortable: false,
                              style: {
                                textAlign: "right",
                              },
                              maxWidth: "10%",
                              width: "10%",
                              Cell: (row) => (
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={(e) => {
                                    props.handleRemoveTemplateBrandItemVariation(
                                      brand.original.id,
                                      item.original.id,
                                      row.original.id
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                              ),
                            },
                          ]}
                        />
                      </div>
                    )
                  );
                }}
              />
            </div>
          );
        }}
        collapseOnDataChange={false}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={props.inventory_reducer.template.brands}
        pages={props.pages} // Display the total number of pages
        loading={props.loading} // Display the loading overlay when we need it
        filterable
        defaultPageSize={50}
        className="-striped -highlight"
      />
    </div>
  );
};

export default ModifyStockTableComponent;
