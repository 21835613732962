import React, { Component } from "react";
import { Map } from "immutable";

import { connect } from "react-redux";
import { variation as Variation } from "../../redux/actions";
import VariationTableView from "../views/variation-table";
import _debounce from "lodash.debounce";

class VariationTableContainer extends Component {
  render() {
    console.log(this.props);
    return (
      <VariationTableView
        {...this.props}
        _updateVariantSkuColumn={this._updateVariantSkuColumn}
        _updateVariantColumn={this._updateVariantColumn}
      />
    );
  }

  _updateVariantSkuColumn = (id, ref, field, value) => {
    this.props.updateVariantSkuColumn(id, ref, field, value);
  };

  _updateVariantColumn = (id, field, value) => {
    this.props.updateVariantColumn(id, field, value);
  };
}

export default connect(
  (state) => {
    return {
      variation: state.productReducer.variation,
    };
  },
  {
    updateVariantColumn: Variation.updateVariantColumn,
    removeVariant: Variation.removeVariant,
    addVariant: Variation.addVariant,
    addVariantSku: Variation.addVariantSku,
    enableVariation: Variation.enableVariation,
    updateVariationTitle: Variation.updateVariationTitle,
    removeVariantSku: Variation.removeVariantSku,
    updateVariantSkuColumn: Variation.updateVariantSkuColumn,
    updateComposerCellValue: Variation.updateComposerCellValue,
  }
)(VariationTableContainer);
