import React, { Fragment } from "react";

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
const TopLeftComponent = (props) => {
  return (
    <Fragment>
      <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
        <InputGroup className="m-b-10">
          <FormControl
            type="text"
            placeholder="Template Name"
            aria-label="Template Name"
            aria-describedby="btnGroupAddon"
            onChange={(e) => {
              props.handleInputChangeTemplate(e.target.value);
            }}
            value={props.template_name}
            style={{ height: "40px" }}
          />
          <Form.Control
            as="select"
            onChange={(e) => {
              props.handleChangeWarehouse(e.target.value);
            }}
            style={{ height: "40px" }}
            value={props.warehouse_selected?.id ?? ""}
          >
            <option value="">SELECT WAREHOUSE</option>
            {props.warehouse_list.map((row) => {
              return (
                <option key={row.id} value={row.id}>
                  {row.name}
                </option>
              );
            })}
          </Form.Control>
          <Button
            onClick={(e) => {
              props.resetSelectedTemplate();
            }}
            className="m-l-5"
            title="Clear and Reload template list"
          >
            <FontAwesomeIcon icon={faSpinner} />
          </Button>
        </InputGroup>
        <ButtonGroup aria-label="Buttons" className="m-b-10">
          <Button
            variant="default"
            size="sm"
            className="text-right"
            disabled={props.stocking_for_approval_loading}
            onClick={(e) => {
              props.requestSubmitForApproval();
            }}
          >
            {props.stocking_for_approval_loading && (
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
                role="status"
                style={{
                  width: "0.8rem",
                  height: "0.8rem",
                }}
              ></Spinner>
            )}{" "}
            SUBMIT FOR APPROVAL
          </Button>
          <Button
            disabled={props.stocking_saving_loading}
            variant="secondary"
            size="sm"
            className="text-right"
            onClick={(e) => {
              props.requestSaveStockingTemplate();
            }}
          >
            {props.stocking_saving_loading && (
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
                role="status"
                style={{
                  width: "0.8rem",
                  height: "0.8rem",
                }}
              ></Spinner>
            )}{" "}
            SAVE TEMPLATE
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Fragment>
  );
};

export default TopLeftComponent;
