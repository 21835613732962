import { afterState, getDefaultVariant } from "./common";
import UpdateComposer from "./update-composer";

const RemoveVariant = (state, action) => {
  // Filter out variant id
  state = {
    ...state,
    variation: {
      ...state.variation,
      data: state.variation.data.filter(
        (variant) => variant.id !== action.payload.id
      ),
    },
  };
  // Auto add new variant if no such remaining variant
  if (state.variation.data.length <= 0) {
    state = {
      ...state,
      variation: {
        ...state.variation,
        data: [getDefaultVariant()],
      },
    };
  }

  return UpdateComposer(afterState(state));
};

export default RemoveVariant;
