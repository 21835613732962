import React, { Component } from "react";
import FormBasicView from "../views/form-basic";
import { updateForm } from "../../redux/actions/payload";
import { connect } from "react-redux";

class FormBasicContainer extends Component {
  state = {
    inputValue: "",
    createBrand: "",
    createShop: "",
  };
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBrandInputChange = this.handleBrandInputChange.bind(this);
    this.handleShopInputChange = this.handleShopInputChange.bind(this);
  }

  handleBrandInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");

    this.setState({
      createBrand: inputValue,
    });

    return inputValue;
  };

  handleShopInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");

    this.setState({
      createShop: inputValue,
    });

    return inputValue;
  };

  handleBrandOptionChange = (value) => {
    let brand = {
      brand: { id: "", name: "" },
    };
    if (value !== null && value !== undefined) {
      brand = { id: value.value, name: value.label };
    }

    this.props.updateForm("brand", brand);
  };

  handleShopOptionChange = (value) => {
    let shop = {
      shop: { id: "", name: "" },
    };
    if (value !== null && value !== undefined) {
      shop = { id: value.value, name: value.label };
    }
    console.log(shop);
    this.props.updateForm("shop", shop);
  };

  handleInputChange = (event) => {
    this.props.updateForm(event.target.name, event.target.value);
  };

  render() {
    return (
      <FormBasicView
        {...this.props}
        handleInputChange={this.handleInputChange}
        handleBrandInputChange={this.handleBrandInputChange}
        handleShopOptionChange={this.handleShopOptionChange}
        handleBrandOptionChange={this.handleBrandOptionChange}
        handleShopInputChange={this.handleShopInputChange}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      form: state.productReducer.form,
      validation: state.productReducer.validation.basic,
    };
  },
  {
    updateForm: updateForm,
  }
)(FormBasicContainer);
