import * as type from "../constants/index";

export const setLoading = (payload) => {
  return { type: type.LOADING, payload: payload };
};

export const setResponse = (payload) => {
  return { type: type.RESPONSE, payload: payload };
};

export const fetchAll = (payload) => {
  return {
    type: type.FETCH_ALL,
    payload: payload,
  };
};

export const fetch = (payload) => {
  return {
    type: type.FETCH,
    payload: payload,
  };
};

export const setUpdateStatus = (payload) => {
  return {
    type: type.UPDATE_STATUS,
    payload: payload,
  };
};

export const callback = (response, callback) => {
  return {
    type: type.CALLBACK,
    payload: { response, callback },
  };
};
