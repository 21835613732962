import * as type from "../../constants/product";

export const setLoading = (payload) => {
  return { type: type.LOADING, payload: payload };
};

export const setResponse = (payload) => {
  return { type: type.RESPONSE, payload: payload };
};

export const updateProductId = (productId) => {
  return { type: type.PRODUCT_ID, payload: productId };
};
