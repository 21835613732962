class GameBoard {
  constructor(container, canvas) {
    this.canvas_ = canvas;
    this.container_ = container;
  }

  clear = () => {
    this.context().clearRect(0, 0, this.canvas().width, this.canvas().height);
  };

  canvas = () => {
    return this.canvas_;
  };

  container = () => {
    return this.container_;
  };

  context = () => {
    return this.canvas().getContext("2d");
  };
}

export default GameBoard;
