import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import ReceivedOrderDatatableContainer from "../container/received-order-datatable";

export default class ListReceivedOrderContainer extends Component {
  render() {
    const { open, onOpenModal, onCloseModal } = this.props;
    return (
      <Fragment>
        <Breadcrumb title="Received Order List" parent="Purchase" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="clearfix"></div>
              <div
                id="batchDelete"
                className="category-table user-list order-table coupon-list-delete"
              >
                <ReceivedOrderDatatableContainer />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
