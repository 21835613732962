const FETCH_ALL = "settings/shop/load-all";
const FETCH = "settings/shop/load";
const FORM_STATUS = "settings/shop/form-status";
const LOADING = "settings/shop/progress-loading";
const RESPONSE = "settings/shop/response";
const UPDATE_STATUS = "settings/shop/update/status/boolean";
const CHOOSEN = "settings/shop/choosen";

export {
  FETCH_ALL,
  FETCH,
  FORM_STATUS,
  LOADING,
  RESPONSE,
  UPDATE_STATUS,
  CHOOSEN,
};
