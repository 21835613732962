import * as payload from "./payload";
import * as toast from "components/common/toast";

export const defaultErrorMessage =
  "There was an error occured. Please try again!";

export const setSuccess = (message) => (dispatch) => {
  toast.success(message);
  dispatch(payload.setLoading(false));
};

export const setError = (error) => (dispatch) => {
  console.log(error);
  toast.error(error);
  dispatch(payload.setLoading(false));
};
