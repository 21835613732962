import * as type from "redux/constants/user/user";

const initialState = {
    data: [],
    loading: false,
    response: [],
    row: {
        first_name: '',
        last_name: '',
        role: '',
        email: '',
        username: ''
    }
};

export default function User(state = initialState, action) {
    switch (action.type) {
        case type.FETCH_ALL:
            state = Object.assign({}, state, {
                data: action.payload
            });
        break; 
        case type.FETCH:
            state = Object.assign({}, state, {
                row: action.payload
            });
        break; 
        case type.LOADING:
            const loading = action.payload;
            return {...state, loading}
        break;
        case type.FETCH_LOADING:
            const fetchLoaded = action.payload;
            return {...state, fetchLoaded}
        break;
        case type.RESPONSE:
            const response = action.payload;
            return {...state, response}
        break;
        case type.UPDATE_STATUS:
            const updated = action.payload;
            return {...state, updated}
        break;
    }
    
    return state;
}
