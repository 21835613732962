import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import UpdateCategoryContainer from "../container/update-category";
const DataTableComponent = (props) => {
  const {
    toEdit,
    open,
    data,
    pages,
    loading,
    selected,
    selectedId,
    categoryId,
    onOpenModal,
    onCloseModal,
    editCategory,
    fetchCategory,
    removeCategory,
  } = props;

  return (
    <div>
      <UpdateCategoryContainer
        categoryId={categoryId}
        open={open}
        toEdit={toEdit}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
      />
      <ReactTable
        columns={[
          {
            Header: <b>{"ID"}</b>,
            accessor: "id",
            filterable: false,
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Name"}</b>,
            accessor: "name",
            Cell: (row) => (
              <a
                href="javascript:;"
                onClick={(e) => {
                  editCategory(e, row.original.id);
                }}
              >
                {row.original.name}
              </a>
            ),
          },
          {
            Header: <b>{"Description"}</b>,
            accessor: "description",
          },
          {
            Header: <b>{"Parent"}</b>,
            accessor: "parent",
            sortable: false,
            filterable: false,
            Cell: (row) => <div>{row.original.parent.name}</div>,
          },
          {
            Header: (
              <button
                onClick={(e) => {
                  removeCategory(e);
                }}
                className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
              >
                Delete
              </button>
            ),
            accessor: "delete",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) => (
              <div>
                <span>
                  <input
                    defaultChecked={selectedId.includes(row.original.id)}
                    onChange={(e) => {
                      selected(e, row.original.id);
                    }}
                    type="checkbox"
                    className="checkbox_animated"
                  />
                </span>
              </div>
            ),
          },
        ]}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        onFetchData={fetchCategory} // Request new data when things change
        filterable
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
};

export default DataTableComponent;
