import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import Emitter from "common/event-emitter";
import * as Http from "../../redux/actions/http";

import LiveMonitoringComponent from "../views/component";

class LiveMonitoringContainer extends Component {
  state = {
    query: {
      out_of_stock: false,
      sorted: [],
    },
    allocation_modal: {
      status: false,
      inventory: [],
      update: false,
    },
  };

  componentDidMount() {
    this.props.requestGlobalMetaList().then((response) => {
      this.updateState({
        ...response.data,
      });
    });
  }

  updateState(state) {
    this.setState(state);
  }

  handleQueryOutOfStock() {
    const out_of_stock = !this.state.query.out_of_stock;
    this.updateState({
      ...this.state,
      query: {
        ...this.state.query,
        out_of_stock: out_of_stock,
      },
    });
  }

  handleSortQuery(sort_key) {
    this.updateState({
      ...this.state,
      query: {
        ...this.state.query,
        sorted: sort_key,
      },
    });
  }

  handleAllocationModalOpen(inventory) {
    this.updateState({
      allocation_modal: {
        ...this.state.allocation_modal,
        status: true,
        inventory,
        update: true,
      },
    });
  }

  handleAllocationModalClose() {
    this.updateState({
      allocation_modal: {
        ...this.state.allocation_modal,
        status: false,
      },
    });
  }

  render() {
    return (
      <LiveMonitoringComponent
        {...this.state}
        {...this.props}
        updateState={this.updateState.bind(this)}
        handleSortQuery={this.handleSortQuery.bind(this)}
        handleQueryOutOfStock={this.handleQueryOutOfStock.bind(this)}
        handleAllocationModalOpen={this.handleAllocationModalOpen.bind(this)}
        handleAllocationModalClose={this.handleAllocationModalClose.bind(this)}
      />
    );
  }
}

export default connect(null, {
  requestGlobalMetaList: Http.requestGlobalMetaList,
})(LiveMonitoringContainer);
