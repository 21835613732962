import React, { Component } from "react";

import Cookie from "../api/cookie";

class Auth {
  static getAccessToken() {
    return Cookie.get("session");
  }

  static setSession = (payload) => {
    Cookie.set("session", payload);
  };

  static getExpiresIn = () => {
    return Cookie.get("session")?.expires_in ?? 0;
  };

  static getLoginAt = () => {
    return Cookie.get("session")?.login_at;
  };

  static getExpirationDue = () => {
    return Cookie.get("session")?.expiration_due;
  };

  static isExpiredToken() {
    return new Date(this.getExpirationDue()).getTime() <= new Date().getTime();
  }
}

export default Auth;
