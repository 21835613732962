import React, { Component, Fragment, useState } from "react";
import Breadcrumb from "components/common/breadcrumb";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

import SummaryWidgetsContainer from "../container/summary-widgets";
import HistoryModalContainer from "../container/history-modal";
import FilterContainer from "../container/filter";
import ListSalesDatatableContainer from "../container/list-sales-datatable";
import ListSalesByProductDatatableContainer from "../container/list-salesbyproduct-datatable";
import ListSalesByProductSkuDatatableContainer from "../container/list-salesbyproductsku-datatable";

import moment from "moment";

const ExportOrderComponent = (props) => {
  const {
    tabClick,
    tab,
    sales,
    sales_by_product,
    sales_by_product_sku,
    handleOnCloseModal,
    handleOnOpenModal,
    history_modal,
    filtered,
  } = props;

  return (
    <Fragment>
      <Breadcrumb title="Transactions List" parent="Sales" />
      <div className="container-fluid">
        <FilterContainer
          handleOnHistoryOpenModal={handleOnOpenModal}
          handleOnHistoryCloseModal={handleOnCloseModal}
        />
        <SummaryWidgetsContainer />
        <div className="row">
          <div className="col-sm-12">
            <p>
              Filtered: {filtered?.input?.shop.name ?? "Shop"},{" "}
              {filtered?.input?.status ?? "Status"},{" "}
              {filtered?.input?.start_date?.date
                ? moment(filtered?.input?.start_date?.date).format("MMM Do YY")
                : "Start Date"}
              {" - "}
              {filtered?.input?.end_date?.date
                ? moment(filtered?.input?.end_date?.date).format("MMM Do YY")
                : "End Date"}
            </p>
          </div>
          <div className="col-sm-12">
            <HistoryModalContainer
              handleOnCloseModal={handleOnCloseModal}
              history_modal={history_modal}
            />
            <Tabs
              id="controlled-tab-example"
              activeKey={tab}
              onSelect={(tab) => tabClick(tab)}
            >
              <Tab eventKey="sales" title="Sales">
                <ListSalesDatatableContainer />
              </Tab>
              <Tab eventKey="sales_by_product" title="Sales by Product">
                <ListSalesByProductDatatableContainer />
              </Tab>
              <Tab eventKey="sales_by_product_sku" title="Sales by Product Sku">
                <ListSalesByProductSkuDatatableContainer />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ExportOrderComponent;
