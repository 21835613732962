const FETCH_ALL = 'product/category/load-all';
const FETCH = 'product/category/load';
const FORM_STATUS = 'product/category/form-status';
const LOADING = 'product/category/progress-loading';
const RESPONSE = 'product/category/response';
const UPDATE_STATUS = "product/category/update/status/boolean";
const CHOOSEN = "product/category/choosen";

export {
	FETCH_ALL,
	FETCH,
	FORM_STATUS,
	LOADING,
	RESPONSE,
	UPDATE_STATUS,
	CHOOSEN
}
