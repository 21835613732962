export const resetSelectedTemplateItemVariantStock = (state, action) => {
  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: state.template_selected.brands.map((brand) => {
        brand.items.data = brand.items.data.map((item) => {
          item.stock = 0;
          item.variation.compose = item.variation.compose.map((compose) => {
            compose.stock = 0;
            return compose;
          });

          return item;
        });

        return brand;
      }),
    },
  };
};
export const changeItemStock = (state, action) => {
  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: state.template_selected.brands.map((brand) => {
        if (brand.id == action.payload.brand_id) {
          brand.items.data = brand.items.data.map((item) => {
            if (item.id == action.payload.item_id) {
              item.stock = action.payload.stock;
            }
            return item;
          });
        }
        return brand;
      }),
    },
  };
};

export const changeItemVariantStock = (state, action) => {
  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: state.template_selected.brands.map((brand) => {
        if (brand.id == action.payload.brand_id) {
          brand.items.data = brand.items.data.map((item) => {
            if (item.id == action.payload.item_id) {
              item.variation.compose = item.variation.compose.map((compose) => {
                if (compose.id == action.payload.compose_id) {
                  compose.stock = action.payload.stock;
                }
                return compose;
              });
            }
            return item;
          });
        }
        return brand;
      }),
    },
  };
};
