import React from "react";

import Message_container from "components/common/message-container";
import HeaderContainer from "../../container/checkout/header";
import Card from "react-bootstrap/Card";

class CheckoutComponent extends React.Component {
  render() {
    return (
      <>
        <div className="container-fluid">
          <Card>
            <Card.Body>
              <Message_container
                message={this.props.message?.description ?? ""}
                error={this.props.message?.error ?? false}
              />
              <div className="clearFix" />
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

export default CheckoutComponent;
