import UpdateComposer from "./update-composer";

const UpdateVariant = (state, action) => {
  return {
    ...state,
    variation: {
      ...state.variation,
      data: state.variation.data.map((variant) => {
        if (variant.id == action.payload.id) {
          if (variant[action.payload.column] !== undefined) {
            variant[action.payload.column] = action.payload.value;
          }
        }
        return variant;
      }),
    },
  };
};

export default UpdateVariant;
