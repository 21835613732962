const UPDATE_SESSION = "UPDATE_SESSION";
const INVALID_CREDENTIALS = "INVALID_CREDENTIALS";
const VALID_CREDENTIALS = "VALID_CREDENTIALS";
const CHANGE_USER_SESSION = "CHANGE_USER_SESSION";
const LOADING = "LOADING";
const LOGOUT = "LOGOUT";

export {
  UPDATE_SESSION,
  INVALID_CREDENTIALS,
  VALID_CREDENTIALS,
  CHANGE_USER_SESSION,
  LOADING,
  LOGOUT,
};
