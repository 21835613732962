import React, { Component, Fragment } from "react";

import TestImage1 from "assets/images/sample-image.jpg";
import TestImage2 from "assets/images/sample-image2.jpg";
import addPlusIcon from "assets/images/icons8-plus-grey-64.png";
import trashIcon from "assets/images/icons8-trash-grey-64.png";

import "./style/media.scss";
import { empty } from "common";

export default class MediaView extends Component {
  render() {
    return (
      <Fragment>
        <MainImageWrapper {...this.props} />
        <VariationImageWrapper {...this.props} />
      </Fragment>
    );
  }
}

const ImageWrapper = ({ imageKey, getImage, removeImage }) => {
  if (!empty(getImage(imageKey))) {
    return (
      <div
        className="remove-image"
        onClick={(e) => {
          removeImage(imageKey);
        }}
      >
        <img src={trashIcon} />
      </div>
    );
  }
  return "";
};

const FileWrapper = ({ id, addFileRef, handleFileBrowseEventListener }) => {
  return (
    <input
      type="file"
      ref={(ref) => {
        addFileRef(id, ref);
      }}
      className="file"
      onChange={(e) => handleFileBrowseEventListener(id)}
      accept=".jpg,.jpeg,.png"
    />
  );
};

const ProgressBar = ({ progress }) => {
  return (
    <div className="upload-progress">
      <div className="progress">
        <div
          className="progress-bar bg-primary"
          role="progressbar"
          style={{ width: progress + "%" }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {progress}%
        </div>
      </div>
    </div>
  );
};
const MainImageWrapper = ({
  media,
  getImage,
  addFileRef,
  removeImage,
  handleOutputImage,
  handleFileBrowseEventListener,
  handleClickBrowseEventListener,
}) => {
  return (
    <Fragment>
      <p>Main Images</p>
      <ul className="media-list">
        {media.data.map((image, i) => {
          return (
            <li key={image.key}>
              <div
                className={
                  "image-container" +
                  (image.loading === true ? " upload-loading " : "") +
                  (empty(image.url) === true ? " empty " : "")
                }
              >
                <div className="image-uploaded">
                  <FileWrapper
                    id={image.key}
                    handleFileBrowseEventListener={
                      handleFileBrowseEventListener
                    }
                    addFileRef={addFileRef}
                  />
                  {handleOutputImage(image.key)}
                </div>
                {image.loading === false && (
                  <div className="image-button-container">
                    <div
                      className="add-image"
                      onClick={(e) => {
                        handleClickBrowseEventListener(image.key);
                      }}
                    >
                      <img src={addPlusIcon} />
                    </div>
                    <ImageWrapper
                      imageKey={image.key}
                      getImage={getImage}
                      removeImage={removeImage}
                    />
                  </div>
                )}

                {image.loading === true && (
                  <ProgressBar progress={image.progress} />
                )}
              </div>
              <span>{image.label}</span>
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
};

const VariationImageWrapper = ({
  error,
  enabled,
  getImage,
  addFileRef,
  compose,
  removeImage,
  handleOutputImage,
  handleFileBrowseEventListener,
  handleClickBrowseEventListener,
}) => {
  if (!enabled) {
    return "";
  }

  return (
    <Fragment>
      {compose.length > 0 ? <p>Variation Images</p> : ""}
      <ul className="media-list">
        {compose.map((sku, i) => {
          return (
            <li key={sku.id}>
              <div
                className={
                  "image-container" +
                  (sku.data.image.loading === true ? " upload-loading " : "") +
                  (empty(sku.data.image.url) === true ? " empty " : "")
                }
              >
                <div className="image-uploaded">
                  <FileWrapper
                    id={sku.id}
                    handleFileBrowseEventListener={
                      handleFileBrowseEventListener
                    }
                    addFileRef={addFileRef}
                  />
                  {handleOutputImage(sku.id)}
                </div>

                {sku.data.image.loading === false && (
                  <div className="image-button-container">
                    <div
                      className="add-image"
                      onClick={(e) => {
                        handleClickBrowseEventListener(sku.id);
                      }}
                    >
                      <img src={addPlusIcon} />
                    </div>
                    <ImageWrapper
                      imageKey={sku.id}
                      getImage={getImage}
                      removeImage={removeImage}
                    />
                  </div>
                )}

                {sku.data.image.loading === true && (
                  <ProgressBar progress={sku.data.image.progress} />
                )}
              </div>
              <span>{sku.data.sku}</span>
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
};
