import GameComponent from "../../gameboard/game-component";
import PaginationProp from "../pagination-prop";
import PlayerProp from "./player-prop";

class Pagination {
  constructor(base) {
    this.base = base;
  }

  createComponents() {
    let buttons = [];
    buttons.push(
      new GameComponent(
        this.base.gameboard.canvas(),
        this.base.gameboard.context(),
        PaginationProp.color,
        PlayerProp.start_left,
        this.base.gameboard.canvas().height - 50,
        PaginationProp.width,
        PaginationProp.height,
        "PREVIOUS",
        "white"
      )
    );

    buttons.push(
      new GameComponent(
        this.base.gameboard.canvas(),
        this.base.gameboard.context(),
        PaginationProp.color,
        PlayerProp.start_left + PaginationProp.width + 5,
        this.base.gameboard.canvas().height - 50,
        PaginationProp.label_width,
        PaginationProp.height,
        "1 of (1)",
        "white"
      )
    );

    buttons.push(
      new GameComponent(
        this.base.gameboard.canvas(),
        this.base.gameboard.context(),
        PaginationProp.color,
        PlayerProp.start_left +
          (PaginationProp.width + PaginationProp.label_width) +
          10,
        this.base.gameboard.canvas().height - 50,
        PaginationProp.width,
        PaginationProp.height,
        "NEXT",
        "white"
      )
    );

    return buttons;
  }

  next() {
    return this.base.state.paginations.players.next;
  }

  previous() {
    return this.base.state.paginations.players.previous;
  }

  increment() {
    this.base.updateBaseState((state) => {
      let page = this.base.state.paginations.players.page;
      if (this.getMaxPaginationLength() - 1 > page) {
        page += 1;
      }
      return {
        ...state,
        paginations: {
          ...state.paginations,
          players: {
            ...state.paginations.players,
            page: page,
          },
        },
      };
    });
  }

  decrement() {
    this.base.updateBaseState((state) => {
      return {
        ...state,
        paginations: {
          ...state.paginations,
          players: {
            ...state.paginations.players,
            page:
              state.paginations.players.page <= 0
                ? 0
                : state.paginations.players.page - 1,
          },
        },
      };
    });
  }

  activate() {
    if (this.base.state.paginations.players.next instanceof GameComponent) {
      this.base.state.paginations.players.next.update();
    }

    if (this.base.state.paginations.players.previous instanceof GameComponent) {
      this.base.state.paginations.players.previous.update();
    }

    if (this.base.state.paginations.players.label instanceof GameComponent) {
      this.updateLabel();
      this.base.state.paginations.players.label.update();
    }
  }

  updateLabel() {
    const page = this.base.state.paginations.players.page;

    this.changeLabelTitle(page + 1 + " of " + this.getMaxPaginationLength());
  }
  changeLabelTitle(title) {
    this.base.state.paginations.players.label.changeTitle(title);
  }

  getMaxPaginationLength() {
    return Math.ceil(
      this.base.state.players.length / this.base.state.paginations.limit
    );
  }

  create() {
    return this.createComponents();
  }
}

export default Pagination;
