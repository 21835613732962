import React, { Component, Fragment } from "react";

import { AvField, AvForm } from "availity-reactstrap-validation";
import _debounce from "lodash.debounce";

import Spinner from "components/common/spinner";
import Message_container from "components/common/message-container";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateAccount,
  getUserAccount,
  setUpdateStatus,
  setFetchLoading,
} from "redux/actions/user/user/index";
import { getAllRole } from "redux/actions/user/role-permission/index";

import Modal from "react-responsive-modal";

export class Update_user extends Component {
  state = {
    first_name: "",
    last_name: "",
    role: "",
    email: "",
    username: "",
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) {
      this.setState({
        first_name: this.props.user.row.first_name,
        last_name: this.props.user.row.last_name,
        role: (this.props.user.row?.roles ?? []).map((role) => {
          return role.id;
        }),
        username: this.props.user.row.username,
        email: this.props.user.row.email,
      });
    }

    if (this.props.user.updated === true) {
      this.props.onCloseModal();
      this.props.setUpdateStatus(false);
    }
  }

  handleSubmit(e, errors, values) {
    this.props.updateAccount(this.props.userId, values);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const { first_name, last_name, role, email, username } = this.state;
    const { open, onCloseModal, onOpenModal, roles } = this.props;
    const { response, fetchLoaded, loading } = this.props.user;

    return (
      <Fragment>
        {
          <Modal open={open} onClose={onCloseModal} center>
            <AvForm
              className="needs-validation user-add text-left"
              onSubmit={(e) => this.handleSubmit(e)}
              onInvalidSubmit={this.handleInvalidSubmit}
            >
              <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                  Update User Account
                </h5>
              </div>
              <div className="modal-body">
                <Message_container
                  message={response?.message ?? ""}
                  error={response?.error ?? false}
                />
                <div className="form-group row">
                  <div className="col-sm-6">
                    <AvField
                      label="First Name"
                      value={first_name}
                      className="form-control"
                      name="first_name"
                      id="first_name"
                      type="text"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <AvField
                      label="Last Name"
                      value={last_name}
                      className="form-control"
                      name="last_name"
                      id="last_name"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <AvField
                      label="Role"
                      multiple
                      value={role}
                      type="select"
                      className="form-control"
                      name="role"
                      id="role"
                      required
                    >
                      {roles.map((res, i) => {
                        return (
                          <option key={i} value={res.id}>
                            {res.name}
                          </option>
                        );
                      })}
                    </AvField>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6">
                    <AvField
                      disabled
                      label="Username"
                      value={username}
                      className="form-control"
                      name="username"
                      id="text"
                      type="username"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <AvField
                      disabled
                      label="Email"
                      value={email}
                      className="form-control"
                      name="email"
                      id="email"
                      type="email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onCloseModal("VaryingMdo")}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading === true ? <Spinner loading={true} /> : ""}Save
                </button>
              </div>
            </AvForm>
          </Modal>
        }
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  getAllRole: getAllRole(),
  getUserAccount,
  updateAccount,
  setUpdateStatus,
  setFetchLoading,
})(Update_user);
