import HeaderComposer from "./header-composer";
import RowSpan from "./rowspan";

const UpdateFullVariation = (state, action) => {
  console.log(action);
  return HeaderComposer(
    RowSpan({
      ...state,
      variation: {
        ...state.variation,
        data: action.payload.data,
        compose: action.payload.compose,
        enabled: true,
      },
    })
  );
};

export default UpdateFullVariation;
