import * as type from "../../constants/media";
import { alertSuccess, alertFailed } from "../common";

export const addOrUpdateImage = ({ key, imageUrl, hash, image }) => {
  return {
    type: type.ADD_OR_UPDATE,
    payload: { key, hash, imageUrl, image },
  };
};

export const removeImage = (key) => {
  return {
    type: type.REMOVE,
    payload: { key },
  };
};

export const clear = (id) => {
  return {
    type: type.CLEAR,
    payload: { id },
  };
};

export const responseSuccess = (message) => {
  return {
    type: type.RESPONSE_SUCCESS,
    payload: { message },
  };
};

export const responseFailed = (message) => {
  return {
    type: type.RESPONSE_FAILED,
    payload: { message },
  };
};

export const setProgress = (key, progress) => {
  return {
    type: type.PROGRESS,
    payload: { key, progress },
  };
};

export const setLoading = (key, loading) => {
  return {
    type: type.LOADING,
    payload: { key, loading },
  };
};

export const updateFullMedia = (data) => {
  return {
    type: type.UPDATE_FULL_MEDIA,
    payload: { data },
  };
};
