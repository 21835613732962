import * as type from "../constants/product";

export const setLoading = (loading) => {
  return { type: type.LOADING, payload: loading };
};

export const setSuccess = (payload) => {
  return { type: type.SUCCESS, payload: payload };
};

export const setResponse = (payload) => {
  return { type: type.RESPONSE, payload: payload };
};

export const updateForm = (column, value) => {
  return { type: type.UPDATE_FORM, payload: { column, value } };
};

export const updateSellingForm = (column, value) => {
  return { type: type.UPDATE_SELLING_FORM, payload: { column, value } };
};

export const updateDetailsForm = (column, value) => {
  return { type: type.UPDATE_DETAILS_FORM, payload: { column, value } };
};

export const updateShippingForm = (column, value) => {
  return { type: type.UPDATE_SHIPPING_FORM, payload: { column, value } };
};

export const updateSEOForm = (column, value) => {
  return { type: type.UPDATE_SEO_FORM, payload: { column, value } };
};

export const callback = (response, callback) => {
  return {
    type: type.CALLBACK,
    payload: { response, callback },
  };
};
