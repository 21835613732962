import * as type from "../constants/index";
import moment from "moment";

const initialState = {
  shops: [],
  shop_selected: 0,
  data: [],
};

export default function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case type.DESTROY:
      state = initialState;
      break;
    case type.LOAD_SHOP_LIST:
      state = {
        ...state,
        shops: action.payload,
      };
      break;
    case type.SHOP_SELECTED:
      state = {
        ...state,
        shop_selected: action.payload,
      };
      break;
    case type.LOAD_TRANSACTIONS:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case type.CHANGE_LIVE_STOCK:
      state = {
        ...state,
        daily_sales: state.daily_sales.map((item) => {
          if (item.id == action.payload.id) {
            if (item.variation_id == action.payload.variation_id) {
              item.live_stock = action.payload.live_stock;
            }
          }
          return item;
        }),
      };
      break;
  }

  return state;
}
