import React from "react";
import { Redirect } from "react-router-dom";
import SyncModalComponent from "../views/sync-modal";

import { connect } from "react-redux";
import * as HttpRedux from "../../redux/actions/http";

import { empty } from "common";
import Emitter from "common/event-emitter";

import * as toast from "components/common/toast";
import Http from "api/http";
import Echo from "laravel-echo";

class SyncModalContainer extends React.Component {
  state = {
    loading: false,
    selected: null,
    shops: [],
    lock: false,
  };

  constructor() {
    super();

    this.emitter = Emitter;
  }

  componentDidMount() {
    this.props.getAllCommon((response) => {
      this.setState({
        ...response,
      });
    });

    window.Pusher = require("pusher-js");
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: true,
      authEndpoint: Http.getBaseUrl() + "/broadcasting/auth",
      host: Http.getBaseUrl(),
      auth: {
        headers: {
          Authorization:
            "Bearer " + this.props.userAuthReducer.session.access_token,
        },
      },
    });

    window.Echo.private(
      "channel-product-sync-notify." +
        this.props.userAuthReducer.session.user.id
    )
      .listen("ItemSyncFinished", (e) => {
        toast.success(e.message);
        this.success(e.shop);
        console.log(e);
      })
      .listen("ItemSyncFailed", (e) => {
        toast.error(e.message);
        this.failed(e.shop);
        console.log(e);
      });
  }

  componentWillUnmount() {
    window.Echo = null;
    window.Pusher = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sync.open != this.props.sync.open) {
      if (this.props.sync.open) {
      }
    }
  }

  success(shop_id) {
    this.setState((state) => {
      state.shops = state.shops.map((shop) => {
        if (shop.id == shop_id) {
          shop.lock = false;
          shop.loading = false;
          shop.sync = false;
          shop.failed = false;
        }
        return shop;
      });

      let is_locked = true;
      let locks = state.shops.filter((shop) => shop?.lock === true);
      if (locks.length <= 0) {
        is_locked = false;
      }
      return {
        ...state,
        shops: state.shops,
        lock: is_locked,
        loading: is_locked,
      };
    });

    this.updateSyncModalState();
  }

  failed(shop_id) {
    this.setState((state) => {
      state.shops = state.shops.map((shop) => {
        if (shop.id == shop_id) {
          shop.lock = false;
          shop.loading = false;
          shop.sync = false;
          shop.failed = true;
        }
        return shop;
      });

      let is_locked = true;
      let locks = state.shops.filter((shop) => shop?.lock === true);
      if (locks.length <= 0) {
        is_locked = false;
      }
      return {
        ...state,
        shops: state.shops,
        lock: is_locked,
        loading: is_locked,
      };
    });

    this.updateSyncModalState();
  }

  updateSyncModalState() {
    setTimeout(() => {
      let is_locked = true;
      let locks = this.state.shops.filter((shop) => shop?.lock === true);
      if (locks.length <= 0) {
        is_locked = false;
      }
      this.props.handleLockSyncModal(is_locked);
    }, 1000);
  }

  lock() {
    this.setState({
      loading: true,
      lock: true,
      shops: this.state.shops.map((shop) => {
        if (shop.sync == true) {
          shop.lock = true;
          shop.loading = true;
        }
        return shop;
      }),
    });

    this.props.handleLockSyncModal(true);
  }

  handleChangeSyncStatus(id, e) {
    if (this.state.lock) return;
    this.setState({
      shops: this.state.shops.map((shop) => {
        if (shop.id == id) {
          shop.sync = e.target.checked;
        }
        return shop;
      }),
    });
  }

  hasToSyncShops() {
    return this.state.shops.filter((shop) => shop.sync == true).length > 0;
  }

  handleSubmit() {
    if (this.state.lock) return;
    if (!this.hasToSyncShops()) return;

    this.props.syncProduct(
      this.state.shops.filter((shop) => shop.sync == true),
      (response) => {
        if (response.success) {
          this.lock();
        }
      }
    );
  }

  render() {
    return (
      <SyncModalComponent
        {...this.state}
        {...this.props}
        handleChangeSyncStatus={this.handleChangeSyncStatus.bind(this)}
        handleSubmit={this.handleSubmit.bind(this)}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      commonReducer: state.commonReducer,
      userAuthReducer: state.userAuthReducer,
    };
  },
  {
    getAllCommon: HttpRedux.getAllCommon,
    migrate: HttpRedux.migrate,
    syncProduct: HttpRedux.sync,
  }
)(SyncModalContainer);
