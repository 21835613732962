import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import ProfileComponent from "../views/component";

class ProfileContainer extends Component {
  state = {
    user_notification_list: [],
  };

  componentDidMount() {
    this.props.requestGetAllCommon().then((response) => {
      const data = response.data;
      if (data.success) {
        this.updateState({
          user_notification_list: data.user_notification_list.data,
        });
      }
    });
  }

  changeUserNotifications(option) {
    const index = this.state.user_notification_list.findIndex((settings) => {
      return settings.id == option.id;
    });

    if (index !== -1) {
      this.state.user_notification_list[index].checked = !this.state
        .user_notification_list[index].checked;
      this.updateState({
        user_notification_list: this.state.user_notification_list,
      });
    }
  }

  updateState(state) {
    this.setState(state);
  }

  render() {
    return (
      <>
        <ProfileComponent
          {...this.state}
          updateState={this.updateState.bind(this)}
          changeUserNotifications={this.changeUserNotifications.bind(this)}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    shop: state.shop,
  };
}

export default connect(mapStateToProps, {
  requestGetAllCommon: Http.requestGetAllCommon,
})(ProfileContainer);
