import * as type from "redux/constants/user-auth";

import Http from "api/http";
import Emitter from "common/event-emitter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const updateSession = (payload) => {
  return { type: type.UPDATE_SESSION, payload: payload };
};

export const changeUserSession = (payload) => {
  return { type: type.CHANGE_USER_SESSION, payload: payload };
};

export const invalidCredentials = (payload) => {
  return { type: type.INVALID_CREDENTIALS, payload: payload };
};

export const validCredentials = () => {
  return { type: type.VALID_CREDENTIALS, payload: "Valid credentials" };
};

export const setLoading = (payload) => {
  return { type: type.LOADING, payload: payload };
};

export const logout = () => (dispatch) => {
  // Emitter.emit("VERIFY_USER_MODAL_SHOW");
  Http.GET("logout")
    .then(function (response) {
      dispatch({ type: type.LOGOUT });
    })
    .catch(function (error) {
      dispatch({ type: type.LOGOUT });
      console.log(error?.response?.data?.message ?? "");
    });
};

export const login = (credentials, success_callback, error_callback) => (
  dispatch
) => {
  Http.POST("login", credentials).then(success_callback).catch(error_callback);
};

export const reValidateLogin = (
  credentials,
  success_callback,
  error_callback
) => (dispatch) => {
  Http.POST("revalidate-login", credentials)
    .then(success_callback)
    .catch(error_callback);
};
