import React, { Component, Fragment } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Unlock } from "react-feather";
import { withRouter } from "react-router-dom";

import { AvField, AvForm } from "availity-reactstrap-validation";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateSession, login } from "redux/actions/user/user-auth";
import { ToastContainer } from "react-toastify";

import MessageContainer from "components/auth/message-container";
import Spinner from "react-bootstrap/Spinner";
import uuid from "react-uuid";

export class LoginTabset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeShow: true,
      startDate: new Date(),
      loading: false,
      message: null,
      error: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleLogin = (event) => {
    event.preventDefault();

    this.setState({
      loading: true,
    });
    this.props.login(
      {
        username: this.state.username,
        password: this.state.password,
      },
      (response) => {
        this.setState({
          loading: false,
          message: response.data.message,
          error: !response.data.success,
        });
        if (response.data.success == true) {
          this.props.updateSession({
            access_token: response.data.access_token,
            token_type: response.data.token_type,
            expires_in: response.data.expires_in,
            user: response.data.data,
            sid: uuid(),
          });

          setTimeout((d) => {
            // window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
          }, 2000);
        } else {
        }
      },
      (error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    );
  };

  render() {
    return (
      <div>
        <Fragment>
          <ToastContainer />
          <Tabs>
            <TabList className="nav nav-tabs tab-coupon">
              <Tab className="nav-link" onClick={(e) => this.clickActive(e)}>
                <User />
                Login
              </Tab>
            </TabList>

            <TabPanel>
              <AvForm
                className="form-horizontal needs-validation auth-form"
                onValidSubmit={(e) => this.handleLogin(e)}
                onInvalidSubmit={this.handleInvalidSubmit}
              >
                <MessageContainer
                  error={this.state.error}
                  message={this.state.message}
                />
                <div className="form-group">
                  <AvField
                    required
                    onChange={this.handleChange}
                    name="username"
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    id="exampleInputEmail1"
                  />
                </div>
                <div className="form-group">
                  <AvField
                    required
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                  />
                </div>
                <div className="form-terms">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customControlAutosizing"
                    />
                    <label className="d-block">
                      <input
                        className="checkbox_animated"
                        id="chk-ani2"
                        type="checkbox"
                      />
                      Reminder Me{" "}
                      <span className="pull-right">
                        {" "}
                        <a href="#" className="btn btn-default forgot-pass p-0">
                          lost your password
                        </a>
                      </span>
                    </label>
                  </div>
                </div>
                <div className="form-button">
                  <button className="btn btn-primary" type="submit">
                    {this.state.loading == true ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: 10 + "px" }}
                      />
                    ) : (
                      ""
                    )}
                    Login
                  </button>
                </div>
              </AvForm>
            </TabPanel>
          </Tabs>
        </Fragment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userAuthReducer: state.userAuthReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSession: updateSession,
      login: login,
    },
    dispatch
  );
}

let LoginTabsetRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginTabset);

export default withRouter(LoginTabsetRedux);
