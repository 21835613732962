import React, { Component,Fragment } from 'react'

import { connect } from "react-redux";
import _debounce from 'lodash.debounce';
import { bindActionCreators } from "redux";
import * as CategoryAction from "../../redux/actions/index";
import CategoryFormComponent from "../views/form-category";

class UpdateCategoryContainer extends Component {
    state = {
        name: '',
        parent: {id: 0, name: ''},
        description: '',
        inputValue: ''
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.category) !== JSON.stringify(this.props.category)) {
            this.setState({
                name: this.props.category.toEdit?.name ?? '',
                parent: this.props.category.toEdit?.parent ?? {id: 0, name: ''},
                description: this.props.category.toEdit?.description ?? '',
            });
        }

        if (this.props.category.updated === true) {
            this.props.onCloseModal();
            this.props.setUpdateStatus(false);
        }
    }

    handleSubmit(e) {
        this.props.updateCategory(
            this.props.categoryId,
            this.state
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    handleOptionChange = (value) => {
        if (value === null || value === undefined)  {
            this.setState({
                parent: {id: '', name: ''}
            });
            return;
        }

        this.setState({
            parent: {id: value.value, name: value.label}
        });
    }

    render() {
        const {loading, response} = this.props.category;
        const {open, onCloseModal} = this.props;

        return (
            <CategoryFormComponent
                open={open}
                loading={loading}
                response={response}
                onCloseModal={onCloseModal}
                handleOptionChange={this.handleOptionChange.bind(this)}
                handleInputChange={this.handleInputChange.bind(this)}
                handleSubmit={this.handleSubmit.bind(this)}
                handleChange={this.handleChange.bind(this)}
                {...this.state}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        category: state.category
    };
}

export default connect(
    mapStateToProps,
    {
        updateCategory: (categoryId, data) => CategoryAction.updateCategory(categoryId, data),
        setUpdateStatus: d => CategoryAction.setUpdateStatus(d),
        chooseCategoryRequest: d => CategoryAction.chooseCategoryRequest(d)
    }
)(UpdateCategoryContainer);
