import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import * as Toast from "components/common/toast";
import * as Http from "../../redux/actions/http";

import ApprovalItemListComponent from "../views/approval-item-list";

const ApprovalItemListContainer = (props) => {
  const [approval, setApproval] = useState([]);
  useEffect(() => {
    props
      .requestGetApproval(props.id)
      .then((response) => {
        setApproval(response.data);
        props.updateState({
          template: response.data,
        });
      })
      .catch((error) => {
        Toast.error(error.toString());
      });
  }, []);

  return (
    <>
      <ApprovalItemListComponent {...props} approval={approval} />
    </>
  );
};

export default connect(null, {
  ...Http,
})(ApprovalItemListContainer);
