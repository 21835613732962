import uuid from "react-uuid";
import { empty } from "common";

export const loadProductListing = (state, action) => {
  return {
    ...state,
    products: action.payload,
  };
};

export const updateUnitCost = (state, action) => {
  return {
    ...state,
    products: {
      ...state.products,
      data: state.products.data.map((d) => {
        if (
          d.id === action.payload.id &&
          d.item_id === action.payload.item_id
        ) {
          if (!empty(action.payload.variation_id)) {
            if (d.variations.length > 0) {
              d.variations = d.variations.map((v) => {
                if (v.id === action.payload.variation_id) {
                  v.unit_cost = action.payload.unit_cost;
                  v.unit_cost.update = false;
                }
                return v;
              });
            }
          } else {
            d.unit_cost = action.payload.unit_cost;
            d.unit_cost.update = false;
          }
        }
        return d;
      }),
    },
  };
};

export const updateUnitCostStatus = (state, action) => {
  return {
    ...state,
    products: {
      ...state.products,
      data: state.products.data.map((d) => {
        if (
          d.id === action.payload.id &&
          d.item_id === action.payload.item_id
        ) {
          if (!empty(action.payload.variation_id)) {
            if (d.variations.length > 0) {
              d.variations = d.variations.map((v) => {
                if (v.id === action.payload.variation_id) {
                  v.unit_cost.update = action.payload.status;
                }
                return v;
              });
            }
          } else {
            d.unit_cost.update = action.payload.status;
          }
        }
        return d;
      }),
    },
  };
};

export const updateTableRowCellLoading = (state, action) => {
  return {
    ...state,
    products: {
      ...state.products,
      data: state.products.data.map((d) => {
        if (
          d.id === action.payload.id &&
          d.item_id === action.payload.item_id
        ) {
          if (!empty(action.payload.variation_id)) {
            if (d.variations.length > 0) {
              d.variations = d.variations.map((v) => {
                if (v.id === action.payload.variation_id) {
                  v[action.payload.column].loading = action.payload.status;
                }
                return v;
              });
            }
          } else {
            d[action.payload.column].loading = action.payload.status;
          }
        }
        return d;
      }),
    },
  };
};
