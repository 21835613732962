import React, { Component, Fragment } from "react";

import Emitter from "common/event-emitter";
import * as toast from "components/common/toast";
import { empty } from "common";

import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import * as Payload from "../../redux/actions/payload";

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";

import ListBrandTableComponent from "../views/list-brand-table";
import StockingSummaryModalContainer from "./stocking-summary-modal";

class ListBrandTableContainer extends Component {
  state = {
    modalStatus: false,
    inventory_id: "",
    selected: null,
    loading: false,
    modal: {
      summary_status: false,
    },
    brand_items: [],
  };

  constructor() {
    super();
    this.fetchBrandList = this.fetchBrandList.bind(this);
    this.fetchBrandItems = this.fetchBrandItems.bind(this);
    this.handleAddBrand = this.handleAddBrand.bind(this);
    this.handleAddBrandItem = this.handleAddBrandItem.bind(this);
    this.handleAddBrandItemVariant = this.handleAddBrandItemVariant.bind(this);
    this.handleSummaryModalOpen = this.handleSummaryModalOpen.bind(this);
    this.handleSummaryModalClose = this.handleSummaryModalClose.bind(this);
    this.handleResetTemplateStock = this.handleResetTemplateStock.bind(this);
  }

  handleAllocationModalOpen(inventory_id, selected) {
    this.setState((state) => {
      return {
        ...state,
        modalStatus: true,
        inventory_id: inventory_id,
        selected: selected,
      };
    });
  }

  fetchBrandItems(state, instance) {
    this.props.requestBrandItemsList(
      state.brandId,
      {
        filtered: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
        limit: state.pageSize,
        page: state.page == 0 ? 1 : state.page + 1,
      },

      (response) => {
        this.setBrandItemDataTable(
          state.brandId,
          response.data?.data[0]?.items?.data ?? [],
          response.data?.data[0]?.items?.total ?? 0,
          state.pageSize
        );
      },
      (error) => {}
    );
  }

  fetchBrandList(state, instance) {
    this.getDaTableRecords(state);
  }

  setDataTable(data, total, pageSize) {
    this.setState({
      data: data,
      pages: Math.ceil(total / pageSize),
      pageSize: pageSize,
      loading: false,
      total: total,
    });
  }

  setBrandItemDataTable(brand_id, data, total, pageSize) {
    this.setState((state) => {
      return {
        ...state,
        brand_items: {
          [brand_id]: {
            data: data,
            pages: Math.ceil(total / pageSize),
            pageSize: pageSize,
            loading: false,
            total: total,
          },
        },
      };
    });
  }

  getDaTableRecords(state) {
    this.setState({
      loading: true,
    });
    this.props.requestBrandItemList(
      {
        filtered: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
        limit: state.pageSize,
        page: state.page == 0 ? 1 : state.page + 1,
      },

      (response) => {
        this.setState({
          loading: false,
        });
        this.setDataTable(
          response.data.data,
          response.data.total,
          state.pageSize
        );
      },
      (error) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  componentDidMount() {
    // this.props.getBrandItemList();
  }

  updateState(state) {
    this.setState(state);
  }

  handleAddBrand(brand) {
    this.props.addBrand(brand);
  }

  handleAddBrandItem(brand, item) {
    this.props.addBrandItem(brand, item);
  }

  handleAddBrandItemVariant(brand, item, variant) {
    this.props.addBrandItemVariant(brand, item, variant);
  }

  handleSummaryModalOpen() {
    this.setState({
      modal: {
        ...this.state.modal,
        summary_status: true,
      },
    });
  }

  handleSummaryModalClose() {
    this.setState({
      modal: {
        ...this.state.modal,
        summary_status: false,
      },
    });
  }

  handleResetTemplateStock() {
    this.props.resetTemplateBrandItemStock();
    toast.success("Successfully ressetted stock.", "topRight");
  }

  render() {
    return (
      <Fragment>
        <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
          <ButtonGroup aria-label="Buttons">
            <Button
              variant="secondary"
              size="sm"
              className="text-right"
              onClick={(e) => {
                this.handleSummaryModalOpen();
              }}
            >
              SUMMARY
            </Button>
            <Button
              variant="default"
              size="sm"
              className="text-right"
              onClick={(e) => {
                this.handleResetTemplateStock();
              }}
            >
              RESET
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
        <StockingSummaryModalContainer
          handleSummaryModalOpen={this.handleSummaryModalOpen}
          handleSummaryModalClose={this.handleSummaryModalClose}
          modal_status={this.state.modal.summary_status}
        />
        <ListBrandTableComponent
          {...this.state}
          handleAddBrand={this.handleAddBrand}
          handleAddBrandItem={this.handleAddBrandItem}
          handleAddBrandItemVariant={this.handleAddBrandItemVariant}
          fetchBrandList={this.fetchBrandList}
          fetchBrandItems={this.fetchBrandItems}
        />
      </Fragment>
    );
  }
}

export default connect(null, {
  addBrand: Payload.addBrand,
  addBrandItem: Payload.addBrandItem,
  addBrandItemVariant: Payload.addBrandItemVariant,
  requestBrandItemList: Http.requestBrandItemList,
  requestBrandItemsList: Http.requestBrandItemsList,
  resetTemplateBrandItemStock: Payload.resetTemplateBrandItemStock,
})(ListBrandTableContainer);
