import { empty } from "common";
import uuid from "react-uuid";

import GameComponent from "../../gameboard/game-component";
import Player from "../../gameboard/player";
import UpdatePlayerCoordinates from "./coordinates";
import PlayerProp from "./prop";

class CreateNewPlayers {
  constructor(base) {
    this.base = base;
  }

  createComponent(title) {
    return new GameComponent(
      this.base.gameboard.canvas(),
      this.base.gameboard.context(),
      PlayerProp.color,
      0,
      0,
      PlayerProp.width,
      PlayerProp.height,
      title
    );
  }

  createPlayers(shops) {
    const players = [];
    shops.forEach((shop) => {
      let component = this.createComponent(
        shop.shop_name + " - " + shop.app_name
      );

      players.push(
        new Player(uuid(), component, shop, shop, null, null, null, null)
      );
    });

    return players;
  }

  handle(shops) {
    let players = this.createPlayers(shops);

    return new UpdatePlayerCoordinates(this.base).handle(players);
  }
}

export default CreateNewPlayers;
