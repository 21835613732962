import React, { Component, Fragment } from "react";

import Modal from "react-responsive-modal";
import "./style.scss";

import Table from "react-bootstrap/Table";

export default class StockingSummaryModalComponent extends Component {
  render() {
    const { modal_status, handleModalClose, template } = this.props;

    const style = {
      closeButton: {
        top: "-14px",
        right: "-8px",
      },
      closeIcon: {
        color: "#fff",
        fontSize: "20px",
      },
      modalContainer: {
        width: "400px",
      },
    };

    return (
      <Fragment>
        <Modal
          open={modal_status}
          onClose={handleModalClose}
          center
          closeOnEsc={false}
          closeOnOverlayClick={false}
          styles={style}
          classNames={{
            modal: "allocation_modal",
          }}
        >
          <Table striped bordered hover className="m-t-20">
            <tbody>
              {(template?.brands ?? []).map((brand) => {
                return (
                  <tr key={brand.id}>
                    <td>
                      <Table>
                        <thead>
                          <tr>
                            <th className="text-center">***{brand.name}***</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {brand.items.map((item) => {
                            return (
                              <>
                                <tr key={item.id}>
                                  <td>{item.basic.title}</td>
                                  <td className="text-right">
                                    {item.has_variation ? "-" : item.stock}
                                  </td>
                                </tr>
                                {item.variation.compose.length > 0 && (
                                  <tr key={"compose_" + item.id}>
                                    <td colSpan="2">
                                      <Table>
                                        <thead>
                                          <tr>
                                            <th>Variant</th>
                                            <th className="text-center">
                                              Stock
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {item.variation.compose.map(
                                            (compose) => {
                                              return (
                                                <tr key={compose.id}>
                                                  <td>{compose.sku}</td>
                                                  <td className="text-right">
                                                    {compose.stock}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </Table>
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal>
      </Fragment>
    );
  }
}
