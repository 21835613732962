const COLOR = "blue";
const WIDTH = 200;
const HEIGHT = 70;
const START_TOP = 20;
const START_LEFT = 20;
const DISTANCE = 20;
const LINE_COLOR = "black";
const CONNECTED_LINE_COLOR = "green";
const CONNECTED_COLOR = "green";
const CONNECTED_LINE_ERROR_COLOR = "red";

export default {
  color: COLOR,
  height: HEIGHT,
  width: WIDTH,
  start_top: START_TOP,
  start_left: START_LEFT,
  distance: DISTANCE,
  line_color: LINE_COLOR,
  connected_line_color: CONNECTED_LINE_COLOR,
  connected_color: CONNECTED_COLOR,
  connected_line_error_color: CONNECTED_LINE_ERROR_COLOR,
};
