import React, { Component } from "react";

import { connect } from "react-redux";
import * as shopee from "../../redux/actions/http";
import * as payload from "../../redux/actions/payload";
import * as toast from "components/common/toast";
import _ from "underscore";

import ListShopeeProductDataTableComponent from "../views/list-datatable";

class ListShopeeProductDataTableContainer extends Component {
  state = {
    data: [],
    pages: null,
    pageSize: 0,
    selectedId: [],
    open: false,
  };

  constructor() {
    super();
    this.fetchProduct = this.fetchProduct.bind(this);
    this.selected = this.selected.bind(this);
  }

  componentDidMount() {
    this.props.destroy();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.shopeeReducer.products.data, this.state.data)) {
      this.setState({
        data: this.props.shopeeReducer.products.data,
        selectedId: [],
        pages: Math.ceil(
          this.props.shopeeReducer.products.total / this.state.pageSize
        ),
      });
    }
  }

  fetchProduct(state, instance) {
    this.props.getProductList(
      {
        criteria: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
      },
      state.pageSize,
      state.page == 0 ? 1 : state.page + 1
    );

    this.setState({
      data: this.props.shopeeReducer.products.data,
      pages: Math.ceil(
        this.props.shopeeReducer.products.total / state.pageSize
      ),
      pageSize: state.pageSize,
    });
  }

  updateUnitCostStatus(id, itemId, variationId) {
    this.props.updateUnitCostStatus(id, itemId, variationId, true);
  }

  updateUnitCost(id, itemId, variationId, unitCost, currency) {
    this.props.requestUpdateUnitCost(
      id,
      itemId,
      variationId,
      unitCost,
      currency
    );
  }

  editProduct(productId) {}
  // comment
  selected(e, productId) {
    if (!e.target.checked) {
      this.setState({
        selectedId: this.state.selectedId.filter((d) => d !== productId),
      });
    } else {
      this.setState({
        selectedId: this.state.selectedId.concat(productId),
      });
    }
  }

  syncShopeeProduct() {
    this.props.syncShopeeProduct();
  }

  render() {
    const { loading } = this.props.shopeeReducer;

    return (
      <ListShopeeProductDataTableComponent
        loading={loading}
        fetchProduct={this.fetchProduct}
        selected={this.selected.bind(this)}
        updateUnitCostStatus={this.updateUnitCostStatus.bind(this)}
        updateUnitCost={this.updateUnitCost.bind(this)}
        syncShopeeProduct={this.syncShopeeProduct.bind(this)}
        {...this.state}
      />
    );
  }
}

// comment

function mapStateToProps(state) {
  return {
    shopeeReducer: state.shopeeReducer,
  };
}

export default connect(mapStateToProps, {
  getProductList: (payload, limit, page) =>
    shopee.getProductListRequest(payload, limit, page),
  updateUnitCostStatus: (id, itemId, variationId, status) =>
    payload.updateUnitCostStatus(id, itemId, variationId, status),
  requestUpdateUnitCost: (id, itemId, variationId, unitCost, currency) =>
    shopee.requestUpdateUnitCost(id, itemId, variationId, unitCost, currency),
  syncShopeeProduct: shopee.syncShopeeProduct,
  destroy: payload.destroy,
})(ListShopeeProductDataTableContainer);
