import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import DatatableContainer from "../container/datatable";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";

class ListPuchaseOrderContainer extends Component {
  state = {
    statuses: [],
    payment_modes: [],
    payment_statuses: [],
    delivery_statuses: [],
  };

  componentDidMount() {
    this.props.getAllCommon(
      (response) => {
        const data = response.data;
        if (data.success === false) {
          return;
        }
        this.setState({
          statuses: data.statuses,
          payment_modes: data.payment_modes.data,
          payment_statuses: data.payment_statuses.data,
          delivery_statuses: data.delivery_statuses.data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
    const { open, onOpenModal, onCloseModal } = this.props;
    const { statuses } = this.state;
    return (
      <Fragment>
        <Breadcrumb title="Purchase Order List" parent="Purchase" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="clearfix"></div>
              <div
                id="batchDelete"
                className="category-table user-list order-table coupon-list-delete"
              >
                <DatatableContainer {...this.state} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  createPurchaseOrder: Action.createPurchaseOrder,
  getAllCommon: Action.getAllCommon,
})(ListPuchaseOrderContainer);
