import Http from "api/http";
import { validateSaveStockingTemplate } from "./validations/save-stocking-template";

export const requestAllCommonList = () => (dispatch) => {
  return Http.GET("inventory/get-all-common");
};

export const requestSaveStockingTemplate = (data) => (dispatch) => {
  validateSaveStockingTemplate(data);
  return Http.POST("inventory/stocking/save-template", data);
};

export const requestSubmitForApproval = (data) => (dispatch) => {
  validateSaveStockingTemplate(data);
  return Http.POST("inventory/stocking/submit-for-approval", data);
};

export const requestStockingTemplateList = () => (dispatch) => {
  return Http.GET("inventory/stocking/get-stocking-template-list");
};

export const requestBrandList = (data) => (dispatch) => {
  return Http.POST("inventory/stocking/get-brand-list", data);
};

export const requestBrandItemsList = (brand_id, filter) => (dispatch) => {
  return Http.POST(
    "inventory/stocking/get-brand-items-list/" + brand_id,
    filter
  );
};
