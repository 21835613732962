import React, { Component, Fragment } from "react";
import SummaryWidgetsComponent from "../views/summary-widgets";

import { connect } from "react-redux";
import * as SalesActions from "../../redux/actions/index";

export class SummaryWidgetsContainer extends Component {
  render() {
    const { summary } = this.props.orderExportReducer.transactions;

    return <SummaryWidgetsComponent {...summary} />;
  }
}

function mapStateToProps(state) {
  return {
    orderExportReducer: state.orderExportReducer,
  };
}

export default connect(mapStateToProps, null)(SummaryWidgetsContainer);
