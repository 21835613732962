import React, { Component } from "react";

import { connect } from "react-redux";
import { List } from "immutable";
import SidebarView from "../views/sidebar";

class SidebarContainer extends Component {
  state = {
    data: [],
    sort: [
      "basic-info",
      "sell-cost-info",
      "variation",
      "wholesale",
      "detailed-desc",
      "media",
      "shipping-info",
      "campaign-info",
      "seo-info",
    ],
  };

  goto(selector) {
    const a = this.props.data.filter((d) => d.selector === selector);
    if (a.length > 0) {
      const top = this.offsetTop(a[0].ref);
      window.scroll({
        behavior: "smooth",
        top: top - 250,
      });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      const s = this.props.data.filter((d) => d.selected === true);

      this.setState({
        data: this.props.data.map((d, i) => {
          if (i === 0) {
            if (s.length <= 0) {
              d.selected = true;
            }
          }
          if (d.selector === "wholesale") {
            d.visible = this.props.wholesale.enabled;
          }
          if (d.selector === "variation") {
            d.visible = this.props.variation.enabled;
          }
          return d;
        }),
      });
    }, 500);

    document.addEventListener("scroll", this.scroll.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.wholesale.enabled !== this.props.wholesale.enabled) {
      this.setState({
        data: this.state.data.map((c) => {
          if (c.selector === "wholesale") {
            c.visible = this.props.wholesale.enabled;
          }
          return c;
        }),
      });
    }
    if (prevProps.variation.enabled !== this.props.variation.enabled) {
      this.setState({
        data: this.state.data.map((c) => {
          if (c.selector === "variation") {
            c.visible = this.props.variation.enabled;
          }
          return c;
        }),
      });
    }
  }

  scroll(e) {
    this.props.data.forEach((a) => {
      const scroll = window.pageYOffset + 250;
      const top = this.offsetTop(a.ref);
      const eleHeight = a.ref.offsetHeight;
      if (scroll >= top && top + eleHeight >= scroll) {
        this.selector(a.selector);
      }
    });
  }

  selector(selector) {
    this.setState({
      data: this.state.data.map((c) => {
        c.selected = false;
        if (c.selector === selector) {
          c.selected = true;
        }
        return c;
      }),
    });
  }

  offsetTop(el) {
    return (
      el.getBoundingClientRect().top +
      (window.pageYOffset || document.documentElement.scrollTop)
    );
  }

  render() {
    return (
      <SidebarView
        {...this.props}
        goto={this.goto.bind(this)}
        anchor={this.state.data}
      />
    );
  }
}

export default connect((state) => {
  return {
    wholesale: state.productReducer.wholesale,
    variation: state.productReducer.variation,
  };
})(SidebarContainer);
