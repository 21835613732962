import React, { Component, Fragment } from "react";

import ReceivedOrderDatatableComponent from "../views/received-order-datatable";
import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";
import * as toast from "components/common/toast";
import Emitter from "common/event-emitter";
import { empty, isNumber } from "common";

class ReceivedOrderDatatableContainer extends Component {
  state = {
    data: [],
    pages: null,
    pageSize: 0,
    selectedId: [],
    open: false,
    loading: false,
    filtered: [],
    sorted: [],
  };

  constructor() {
    super();

    this.selected = this.selected.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
      this.setState({
        data: this.state.data,
        selectedId: [],
        pages: Math.ceil(this.state.total / this.state.pageSize),
      });
    }
  }

  componentDidMount() {
    Emitter.on(
      "REFRESH_DATATABLE",
      function (response) {
        this.getDaTableRecords(this.state);
      },
      this
    );
  }

  fetchReceivedOrder(state, instance) {
    this.setState({
      loading: true,
    });
    this.getDaTableRecords(state);
  }

  getDaTableRecords(state) {
    this.props.getAllReceivedOrders(
      {
        filtered: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
      },
      state.pageSize,
      state.page == 0 ? 1 : state.page + 1,
      (response) => {
        this.setDataTable(response.data, response.total, state.pageSize);
      }
    );
  }

  rmovePurchaseOrder(e) {
    const props = this.props;
    const state = this.state;
    if (state.selectedId.length <= 0) return;

    toast.question(
      "Delete",
      "Are you sure you want to remove this?",
      function () {
        props.deletePurchaseOrder(state.selectedId);
      }
    );
  }

  setDataTable(data, total, pageSize) {
    this.setState({
      data: data,
      pages: Math.ceil(total / pageSize),
      pageSize: pageSize,
      loading: false,
      total: total,
    });
  }

  editPurchaseOrder(e, id) {}

  selected(e, id) {
    if (!e.target.checked) {
      this.setState({
        selectedId: this.state.selectedId.filter((d) => d !== id),
      });
    } else {
      this.setState({
        selectedId: this.state.selectedId.concat(id),
      });
    }
  }

  render() {
    return (
      <ReceivedOrderDatatableComponent
        selected={this.selected.bind(this)}
        editPurchaseOrder={this.editPurchaseOrder.bind(this)}
        rmovePurchaseOrder={this.rmovePurchaseOrder.bind(this)}
        fetchReceivedOrder={this.fetchReceivedOrder.bind(this)}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    purchaseOrderReducer: state.purchaseOrderReducer,
  };
}

export default connect(mapStateToProps, {
  getAllReceivedOrders: (filter, limit, page, callback) =>
    Action.getAllReceivedOrdersRequest(filter, limit, page, callback),
})(ReceivedOrderDatatableContainer);
