import React, { Component, Fragment, Suspense } from "react";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";

import * as toast from "components/common/toast";
import moment from "moment";
import { empty } from "common";

const ReceivePurchaseOrderComponent = React.lazy(() =>
  import("../views/receive-purchase-order")
);

class CreatereceivePurchaseOrderContainer extends Component {
  state = {
    supplier: "",
    order_date: "",
    status: "Pending",
    received_remarks: "",
    title: "",
    error: false,
    message: "",
    statuses: [],
    items: [],
    payment_term: "",
    payment_terms: [],
    warehouse: "",
    warehouses: [],
    delivered_by: "",
    received_by: "",
    courier: "",
    order_data_loading: true,
    save_loading: false,
    delivery_status: [],
    delivery_date: moment()._d,
    delivery_statuses: [],
  };

  constructor(props) {
    super(props);

    this.form_ref = React.createRef();
  }

  updateState(callback) {
    this.setState(callback);
  }

  clearState() {
    this.setState({
      supplier: "",
      order_date: "",
      status: "Pending",
      received_remarks: "",
      title: "",
      error: false,
      message: "",
      statuses: [],
      items: [],
      payment_term: [],
      payment_mode: [],
      delivery_status: [],
      delivered_by: "",
      received_by: "",
      courier: "",
    });
  }

  componentDidMount() {
    this.props.getAllCommon((response) => {
      if (response.data.success === false) {
        return;
      }
      this.setState({
        ...response.data,
      });
    });

    this.updateState({
      order_data_loading: true,
    });

    setTimeout(() => {
      this.props.getPurchaseOrder(
        this.props.match.params.id,
        (response) => {
          const data = response.data;

          this.updateState({
            ...data,
            original_delivery_status: data.delivery_status,
            items: data.items.reduce((item, row) => {
              item.push({
                is_db: true,
                ...row,
                receive_quantity: 0,
                item: {
                  option: {
                    label:
                      row.details.variation_name +
                      ", " +
                      row.details.product_name,
                    value: row.details.product_id,
                  },
                  details: {
                    ...row.details,
                  },
                },
              });

              return item;
            }, []),
            order_data_loading: false,
          });
        },
        (error) => {
          console.log(error);
          this.updateState({
            order_data_loading: false,
          });
        }
      );
    }, 2000);
  }

  validateComplete() {
    if (this.isDeliveryCompleted()) {
      throw "Purchase order is already completed.";
    }
  }

  validation() {
    var error = false;
    var delivery_date_invald = false;
    const error_message = "This is required.";

    this.updateState({
      delivery_date_invald: false,
    });
    // Form validation

    return new Promise((resolve, reject) => {
      this.updateState((state) => {
        // Form validation
        if (empty(state.delivery_date)) {
          delivery_date_invald = true;
        }

        if (delivery_date_invald) {
          reject({
            delivery_date_invald,
            message: "Please complete all form fields.",
          });
        }

        if (state.items.filter((item) => item.receive_quantity > 0) <= 0) {
          reject({ message: "No item to receive." });
        }

        const items = state.items.map((row) => {
          row.item_error_message = null;
          row.unit_error_message = null;

          row.receive_quantity_error_message = null;

          if (empty(row.item)) {
            error = true;
            row.item_error_message = error_message;
          }
          if (empty(row.unit) || (row.unit?.id ?? "") == "") {
            error = true;
            row.unit_error_message = error_message;
          }

          if (!empty(row.receive_quantity) && row.receive_quantity < 0) {
            error = true;
            row.receive_quantity_error_message = error_message;
          }

          return row;
        });

        if (error) {
          reject({
            items,
            message:
              "Please correct item fields." +
              state.items
                .reduce((c, row) => {
                  if (empty(row.item)) {
                    c.push("item");
                  }
                  if (empty(row.unit)) {
                    c.push("unit");
                  }
                  if (
                    !empty(row.receive_quantity) &&
                    row.receive_quantity < 0
                  ) {
                    c.push("Receive Quantity");
                  }
                  return c;
                }, [])
                .filter((value, index, self) => self.indexOf(value) === index)
                .join(", "),
          });
        }

        resolve(true);
      });
    });
  }

  handleSubmitOrder(e) {
    e.preventDefault();

    try {
      this.validateComplete();
      this.validation()
        .then((e) => {
          this.form_ref.current.submit();
        })
        .catch((error) => {
          console.log(error.message);
          toast.error(error.message);
          delete error.message;
          if (error)
            this.updateState({
              ...error,
            });
        });
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  }

  handleValidSubmit(event, values) {
    this.updateState({
      save_loading: true,
    });
    setTimeout(() => {
      console.log(this.state);
      this.props.receivePurchaseOrder(
        this.props.match.params.id,
        {
          ...this.state,
        },
        (response) => {
          this.updateState({
            save_loading: false,
          });
          if (response.data.success === true) {
            this.clearState();
            toast.success(response.data.message);
            setTimeout(() => {
              this.props.history.push("/purchase/list-purchase-orders");
            }, 2000);
          } else {
            this.updateState({
              message: response.data.message,
              error: true,
            });
          }
        },
        (error) => {
          console.log(error);
          this.updateState({
            message: error?.message,
            error: true,
            save_loading: false,
          });
        }
      );
    }, 1000);
  }

  handleInputChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  isDeliveryCompleted() {
    console.log(this.state.delivery_statuses);
    return (
      (this.state.delivery_statuses?.data ?? []).filter(
        (status) => status.name.toLowerCase() == "completed"
      )[0]?.id == this.state.original_delivery_status?.id
    );
  }

  render() {
    console.log(this.state);
    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          {!this.state.order_data_loading ? (
            <ReceivePurchaseOrderComponent
              {...this.state}
              {...this.props}
              form_ref={this.form_ref}
              updateState={this.updateState.bind(this)}
              handleInputChange={this.handleInputChange.bind(this)}
              handleSubmitOrder={this.handleSubmitOrder.bind(this)}
              handleValidSubmit={this.handleValidSubmit.bind(this)}
              isDeliveryCompleted={this.isDeliveryCompleted.bind(this)}
            />
          ) : (
            "Loading..."
          )}
        </Suspense>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierReducer: state.supplierReducer,
  };
}

export default connect(mapStateToProps, {
  receivePurchaseOrder: Action.receivePurchaseOrder,
  getPurchaseOrder: Action.getPurchaseOrder,
  getAllCommon: Action.getAllCommon,
})(CreatereceivePurchaseOrderContainer);
