import React, { Component } from "react";

import { connect } from "react-redux";
import RightSidebarComponent from "../views/right-sidebar";

class RightSidebarContainer extends Component {
  render() {
    return <RightSidebarComponent {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    userAuthReducer: state.userAuthReducer,
  };
}

export default connect(mapStateToProps, null)(RightSidebarContainer);
