import React, { useEffect } from "react";

import * as Http from "../../redux/actions/http";
import { connect } from "react-redux";

import * as toast from "components/common/toast";

import AllocationModalComponent from "../views/allocation-modal";

const AllocationModalContainer = (props) => {
  const requestUpdateRealtimeStock = () => {
    setTimeout(() => {
      props.updateState({
        allocation_modal: {
          ...props.allocation_modal,
          update: true,
        },
      });
    }, 1000);
  };

  const promptIfZeroAllocatedStock = (reference, callback) => {
    if ((reference?.allocated_stock ?? 0) <= 0) {
      toast.question(
        "",
        "You have allocated zero (0) stock are you sure want to proceed?",
        callback
      );
    } else {
      callback();
    }
  };

  const findShopReferenceIndex = (reference) => {
    return props.allocation_modal.inventory.shop_links_reference.findIndex(
      (f) =>
        f.shop.id == reference.shop.id &&
        f.product_id == reference.product_id &&
        f.product_variation_id == reference.product_variation_id &&
        f.shop_item_id == reference.shop_item_id &&
        f.shop_item_variation_id == reference.shop_item_variation_id
    );
  };

  const updateShopLinkRefLoadingReplenish = (reference, loading) => {
    const inventory = props.allocation_modal.inventory;
    const shop_ref_index = findShopReferenceIndex(reference);
    const shop_ref = inventory.shop_links_reference[shop_ref_index];

    inventory.shop_links_reference[shop_ref_index] = {
      ...shop_ref,
      loading: {
        ...shop_ref.loading,
        replenish: loading,
      },
    };

    props.updateState({
      allocation_modal: {
        ...props.allocation_modal,
        inventory: inventory,
      },
    });
  };

  const updateShopLinkRefLoadingOverride = (reference, loading) => {
    const inventory = props.allocation_modal.inventory;
    const shop_ref_index = findShopReferenceIndex(reference);
    const shop_ref = inventory.shop_links_reference[shop_ref_index];

    inventory.shop_links_reference[shop_ref_index] = {
      ...shop_ref,
      loading: {
        ...shop_ref.loading,
        override: loading,
      },
    };
    props.updateState({
      allocation_modal: {
        ...props.allocation_modal,
        inventory: inventory,
      },
    });
  };

  const handleRequestRelenish = (inventory, reference) => {
    const callback = () => {
      updateShopLinkRefLoadingReplenish(reference, true);
      props.requestReplenish({ inventory, reference }).then((response) => {
        if (response.data.success) {
          updateShopLinkRefLoadingReplenish(reference, false);
          requestUpdateRealtimeStock();
          toast.success(response.data.message);
        }
      });
    };

    promptIfZeroAllocatedStock(reference, callback);
  };

  const handleRequestOverride = (inventory, reference) => {
    const callback = () => {
      updateShopLinkRefLoadingOverride(reference, true);
      props.requestOverride({ inventory, reference }).then((response) => {
        if (response.data.success) {
          updateShopLinkRefLoadingOverride(reference, false);
          toast.success(response.data.message);
        }
      });
    };

    promptIfZeroAllocatedStock(reference, callback);
  };

  const handleAllocationInputChange = (reference, stock) => {
    const links_reference =
      props.allocation_modal.inventory.shop_links_reference;
    const index = links_reference.indexOf(reference);

    links_reference[index].allocated_stock = stock;

    props.updateState({
      allocation_modal: {
        ...props.allocation_modal,
        update: false,
        inventory: {
          ...props.allocation_modal.inventory,
          shop_links_reference: links_reference,
        },
      },
    });
  };

  useEffect(() => {
    if (props.allocation_modal.update) {
      props
        .requestShopItemsAvailableStock(props.allocation_modal.inventory)
        .then((response) => {
          const available_stock_list = response.data;
          props.updateState({
            allocation_modal: {
              ...props.allocation_modal,
              update: false,
              inventory: {
                ...props.allocation_modal.inventory,
                shop_links_reference: props.allocation_modal.inventory.shop_links_reference.map(
                  (reference) => {
                    const reference_idx = available_stock_list.findIndex(
                      (f) =>
                        f.shop_id == reference.shop.id &&
                        f.shop_item_id == reference.shop_item_id &&
                        f.shop_item_variation_id ==
                          reference.shop_item_variation_id
                    );

                    if (reference_idx !== -1) {
                      reference = {
                        ...reference,
                        loading: {
                          realtime_stock: false,
                        },
                        available_stock:
                          available_stock_list[reference_idx].available_stock,
                      };
                    }
                    return reference;
                  }
                ),
              },
            },
          });
        });
    }
  }, [props.allocation_modal.update]);

  return (
    <AllocationModalComponent
      {...props}
      handleRequestRelenish={handleRequestRelenish.bind(this)}
      handleRequestOverride={handleRequestOverride.bind(this)}
      handleAllocationInputChange={handleAllocationInputChange.bind(this)}
    />
  );
};

export default connect(null, {
  requestOverride: Http.requestOverride,
  requestReplenish: Http.requestReplenish,
  requestShopItemsAvailableStock: Http.requestShopItemsAvailableStock,
})(AllocationModalContainer);
