import React from "react";

const ButtonWholesaleVariationsView = ({
  variationEnabled,
  wholesaleEnabled,
  handleEnableVariation,
  handleEnableWholesale,
}) => {
  const wholesaleButtonProps = () => {
    if (wholesaleEnabled === true) {
      return {
        className: "default",
        label: "DISABLE WHOLESALE",
      };
    }
    return {
      className: "default",
      label: "ENABLE WHOLESALE",
    };
  };

  const variationButtonProps = () => {
    if (variationEnabled === true) {
      return {
        className: "default",
        label: "DISABLE VARIATIONS",
      };
    }
    return {
      className: "default",
      label: "ENABLE VARIATIONS",
    };
  };

  return (
    <div className="form-group col-sm-12">
      <button
        type="button"
        className={"btn m-r-10 btn-" + variationButtonProps().className}
        onClick={(e) => {
          handleEnableVariation(e);
        }}
      >
        {variationButtonProps().label}
      </button>

      <button
        type="button"
        className={"btn m-r-10 btn-" + wholesaleButtonProps().className}
        onClick={(e) => {
          handleEnableWholesale(e);
        }}
      >
        {wholesaleButtonProps().label}
      </button>
    </div>
  );
};

export default ButtonWholesaleVariationsView;
