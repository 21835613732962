import React, { Component } from "react";

import { connect } from "react-redux";
import * as product from "../../redux/actions/product/http";
import * as toast from "components/common/toast";
import Emitter from "common/event-emitter";
import ListDataTableComponent from "../views/list-datatable";

class ListDataTableContainer extends Component {
  state = {
    data: [],
    pages: null,
    pageSize: 0,
    selectedId: [],
    open: false,
    loading: false,
    with_case: false,
    table_state: [],
  };

  constructor() {
    super();
    this.fetchProduct = this.fetchProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.deactivateProduct = this.deactivateProduct.bind(this);
    this.activateProduct = this.activateProduct.bind(this);
    this.selected = this.selected.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.handleClearedProductDuplicateCase = this.handleClearedProductDuplicateCase.bind(
      this
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
      this.setState({
        data: this.state.data,
        selectedId: [],
        pages: Math.ceil(this.state.total / this.state.pageSize),
      });
    }

    if (
      prevProps.with_case != this.props.with_case ||
      prevProps.show_cleared_duplicate_case !=
        this.props.show_cleared_duplicate_case
    ) {
      this.defaultList();
    }
  }

  componentWillUnmount() {
    Emitter.off("PRODUCT_REFRESH_LIST");
    Emitter.off("PRODUCT_REFRESH_DATATABLE");
    Emitter.off("PRODUCT_REFRESH_DUPLICATE_CASE_DATATABLE");
  }

  refreshDataTable() {
    Emitter.on("PRODUCT_REFRESH_LIST", (state) => {
      this.defaultList();
    });

    Emitter.on("PRODUCT_REFRESH_DATATABLE", (state) => {
      this.defaultProductList();
    });

    Emitter.on("PRODUCT_REFRESH_DUPLICATE_CASE_DATATABLE", (state) => {
      this.defaultListWithDuplicateCase();
    });
  }

  componentDidMount() {
    this.refreshDataTable();
  }

  fetchProduct(state, instance) {
    this.fetch(state);
  }

  fetch(state) {
    this.setState({
      table_state: { ...state },
    });

    if (this.props.with_case) {
      this.fetchByProductWithDuplicateCaseList(state);
    } else if (this.props.show_cleared_duplicate_case) {
      this.fetchByProductShowClearedDuplicateCaseList(state);
    } else {
      this.fetchByProductList(state);
    }
  }

  fetchByProductList(state) {
    this.setState({
      loading: true,
    });
    this.props.getProductList(
      {
        criteria: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
        per_page: state.pageSize,
        page: state.page == 0 ? 1 : state.page + 1,
      },
      (response) => {
        this.updateState(state, response.data);
      },
      (error) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  fetchByProductWithDuplicateCaseList(state) {
    this.setState({
      loading: true,
    });
    this.props.getProductListWithDuplicateCase(
      {
        criteria: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
        per_page: state.pageSize,
        page: state.page == 0 ? 1 : state.page + 1,
      },
      (response) => {
        this.updateState(state, response.data);
      },
      (error) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  fetchByProductShowClearedDuplicateCaseList(state) {
    this.setState({
      loading: true,
    });
    this.props.getProductListShowCLearedDuplicateCase(
      {
        criteria: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
        per_page: state.pageSize,
        page: state.page == 0 ? 1 : state.page + 1,
      },
      (response) => {
        this.updateState(state, response.data);
      },
      (error) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  getDefaultTableState() {
    return {
      criteria: (this.state.table_state?.filtered ?? []).reduce((c, i) => {
        c.push({ [i.id]: i.value });
        return c;
      }, []),
      sorted: (this.state.table_state?.filtered ?? []).reduce((c, i) => {
        c.push({ [i.id]: { desc: i.desc } });
        return c;
      }, []),
      per_page: this.state.table_state?.pageSize ?? 10,
      page:
        (this.state.table_state?.page ?? 0) == 0
          ? 1
          : (this.state.table_state?.page ?? 0) + 1,
    };
  }

  defaultList() {
    if (this.props.with_case) {
      this.defaultListWithDuplicateCase();
    } else if (this.props.show_cleared_duplicate_case) {
      this.defaultListShowClearedDuplicateCase();
    } else {
      this.defaultProductList();
    }
  }

  defaultListWithDuplicateCase() {
    this.setState({
      loading: true,
    });
    this.props.getProductListWithDuplicateCase(
      this.getDefaultTableState(),
      (response) => {
        this.updateState(this.state, response.data);
      },
      (error) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  defaultListShowClearedDuplicateCase() {
    this.setState({
      loading: true,
    });
    this.props.getProductListShowCLearedDuplicateCase(
      this.getDefaultTableState(),
      (response) => {
        this.updateState(this.state, response.data);
      },
      (error) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  defaultProductList() {
    this.setState({
      loading: true,
    });
    this.props.getProductList(
      this.getDefaultTableState(),
      (response) => {
        this.updateState(this.state, response.data);
      },
      (error) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  updateState(state, data) {
    this.setState({
      data: data.data,
      pages: Math.ceil(data.total / state.pageSize),
      total: data.total,
      pageSize: state.pageSize,
      loading: false,
    });
  }

  deleteProduct(productId) {
    const props = this.props;
    toast.question(
      "Delete",
      "Are you sure you want to remove this?",
      function () {
        props.deleteProduct(productId);
      }
    );
  }

  deactivateProduct(productId) {
    const props = this.props;
    toast.question(
      "Deactivate",
      "Are you sure you want to deactivate this?",
      function () {
        props.deactivateProduct(productId);
      }
    );
  }

  activateProduct(productId) {
    const props = this.props;
    toast.question(
      "Activate",
      "Are you sure you want to activate this?",
      function () {
        props.activateProduct(productId);
      }
    );
  }

  editProduct(productId) {}
  // comment
  selected(e, productId) {
    if (!e.target.checked) {
      this.setState({
        selectedId: this.state.selectedId.filter((d) => d !== productId),
      });
    } else {
      this.setState({
        selectedId: this.state.selectedId.concat(productId),
      });
    }
  }

  updateUnitCost() {}
  updateUnitCostStatus() {}

  handleClearedProductDuplicateCase(product_id, status) {
    this.props.clearedProductDuplicateCase(product_id, status);
  }

  render() {
    return (
      <ListDataTableComponent
        fetchProduct={this.fetchProduct}
        deleteProduct={this.deleteProduct.bind(this)}
        editProduct={this.editProduct.bind(this)}
        activateProduct={this.activateProduct}
        deactivateProduct={this.deactivateProduct}
        selected={this.selected.bind(this)}
        updateUnitCost={this.updateUnitCost.bind(this)}
        updateUnitCostStatus={this.updateUnitCostStatus.bind(this)}
        handleOpenGameboardModal={this.props.handleOpenGameboardModal}
        handleClearedProductDuplicateCase={
          this.handleClearedProductDuplicateCase
        }
        {...this.state}
      />
    );
  }
}

// comment

function mapStateToProps(state) {
  return {
    productReducer: state.productReducer,
  };
}

export default connect(mapStateToProps, {
  getProductList: product.getProductList,
  getProductListWithDuplicateCase: product.getProductListWithDuplicateCase,
  clearedProductDuplicateCase: product.clearedProductDuplicateCase,
  getProductListShowCLearedDuplicateCase:
    product.getProductListShowCLearedDuplicateCase,
  deleteProduct: (d) => product.deleteProduct(d),
  deactivateProduct: (d) => product.deactivateProduct(d),
  activateProduct: (d) => product.activateProduct(d),
})(ListDataTableContainer);
