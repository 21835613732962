class Tickle {
  constructor(base) {
    this.base = base;
  }

  openPlayer = () => {
    this.base.setState({
      tickle: { player: { popupBox: true } },
    });
    console.log(this.parent.state.players);
  };

  closePlayer = () => {
    this.base.setState({
      tickle: { player: { popupBox: false } },
    });
  };

  closeOpponent = () => {
    this.base.setState({
      tickle: { opponent: { popupBox: false } },
    });
  };
}

export default Tickle;
