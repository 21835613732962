import React from "react";
import Component from "../views";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/payload";

const Container = (props) => {
  return (
    <>
      <Component
        data={props.inStoreOrderReducer.shipping}
        changeShippingData={props.changeShippingData.bind(this)}
      />
    </>
  );
};

export default connect(
  (state) => {
    return {
      inStoreOrderReducer: state.inStoreOrderReducer,
    };
  },
  {
    ...Action,
  }
)(Container);
