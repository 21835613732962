import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import Message_container from "components/common/message-container";

import BatchUpdateContainer from "../container/batch-update";
import ItemsDatatableContainer from "../container/items-datatable";

import Spinner from "components/common/spinner";

export default class ReOrderLevelComponent extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Re-order Level" parent="product" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-right m-b-20">
              <button
                onClick={(e) => {
                  this.props.submit();
                }}
                type="button"
                className="btn btn-lg btn-primary"
              >
                {this.props.updating ? <Spinner loading={true} /> : ""}
                SUBMIT
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <Message_container
                    message={this.props.message}
                    error={this.props.error}
                  />
                  <BatchUpdateContainer {...this.props} />
                  <ItemsDatatableContainer {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
