import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import _debounce from "lodash.debounce";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as ShopAction from "../../redux/actions/index";
import FormComponent from "../views/form";

export class UpdateShopContainer extends Component {
  state = {
    shop_name: "",
    app_key: "",
    is_main_store: false,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.shopReducer) !==
      JSON.stringify(this.props.shopReducer)
    ) {
      this.setState({
        ...this.props.shopReducer.edit,
      });
    }

    if (this.props.shopReducer.updated === true) {
      this.props.onCloseModal();
      this.props.setUpdateStatus(false);
    }
  }

  handleSubmit(e, values) {
    this.props.updateShop(this.props.shopId, values);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleCheckboxChange(e) {
    e.persist();

    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  render() {
    const { open, onCloseModal, onOpenModal } = this.props;
    const { response, loading } = this.props.shopReducer;

    return (
      <FormComponent
        handleChange={this.handleChange.bind(this)}
        handleSubmit={this.handleSubmit.bind(this)}
        handleCheckboxChange={this.handleCheckboxChange.bind(this)}
        loading={loading}
        response={response}
        {...this.props}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    shopReducer: state.shopReducer,
  };
}

export default connect(mapStateToProps, {
  updateShop: (shopId, data) => ShopAction.updateShop(shopId, data),
  setUpdateStatus: (d) => ShopAction.setUpdateStatus(d),
})(UpdateShopContainer);
