export const empty = (value) => {
  if (value == "" || value === undefined || value == "null" || value === null) {
    return true;
  }
  return false;
};

export const emptyNumeric = (value) => {
  if (value == 0 || empty(value)) {
    return true;
  }
  return false;
};

export const isNumber = (value) => {
  return !Number.isNaN(value) || value.toString().toLowerCase() !== "nan";
};

export const numberOnly = (value) => {
  return value.toString().replace(new RegExp(/[^?0-9.]/g), "");
};

export const isNumberPositive = (value) => {
  if (!isNumber(value)) {
    return false;
  }
  return value > 0;
};

export const formatNumber = () => {};

export const excerpt = (text, length, more) => {
  if (text.length > length) {
    return text.substr(0, length) + (empty(more) ? "..." : more);
  }

  return text;
};
