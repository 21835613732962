export const getProductSettings = (state, action) => {
  return { ...state, settings: action.payload };
};

export const anchorSelected = (state, action) => {
  return {
    ...state,
    anchor: state.anchor.map((a) => {
      a.selected = false;
      if (a.selector === action.payload.selector) {
        a.selected = true;
      }
      return a;
    }),
  };
};

export const addAnchor = (state, action) => {
  return {
    ...state,
    anchor: state.anchor.concat({
      title: action.payload.title,
      selector: action.payload.selector,
      selected: false,
      ref: action.payload.ref,
    }),
  };
};

export const updateForm = (state, action) => {
  return {
    ...state,
    form: {
      ...state.form,
      [action.payload.column]: action.payload.value,
    },
  };
};

export const updateSellingForm = (state, action) => {
  return {
    ...state,
    form: {
      ...state.form,
      selling: {
        ...state.form.selling,
        [action.payload.column]: action.payload.value,
      },
    },
  };
};

export const updateDetailsForm = (state, action) => {
  return {
    ...state,
    form: {
      ...state.form,
      details: {
        ...state.form.details,
        [action.payload.column]: action.payload.value,
      },
    },
  };
};

export const updateShippingForm = (state, action) => {
  return {
    ...state,
    form: {
      ...state.form,
      shipping: {
        ...state.form.shipping,
        [action.payload.column]: action.payload.value,
      },
    },
  };
};

export const updateSEOForm = (state, action) => {
  return {
    ...state,
    form: {
      ...state.form,
      seo: {
        ...state.form.seo,
        [action.payload.column]: action.payload.value,
      },
    },
  };
};

export const loadProducts = (state, action) => {
  return {
    ...state,
    listing: {
      ...state.listing,
      data: action.payload.data,
      total: action.payload.total,
    },
  };
};

export const updateProductId = (state, action) => {
  return {
    ...state,
    product_id: action.payload,
  };
};
