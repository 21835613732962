import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import LinkReferenceComponent from "../views/component";
import GameComponent from "./gameboard/game-component";
import LineComponent from "./gameboard/line-component";
import GameBoard from "./gameboard/game-board";
import Opponent from "./gameboard/opponent";
import Player from "./gameboard/player";
import Tickle from "./gameboard/tickle";

import * as AssociationHttp from "../../redux/actions/http";

import uuid from "react-uuid";
import { empty } from "common";
import Emitter from "common/event-emitter";

import GamePlay from "./gameplay";

class LinkReferenceContainer extends Component {
  state = {
    message: null,
    error: null,
    open: false,
    shops: [],
    score: 0,
    start: false,
    opponents: [],
    players: [],
    selectedShop: [],
    productSearch: "",
    selected: null,
    paginations: {
      limit: 4,
      players: {
        page: 0,
        next: 0,
        label: null,
        previous: 0,
      },
      opponents: {
        page: 0,
        next: 0,
        label: null,
        previous: 0,
      },
    },
    tickle: {
      popupBox: false,
    },
  };

  constructor() {
    super();

    this.onmousedown = this.onmousedown.bind(this);
    this.onmousemove = this.onmousemove.bind(this);
    this.handleShopOptionChange = this.handleShopOptionChange.bind(this);
    this.handleSearchProduct = this.handleSearchProduct.bind(this);
    this.addNewOpponent = this.addNewOpponent.bind(this);
    this.onTickleCloseModal = this.onTickleCloseModal.bind(this);
    this.handleDetachOpponent = this.handleDetachOpponent.bind(this);
    this.selected_shop_input_ref = React.createRef();
    this.emitter = Emitter;
    this.tickle = new Tickle(this);
    this.gameplay = GamePlay(this);
  }

  componentDidMount() {
    this.props.requestGetShops().then((response) => {
      this.updateBaseState({
        shops: response.data.data,
      });
    });

    this.setupGameboard();

    this.props
      .requestGetProduct(this.props.match.params.id)
      .then((response) => {
        if (response.data.success) {
          this.loadPlayers(response.data.data);
        }
      });
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {}

  onmousemove(e) {
    const { x, y } = this.getMousePointerCoordinates(e);
    this.gameplay.target(x, y);
  }

  onmousedown(e) {
    const { x, y } = this.getMousePointerCoordinates(e);
    this.gameplay.pointer(x, y);
  }

  getMousePointerCoordinates(e) {
    const extraPixels = 0;
    const containerCoordinates = this.gameboard
      .container()
      .getBoundingClientRect();

    var x = e.clientX - containerCoordinates.left;
    var y = Math.round(e.clientY - containerCoordinates.top) - extraPixels;

    return { x, y };
  }

  gameboardUpdater() {
    setTimeout(() => {
      this.gameboard.clear();

      this.gameplay.opponent.activate();
      this.gameplay.opponent.updateCoordinates();
      this.gameplay.opponent.pagination.activate();

      this.gameplay.player.activate();
      this.gameplay.player.updateCoordinates();
      this.gameplay.player.pagination.activate();
    }, 500);
  }

  setupGameboard() {
    const container = document.getElementsByClassName(
      "linkref-gamecontainer"
    )[0];
    const canvas = container.getElementsByClassName(
      "linkref-gameboard-canvas"
    )[0];
    this.gameboard = new GameBoard(container, canvas);

    this.gameboard.canvas().height = 1000;
    this.gameboard.canvas().width = 1000;
  }

  clearEverthing() {
    this.setState((state) => {
      return {
        ...state,
        opponents: [],
        players: [],
        selectedShop: [],
        productSearch: "",
        selected: null,
        paginations: {
          limit: 4,
          players: {
            page: 0,
            next: 0,
            label: null,
            previous: 0,
          },
          opponents: {
            page: 0,
            next: 0,
            label: null,
            previous: 0,
          },
        },
      };
    });
  }

  loadPlayers(player) {
    this.clearEverthing();
    this.gameplay.player.createPlayers(player);
    this.gameboardUpdater();
  }

  updateBaseState(stateData) {
    if (typeof stateData == "function") {
      this.setState(stateData);
    } else {
      this.setState((state) => {
        return {
          ...state,
          ...stateData,
        };
      });
    }
  }

  storeConnection(player, opponent) {
    this.props.storeConnection(
      {
        player: player.getBrief(),
        opponent: opponent.getBrief(),
      },
      (response) => {}
    );
  }

  deleteConnection(player, opponent) {
    this.props.deleteConnection(
      {
        player: player.getBrief(),
        opponent: opponent.getBrief(),
      },
      (response) => {}
    );
  }

  getShops() {
    const shops = this.state.shops ?? [];
    return shops.map((d) => {
      return {
        label: d.app_name + ", " + d.shop_name,
        value: d.id,
      };
    });
  }

  handleSearchProduct = (value) => {
    this.setState({
      selected_product: value,
    });
  };

  handleShopOptionChange = (value) => {
    if (empty(value)) return;
    this.setState({
      selected_shop: value,
    });

    this.selected_shop_input_ref.current.value = value.value;
  };

  addNewOpponent = () => {
    if (empty(this.state.selected_shop)) return;
    if (empty(this.state.selected_product)) return;

    this.gameplay.opponent.createOpponents([this.state.selected_product]);
    this.gameboardUpdater();
  };

  handleDetachOpponent(player, opponent) {
    this.gameplay.detachPlayerOpponent(player, opponent);
    this.gameboardUpdater();
  }

  onTickleCloseModal() {
    this.tickle.closePlayerOpponentsListBox();
  }

  render() {
    return (
      <>
        <LinkReferenceComponent
          {...this.state}
          {...this.props}
          shopOptionsList={this.getShops()}
          handleShopOptionChange={this.handleShopOptionChange}
          selected_shop_input_ref={this.selected_shop_input_ref}
          handleSearchProduct={this.handleSearchProduct}
          onTickleCloseModal={this.onTickleCloseModal}
          addNewOpponent={this.addNewOpponent}
          handleDetachOpponent={this.handleDetachOpponent}
          onmousedown={this.onmousedown}
          onmousemove={this.onmousemove}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    shop: state.shop,
  };
}

export default connect(mapStateToProps, {
  ...AssociationHttp,
})(LinkReferenceContainer);
