const CREATE = "user/create";
const DELETE = "user/delete";
const UPDATE = "user/update";
const LOADING = "user/loading";
const RESPONSE = "user/response";
const FETCH_ALL = "user/fetch-all";
const FETCH = "user/fetch";
const FETCH_LOADING = "user/fetch-loading";
const UPDATE_STATUS = "user/update/status/boolean";

export {
    CREATE,
    DELETE,
    UPDATE,
    LOADING,
    RESPONSE,
    FETCH_ALL,
    FETCH,
    FETCH_LOADING,
    UPDATE_STATUS
};
