import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, ...main_props } = this.props;

    return (
      <Route
        exact
        {...main_props}
        render={(props) =>
          main_props.userAuthReducer.is_loggedin == true ? (
            <Component {...props} />
          ) : (
            <Redirect to="/auth/login" />
          )
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    userAuthReducer: state.userAuthReducer,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
