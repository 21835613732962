const FETCH = 'user/permission/permission/fetch';
const STATUS = 'user/permission/permission/status';
const LOADING = 'user/permission/permission/progress-loading';
const RESPONSE = 'user/permission/permission/response';
const UPDATE_GROUP_CHECKBOX = 'user/permission/permission/set-selected';
const UPDATE_GROUP_ACTION_CHECKBOX = 'user/permission/set-action-selected';
const UPDATE_GROUP_ACTION = 'user/permission/permission/update-group-action';
const CURRENT_ROLE = 'user/permission/permission/current-role';

export {
	FETCH,
	STATUS,
	UPDATE_GROUP_CHECKBOX,
	UPDATE_GROUP_ACTION_CHECKBOX,
	UPDATE_GROUP_ACTION,
	LOADING,
	RESPONSE,
	CURRENT_ROLE
};
