import React, { useEffect, useState, useReducer } from "react";

import _debounce from "lodash.debounce";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Settings } from "react-feather";

const TabsetProfileComponent = (props) => {
  const [data, setUser] = useState([]);
  const [settings, setUserSettingsNotifications] = useState([]);

  const initialState = { data: [] };
  function reducer(state, action) {
    switch (action.type) {
      case "set":
        return { data: action.payload };
      case "change":
        return {
          ...state,
          data: {
            ...state.data,
            [action.payload.name]: action.payload.value,
          },
        };
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  // Set user profile
  useEffect(() => {
    props.getUserProfile().then((response) => {
      if (response.data.success) {
        dispatch({ type: "set", payload: response.data.user });
      }
    });
  }, []);

  const handleUserChangeInfo = (name, value) => {
    const payload = {
      name,
      value,
    };
    dispatch({
      type: "change",
      payload: payload,
    });

    setTimeout(() => {
      props.updateUserProfileInfo(payload.name, payload.value);
    }, 300);
  };

  useEffect(() => {
    setUserSettingsNotifications(
      props.user_notification_list.map((settings) => {
        const available = (state.data?.settings ?? []).filter(
          (user_settings) => user_settings.option.id == settings.id
        );
        settings.checked = false;
        if (available.length > 0) {
          settings.checked = true;
        }

        return settings;
      })
    );
  }, [state.data, props.user_notification_list]);

  return (
    <div>
      <Tabs>
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">
            <User className="mr-2" />
            Profile
          </Tab>
          <Tab className="nav-link">
            <Settings className="mr-2" />
            Settings
          </Tab>
        </TabList>

        <TabPanel>
          <div className="tab-pane fade show active">
            <h5 className="f-w-600 f-16">Profile</h5>
            <div className="table-responsive profile-table">
              <table className="table table-responsive">
                <tbody>
                  <tr>
                    <td>First Name:</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={state.data?.first_name ?? "-"}
                        name="first_name"
                        onChange={(e) => {
                          handleUserChangeInfo(e.target.name, e.target.value);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Last Name:</td>
                    <td>
                      {" "}
                      <input
                        type="text"
                        className="form-control"
                        value={state.data?.last_name ?? "-"}
                        name="last_name"
                        onChange={(e) => {
                          handleUserChangeInfo(e.target.name, e.target.value);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>{state.data?.email ?? "-"}</td>
                  </tr>
                  <tr>
                    <td>Mobile Number:</td>
                    <td>
                      {" "}
                      <input
                        type="text"
                        className="form-control"
                        value={state.data?.mobile ?? "-"}
                        name="mobile"
                        onChange={(e) => {
                          handleUserChangeInfo(e.target.name, e.target.value);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Tel. Number:</td>
                    <td>
                      {" "}
                      <input
                        type="text"
                        className="form-control"
                        value={state.data?.tel_no ?? "-"}
                        name="tel_no"
                        onChange={(e) => {
                          handleUserChangeInfo(e.target.name, e.target.value);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          {/* <div className="tab-pane fade"> */}
          <div className="account-setting">
            <h5 className="f-w-600 f-16">Notifications</h5>
            <div className="row">
              <div className="col">
                {settings.map((option, i) => {
                  return (
                    <label className="d-block" key={i}>
                      <input
                        className="checkbox_animated"
                        type="checkbox"
                        onChange={(e) => {
                          props.updateUserNotifications(option);
                        }}
                        checked={option?.checked ? "checked" : ""}
                      />
                      {option.name}
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="account-setting deactivate-account">
            <h5 className="f-w-600 f-16">Deactivate Account</h5>
            <div className="row">
              <div className="col">
                <label className="d-block">
                  <input
                    className="radio_animated"
                    id="edo-ani"
                    type="radio"
                    name="rdo-ani"
                    defaultChecked
                  />
                  I have a privacy concern
                </label>
                <label className="d-block">
                  <input
                    className="radio_animated"
                    id="edo-ani1"
                    type="radio"
                    name="rdo-ani"
                  />
                  This is temporary
                </label>
                <label className="d-block mb-0">
                  <input
                    className="radio_animated"
                    id="edo-ani2"
                    type="radio"
                    name="rdo-ani"
                    defaultChecked
                  />
                  Other
                </label>
              </div>
            </div>
            <button type="button" className="btn btn-primary">
              Deactivate Account
            </button>
          </div>
          <div className="account-setting deactivate-account">
            <h5 className="f-w-600 f-16">Delete Account</h5>
            <div className="row">
              <div className="col">
                <label className="d-block">
                  <input
                    className="radio_animated"
                    id="edo-ani3"
                    type="radio"
                    name="rdo-ani1"
                    defaultChecked
                  />
                  No longer usable
                </label>
                <label className="d-block">
                  <input
                    className="radio_animated"
                    id="edo-ani4"
                    type="radio"
                    name="rdo-ani1"
                  />
                  Want to switch on other account
                </label>
                <label className="d-block mb-0">
                  <input
                    className="radio_animated"
                    id="edo-ani5"
                    type="radio"
                    name="rdo-ani1"
                    defaultChecked
                  />
                  Other
                </label>
              </div>
            </div>
            <button type="button" className="btn btn-primary">
              Delete Account
            </button>
          </div>
          {/* </div> */}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default TabsetProfileComponent;
