import * as type from "redux/constants/user/permission/role";

const initialState = {
  data: [],
};

export default function Role_permission(state = initialState, action) {
  switch (action.type) {
    case type.FETCH_ALL:
      state = Object.assign({}, state, {
        data: action.payload,
      });
      break;
    case type.FORM_STATUS:
      state = Object.assign({}, state, {
        formStatus: action.payload,
      });
      break;
    case type.LOADING:
      const loading = action.payload;
      return { ...state, loading };
      break;
    case type.RESPONSE:
      const response = action.payload;
      return { ...state, response };
      break;
  }

  return state;
}
