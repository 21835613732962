import { connect } from "react-redux";

const SUPER_ADMIN = 1;

const Permission = (roles) => {
  const canView = (page) => {
    if (roles.filter((d) => d.id == SUPER_ADMIN).length > 0) {
      return true;
    }
    const can = roles.reduce((c, r) => {
      let x = (r.permissions ?? []).reduce((c, p) => {
        if (page && p.name.toLowerCase().trim() == page.toLowerCase().trim()) {
          let x = p.actions.reduce((c, a) => {
            if (a.name.toLowerCase().trim() == "view") {
              c = true;
            }
            return c;
          }, false);
          if (x) {
            c = x;
          }
        }

        return c;
      }, false);
      if (x) {
        c = x;
      }
      return c;
    }, false);

    return can;
  };

  return { canView };
};

export default Permission;
