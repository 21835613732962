import React, { Component, Fragment, useState } from "react";

import ApprovalModalComponent from "../views/approval-modal";
import { empty } from "common";
import * as toast from "components/common/toast";
import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";

const ApprovalModalContainer = (props) => {
  const approve = "approve";
  const disapprove = "disapprove";
  const resubmit = "resubmit";
  const defaultLoading = {
    approve: false,
    disapprove: false,
    resubmit: false,
    request: false,
    loading: false,
  };

  const [loading, setLoading] = useState(defaultLoading);

  const validate = (type) => {
    if (empty(type)) {
      throw "Action type is required.";
    }

    if (empty(props.template.approval_remarks)) {
      throw "Approval Remarks is required.";
    }
    const has_check = props.template.brands.reduce((checked, brand) => {
      if (checked) {
        return checked;
      }

      checked =
        brand.items.data.filter((item) => item?.checked == true).length > 0;
      if (!checked) {
        checked = brand.items.data.reduce((checked, item) => {
          checked =
            item.variation.compose.filter((compose) => compose?.checked == true)
              .length > 0;
          return checked;
        }, false);
      }

      return checked;
    }, false);

    if (!has_check && [resubmit].includes(type)) {
      // throw "You must check which items to re-submit.";
    }
  };

  const submitRequest = (type) => {
    try {
      validate(type);
      props
        .submitRequest(type, props.template)
        .then((response) => {
          setLoading(defaultLoading);
          if (response.data.success) {
            toast.success(response.data.message);
            props.hideApprovalModal();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(defaultLoading);
          toast.error(error.toString());
        });
    } catch (error) {
      setLoading(defaultLoading);
      toast.error(error.toString());
    }
  };

  const submitApproveRequest = () => {
    setLoading({
      ...defaultLoading,
      approve: true,
      loading: true,
    });
    submitRequest(approve);
  };

  const submitDisapproveRequest = () => {
    setLoading({
      ...defaultLoading,
      disapprove: true,
      loading: true,
    });
    submitRequest(disapprove);
  };

  const submitResubmitRequest = () => {
    setLoading({
      ...defaultLoading,
      resubmit: true,
      loading: true,
    });
    submitRequest(resubmit);
  };

  return (
    <ApprovalModalComponent
      {...props}
      loading={loading}
      submitApproveRequest={submitApproveRequest.bind(this)}
      submitDisapproveRequest={submitDisapproveRequest.bind(this)}
      submitResubmitRequest={submitResubmitRequest.bind(this)}
    />
  );
};

export default connect(null, {
  submitRequest: Http.submitRequest,
})(ApprovalModalContainer);
