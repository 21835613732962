import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";

import { AvField, AvForm } from "availity-reactstrap-validation";
import _debounce from "lodash.debounce";

import Spinner from "components/common/spinner";
import Message_container from "components/common/message-container";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createAccount } from "redux/actions/user/user/index";
import { getAllRole } from "redux/actions/user/role-permission/index";

export class Create_user extends Component {
  state = {
    password: "",
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    if (this.state.clear == true) return;
    if (prevProps.user.response?.message != "") {
      if (prevProps.user.response?.error == false) {
        this.setState({
          first_name: "",
          last_name: "",
          role: "",
          username: "",
          email: "",
          password: "",
          confirm_password: "",
          description: "",
          actions: [],
          clear: true,
        });
      }
    }
  };

  componentDidMount() {
    this.props.getAllRole();
  }

  handleSubmit(e, errors, values) {
    console.log(values);
    this.props.createAccount(values);
  }

  // debounce to not pound the 'server'
  validateConfirmPassword = _debounce((value, ctx, input, cb) => {
    // cancel pending 'network call'
    clearTimeout(this.timeout);
    const _self = this;
    // simulate network call
    this.timeout = setTimeout(() => {
      cb(value === ctx.password);
    }, 500);
  }, 300);

  render() {
    const {
      first_name,
      last_name,
      role,
      email,
      username,
      password,
      confirm_password,
    } = this.state;
    const { data: roles } = this.props.role;
    const { response, loading } = this.props.user;
    return (
      <Fragment>
        <Breadcrumb title="Create User" parent="Users" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-header">
                  <h5> Account Details</h5>
                </div>
                <div className="card-body">
                  <AvForm
                    className="needs-validation user-add"
                    onSubmit={this.handleSubmit}
                    onInvalidSubmit={this.handleInvalidSubmit}
                  >
                    <Message_container
                      message={response?.message ?? ""}
                      error={response?.error ?? false}
                    />
                    <div className="form-group row">
                      <label className="col-xl-3 col-md-4">
                        <span>*</span> First Name
                      </label>
                      <div className="col-xl-8 col-md-7">
                        <AvField
                          value={first_name}
                          className="form-control"
                          name="first_name"
                          id="first_name"
                          type="text"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-md-4">
                        <span>*</span> Last Name
                      </label>
                      <div className="col-xl-8 col-md-7">
                        <AvField
                          value={last_name}
                          className="form-control"
                          name="last_name"
                          id="last_name"
                          type="text"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-md-4">
                        <span>*</span> Role
                      </label>
                      <div className="col-xl-8 col-md-7">
                        <AvField
                          value={role}
                          multiple
                          type="select"
                          className="form-control"
                          name="role"
                          id="role"
                          required
                        >
                          {roles.map((res, i) => {
                            return (
                              <option key={i} value={res.id}>
                                {res.name}
                              </option>
                            );
                          })}
                        </AvField>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-md-4">
                        <span>*</span> Username
                      </label>
                      <div className="col-xl-8 col-md-7">
                        <AvField
                          value={username}
                          className="form-control"
                          name="username"
                          id="text"
                          type="username"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-md-4">
                        <span>*</span> Email
                      </label>
                      <div className="col-xl-8 col-md-7">
                        <AvField
                          value={email}
                          className="form-control"
                          name="email"
                          id="email"
                          type="email"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-md-4">
                        <span>*</span> Password
                      </label>
                      <div className="col-xl-8 col-md-7">
                        <AvField
                          value={password}
                          className="form-control"
                          name="password"
                          id="password"
                          type="password"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-md-4">
                        <span>*</span> Confirm Password
                      </label>
                      <div className="col-xl-8 col-md-7">
                        <AvField
                          value={confirm_password}
                          className="form-control"
                          name="confirm_password"
                          id="confirm_password"
                          type="password"
                          required
                          validate={{ async: this.validateConfirmPassword }}
                        />
                      </div>
                    </div>
                    <div className="pull-right">
                      <button type="submit" className="btn btn-primary">
                        <Spinner loading={loading} />
                        Save
                      </button>
                    </div>
                  </AvForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    role: state.rolePermission,
  };
}

export default connect(mapStateToProps, {
  getAllRole: getAllRole(),
  createAccount,
})(Create_user);
