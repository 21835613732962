import { empty } from "common";

const ListOpponents = (base) => {
  const all = () => {
    return base.state.opponents;
  };

  const activeOpponentsByPage = (opponents) => {
    let opponents_ = empty(opponents) ? all() : opponents;
    return opponents_.slice(
      base.state.paginations.opponents.page * base.state.paginations.limit,
      base.state.paginations.limit * (base.state.paginations.opponents.page + 1)
    );
  };

  const updateActiveStatus = () => {
    const activeOpponents = activeOpponentsByPage();

    return all().map((opponent) => {
      opponent.changeActive(false);
      const active = activeOpponents.filter(
        (o) => o.getId() === opponent.getId()
      );
      if (active.length > 0) {
        opponent.changeActive(true);
      }
      return opponent;
    });
  };

  const allActive = () => {
    updateActiveStatus();
    return base.state.opponents.filter(
      (opponent) => opponent.isActive() === true
    );
  };

  return {
    all,
    allActive,
  };
};

export default ListOpponents;
