import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ButtonView from "../views/button";
import { connect } from "react-redux";
import { submit, draft } from "../../redux/actions/index";

class ButtonContainer extends Component {
  submit = () => {
    this.props.submit();
  };

  cancel = () => {};

  draft = () => {
    this.props.draft();
  };

  render() {
    if ((this.props.success?.success ?? null) === true) {
      return <Redirect to="/products/product/product-list" />;
    }

    return (
      <ButtonView
        submit={this.submit.bind(this)}
        draft={this.draft.bind(this)}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      success: state.productReducer.success,
    };
  },
  {
    submit: submit,
    draft: draft,
  }
)(ButtonContainer);
