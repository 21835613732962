import React, { Component, Fragment } from "react";
import ListShopComponent from "../views/list-shop";

import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";

export class ListShopContainer extends Component {
  state = {
    open: false,
    progress: {
      sync_item: {
        status: false,
        id: null,
      },
      generate_daily_orders: {
        status: false,
        id: null,
      },
      update_daily_orders_status: {
        status: false,
        id: null,
      },
    },
  };
  constructor(props) {
    super();
  }
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  changeProgressItemSync = (status, id) => {
    this.changeProgressStatus("sync_item", status, id);
  };

  changeProgressGenerateDailyOrders = (status, id) => {
    this.changeProgressStatus("generate_daily_orders", status, id);
  };

  changeProgressUpdateDailyOrderStatus = (status, id) => {
    this.changeProgressStatus("update_daily_orders_status", status, id);
  };

  changeProgressStatus = (key, status, id) => {
    this.setState({
      progress: {
        ...this.state.progress,
        [key]: {
          status: status,
          id: id,
        },
      },
    });
  };

  syncShopItems(shop_id) {
    this.changeProgressItemSync(true, shop_id);
    this.props
      .requestSyncShopItems(shop_id)
      .then((response) => {
        this.changeProgressItemSync(false, shop_id);
        if (response.data.success === true) {
          this.changeProgressItemSync("s", shop_id);
        } else {
          this.changeProgressItemSync("f", shop_id);
        }
      })
      .catch((error, content) => {
        this.changeProgressItemSync("f", shop_id);
      });
  }

  generateDailyOrders(shop_id) {
    this.changeProgressGenerateDailyOrders(true, shop_id);
    this.props
      .requestGenerateDailyOrders(shop_id)
      .then((response) => {
        this.changeProgressGenerateDailyOrders(false, shop_id);
        if (response.data.success === true) {
          this.changeProgressGenerateDailyOrders("s", shop_id);
        } else {
          this.changeProgressGenerateDailyOrders("f", shop_id);
        }
      })
      .catch((error, content) => {
        this.changeProgressGenerateDailyOrders("f", shop_id);
      });
  }

  updateDailyOrderStatus(shop_id) {
    this.changeProgressUpdateDailyOrderStatus(true, shop_id);
    this.props
      .requestUpdateDailyOrdersStatus(shop_id)
      .then((response) => {
        this.changeProgressUpdateDailyOrderStatus(false, shop_id);
        if (response.data.success === true) {
          this.changeProgressUpdateDailyOrderStatus("s", shop_id);
        } else {
          this.changeProgressUpdateDailyOrderStatus("f", shop_id);
        }
      })
      .catch((error, content) => {
        this.changeProgressUpdateDailyOrderStatus("f", shop_id);
      });
  }
  render() {
    const { open } = this.state;
    return (
      <ListShopComponent
        {...this.state}
        open={open}
        onOpenModal={this.onOpenModal}
        onCloseModal={this.onCloseModal}
        syncShopItems={this.syncShopItems.bind(this)}
        generateDailyOrders={this.generateDailyOrders.bind(this)}
        updateDailyOrderStatus={this.updateDailyOrderStatus.bind(this)}
      />
    );
  }
}

export default connect(null, {
  ...Http,
})(ListShopContainer);
