import React from "react";

import CheckoutComponent from "../../views/checkout/checkout";

class CheckoutContainer extends React.Component {
  render() {
    return <CheckoutComponent />;
  }
}

export default CheckoutContainer;
