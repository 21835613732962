import React, { Component, Fragment } from "react";
import ExportOrderComponent from "../views/list-transactions";

import { connect } from "react-redux";
import * as SalesActions from "../../redux/actions/index";
import Emitter from "common/event-emitter";

export class ExportOrderContainer extends Component {
  state = {
    tab: "sales",
    history_modal: {
      modal: false,
    },
  };

  componentDidMount() {
    // this.props.destroy();
    this.props.getHistoryList();
    this.listener();
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  listener() {
    Emitter.on("SALES_FILTERED_BY", (state) => {
      console.log(state);
      this.setState({
        filtered: state,
      });
    });
  }

  tabClick = (tab) => {
    this.setState({
      tab: tab,
    });

    if (tab === "sales") {
      // this.props.getTransactions();
    }
  };

  tabCompletedOrders = () => {
    this.props.getTransactions();
  };

  handleOnCloseModal() {
    this.setState({
      history_modal: { modal: false },
    });
  }

  handleOnOpenModal() {
    this.props.getHistoryList();
    this.setState({
      history_modal: { modal: true },
    });
  }

  render() {
    return (
      <ExportOrderComponent
        {...this.state}
        tabClick={this.tabClick.bind(this)}
        handleOnCloseModal={this.handleOnCloseModal.bind(this)}
        handleOnOpenModal={this.handleOnOpenModal.bind(this)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    orderExportReducer: state.orderExportReducer,
  };
}

export default connect(mapStateToProps, {
  getTransactions: (filters, per_page, page) =>
    SalesActions.getTransactionsRequest(filters, per_page, page),
  destroy: SalesActions.destroy,
  getHistoryList: SalesActions.getHistoryList,
})(ExportOrderContainer);
