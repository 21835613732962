import { empty } from "common";
import uuid from "react-uuid";

import GameComponent from "../../gameboard/game-component";
import Player from "../../gameboard/player";
import UpdatePlayerCoordinates from "./update-coordinates";
import PlayerProp from "./player-prop";
import TickeShowConProp from "./tickle-show-con-prop";

class CreateNewPlayers {
  constructor(base) {
    this.base = base;
  }

  createComponent(...titles) {
    return new GameComponent(
      this.base.gameboard.canvas(),
      this.base.gameboard.context(),
      PlayerProp.color,
      0,
      0,
      PlayerProp.width,
      PlayerProp.height,
      titles
    );
  }

  createPlayerTickleShowConComponent() {
    return new GameComponent(
      this.base.gameboard.canvas(),
      this.base.gameboard.context(),
      TickeShowConProp.color,
      0,
      0,
      TickeShowConProp.width,
      TickeShowConProp.height,
      ""
    );
  }

  createPlayer(product) {
    let component = this.createComponent(
      product.basic.title,
      "#" + product.id,
      product.basic.shop.name + " " + product.basic.shop.app
    );
    let ticle_show_con_component = this.createPlayerTickleShowConComponent();
    const player = new Player(
      uuid(),
      component,
      product,
      product,
      null,
      null,
      null,
      ticle_show_con_component
    );

    return [player];
  }

  createPlayerWithVariants(product) {
    let players = [];

    product.variation.compose.forEach((compose) => {
      let tickle_show_con_component = this.createPlayerTickleShowConComponent();
      let component = this.createComponent(
        compose.sku,
        product.basic.title,
        "#" + compose.id,
        product.basic.shop.name + " " + product.basic.shop.app
      );
      const player = new Player(
        uuid(),
        component,
        product,
        compose,
        null,
        null,
        null,
        tickle_show_con_component
      );
      players.push(player);
    });

    return players;
  }

  handle(product, opponents) {
    let players = [];
    if (product.has_variation === true) {
      players = this.createPlayerWithVariants(product);
    } else {
      players = this.createPlayer(product);
    }

    return new UpdatePlayerCoordinates(this.base).handle(players);
  }
}

export default CreateNewPlayers;
