import React, { Component } from "react";

import { connect } from "react-redux";
import UpdateCategoryContainer from "./update-category";
import * as toast from "components/common/toast";
import * as CategoryAction from "../../redux/actions/index";
import DataTableComponent from "../views/datatable";

class DataTableContainer extends Component {
  state = {
    data: [],
    pages: null,
    pageSize: 0,
    selectedId: [],
    open: false,
  };

  constructor() {
    super();
    this.fetchCategory = this.fetchCategory.bind(this);
    this.removeCategory = this.removeCategory.bind(this);
    this.selected = this.selected.bind(this);
    this.editCategory = this.editCategory.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.category.data.data) !==
      JSON.stringify(prevProps.category.data.data)
    ) {
      this.setState({
        data: this.props.category.data.data,
        selectedId: [],
        pages: Math.ceil(this.props.category.data.total / this.state.pageSize),
      });
    }
  }

  fetchCategory(state, instance) {
    this.props.getAllCategory(
      {
        criteria: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
      },
      state.pageSize,
      state.page == 0 ? 1 : state.page + 1
    );

    this.setState({
      data: this.props.category.data.data,
      pages: Math.ceil(this.props.category.data.total / state.pageSize),
      pageSize: state.pageSize,
    });
  }

  removeCategory(e) {
    const props = this.props;
    const state = this.state;
    if (state.selectedId.length <= 0) return;

    toast.question(
      "Delete",
      "Are you sure you want to remove this?",
      function () {
        props.deleteCategory(state.selectedId);
      }
    );
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  editCategory(e, categoryId) {
    this.setState({ categoryId: categoryId });
    this.onOpenModal();
    this.props.getCategory(categoryId);
    this.props.setUpdateStatus(false);
  }
  // comment
  selected(e, categoryId) {
    if (!e.target.checked) {
      this.setState({
        selectedId: this.state.selectedId.filter((d) => d !== categoryId),
      });
    } else {
      this.setState({
        selectedId: this.state.selectedId.concat(categoryId),
      });
    }
  }

  render() {
    const { loading, toEdit } = this.props.category;
    return (
      <DataTableComponent
        toEdit={toEdit}
        loading={loading}
        onCloseModal={this.onCloseModal}
        onOpenModal={this.onOpenModal}
        fetchCategory={this.fetchCategory}
        removeCategory={this.removeCategory}
        editCategory={this.editCategory.bind(this)}
        selected={this.selected.bind(this)}
        {...this.state}
      />
    );
  }
}

// comment

function mapStateToProps(state) {
  return {
    category: state.category,
  };
}

export default connect(mapStateToProps, {
  getAllCategory: (payload, limit, page) =>
    CategoryAction.getAllRequest(payload, limit, page),
  deleteCategory: (d) => CategoryAction.deleteCategory(d),
  getCategory: (d) => CategoryAction.getCategoryRequest(d),
  setUpdateStatus: (d) => CategoryAction.setUpdateStatus(d),
})(DataTableContainer);
