import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";

export const getAllRequest = (filters, per_page, page) => (dispatch) => {
  dispatch(payload.setLoading(true));
  return Http.POST("product/brand/getAll", { payload: filters, per_page, page })
    .then(function (response) {
      dispatch(payload.fetchAll(response.data));
      dispatch(payload.setLoading(false));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getAll = (filters, per_page, page) => (dispatch) => {
  return function (dispatch) {
    return dispatch(getAllRequest(filters, per_page, page));
  };
};

export const getBrandRequest = (brandId) => (dispatch) => {
  return Http.GET("product/brand/get/" + brandId)
    .then(function (response) {
      dispatch(payload.fetch(response.data));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getBrand = () => (dispatch) => {
  return function (dispatch) {
    return dispatch(getBrandRequest());
  };
};

interface brand {
  name: string;
  description: string;
}

export const createBrand = (data: brand) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PUT("product/brand/create", data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

export const updateBrand = (brandId, data: brand) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PATCH("product/brand/update/" + brandId, data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(payload.setUpdateStatus(true));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

interface brandId {
  id: int; //brand id
}
export const deleteBrand = (data: brandId) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.POST("product/brand/delete", { payload: data })
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};
