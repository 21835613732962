import React, { useState, useEffect, Fragment } from "react";

import DataTableComponent from "../views/datatable";
import { connect } from "react-redux";
import * as ShopAction from "../../redux/actions/index";
import * as toast from "components/common/toast";

const DataTableContainer = (props) => {
  const [selectedId, setSelectedId] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shopId, setShopId] = useState(null);
  const [datatable, setDatatable] = useState({
    data: [],
    pages: null,
    pageSize: 0,
    open: false,
  });

  const fetchShop = (state, instance) => {
    props
      .getAllRequest(
        {
          filtered: state.filtered.reduce((c, i) => {
            c.push({ [i.id]: i.value });
            return c;
          }, []),
          sorted: state.sorted.reduce((c, i) => {
            c.push({ [i.id]: { desc: i.desc } });
            return c;
          }, []),
        },
        state.pageSize,
        state.page == 0 ? 1 : state.page + 1
      )
      .then(function (response) {
        setDatatable({
          data: response.data.data,
          pages: Math.ceil(response.data.total / state.pageSize),
          pageSize: state.pageSize,
        });
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  const removeShop = (e) => {
    if (selectedId.length <= 0) return;

    toast.question(
      "Delete",
      "Are you sure you want to remove this?",
      function () {
        props.deleteShop(selectedId);
      }
    );
  };

  const connect = (shopId) => {
    props.connect(shopId);
  };

  const onOpenModal = () => {
    setModal(true);
  };

  const onCloseModal = () => {
    setModal(false);
  };

  const editShop = (e, shopId) => {
    setShopId(shopId);
    onOpenModal();
    props.getShopRequest(shopId);
    props.setUpdateStatus(false);
  };

  const selected = (e, shopId) => {
    if (!e.target.checked) {
      setSelectedId(selectedId.filter((d) => d !== shopId));
    } else {
      setSelectedId(selectedId.concat(shopId));
    }
  };

  function updateShopProgressSyncStatus(status, shop_id) {
    updateShopProgressStatus("sync_item", status, shop_id);
  }

  function updateShopProgressGenerateDailyOrdersStatus(status, shop_id) {
    updateShopProgressStatus("generate_daily_orders", status, shop_id);
  }

  function updateShopProgressUpdateDailyOrdersStatus(status, shop_id) {
    updateShopProgressStatus("update_daily_orders_status", status, shop_id);
  }

  function updateShopProgressStatus(key, status, shop_id) {
    setDatatable({
      ...datatable,
      data: datatable.data.map((shop) => {
        if (shop.id == shop_id) {
          shop = {
            ...shop,
            progress: {
              ...(shop?.progress ?? []),
              [key]: status,
            },
          };
        }
        return shop;
      }),
    });
  }

  useEffect(() => {
    updateShopProgressSyncStatus(
      props.progress.sync_item.status,
      props.progress.sync_item.id
    );
  }, [props.progress.sync_item]);

  useEffect(() => {
    updateShopProgressGenerateDailyOrdersStatus(
      props.progress.generate_daily_orders.status,
      props.progress.generate_daily_orders.id
    );
  }, [props.progress.generate_daily_orders]);

  useEffect(() => {
    updateShopProgressUpdateDailyOrdersStatus(
      props.progress.update_daily_orders_status.status,
      props.progress.update_daily_orders_status.id
    );
  }, [props.progress.update_daily_orders_status]);

  return (
    <DataTableComponent
      loading={loading}
      editShop={editShop.bind(this)}
      removeShop={removeShop.bind(this)}
      onOpenModal={onOpenModal.bind(this)}
      onCloseModal={onCloseModal.bind(this)}
      selected={selected.bind(this)}
      fetchShop={fetchShop}
      modal={modal}
      shopId={shopId}
      selectedId={selectedId}
      datatable={datatable}
      {...props}
    />
  );
};

function mapStateToProps(state) {
  return {
    shopReducer: state.shopReducer,
  };
}

export default connect(mapStateToProps, {
  ...ShopAction,
})(DataTableContainer);
