import React from "react";
import OrderComponent from "../views";

const OrderContainer = (props) => {
  return (
    <>
      <OrderComponent />
    </>
  );
};

export default OrderContainer;
