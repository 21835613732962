import React, { useEffect, useState } from "react";
import Component from "../views";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/payload";
import { requestCalculatePayment } from "../../redux/actions/http";

const Container = (props) => {
  const [payment, setPayment] = useState({ fullpayment: 0, downpayment: 0 });
  useEffect(() => {
    props
      .requestCalculatePayment(props.inStoreOrderReducer.basket)
      .then((response) => {
        setPayment(response);
      });
  }, []);

  return (
    <>
      <Component data={props.inStoreOrderReducer.shipping} payment={payment} />
    </>
  );
};

export default connect(
  (state) => {
    return {
      inStoreOrderReducer: state.inStoreOrderReducer,
    };
  },
  {
    requestCalculatePayment: requestCalculatePayment,
  }
)(Container);
