import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import _debounce from "lodash.debounce";

const ListDataTableComponent = (props) => {
  const {
    open,
    data,
    pages,
    loading,
    selected,
    selectedId,
    productId,
    editProduct,
    fetchProduct,
    deleteProduct,
    activateProduct,
    deactivateProduct,
    handleOpenGameboardModal,
    updateUnitCost,
    updateUnitCostStatus,
    handleClearedProductDuplicateCase,
  } = props;

  return (
    <div>
      <ReactTable
        columns={[
          {
            Header: <b>{"Actions"}</b>,
            sortable: false,
            filterable: false,
            width: 170,
            style: {
              textAlign: "left",
            },
            Cell: (row) => (
              <div>
                <Link
                  to={"edit-product/" + row.original.id}
                  click="editProduct(row.original.id)"
                  className="btn btn-xs btn-secondary mr-1"
                  title="Edit"
                >
                  <i className="fa fa-edit"></i>
                </Link>
                {row.original.live ? (
                  <span
                    onClick={(e) => {
                      deactivateProduct(row.original.id);
                    }}
                    className="btn btn-xs btn-success mr-1"
                    title="Deactive"
                  >
                    <i className="fa fa-pause"></i>
                  </span>
                ) : (
                  <span
                    onClick={(e) => {
                      activateProduct(row.original.id);
                    }}
                    className="btn btn-xs btn-primary mr-1"
                    title="Activate"
                  >
                    <i className="fa fa-play"></i>
                  </span>
                )}

                <span
                  onClick={(e) => {
                    deleteProduct(row.original.id);
                  }}
                  className="btn btn-xs btn-dark mr-1"
                  title="Delete"
                >
                  <i className="fa fa-trash"></i>
                </span>

                <span
                  onClick={(e) => {
                    handleOpenGameboardModal(row.original);
                  }}
                  className="btn btn-xs btn-primary mr-1"
                  title="Manage Associations"
                >
                  <i className="fa fa-folder"></i>
                </span>

                <Link
                  to={"link-reference/" + row.original.id}
                  click="editProduct(row.original.id)"
                  className="btn btn-xs btn-secondary mr-1 hidden hide"
                  title="Edit"
                  style={{ display: "none" }}
                >
                  <i className="fa fa-edit"></i>
                </Link>
              </div>
            ),
          },
          {
            Header: <b>{"Image"}</b>,
            accessor: "Image",
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) => (
              <img src={row.original.media?.main[0]?.url ?? ""} width="50" />
            ),
          },
          {
            Header: <b>{"Name"}</b>,
            accessor: "title",
            width: 400,
            Cell: (row) => (
              <a
                href="javascript:;"
                onClick={(e) => {
                  editProduct(e, row.original.id);
                }}
              >
                {row.original.basic.title}
              </a>
            ),
          },
          {
            Header: <b>{"Category"}</b>,
            accessor: "category",
            Cell: (row) => <div>{row.original.basic.category.name}</div>,
          },

          {
            Header: <b>{"Price"}</b>,
            sortable: false,
            filterable: false,
            style: {
              textAlign: "right",
            },
            Cell: (row) => (
              <div>
                {row.original.variation?.compose?.length > 0 ||
                row.original?.wholesale?.length > 0 ? (
                  "Multiple Items"
                ) : (
                  <div>{row.original.basic.selling.price}</div>
                )}
              </div>
            ),
          },
          {
            Header: <b>{"Status"}</b>,
            accessor: "status",
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) =>
              row.original.status.toLowerCase() === "live" ? (
                <span className="badge badge-success">
                  {row.original.status.toUpperCase()}
                </span>
              ) : (
                <span className="badge badge-primary">
                  {row.original.status.toUpperCase()}
                </span>
              ),
          },
        ]}
        SubComponent={(item) => {
          return (
            <div style={{ padding: "20px" }}>
              <Tabs defaultActiveKey="variations">
                <Tab eventKey="variations" title="Variations">
                  <ReactTable
                    data={item.original.variation.compose}
                    collapseOnDataChange={false}
                    showPagination={false}
                    defaultPageSize={item.original.variation.compose.length}
                    columns={[
                      {
                        Header: <b>{"Sku"}</b>,
                        accessor: "sku",
                        filterable: false,
                        style: {
                          textAlign: "left",
                        },
                      },
                      {
                        Header: <b>{"Stock"}</b>,
                        accessor: "stocks",
                        filterable: false,
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        Header: <b>{"Price"}</b>,
                        accessor: "price",
                        filterable: false,
                        style: {
                          textAlign: "center",
                        },
                      },
                    ]}
                  />
                </Tab>
                <Tab
                  eventKey="duplicate_cases"
                  title={
                    "Duplicate Cases (" +
                    (item.original.duplicate_case.is_duplicate_cleared
                      ? "Cleared"
                      : "Active") +
                    ")"
                  }
                >
                  {item.original.duplicate_case.is_duplicate_cleared ? (
                    <Button
                      onClick={(e) => {
                        handleClearedProductDuplicateCase(
                          item.original.id,
                          false
                        );
                      }}
                      variant="secondary"
                      size="sm"
                      className="text-right m-b-20 m-t-20"
                    >
                      Active
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => {
                        handleClearedProductDuplicateCase(
                          item.original.id,
                          true
                        );
                      }}
                      variant="primary"
                      size="sm"
                      className="text-right m-b-20 m-t-20"
                    >
                      Cleared
                    </Button>
                  )}

                  <ReactTable
                    data={item.original.duplicate_case.products}
                    collapseOnDataChange={false}
                    defaultPageSize={
                      item.original.duplicate_case.products.length
                    }
                    showPagination={false}
                    columns={[
                      {
                        Header: <b>{"Id"}</b>,
                        accessor: "id",
                        filterable: false,
                        width: 80,
                        style: {
                          textAlign: "left",
                        },
                      },
                      {
                        Header: <b>{"Title"}</b>,
                        accessor: "title",
                        filterable: true,
                        style: {
                          textAlign: "left",
                        },
                      },
                      {
                        Header: <b>{"Rate"}</b>,
                        accessor: "rate",
                        filterable: false,
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        Header: <b>{"Actions"}</b>,
                        sortable: false,
                        filterable: false,
                        width: 200,
                        style: {
                          textAlign: "center",
                        },
                        Cell: (row) => (
                          <div>
                            {row.original.is_deleted ? (
                              <i className="fa fa-times"></i>
                            ) : (
                              <span
                                onClick={(e) => {
                                  deleteProduct(row.original.id);
                                }}
                                className="btn btn-xs btn-dark mr-1"
                                title="Delete"
                              >
                                <i className="fa fa-trash"></i>
                              </span>
                            )}
                          </div>
                        ),
                      },
                    ]}
                  />
                </Tab>
                <Tab
                  eventKey="suppliers"
                  title={"Suppliers (" + item.original.suppliers.length + ")"}
                >
                  <ReactTable
                    data={item.original.suppliers}
                    collapseOnDataChange={false}
                    defaultPageSize={item.original.suppliers.length}
                    showPagination={false}
                    columns={[
                      {
                        Header: <b>{"Supplier Name"}</b>,
                        accessor: "supplier_name",
                        sortable: false,
                        filterable: false,
                        style: {
                          textAlign: "left",
                        },
                      },
                      {
                        Header: <b>{"Variation"}</b>,
                        accessor: "variation_name",
                        sortable: false,
                        filterable: false,
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        Header: <b>{"Price"}</b>,
                        accessor: "price",
                        sortable: true,
                        filterable: false,
                        width: 200,
                        style: {
                          textAlign: "right",
                        },
                      },
                    ]}
                  />
                </Tab>
              </Tabs>
            </div>
          );
        }}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        onFetchData={_debounce(fetchProduct, 300)} // Request new data when things change
        filterable
        defaultPageSize={10}
        className="-striped -highlight"
        showPaginationTop={true}
        collapseOnDataChange={false}
      />
    </div>
  );
};

export default ListDataTableComponent;
