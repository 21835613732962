import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

import Message_container from "components/common/message-container";
import Bus from "common/event-emitter";
import Form from "react-bootstrap/Form";

const Component = (props) => {
  const [message, setMessage] = useState({
    error: false,
    description: "",
  });

  useEffect(() => {
    Bus.on("message", ({ error, message: description }) => {
      setMessage({ error, description });

      // Clear out display after few seconds
      if (error) {
        setTimeout(() => {
          setMessage(false, "");
        }, 10000);
      }
    });
  }, []);

  return (
    <>
      <Container fluid>
        <Card>
          <Card.Body>
            <Message_container
              message={message.description}
              error={message.error}
            />
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default Component;
