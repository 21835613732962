import React, { Component, Fragment } from "react";
import ListSalesDatatableComponent from "../views/list-sales-datatable";

import { connect } from "react-redux";
import * as SalesActions from "../../redux/actions/index";

export class ListSalesDatatableContainer extends Component {
  state = {
    data: [],
    pages: null,
    pageSize: 0,
  };

  constructor() {
    super();
    this.fetchSales = this.fetchSales.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.orderExportReducer.transactions.sales.updated !=
      prevProps.orderExportReducer.transactions.sales.updated
    ) {
      this.setState({
        data: this.props.orderExportReducer.transactions.sales.data,
        selectedId: [],
        pages: Math.ceil(
          this.props.orderExportReducer.transactions.sales.total /
            this.state.pageSize
        ),
      });
    }
  }

  fetchSales(state, instance) {
    this.props.getSales(
      this.props.orderExportReducer.filter,
      {
        filtered: state.filtered.reduce((c, i) => {
          c.push({ [i.id]: i.value });
          return c;
        }, []),
        sorted: state.sorted.reduce((c, i) => {
          c.push({ [i.id]: { desc: i.desc } });
          return c;
        }, []),
      },
      state.pageSize,
      state.page == 0 ? 1 : state.page + 1
    );

    this.setState({
      data: this.props.orderExportReducer.transactions.sales.data,
      pages: Math.ceil(
        this.props.orderExportReducer.transactions.sales.total / state.pageSize
      ),
      pageSize: state.pageSize,
    });
  }

  render() {
    const { sales } = this.props.orderExportReducer.transactions;

    return (
      <ListSalesDatatableComponent
        transactions={sales}
        fetchSales={this.fetchSales}
        {...this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    orderExportReducer: state.orderExportReducer,
  };
}

export default connect(mapStateToProps, {
  getSales: (coveredDate, filters, per_page, page) =>
    SalesActions.getSales(coveredDate, filters, per_page, page),
})(ListSalesDatatableContainer);
