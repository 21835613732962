import React from "react";
import _ from "lodash";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { connect } from "react-redux";
import * as UserAction from "redux/actions/user/user/index";
import  { getAllRole } from "redux/actions/user/role-permission/index";

import * as toast from "components/common/toast";

import Update_user from "../update-user";

class Datatable extends React.Component {

    constructor() {
        super();
        this.state = {
            data: [],
            pages: null,
            loading: true,
            pageSize: 0,
            id: [],
            modal: [],
            open: false,
            fetchLoading: false
        };
        this.fetchData = this.fetchData.bind(this);
        this.remove = this.remove.bind(this);
        this.selected = this.selected.bind(this);
        this.edit = this.edit.bind(this);
    }

    componentDidUpdate(prevProps) {        
        if (JSON.stringify(this.props.user.data.data) !== JSON.stringify(prevProps.user.data.data)) {
            this.setState({
                data: this.props.user.data.data,
                id: [],
                pages: Math.ceil(this.props.user.data.total / this.state.pageSize)
            });
        }
    }

    componentDidMount() {
        this.props.getAllRole();
    }

    //comment
    fetchData(state, instance) {
        this.props.getAllUser(
            {
                filtered: state.filtered.reduce( (c, i) => {
                    c.push({
                        [i.id]: i.value
                    });
                    return c;
                }, []),
                sorted: state.sorted.reduce( (c, i) => {
                    c.push({
                        [i.id]: {desc: i.desc}
                    });
                    return c;
                }, [])
            },
            state.pageSize, 
            state.page == 0 ? 1 : state.page+1
        );
        
        this.setState({
            data: this.props.user.data.data,
            pages: Math.ceil(this.props.user.data.total / state.pageSize),
            pageSize: state.pageSize
        });
    }

    remove(e) {
        const props = this.props;
        const state = this.state;
        if (state.id.length <= 0) return;
        toast.question(
            'Delete',
            'Are you sure you want to remove this?',
            function() {
                props.deleteAccount(state.id);
            }
        );
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    edit(e, userId) {
        this.setState({userId:userId});
        this.onOpenModal();
        this.props.getUserAccount(userId);
        this.props.setUpdateStatus(false);
    }
    // comment
    selected(e, userId) {
        if (!e.target.checked) {
            this.setState({
                id: this.state.id.filter(d => d !== userId)
            });
        } else {
            this.setState({
                id: this.state.id.concat(userId)
            });
        }
    }

    render() {
        const { data, pages, userId, open } = this.state;
        const { loading } = this.props.user;
        return (
            <div>
                <Update_user 
                    userId={userId} 
                    open={open}
                    onCloseModal={e=>{this.onCloseModal(e)}} 
                    onOpenModal={e=>{this.onOpenModal(e)}} 
                    roles={this.props.role.data}
                />
                <ReactTable
                // comment
                  columns={[
                    {
                        Header: <b>{'ID'}</b>,
                        accessor: 'id',
                        filterable: false,
                        style: {
                            textAlign: 'center'
                        }
                    },
                    {
                        Header: <b>{'Username'}</b>,
                        accessor: 'username',
                        Cell: (row) => (
                            <a href="javascript:;" onClick={e=>{this.edit(e, row.original.id)}}>{row.original.username}</a>
                        )
                    },
                    {
                        Header: <b>{'Name'}</b>,
                        accessor: 'name',
                    },
                    {
                        Header: <b>{'Email'}</b>,
                        accessor: 'email'
                    },
                    {
                        Header: <b>{'Last Login'}</b>,
                        accessor: 'lastLogin',
                        filterable: false,
                        sortable: false
                    },
                    {
                        Header: <b>{'Block'}</b>,
                        accessor: 'isBlock',
                        filterable: false,
                        sortable: false,
                        style: {
                            textAlign: 'center'
                        },
                        Cell: (row) => (
                                (row.value === true ? (<span className="badge badge-danger">Yes</span>) :
                             (<span className="badge badge-dark">No</span>))
                        )
                    },
                    {
                        Header: <button onClick={e=>{this.remove(e)}} className="btn btn-danger btn-sm btn-delete mb-0 b-r-4">
                            Delete</button>,
                        accessor: 'delete',
                        sortable: false,
                        filterable: false,
                        style: {
                            textAlign: 'center'
                        },
                        Cell: (row) => (
                            <div>
                                <span>
                                    <input defaultChecked={this.state.id.includes(row.original.id)} onChange={e=>{this.selected(e, row.original.id)}} type="checkbox" className="checkbox_animated" />
                                </span>
                            </div>
                        )
                    }
                  ]}
                  manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                  data={data}
                  pages={pages} // Display the total number of pages
                  loading={loading} // Display the loading overlay when we need it
                  onFetchData={this.fetchData} // Request new data when things change
                  filterable
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
            </div>
        );
    }
}

// comment

function mapStateToProps(state) {
    return {
        user: state.user,
        role: state.rolePermission
    };
}


export default connect(
    mapStateToProps, {
        getAllUser: (filter, limit, page) => UserAction.getAllUserRequest(filter, limit, page),
        setLoading: d => UserAction.setLoading(d),
        deleteAccount: d => UserAction.deleteAccount(d),
        getUserAccount: d => UserAction.getUserAccount(d),
        setUpdateStatus: d => UserAction.setUpdateStatus(d),
        getAllRole: getAllRole()
    }
)(Datatable);