import React, { useState } from "react";
import uuid from "react-uuid";
import { List } from "immutable";

const isAdefaultKey = (state, key) => {
  return state.media.data.filter((d) => d.key === key).length > 0;
};

const updateVariationImage = (state, action) => {
  const composeIndex = state.variation.compose.findIndex(
    (c) => c.id === action.payload.key
  );
  state.variation.compose[composeIndex].data.image.url =
    action.payload.imageUrl;
  state.variation.compose[composeIndex].data.image.hash = action.payload.hash;

  return state.variation.compose;
};

const addOrUpdateNonVariationImage = (state, action) => {
  const imageIndex = state.media.data.findIndex(
    (m) => m.key === action.payload.key
  );
  state.media.data[imageIndex].hash = action.payload.hash;
  state.media.data[imageIndex].url = action.payload.imageUrl;

  return state.media.data;
};

const removeVariationImage = (state, action) => {
  const composeIndex = state.variation.compose.findIndex(
    (c) => c.id === action.payload.key
  );
  state.variation.compose[composeIndex].data.image.url = "";
  state.variation.compose[composeIndex].data.image.hash = "";

  return state.variation.compose;
};

const removeNonVariationImage = (state, action) => {
  const imageIndex = state.media.data.findIndex(
    (c) => c.key === action.payload.key
  );
  state.media.data[imageIndex].url = "";
  state.media.data[imageIndex].hash = "";

  return state.media.data;
};

const setVariantImageLoading = (state, action) => {
  const composeIndex = state.variation.compose.findIndex(
    (c) => c.id === action.payload.key
  );
  state.variation.compose[composeIndex].data.image.loading =
    action.payload.loading;

  return state.variation.compose;
};

const setVariantImageProgress = (state, action) => {
  const composeIndex = state.variation.compose.findIndex(
    (c) => c.id === action.payload.key
  );

  state.variation.compose[composeIndex].data.image.progress =
    action.payload.progress;

  return state.variation.compose;
};

const setMainImageLoading = (state, action) => {
  const imageIndex = state.media.data.findIndex(
    (c) => c.key === action.payload.key
  );
  state.media.data[imageIndex].loading = action.payload.loading;

  return state.media.data;
};

const setMainImageProgress = (state, action) => {
  const imageIndex = state.media.data.findIndex(
    (c) => c.key === action.payload.key
  );
  state.media.data[imageIndex].progress = action.payload.progress;

  return state.media.data;
};

export const removeImage = (state, action) => {
  if (isAdefaultKey(state, action.payload.key)) {
    return {
      ...state,
      media: {
        ...state.media,
        data: removeNonVariationImage(state, action),
      },
    };
  }
  return {
    ...state,
    variation: {
      ...state.variation,
      compose: removeVariationImage(state, action),
    },
  };
};

export const addOrUpdateImage = (state, action) => {
  if (isAdefaultKey(state, action.payload.key)) {
    return {
      ...state,
      media: {
        ...state.media,
        data: addOrUpdateNonVariationImage(state, action),
      },
    };
  }
  return {
    ...state,
    variation: {
      ...state.variation,
      compose: updateVariationImage(state, action),
    },
  };

  return state;
};

export const setLoading = (state, action) => {
  if (isAdefaultKey(state, action.payload.key)) {
    return {
      ...state,
      media: {
        ...state.media,
        data: setMainImageLoading(state, action),
      },
    };
  }

  return {
    ...state,
    variation: {
      ...state.variation,
      compose: setVariantImageLoading(state, action),
    },
  };
};

export const setProgress = (state, action) => {
  if (isAdefaultKey(state, action.payload.key)) {
    return {
      ...state,
      media: {
        ...state.media,
        data: setMainImageProgress(state, action),
      },
    };
  }

  return {
    ...state,
    variation: {
      ...state.variation,
      compose: setVariantImageProgress(state, action),
    },
  };
};

export const clear = (state, action) => {
  return {
    ...state,
    media: {
      ...state.media,
      data: [],
    },
  };
};

export const responseSuccess = (state, action) => {
  return {
    ...state,
    media: {
      ...state.media,
      response: {
        success: true,
        message: action.payload.message,
      },
    },
  };
};

export const responseFailed = (state, action) => {
  return {
    ...state,
    media: {
      ...state.media,
      response: {
        success: false,
        message: action.payload.message,
      },
    },
  };
};

export const updateFullMedia = (state, action) => {
  return {
    ...state,
    media: {
      ...state.media,
      data: state.media.data.map((m) => {
        const image = action.payload.data.filter((d) => d.key === m.key);
        if (image.length > 0) {
          m = image[0];
        }
        return m;
      }),
    },
  };
};
