import React, { Component } from "react";
import ListProductView from "../views/list-product";
import {
  getProductList,
  getProductListWithDuplicateCase,
} from "../../redux/actions/product/http";
import { destroy } from "../../redux/actions/index";
import { requestGetProduct } from "../../redux/actions/association/http";
import { getProductListRequest as getShopeeProductListRequest } from "../../../../shared/shopee";
import { getGlobalConfigRequest } from "redux/actions/common/http";
import { connect } from "react-redux";

import Emitter from "common/event-emitter";

export class ListProductContainer extends Component {
  state = {
    gameboard: {
      open: false,
    },
    migration: {
      open: false,
      lock: false,
    },
    sync: {
      open: false,
      lock: false,
    },
    with_case: false,
    show_cleared_duplicate_case: false,
  };

  constructor(props) {
    super();
    this.handleOpenGameboardModal = this.handleOpenGameboardModal.bind(this);
    this.handleCloseGameboardModal = this.handleCloseGameboardModal.bind(this);
    this.handleOpenMigrationModal = this.handleOpenMigrationModal.bind(this);
    this.handleCloseMigrationModal = this.handleCloseMigrationModal.bind(this);
    this.handleLockMigrationModal = this.handleLockMigrationModal.bind(this);
    this.handleOpenSyncModal = this.handleOpenSyncModal.bind(this);
    this.handleCloseSyncModal = this.handleCloseSyncModal.bind(this);
    this.handleLockSyncModal = this.handleLockSyncModal.bind(this);
    this.handleDupplicatesChecker = this.handleDupplicatesChecker.bind(this);
    this.handleShowClearedDupplicateCase = this.handleShowClearedDupplicateCase.bind(
      this
    );
  }

  componentDidMount() {
    this.refreshProductDataTable();
    this.props.getGlobalConfig();
  }

  tabClick = (tab) => {
    this.setState({
      tab: tab,
    });

    if (tab === "shopee_products") {
      this.props.getShopeeProductList();
    }
  };

  refreshProductDataTable() {
    Emitter.emit("PRODUCT_REFRESH_DATAtABLE", {
      with_case: this.state.with_case,
    });
  }

  refreshProductDuplicateCaseDataTable() {
    Emitter.emit("PRODUCT_REFRESH_DUPLICATE_CASE_DATATABLE", {
      with_case: this.state.with_case,
    });
  }

  refreshProductList() {
    Emitter.emit("PRODUCT_REFRESH_LIST");
  }

  handleCloseGameboardModal(data) {
    // this.refreshProductList();
    this.setState((state) => {
      return {
        ...state,
        gameboard: {
          ...state.gameboard,
          open: false,
        },
      };
    });
  }

  handleOpenGameboardModal(data) {
    let d = data;
    this.props.requestGetProduct(data.id).then((response) => {
      if (response.data.success) {
        d = response.data.data;
        this.setState((state) => {
          return {
            ...state,
            gameboard: {
              ...state.gameboard,
              open: true,
              product: response.data.data,
            },
          };
        });
      }
    });
  }

  handleOpenMigrationModal() {
    this.setState((state) => {
      return {
        ...state,
        migration: {
          ...state.migration,
          open: true,
        },
      };
    });
  }

  handleCloseMigrationModal() {
    if (this.state.migration.lock) return;

    this.setState((state) => {
      return {
        ...state,
        migration: {
          ...state.migration,
          open: false,
        },
      };
    });
  }

  handleLockMigrationModal(lock) {
    this.setState((state) => {
      return {
        ...state,
        migration: {
          ...state.migration,
          lock: lock,
        },
      };
    });
  }

  handleOpenSyncModal() {
    this.setState((state) => {
      return {
        ...state,
        sync: {
          ...state.sync,
          open: true,
        },
      };
    });
  }

  handleCloseSyncModal() {
    if (this.state.sync.lock) return;

    this.setState((state) => {
      return {
        ...state,
        sync: {
          ...state.sync,
          open: false,
        },
      };
    });
  }

  handleLockSyncModal(lock) {
    this.setState((state) => {
      return {
        ...state,
        sync: {
          ...state.sync,
          lock: lock,
        },
      };
    });
  }

  closeGameboard() {
    this.setState({
      gameboard: { ...this.state.gameboard, open: false },
    });
  }

  handleDupplicatesChecker(checked) {
    this.setState((state) => {
      return {
        ...state,
        with_case: checked,
        show_cleared_duplicate_case: checked
          ? false
          : state.show_cleared_duplicate_case,
      };
    });
    // this.refreshProductDuplicateCaseDataTable();
  }

  handleShowClearedDupplicateCase(checked) {
    this.setState((state) => {
      return {
        ...state,
        show_cleared_duplicate_case: checked,
        with_case: checked ? false : state.with_case,
      };
    });

    // this.refreshProductDuplicateCaseDataTable();
  }

  render() {
    return (
      <ListProductView
        tabClick={this.tabClick.bind(this)}
        handleOpenGameboardModal={this.handleOpenGameboardModal}
        handleCloseGameboardModal={this.handleCloseGameboardModal}
        handleOpenMigrationModal={this.handleOpenMigrationModal}
        handleCloseMigrationModal={this.handleCloseMigrationModal}
        handleLockMigrationModal={this.handleLockMigrationModal}
        handleOpenSyncModal={this.handleOpenSyncModal}
        handleCloseSyncModal={this.handleCloseSyncModal}
        handleLockSyncModal={this.handleLockSyncModal}
        handleDupplicatesChecker={this.handleDupplicatesChecker}
        handleShowClearedDupplicateCase={this.handleShowClearedDupplicateCase}
        {...this.state}
      />
    );
  }
}

export default connect(null, {
  getProductList: getProductList,
  getShopeeProductList: getShopeeProductListRequest,
  getGlobalConfig: getGlobalConfigRequest,
  getProductListWithDuplicateCase: getProductListWithDuplicateCase,
  requestGetProduct: requestGetProduct,
  destroy: destroy,
})(ListProductContainer);
