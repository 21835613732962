import React, { Component } from "react";

import "./style/button.css";

export default class ButtonView extends Component {
  render() {
    const { submit, draft } = this.props;
    return (
      <div className="footer-container">
        <button type="button" className="btn product-button m-r-10">
          Cancel
        </button>
        <button
          type="button"
          className="btn product-button m-r-10"
          onClick={(e) => draft()}
        >
          Draft
        </button>
        <button
          type="button"
          className="btn btn-primary product-button"
          onClick={(e) => submit()}
        >
          Submit
        </button>
      </div>
    );
  }
}
