import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import * as Http from "../../redux/actions/http";
import ReOrderLevelComponent from "../views/component";

class ReOrderLevelContainer extends Component {
  state = {
    keep_effect_updated: false,
    all_reorder_level: 0,
    updating: false,
    datatable: [],
    message: null,
    error: null,
  };

  componentDidMount() {}

  updateState(state) {
    this.setState(state);
  }

  dataTableApplyReOrderLevelToAlItemsAndVariants(value) {
    this.updateState({
      all_reorder_level: value,
      keep_effect_updated: !this.state.keep_effect_updated,
    });
  }

  validate(items) {
    if (items.length <= 0) {
      throw "You should check items to update re-order level";
    }
  }

  onRequestUpdate() {
    this.updateState({
      updating: true,
    });
  }

  offRequestUpdate() {
    this.updateState({
      updating: false,
    });
  }

  submitRequest(items) {
    if (this.state.updating) return;

    this.onRequestUpdate();
    this.props
      .requestApplyReOrderLevelChanges(items)
      .then((response) => {
        this.offRequestUpdate();
        if (response.data.success) {
          this.updateState({
            error: false,
            message: response.data.message,
          });
        }
      })
      .catch((error) => {
        this.offRequestUpdate();
        this.updateState({
          error: true,
          message: error.toString(),
        });
      });
  }

  submit() {
    // Get selected items and variants only
    const items_checked_only = this.state.datatable.data
      .filter((item) => item.checked == true)
      .map((item) => {
        item.variation.compose = item.variation.compose.filter(
          (variant) => variant.checked == true
        );

        return item;
      });

    const remove_unecessary_keys = items_checked_only.map((item) => {
      delete item.variation.variants;
      item.variation.compose = item.variation.compose.map((compose) => {
        delete compose.links;
        return compose;
      });
      return item;
    });

    try {
      this.validate(remove_unecessary_keys);
      this.submitRequest(remove_unecessary_keys);
    } catch (e) {
      this.updateState({
        error: true,
        message: e.toString(),
      });
    } finally {
    }
  }

  render() {
    return (
      <>
        <ReOrderLevelComponent
          {...this.state}
          submit={this.submit.bind(this)}
          updateState={this.updateState.bind(this)}
          dataTableApplyReOrderLevelToAlItemsAndVariants={this.dataTableApplyReOrderLevelToAlItemsAndVariants.bind(
            this
          )}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    shop: state.shop,
  };
}

export default connect(mapStateToProps, {
  requestApplyReOrderLevelChanges: Http.requestApplyReOrderLevelChanges,
})(ReOrderLevelContainer);
