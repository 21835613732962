import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";

export const getAllRequest = (criteria, per_page, page) => (dispatch) => {
  dispatch(payload.setLoading(true));
  return Http.POST("product/category/getAll", {
    payload: criteria,
    per_page,
    page,
  })
    .then(function (response) {
      dispatch(payload.fetchAll(response.data));
      dispatch(payload.setLoading(false));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getAll = (criteria, per_page, page) => (dispatch) => {
  return function (dispatch) {
    return dispatch(getAllRequest(criteria, per_page, page));
  };
};

export const getCategoryRequest = (categoryId) => (dispatch) => {
  return Http.GET("product/category/get/" + categoryId)
    .then(function (response) {
      dispatch(payload.fetch(response.data));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getCategory = () => (dispatch) => {
  return function (dispatch) {
    return dispatch(getAllRequest());
  };
};

export const chooseCategoryRequest = (filters) => (dispatch) => {
  return Http.POST("product/category/choose", { payload: filters })
    .then(function (response) {
      dispatch(payload.choosenCategory(response.data));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const chooseCategory = (filters) => (dispatch) => {
  return function (dispatch) {
    return dispatch(chooseCategoryRequest(filters));
  };
};

interface category {
  name: string;
  description: string;
  parentId: integer;
}

export const createCategory = (data: category) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PUT("product/category/create", data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

export const updateCategory = (categoryId, data: category) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PATCH("product/category/update/" + categoryId, data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(payload.setUpdateStatus(true));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

interface categoryId {
  id: int; //category id
}
export const deleteCategory = (data: categoryId) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.POST("product/category/delete", { payload: data })
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};
