import * as type from "redux/constants/user/permission/group";

export const setLoading = (payload) => {
  return { type: type.LOADING, payload: payload };
};

export const setResponse = (payload) => {
  return { type: type.RESPONSE, payload: payload };
};

export const setFormStatus = (payload) => {
  return { type: type.FORM_STATUS, payload: payload };
};

export const loadActionGroup = (payload) => {
  return {
    type: type.LOAD_ACTION_GROUP,
    payload: payload,
  };
};

export const updateGroupCheckBox = (payload) => {
  return {
    type: type.UPDATE_GROUP_CHECKBOX,
    payload: payload,
  };
};

export const updateGroupActions = (payload) => {
  return {
    type: type.UPDATE_GROUP_ACTION,
    payload: payload,
  };
};

export const updateGroupActionCheckbox = (actionGroupId, actionId) => {
  return {
    type: type.UPDATE_GROUP_ACTION_CHECKBOX,
    payload: { actionGroupId, actionId },
  };
};
