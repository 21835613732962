import React, { Component, Fragment } from 'react';

import ListCategoryComponent from "../views/list-category";

export class ListCategoryContainer extends Component {
    state = {
        open: false
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    render() {
        const {open} = this.state;
        return (
            <ListCategoryComponent
                open={open}
                onOpenModal={this.onOpenModal}
                onCloseModal={this.onCloseModal}
            />
        )
    }
}

export default ListCategoryContainer;
