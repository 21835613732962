import React, {Component, Fragment} from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom'
import Breadcrumb from "components/common/breadcrumb";
import logo from 'assets/images/logo-color.png'

import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";

class NotFound extends Component {

    constructor (props) {
        super (props)
    }

    render (){
    
        return (
            <Fragment>
                 <Breadcrumb title={'404 Page'} parent="Dashboard"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h1>404</h1>
                                    <h2>page not found</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userAuth: state.userAuth
    };
}

let NotFoundRedux = connect(
    mapStateToProps
)(NotFound);

export default NotFoundRedux;
