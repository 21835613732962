import * as type from "../constants/index";
import * as allocationTypeChanges from "./allocation-type-changes";
import * as removeBrandItemsVariant from "./remove-brand-items-variant";
import * as addBrandItemVariant from "./add-brand-item-variant";
import * as changeItemVariantStock from "./change-item-variant-stock";

const initialState = {
  data: [],
  loading: false,
  formStatus: false,
  allocation_type_selected: [],
  template_selected: { info: [], brands: [], warehouse: [] },
};

const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
};

function destroy() {
  return initialState;
}

function setTemplateSelected(state, action) {
  return {
    ...state,
    template_selected: action.payload,
  };
}

const InventoryStockingReducer = createReducer(initialState, {
  [type.DESTROY]: destroy,
  [type.CHANGE_SEL_STOCKING_TEMPLATE]: setTemplateSelected,

  [type.CHANGE_HEADER_ALLOCATION_TYPE]:
    allocationTypeChanges.changeAllocationTypeViaHeader,
  [type.CHANGE_ITEM_ALLOCATION_TYPE]:
    allocationTypeChanges.changeAllocationTypeViaItem,
  [type.CHANGE_ITEM_VARIANT_ALLOCATION_TYPE]:
    allocationTypeChanges.changeAllocationTypeViaItemVariant,

  [type.REMOVE_SELECTED_TEMPLATE_BRAND]:
    removeBrandItemsVariant.removeSelectedTemplateBrand,
  [type.REMOVE_SELECTED_TEMPLATE_BRAND_ITEM]:
    removeBrandItemsVariant.removeSelectedTemplateBrandItem,
  [type.REMOVE_SELECTED_TEMPLATE_BRAND_ITEM_VARIATION]:
    removeBrandItemsVariant.removeSelectedTemplateBrandItemVariation,

  [type.ADD_BRAND_SELECTED_TEMPLATE]:
    addBrandItemVariant.addBrandToSelectedTemplate,

  [type.ADD_BRAND_ITEM_SELECTED_TEMPLATE]:
    addBrandItemVariant.addBrandItemToSelectedTemplate,

  [type.ADD_BRAND_ITEM_VARIANT_SELECTED_TEMPLATE]:
    addBrandItemVariant.addBrandItemVariantToSelectedTemplate,

  [type.CHANGE_ITEM_STOCK]: changeItemVariantStock.changeItemStock,
  [type.CHANGE_ITEM_VARIANT_STOCK]:
    changeItemVariantStock.changeItemVariantStock,

  [type.RESET_SELECTED_TEMPLATE_ITEM_VARIANT_STOCK]:
    changeItemVariantStock.resetSelectedTemplateItemVariantStock,
});

export default InventoryStockingReducer;
