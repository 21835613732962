import * as type from "redux/constants/user/permission/action";

export const setLoading = (payload) => {
    return { type: type.LOADING, payload: payload};
}

export const  setResponse = (payload) => {
    return { type: type.RESPONSE, payload: payload };
}

export const  setFormStatus = (payload) => {
	return {type: type.FORM_STATUS, payload: payload }
}

export const  loadAction = (payload) => {
	return {
		type: type.LOAD_ACTION,
		payload: payload
	};
}

export const  updateActionCheckbox = (payload) => {
	return {
		type: type.UPDATE_ACTION_CHECKBOX,
		payload: payload
	};
}