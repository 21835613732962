import React, { Component } from "react";
import { connect } from "react-redux";
import { updateDetailsForm } from "../../redux/actions/payload";
import DetailedDescriptionView from "../views/detailed-description";

class DetailedDescriptionContainer extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = (event) => {
    this.props.updateDetailsForm(event.target.name, event.target.value);
  };

  handleDetailsChange = (event, editor) => {
    // console.log({ event, editor });
    // console.log(editor.data.get());
    this.props.updateDetailsForm("detailed", editor.data.get());
  };

  render() {
    return (
      <DetailedDescriptionView
        handleInputChange={this.handleInputChange}
        handleDetailsChange={this.handleDetailsChange}
        form={this.props.form}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      form: state.productReducer.form,
    };
  },
  {
    updateDetailsForm: updateDetailsForm,
  }
)(DetailedDescriptionContainer);
