import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

import NumberFormat from "react-number-format";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const ListOrderDataTableComponent = (props) => {
  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"Order No"}</b>,
            accessor: "order_no",
            width: 150,
            Cell: (row) => (
              <Link
                to={"edit/" + row.original.id}
                title="Edit"
                style={{
                  textDecoration: "underline",
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />{" "}
                {row.original.order_no}
              </Link>
            ),
          },
          {
            Header: <b>{"Order Date"}</b>,
            accessor: "order_at",
          },
          {
            Header: <b>{"Customer"}</b>,
            id: "customer",
            accessor: "customer.label",
          },
          {
            Header: <b>{"Payment Mode"}</b>,
            id: "payment_mode",
            accessor: "payment_mode.name",
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Status"}</b>,
            id: "status",
            accessor: "payment_status.name",
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Total Amount"}</b>,
            accessor: "total_amount",
            filterable: false,
            style: {
              textAlign: "right",
            },
            Cell: (row) => (
              <NumberFormat
                value={row.original.total_amount}
                displayType={"text"}
                prefix={""}
                thousandSeparator={true}
              />
            ),
          },
        ]}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={props.data}
        pages={props.pages} // Display the total number of pages
        loading={props.loading} // Display the loading overlay when we need it
        onFetchData={props.fetchSalesOrder} // Request new data when things change
        filterable
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
};

export default ListOrderDataTableComponent;
