import React, { Component, Fragment, useEffect } from "react";

import { connect } from "react-redux";
import { isNumber, empty } from "common";
import * as Http from "../../redux/actions/http";
import * as Action from "../../redux/actions/payload";

import StockingListTopDatatableComponent from "../views/stocking-list-datatable";

const StockingListDatatableContainer = (props) => {
  // props.destroy();
  const renderItemStockEditable = (cellInfo) => {
    if (cellInfo.original.has_variation) {
      return <></>;
    }

    return (
      <div
        style={{ backgroundColor: "#F0ECE3" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          if (!isNumber(e.target.innerHTML)) {
            return;
          }
          props.changeItemStock(
            cellInfo.column.brand_id,
            cellInfo.original.id,
            parseInt(e.target.innerHTML)
          );
        }}
        dangerouslySetInnerHTML={{
          __html: (
            props.inventory_stocking_reducer.template_selected.brands ?? []
          ).reduce((c, brand) => {
            if (brand.id == cellInfo.column.brand_id) {
              c = brand.items.data.reduce((c, item) => {
                if (item.id == cellInfo.original.id) {
                  c = item.stock;
                }
                return c;
              }, 0);
            }

            return c;
          }, 0),
        }}
      />
    );
  };

  const renderVariantStockEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: "#F0ECE3" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          if (!isNumber(e.target.innerHTML)) {
            return;
          }
          props.changeItemVariantStock(
            cellInfo.column.brand_id,
            cellInfo.column.item_id,
            cellInfo.original.id,
            parseInt(e.target.innerHTML)
          );
        }}
        dangerouslySetInnerHTML={{
          __html: (
            props.inventory_stocking_reducer.template_selected?.brands ?? []
          ).reduce((c, brand) => {
            if (brand.id == cellInfo.column.brand_id) {
              c = brand.items.data.reduce((c, item) => {
                if (item.id == cellInfo.column.item_id) {
                  c = item.variation.compose.reduce((c, compose) => {
                    if (compose.id == cellInfo.original.id) {
                      c = compose.stock;
                    }
                    return c;
                  }, 0);
                }
                return c;
              }, 0);
            }

            return c;
          }, 0),
        }}
      />
    );
  };

  // Monitor template selected changes
  useEffect(() => {
    props.updateState({
      template_name:
        props.inventory_stocking_reducer.template_selected?.name ?? "",
      warehouse_selected:
        props.inventory_stocking_reducer.template_selected?.warehouse,
    });
  }, [props.inventory_stocking_reducer.template_selected]);

  return (
    <StockingListTopDatatableComponent
      {...props}
      renderItemStockEditable={renderItemStockEditable.bind(this)}
      renderVariantStockEditable={renderVariantStockEditable.bind(this)}
    />
  );
};

export default connect(
  (state) => {
    return {
      inventory_stocking_reducer: state.inventoryStockingReducer,
    };
  },
  {
    changeSelectedStockingTemplate: Action.changeSelectedStockingTemplate,
    changeHeaderAllocationType: Action.changeHeaderAllocationType,
    changeItemAllocationType: Action.changeItemAllocationType,
    changeItemVariantAllocationType: Action.changeItemVariantAllocationType,
    removeSelectedTemplateBrand: Action.removeSelectedTemplateBrand,
    removeSelectedTemplateBrandItem: Action.removeSelectedTemplateBrandItem,
    removeSelectedTemplateBrandItemVariation:
      Action.removeSelectedTemplateBrandItemVariation,
    changeItemStock: Action.changeItemStock,
    changeItemVariantStock: Action.changeItemVariantStock,
    destroy: Action.destroy,
  }
)(StockingListDatatableContainer);
