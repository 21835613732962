import React, { Component } from "react";

import { connect } from "react-redux";
import { getCategoryTree } from "../../redux/actions/http";
import {
  createCategoryBox,
  confirmCategory,
  editCategory,
  cancelCategory,
} from "../../redux/actions/category/payload";
import CategoryView from "../views/category";

class CategoryContainer extends Component {
  componentDidMount() {
    if (this.props.category.data.length <= 0) {
      this.props.getCategoryTree();
    }
  }

  render() {
    console.log(this.props);
    return <CategoryView {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    category: state.productReducer.category,
  };
}

export default connect(mapStateToProps, {
  createCategoryBox: (categoryParentId, categoryId, checked) =>
    createCategoryBox({ categoryParentId, categoryId, checked }),
  getCategoryTree: getCategoryTree,
  confirmCategory: confirmCategory,
  editCategory: editCategory,
  cancelCategory: cancelCategory,
})(CategoryContainer);
