import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import * as SupplierAction from "../../redux/actions/index";
import FormComponent from "../views/form";
import Message_container from "components/common/message-container";
import Breadcrumb from "components/common/breadcrumb";
import * as toast from "components/common/toast";

class UpdateSupplierContainer extends Component {
  state = {
    name: "",
    address: "",
    website: "",
    viber: "",
    messenger: "",
    contact_name: "",
    contact_no: "",
    error: false,
    message: "",
  };

  componentDidMount = () => {
    this.props.getSupplier(this.props.match.params.id, (response) => {
      this.setState({
        name: response.name,
        address: response.address,
        website: response.website,
        viber: response.viber,
        messenger: response.messenger,
        contact_name: response.contact_name,
        contact_no: response.contact_no,
      });
    });
  };

  handleSubmit(e) {
    this.props.updateSupplier(
      this.props.match.params.id,
      this.state,
      (response) => {
        if (response.success === true) {
          this.setState({
            message: response.message,
            error: false,
          });
          toast.success(response.message);
        } else {
          this.setState({
            message: response.message,
            error: true,
          });
        }
      }
    );
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const { loading, response } = this.props.supplierReducer;
    const { message, error } = this.state;
    console.log(this.state);
    return (
      <>
        <Breadcrumb title="Update Supplier" parent="Suppliers" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <Message_container message={message} error={error} />
                  <FormComponent
                    handleChange={this.handleChange.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    loading={loading}
                    response={response}
                    {...this.props}
                    {...this.state}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierReducer: state.supplierReducer,
  };
}

export default connect(mapStateToProps, {
  updateSupplier: SupplierAction.updateSupplier,
  getSupplier: SupplierAction.getSupplierRequest,
})(UpdateSupplierContainer);
