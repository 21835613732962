const RowSpan = (state) => {
  const x = {
    ...state,
    variation: {
      ...state.variation,
      compose: state.variation.compose.map((sku, i) => {
        const previousSku = state.variation.compose[i - 1];
        if (previousSku === undefined) {
          sku.data.options = sku.data.options.map((option, i) => {
            option.rowSpan = "";
            option.first = true;
            option = setRowSpan(state.variation.data, sku, option, i);

            return option;
          });
          return sku;
        }

        sku.data.options = sku.data.options.map((option, i) => {
          option.rowSpan = "";
          option.first = false;
          if (previousSku.data.options[i].value !== option.value) {
            option.first = true;
            option = setRowSpan(state.variation.data, sku, option, i);
          }
          return option;
        });

        return sku;
      }),
    },
  };
  console.log(x);
  return x;
};

const setRowSpan = (data, sku, option, i) => {
  for (var x = i; x < sku.data.options.length - 1; x++) {
    let rowSpan = data[x].sku_data.length;
    for (var y = i + 1; y < sku.data.options.length; y++) {
      rowSpan = rowSpan * data[y].sku_data.length;
    }
    option.rowSpan = rowSpan / data[x].sku_data.length;
  }
  return option;
};

export default RowSpan;
