import React from "react";

import PropTypes from "prop-types";

import { ArrowLeft } from "react-feather";
import Nav from "react-bootstrap/Nav";

import "../style/header.scss";

const CheckoutHeaderComponent = (props) => {
  return (
    <>
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-lg-6">
              <Nav variant="pills" defaultActiveKey="basket">
                {props.menus.map((menu, i) => {
                  return (
                    <Nav.Item key={i}>
                      <Nav.Link
                        eventKey={menu.name}
                        disabled={menu.disabled}
                        onClick={(e) => props.navClickHandler(menu)}
                      >
                        {menu.title.toUpperCase()}
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </div>
            <div className="col-lg-6" style={{ textAlign: "right" }}>
              <span className="cursor-pointer" onClick={props.togglePage}>
                <ArrowLeft />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CheckoutHeaderComponent.propTypes = {
  togglePage: PropTypes.func,
  navClickHandler: PropTypes.func,
};

export default CheckoutHeaderComponent;
