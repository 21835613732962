import * as type from "../../constants/wholesale";

export const enableWholesale = (payload) => {
  return {
    type: type.ENABLE,
    payload: payload,
  };
};

export const addTier = (tier) => {
  return {
    type: type.ADD_TIER,
    payload: { tier },
  };
};

export const removeTier = (id) => {
  return {
    type: type.REMOVE_TIER,
    payload: { id },
  };
};

export const updateTierColumn = (id, column, value) => {
  return {
    type: type.UPDATE_TIER_COLUMN,
    payload: {
      id,
      column,
      value,
    },
  };
};

export const clear = () => {
  return {
    type: type.CLEAR,
  };
};

export const updateFullWholesale = (data) => {
  return {
    type: type.UPDATE_FULL,
    payload: { data },
  };
};
