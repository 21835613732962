import React, { useState } from "react";
import { connect } from "react-redux";
import * as Payload from "../../redux/actions/payload";
import Bus from "common/event-emitter";
import DatatableComponent from "../views/datatable";

const DatatableContainer = (props) => {
  function setResponseMessage(error, message) {
    Bus.emit("message", { error, message });

    // Clear out display after few seconds
    if (error) {
      setTimeout(() => {
        setResponseMessage(false, "");
      }, 10000);
    }
  }

  function increase(id) {
    try {
      props.increase(id);
    } catch (error) {
      console.log(error);
      setResponseMessage(true, error.toString());
    }
  }

  function decrease(id) {
    try {
      props.decrease(id);
    } catch (error) {
      setResponseMessage(true, error.toString());
    }
  }

  return (
    <DatatableComponent
      data={props.inStoreOrderReducer.basket}
      increase={increase.bind(this)}
      decrease={decrease.bind(this)}
    />
  );
};

export default connect(
  (state) => {
    return {
      inStoreOrderReducer: state.inStoreOrderReducer,
    };
  },
  {
    ...Payload,
  }
)(DatatableContainer);
