import React from "react";
import CustomerComponent from "../views";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/payload";

const CustomerContainer = (props) => {
  console.log(props.inStoreOrderReducer.customer);
  return (
    <>
      <CustomerComponent
        data={props.inStoreOrderReducer.customer}
        changeCustomerData={props.changeCustomerData.bind(this)}
      />
    </>
  );
};

export default connect(
  (state) => {
    return {
      inStoreOrderReducer: state.inStoreOrderReducer,
    };
  },
  {
    ...Action,
  }
)(CustomerContainer);
