import React, { Component, Fragment, Suspense } from "react";

import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";

import * as toast from "components/common/toast";

import { empty } from "common";
import moment from "moment";

import PurchaseOrder from "./purchase-order";

const PuchaseOrderComponent = React.lazy(() =>
  import("../views/purchase-order")
);

class CreatePuchaseOrderContainer extends Component {
  componentDidMount() {
    this.props.retrieveAllCommonData().then((data) => {
      this.props.updateState({
        ...data,
        order_data_loading: false,
      });
    });
  }

  handleValidSubmit(event, values) {
    try {
      this.props.updateState({
        save_loading: true,
      });
      setTimeout(() => {
        this.props.createPurchaseOrder(
          {
            ...this.props.getStateData(),
            supplier: this.props.supplier.value,
          },
          (response) => {
            this.props.updateState({
              save_loading: false,
            });
            if (response.data.success === true) {
              this.props.clearState();
              response.data.message =
                response.data.message +
                '<br /><a href="/purchase/list-purchase-orders">Go to purchases list.</a>';
              toast.success(response.data.message);
            }

            this.props.updateState({
              message: response.data.message,
              error: !response.data.success,
            });
          },
          (error) => {
            console.log(error);
            this.props.updateState({
              message: error?.message,
              error: true,
              save_loading: false,
            });
          }
        );
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  }

  render() {
    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          {!this.props.order_data_loading ? (
            <PuchaseOrderComponent
              {...this.state}
              {...this.props}
              handleValidSubmit={this.handleValidSubmit.bind(this)}
            />
          ) : (
            "Loading..."
          )}
        </Suspense>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierReducer: state.supplierReducer,
  };
}

const PurchaseOrderBase = PurchaseOrder(CreatePuchaseOrderContainer);

export default connect(mapStateToProps, {
  createPurchaseOrder: Action.createPurchaseOrder,
  getAllCommon: Action.getAllCommon,
})(PurchaseOrderBase);
