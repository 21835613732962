import React, { Component,Fragment } from 'react'

import { connect } from "react-redux";
import * as Action_permission from "redux/actions/user/action-permission/index";
import * as Action_group_permission from "redux/actions/user/group-permission/index";
import Spinner from "components/common/spinner";
import Message_container from "components/common/message-container";

export class List_action extends Component {

    componentDidMount() {        
        this.props.getAllAction();
    }


    updateActionCheckboxSel(id) {        
        this.props.updateActionCheckbox(id);        
        this.props.updateGroupActions(this.props.action.data);
    }
    
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <h5> Action List</h5>
                       <ol>
                        {
                            this.props.action.data.map((item, i) => {
                                return (<li key={i}>
                                    <label htmlFor={'action_check_'+i}>
                                        <input type="checkbox" checked={item.checked === true ? 'checked' : ''} onChange={d => {this.updateActionCheckboxSel(item.id)}} className="checkbox_animated"  name={'action_check_'+i} id={'action_check_'+i} />
                                        {item.name}
                                    </label>

                                </li>)
                            })
                        }
                        </ol>
                    </div>
                </div>  
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        action: state.actionPermission
    };
}

export default connect(
    mapStateToProps,
    {
        getAllAction: Action_permission.getAllAction(),
        setActionGroupFormStatus: d => Action_group_permission.setFormStatus(d),
        updateGroupActions: d => Action_group_permission.updateGroupActions(d),
        updateActionCheckbox: d => Action_permission.updateActionCheckbox(d)
    }
)(List_action);
