import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import TableComponent from "./table";
import SummaryWidgetComponent from "./summary-widget";
import AllocationModalContainer from "../container/allocation-modal";

export default class LiveMonitoringComponent extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Live monitoring" parent="inventory" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <SummaryWidgetComponent {...this.props} />
            </div>
            <div className="col-sm-12">
              <AllocationModalContainer {...this.props} />
              <TableComponent {...this.props} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
