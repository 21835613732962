import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";
import axios from "axios";
import moment from "moment";

const formatDate = (date) => {
  return moment(date).format("Y/M/D HH:mm");
};

export const requestStockUpdatesApprovalList = (filter) => (dispatch) => {
  return Http.POST("approval/stock-updates/list", {
    ...filter,
    payload: {
      criteria: filter.filtered,
      sorted: filter.sorted,
      per_page: filter.per_page,
      page: filter.page,
    },
  });
};

export const requestGetApproval = (id) => (dispatch) => {
  return Http.GET("approval/stock-updates/get/" + id);
};

export const requestListOfOverrideAffectedOrders = (id) => (dispatch) => {
  return Http.GET("approval/stock-updates/override-list-affected-orders/" + id);
};

export const requestUpdateOrderToBeExemptedFromOverrideRemoval = (
  order_no,
  payload
) => (dispatch) => {
  return Http.POST(
    "approval/stock-updates/override-removal-exemption/" + order_no,
    payload
  );
};

export const requestUpdateOrderToBeExemptedFromOverrideRemovalBatches = (
  payload
) => (dispatch) => {
  return Http.POST(
    "approval/stock-updates/override-removal-exemption-batches",
    payload
  );
};

export const submitRequest = (type, template) => (dispatch) => {
  return Http.POST("approval/stock-updates/" + type.toLowerCase().trim(), {
    template,
  });
};
