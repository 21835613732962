import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";

export const getAllRequest = (filters, per_page, page) => (dispatch) => {
  dispatch(payload.setLoading(true));
  return Http.POST("supplier/getAll", {
    payload: filters,
    per_page,
    page,
  })
    .then(function (response) {
      dispatch(payload.fetchAll(response.data));
      dispatch(payload.setLoading(false));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getAll = (filters, per_page, page) => (dispatch) => {
  return function (dispatch) {
    return dispatch(getAllRequest(filters, per_page, page));
  };
};

export const getSupplierRequest = (id, callback) => (dispatch) => {
  return Http.GET("supplier/get/" + id)
    .then(function (response) {
      dispatch(payload.callback(response.data, callback));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getSupplier = (id) => (dispatch) => {
  return function (dispatch) {
    return dispatch(getSupplierRequest(id));
  };
};

interface supplier {
  name: string;
  address: string;
  contact_name: string;
  contact_no: string;
  website: string;
  viber: string;
  messenger: string;
}

export const createSuplier = (data: supplier, callback) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PUT("supplier/create", data)
    .then(function (response) {
      dispatch(payload.setLoading(false));
      dispatch(payload.callback(response.data, callback));
    })
    .catch(function (error, content) {
      dispatch(payload.setLoading(false));
      dispatch(common.setError(error.message));
    });
};

export const updateSupplier = (id, data: supplier, callback) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PATCH("supplier/update/" + id, data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(payload.setLoading(false));
        dispatch(payload.callback(response.data, callback));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

interface brandId {
  id: int; //id
}
export const deleteSupplier = (data: id) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.POST("supplier/delete", { payload: data })
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(getAllRequest([], 10, 1));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};

export const upload = (file, success_callback, error_callback) => (
  dispatch
) => {
  const f = new FormData();
  f.append("file", file);
  Http.POST("supplier/upload", f).then(success_callback).catch(error_callback);
};
