import GameComponent from "../gameboard/game-component";
import PlayerProp from "./player/prop";
import * as ButtonProp from "./button-prop";

class StartButton {
  constructor(base) {
    this.base = base;
  }

  createComponents() {
    const start_left =
      this.base.gameboard.canvas().width / 2 - ButtonProp.StartButtonProp.width;
    return new GameComponent(
      this.base.gameboard.canvas(),
      this.base.gameboard.context(),
      ButtonProp.StartButtonProp.color,
      start_left,
      this.base.gameboard.canvas().height - 100,
      ButtonProp.StartButtonProp.width,
      ButtonProp.StartButtonProp.height,
      "MIGRATE",
      "white"
    );
  }

  handleCreate() {
    if (this.base.state.start_button instanceof GameComponent === false) {
      this.base.updateBaseState((state) => {
        return {
          ...state,
          start_button: this.createComponents(),
        };
      });
    }
  }

  progress() {
    this.base.state.start_button.changeTitle("MIGRATING...");
    this.base.state.start_button.lock();
    this.update(this.base.state.start_button);
  }

  start() {
    this.base.state.start_button.changeTitle("MIGRATE");
    this.base.state.start_button.unlock();
    this.update(this.base.state.start_button);
  }

  update(component) {
    this.base.updateBaseState((state) => {
      return {
        ...state,
        start_button: component,
      };
    });
  }

  activate() {
    this.handleCreate();
    if (this.base.state.start_button instanceof GameComponent) {
      this.base.state.start_button.update();
    }
  }

  create() {
    return this.createComponents();
  }
}

export default StartButton;
