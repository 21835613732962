import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";

export const requestLiveInventoryList = (filter) => (dispatch) => {
  return Http.POST("inventory/live-monitoring/updates", {
    payload: {
      ...filter,
    },
  });
};

export const requestOutOfStockInventoryList = (filter) => (dispatch) => {
  return Http.POST("inventory/live-monitoring/updates-by-out-of-stock", {
    payload: {
      ...filter,
    },
  });
};

export const requestGlobalMetaList = (filter) => (dispatch) => {
  return Http.GET("inventory/live-monitoring/global-meta", {
    payload: {
      ...filter,
    },
  });
};

export const requestShopItemsAvailableStock = (data) => (dispatch) => {
  return Http.POST("inventory/allocation/get-items-available-stock", data);
};

export const requestReplenish = (data) => (dispatch) => {
  return Http.POST("inventory/allocation/replenish", data);
};

export const requestOverride = (data) => (dispatch) => {
  return Http.POST("inventory/allocation/override", data);
};
