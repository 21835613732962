import React, { Component, Suspense } from "react";

import EventEmitter  from "common/event-emitter";
import OrderContainer from "./order/order";
import CheckoutContainer from "./checkout/checkout";

class CreateInStoreOrderContainer extends Component {

  state = {
    togglePage: false
  }

  togglePage() {
    this.setState(state => {
      return {
        togglePage: !state.togglePage
      }
    })
  }

  componentDidMount() {
    EventEmitter.on("TOGGLE-PAGE", (toggle) => {
      this.togglePage();
    });
  }

  componentWillUnmount() {
    EventEmitter.off('TOGGLE-PAGE');
  }

  render() {
    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          {
            this.state.togglePage ? <OrderContainer /> : <CheckoutContainer />
          }
        </Suspense>
      </>
    );
  }
}


export default CreateInStoreOrderContainer
