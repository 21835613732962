import React, { Fragment } from "react";

import Modal from "react-responsive-modal";
import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
// import Spinner from "components/common/spinner";
import Message_container from "components/common/message-container";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import CardGroup from "react-bootstrap/CardGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";

import { empty } from "common";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import DefaultImage from "assets/images/default.jpg";

const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
    height="50"
    width="50"
    style={{ color: "#fff" }}
  >
    <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
  </svg>
);

const AllocationModalComponent = (props) => {
  console.log(props);
  const style = {
    closeButton: {
      top: "-29px",
      right: "-4px",
    },
    closeIcon: {
      color: "#fff",
      fontSize: "50px",
    },
    modalContainer: {
      width: "400px",
    },
  };

  const inventory = props.allocation_modal.inventory;
  const available_stock = inventory?.product?.available_stock;

  const ModalTitle = () => {
    return (
      <>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          <span
            className={available_stock <= 0 ? "text-danger" : "text-success"}
          >
            ({available_stock}){" "}
          </span>
          {inventory?.product?.has_variation && (
            <>
              {inventory?.product?.variation_name} <br />{" "}
            </>
          )}
          <small>{inventory?.product?.basic?.title}</small>
        </h5>
      </>
    );
  };

  const LoadingContainer = () => {
    return (
      <Spinner
        animation="border"
        variant="primary"
        size="sm"
        role="status"
        style={{
          width: "0.8rem",
          height: "0.8rem",
        }}
      ></Spinner>
    );
  };

  const AvailableStockSpinner = () => {
    return (
      <Spinner
        animation="border"
        variant="primary"
        size="sm"
        role="status"
        style={{
          width: "1rem",
          height: "1rem",
        }}
      ></Spinner>
    );
  };

  return (
    <Fragment>
      <Modal
        open={props.allocation_modal.status}
        onClose={props.handleAllocationModalClose}
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        styles={style}
        classNames={{
          modal: "allocation_modal_v2",
        }}
        closeIconSize={50}
        closeIconSvgPath={closeIcon}
      >
        <AvForm
          className="needs-validation user-add text-left"
          onValidSubmit={(e) => props.handleSubmitAllocation(e)}
          onInvalidSubmit={props.handleInvalidSubmit}
        >
          <div className="modal-header">
            <ModalTitle />
          </div>
          <div className="modal-body show-grid">
            <Message_container
              message={props.response?.message ?? ""}
              error={props.response?.error ?? false}
            />
            <Container fluid>
              <Row className="">
                {(inventory?.shop_links_reference ?? []).map((_, idx) => {
                  return (
                    <Col xs={12} sm={12} md={2} lg={6} key={idx}>
                      <Card>
                        <Card.Img
                          variant="top"
                          src={DefaultImage}
                          height="150"
                        />
                        <Card.Body>
                          <Card.Title>
                            {!empty(_.shop_item_variation_id)
                              ? _.shop_item_variation.name
                              : _.shop_item_name}
                          </Card.Title>
                          <Card.Text>
                            <small>
                              <strong>
                                {_.shop.name} ({_.shop.platform.name})
                              </strong>
                            </small>
                            <br />
                            <small>{_.shop_item_name.substr(0, 100)}</small>
                          </Card.Text>
                          <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text>
                              {_.loading?.realtime_stock == null ? (
                                <AvailableStockSpinner />
                              ) : (
                                _.available_stock
                              )}
                            </InputGroup.Text>
                            <Form.Control
                              aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"
                              onChange={(e) => {
                                props.handleAllocationInputChange(
                                  _,
                                  e.target.value
                                );
                              }}
                              autoFocus
                              name={"allocated_stock_" + _.idx}
                              value={_.allocated_stock ?? 0}
                            />
                          </InputGroup>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="primary"
                              className="btn-xs"
                              onClick={(e) =>
                                props.handleRequestOverride(
                                  props.allocation_modal.inventory,
                                  _
                                )
                              }
                            >
                              {(_.loading?.override ?? false) && (
                                <LoadingContainer />
                              )}{" "}
                              Override
                            </Button>

                            <Button
                              variant="secondary"
                              className="btn-xs"
                              onClick={(e) =>
                                props.handleRequestRelenish(
                                  props.allocation_modal.inventory,
                                  _
                                )
                              }
                            >
                              {(_.loading?.replenish ?? false) && (
                                <LoadingContainer />
                              )}{" "}
                              Add
                            </Button>
                          </ButtonGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </div>
        </AvForm>
      </Modal>
    </Fragment>
  );
};

export default AllocationModalComponent;
