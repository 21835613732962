import Http from "api/http";
import { List } from "immutable";

import * as common from "redux/actions/user/permission/common";
import * as payload from "redux/actions/user/permission/payload";

interface permission {
  name: string;
  description: string;
  actions: string;
}

export const deletePermission = (roleId, data: permission) => (dispatch) => {
  // Delete unchecked permission
  Http.PATCH("permission/delete", {
    roleId,
    permission: List(data)
      .toJS()
      .filter((d) => {
        d.actions = d.actions.filter((d) => {
          return d.checked === false;
        });
        return d;
      }),
  })
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(payload.clearCurrentRole());
        dispatch(payload.changeUpdateStatus(false));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error) {
      dispatch(common.setError(error.message));
    });
};

export const updatePermission = (roleId, data: permission) => (dispatch) => {
  dispatch(payload.setLoading(true));
  // Create checked permission
  Http.PUT("permission/create", {
    roleId,
    permission: List(data)
      .toJS()
      .filter((d) => {
        d.actions = d.actions.filter((d) => {
          return d.checked === true;
        });
        return d;
      }),
  })
    .then(function (response) {
      if (response.data.success === true) {
        // Delete unchecked permission
        dispatch(deletePermission(roleId, data));
        dispatch(common.setSuccess(response.data.message));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error) {
      dispatch(common.setError(error.message));
    });
};

export const getPermissionRequest = (roleId) => (dispatch) => {
  return Http.GET("permission/get/" + roleId)
    .then(function (response) {
      if (response.data.success === false) {
        return;
      }
      dispatch(payload.setCurrentRole(roleId));
      dispatch(payload.fetch(response.data.data));
      dispatch(payload.changeUpdateStatus(true));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getPermission = (roleId) => (dispatch) => {
  return function (dispatch) {
    return dispatch(getPermissionRequest(roleId));
  };
};
