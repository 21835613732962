import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";

import _debounce from "lodash.debounce";

const ItemsDatatableComponent = (props) => {
  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: (
              <input
                type="checkbox"
                className="form-control"
                checked={props.allChecked ? "checked" : ""}
                onChange={(e) => {}}
              />
            ),
            accessor: "checked",
            sortable: false,
            filterable: false,
            width: 70,
            Cell: (row) => {
              return (
                <input
                  onChange={(e) => {
                    props.checkbox(row);
                  }}
                  checked={row.original?.checked ? "checked" : ""}
                  type="checkbox"
                  className="form-control"
                />
              );
            },
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Item"}</b>,
            id: "title",
            accessor: "basic.title",
            width: 300,
            sortable: true,
            style: {
              textAlign: "left",
            },
            Cell: (row) =>
              !row.original.has_variation ? (
                row.original.basic.title
              ) : (
                <>
                  <strong>{row.original.variant_name}</strong> <br />
                  {row.original.basic.title}
                </>
              ),
          },
          {
            Header: <b>{"Current Stock"}</b>,
            id: "current_stock",
            sortable: true,
            filterable: false,
            style: {
              textAlign: "left",
            },
            width: 200,
            Cell: (row) => {
              let inventories = [];
              inventories = row.original.inventory;
              if (row.original.has_variation) {
                inventories = row.original.variant_inventory;
              }

              // If the item has order_forecast stored already
              // then replace the default data from the records
              if (row.original.saved_forecast_inventories.length > 0) {
                inventories = row.original.saved_forecast_inventories;
              }
              return inventories.map((inventory) => {
                return (
                  <>
                    {inventory.warehouse.name}:{inventory.stock}
                    <br />
                  </>
                );
              });
            },
          },
          {
            Header: <b>{"Current Order"}</b>,
            id: "current_order",
            filterable: false,
            sortable: true,
            style: {
              textAlign: "center",
            },
            width: 200,
            Cell: (row) => {
              let total_pending_orders = [];
              total_pending_orders =
                row.original.basic.extra_fields.total_pending_orders;
              if (row.original.has_variation) {
                total_pending_orders =
                  row.original.variant_current_order.extra_fields
                    .total_pending_orders;
              }
              // If there was a saved records from the database, default it
              if (row.original.saved_current_order?.total_pending_orders) {
                total_pending_orders =
                  row.original.saved_current_order.total_pending_orders;
              }

              return (
                <>
                  <overall title="Overall Total">
                    {total_pending_orders.total}
                  </overall>
                  <br />
                  <small style={{ fontSize: "11px" }}>
                    <color className="text-success" title="Processed">
                      {total_pending_orders.processed}
                    </color>{" "}
                    /{" "}
                    <color className="text-danger" title="Pending">
                      {total_pending_orders.unprocess}
                    </color>
                  </small>
                </>
              );
            },
          },
          {
            Header: <b>{"Extra Order"}</b>,
            filterable: false,
            sortable: false,
            style: {
              textAlign: "center",
            },
            width: 150,
            Cell: props.renderEditableExtraOrder,
          },
          {
            Header: <b>{"Re-order quantity"}</b>,
            filterable: false,
            sortable: false,
            style: {
              textAlign: "center",
            },
            width: 150,
            Cell: props.renderEditable,
          },
          {
            Header: <b>{"Remaining Quantity"}</b>,
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 200,
            Cell: (row) =>
              row.original.total_inventory +
              parseInt(row.original?.order_quantity ?? 0) -
              parseInt(row.original?.extra_order ?? 0),
          },
          {
            Header: <b>{"1 day Forecast (30d)"}</b>,
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 200,
            Cell: (row) =>
              !row.original.has_variation
                ? row.original?.meta_data?.order_forecast?.day_1 ?? 0
                : row.original.variant_meta_data.order_forecast.day_1,
          },
          {
            Header: <b>{"2 days Forecast (30d)"}</b>,
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 200,
            Cell: (row) =>
              !row.original.has_variation
                ? row.original?.meta_data?.order_forecast?.day_2 ?? 0
                : row.original.variant_meta_data.order_forecast.day_2,
          },
          {
            Header: <b>{"3 days Forecast (30d)"}</b>,
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 200,
            Cell: (row) =>
              !row.original.has_variation
                ? row.original?.meta_data?.order_forecast?.day_3 ?? 0
                : row.original.variant_meta_data.order_forecast.day_3,
          },
        ]}
        manual
        data={props.data}
        pages={props.pages} // Display the total number of pages
        loading={props.loading} // Display the loading overlay when we need it
        onFetchData={_debounce(props.requestProductList, 1000)} // Request new data when things change
        pageSizeOptions={[10, 20]}
        freezeWhenExpanded={true}
        filterable
        filterable
        defaultPageSize={20}
        collapseOnDataChange={false}
        collapseOnPageChange={false}
        className="-striped -highlight"
      />
    </div>
  );
};

export default ItemsDatatableComponent;
