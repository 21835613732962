import Http from "api/http";

const SelectCategoryService = (inputValue, callback) => {
  Http.POST("product/category/choose", {
    payload: { criteria: { name: inputValue } },
  })
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      console.log(error.response?.data);
    });
};

export default SelectCategoryService;
