import React, { Component, Fragment } from "react";
import Breadcrumb from "../../../../../components/common/breadcrumb";
import data from "../../../../../assets/data/pro_list";
import ListDataTableContainer from "../container/list-datatable";

import { ListShopeeProductDataTableContainer } from "../../../../shared/shopee";
import AssociationModalContainer from "../container/association-modal";
import MigrationModalContainer from "../container/migration-modal";
import SyncModalContainer from "../container/sync-modal";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export class ListProductView extends Component {
  render() {
    const {
      tabClick,
      tab,
      handleOpenGameboardModal,
      handleCloseGameboardModal,
      handleOpenSyncModal,
      handleCloseSyncModal,
      handleLockSyncModal,
      handleOpenMigrationModal,
      handleCloseMigrationModal,
      handleLockMigrationModal,
      handleDupplicatesChecker,
      handleShowClearedDupplicateCase,
      gameboard,
      migration,
      sync,
      with_case,
      show_cleared_duplicate_case,
    } = this.props;
    return (
      <Fragment>
        <Breadcrumb title="Product List" parent="Digital" />
        <MigrationModalContainer
          migration={migration}
          handleLockMigrationModal={handleLockMigrationModal}
          handleCloseMigrationModal={handleCloseMigrationModal}
        />
        <SyncModalContainer
          sync={sync}
          handleLockSyncModal={handleLockSyncModal}
          handleCloseSyncModal={handleCloseSyncModal}
        />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col>
                      {" "}
                      <h5>Product Lists</h5>
                    </Col>
                    <Col md="auto">
                      <Form.Check
                        inline
                        label="Show Duplicate Case List"
                        name="group1"
                        type="checkbox"
                        checked={with_case}
                        onChange={(e) => {
                          handleDupplicatesChecker(e.target.checked);
                        }}
                      />
                    </Col>
                    <Col md="auto">
                      <Form.Check
                        inline
                        label="Show Cleared Duplicate Case"
                        name="group2"
                        type="checkbox"
                        checked={show_cleared_duplicate_case}
                        onChange={(e) => {
                          handleShowClearedDupplicateCase(e.target.checked);
                        }}
                      />
                    </Col>
                    <Col md="auto">
                      <Dropdown size="sm" as={ButtonGroup}>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          Options
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              handleOpenSyncModal();
                            }}
                          >
                            Sync
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              handleOpenMigrationModal();
                            }}
                          >
                            Migrate
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={tab}
                    onSelect={(tab) => tabClick(tab)}
                  >
                    <Tab eventKey="store_products" title="Store Products">
                      <div className="product-physical">
                        <AssociationModalContainer
                          gameboard={gameboard}
                          handleCloseGameboardModal={handleCloseGameboardModal}
                        />

                        <ListDataTableContainer
                          with_case={with_case}
                          show_cleared_duplicate_case={
                            show_cleared_duplicate_case
                          }
                          handleOpenGameboardModal={handleOpenGameboardModal}
                        />
                      </div>
                    </Tab>
                    <Tab eventKey="shopee_products" title="Shopee Products">
                      <ListShopeeProductDataTableContainer />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

export default ListProductView;
