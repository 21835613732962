import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import DatatableContainer from "../container/datatable";
import ImportModalContainer from "../container/import-modal";

import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class ListSupplierComponent extends Component {
  render() {
    const {
      handleShowImportModal,
      handleCloseImportModal,
      import_modal,
    } = this.props;
    return (
      <Fragment>
        <Breadcrumb title="Supplier List" parent="Supplierss" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <Row>
                <Col>
                  {" "}
                  <h5>&nbsp;</h5>
                </Col>
                <Col md="auto">
                  <Dropdown size="sm" as={ButtonGroup}>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      Options
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          handleShowImportModal();
                        }}
                      >
                        Import
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => {}}>Export</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </div>
            <div className="card-body">
              <div className="clearfix"></div>
              <div
                id="batchDelete"
                className="category-table user-list order-table coupon-list-delete"
              >
                <ImportModalContainer
                  import_modal={import_modal}
                  handleCloseImportModal={handleCloseImportModal}
                />
                <DatatableContainer />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
