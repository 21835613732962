import CreateOpponents from "./create-opponent";
import UpdateOpponentCoordinates from "./coordinates";
import ListOpponents from "./list-opponents";
import Character from "./character";
import { empty } from "common";

const Opponent = (base) => {
  const coordinates = () => {
    return new UpdateOpponentCoordinates(base).handle(
      ListOpponents(base).allActive()
    );
  };

  const activate = () => {
    ListOpponents(base)
      .allActive()
      .forEach((opponent) => {
        opponent.getComponent().update();
      });
  };

  const remove = (opponents_) => {
    const opponents = opponents_.length > 0 ? opponents_ : [opponents_];
    base.updateBaseState((state) => {
      return {
        ...state,
        opponents: state.opponents.filter((opponent) => {
          if (opponents.filter((o) => o.sameValueAs(opponent)).length > 0) {
            return false;
          }
          return true;
        }),
      };
    });
  };

  const updateState = (opponents_) => {
    base.updateBaseState((state) => {
      let opponents = empty(opponents_) ? [] : opponents_;

      opponents = [].concat(...opponents).filter((opponent) => {
        if (state.opponents.filter((o) => o.sameValueAs(opponent)).length > 0) {
          return false;
        }
        return true;
      });

      return {
        ...state,
        opponents: state.opponents.concat(...opponents),
      };
    });
  };

  const createOpponents = (shops) => {
    updateState(new CreateOpponents(base).handle(shops));
  };

  const character = (opponent) => {
    return new Character(base, opponent);
  };

  return {
    ListOpponents: ListOpponents(base),
    createOpponents,
    coordinates,
    character,
    activate,
    remove,
  };
};

export default Opponent;
