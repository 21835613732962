import * as type from "../constants/index";

export const setLoading = (payload) => {
  return { type: type.LOADING, payload: payload };
};

export const setResponse = (payload) => {
  return { type: type.RESPONSE, payload: payload };
};

export const destroy = () => {
  return { type: type.DESTROY };
};

export const loadTransactions = (payload) => {
  return {
    type: type.LOAD_TRANSACTIONS,
    payload: payload,
  };
};

export const loadShopList = (payload) => {
  return {
    type: type.LOAD_SHOP_LIST,
    payload: payload,
  };
};

export const loadHistory = (payload) => {
  return {
    type: type.LOAD_HISTORY,
    payload: payload,
  };
};

export const clearTransactions = () => {
  return {
    type: type.CLEAR_TRANSACTIONS,
  };
};

export const loadSales = (payload) => {
  return {
    type: type.LOAD_SALES,
    payload: payload,
  };
};

export const changeSalesLoading = (payload) => {
  return {
    type: type.SALES_LOADING,
    payload: payload,
  };
};

export const changeFilter = (payload) => {
  return {
    type: type.UPDATE_FILTER,
    payload: payload,
  };
};
