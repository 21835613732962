import React, { Fragment } from "react";

import Table from "react-bootstrap/Table";

export default function ApprovalItemListComponent(props) {
  return (
    <>
      {ApprovalInfo(props)}
      {TableContent(props)}
    </>
  );
}

const ApprovalInfo = (props) => {
  return (
    <>
      <p>
        <b>Warehouse:</b> {props.approval?.warehouse?.name}
      </p>
      <p>
        <b>Submitted By:</b> {props.approval?.approval?.approver_user?.name}
      </p>
      <p>
        <b>Submitted Date:</b> {props.approval?.approval?.submitted_at}
      </p>
    </>
  );
};

const FooterContent = () => {};

const TableContent = (props) => {
  return (
    <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
      <Table bordered className="m-t-20">
        <tbody>
          {(props.approval?.brands ?? []).map((brand) => {
            return (
              <tr key={brand.id}>
                <td>
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-center">***{brand.name}***</th>
                        <th>Allocation Type</th>
                        <th>Stock</th>
                        <th
                          style={{
                            display: "none",
                          }}
                        >
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {brand.items.data.map((item) => {
                        return (
                          <Fragment key={item.id}>
                            <tr key={item.id}>
                              <td>{item.basic.title}</td>
                              <td>
                                {item.has_variation
                                  ? "-"
                                  : item.allocation_type.name}
                              </td>
                              <td
                                className="text-right"
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#ff8084",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.has_variation ? "-" : item.stock}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  display: "none",
                                }}
                              >
                                {item.has_variation ? (
                                  "-"
                                ) : (
                                  <input
                                    type="checkbox"
                                    name={"item_" + item.id}
                                    onClick={(e) =>
                                      props.changeApprovalCheck(
                                        item,
                                        e.target.checked
                                      )
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                            {item.variation.compose.length > 0 && (
                              <tr key={"compose_" + item.id}>
                                <td colSpan="4">
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th>Variant</th>
                                        <th>Allocation Type</th>
                                        <th className="text-center">Stock</th>
                                        <th
                                          style={{
                                            display: "none",
                                          }}
                                        >
                                          &nbsp;
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.variation.compose.map((compose) => {
                                        return (
                                          <tr key={compose.id}>
                                            <td>{compose.sku}</td>
                                            <td>
                                              {compose.allocation_type.name}
                                            </td>
                                            <td
                                              className="text-right"
                                              style={{
                                                backgroundColor: "#fff",
                                                color: "#ff8084",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {compose.stock}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                display: "none",
                                              }}
                                            >
                                              <input
                                                type="checkbox"
                                                name={
                                                  "item_variant" +
                                                  item.id +
                                                  "_" +
                                                  compose.id
                                                }
                                                onClick={(e) =>
                                                  props.changeApprovalVariantCheck(
                                                    item,
                                                    compose,
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
