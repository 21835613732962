import * as type from "../constants/index";

export const setLoading = (payload) => {
  return { type: type.LOADING, payload: payload };
};

export const callback = (response, callback) => {
  return {
    type: type.CALLBACK,
    payload: { response, callback },
  };
};
