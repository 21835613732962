import { empty } from "common";
import PlayerProp from "./player-prop";

class UpdatePlayerCoordinates {
  constructor(base) {
    this.base = base;
  }

  handle(players) {
    let previous_player = null;
    // console.log(players);
    players = players.map((player) => {
      player.getComponent().changeX(PlayerProp.start_left);

      if (empty(previous_player)) {
        player.getComponent().changeY(PlayerProp.start_top);
      } else {
        player
          .getComponent()
          .changeY(
            previous_player.getComponent().getHeight() +
              previous_player.getComponent().getY() +
              PlayerProp.distance
          );
      }

      player
        .getTickleShowCon()
        .changeY(
          player.getComponent().getY() -
            player.getTickleShowCon().getHeight() / 2
        );

      player
        .getTickleShowCon()
        .changeX(
          player.getComponent().getX() -
            player.getTickleShowCon().getWidth() / 2
        );

      previous_player = player;

      return player;
    });

    return players;
  }
}

export default UpdatePlayerCoordinates;
