import React, { Component,Fragment } from 'react'

import { connect } from "react-redux";
import _debounce from 'lodash.debounce';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import * as BrandAction from "../../redux/actions/index";
import FormComponent from "../views/form";

export class UpdateBrandContainer extends Component {
    state = {
        name: '',
        description: ''
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.brand) !== JSON.stringify(this.props.brand)) {
            this.setState({
                name: this.props.brand.edit?.name,
                description: this.props.brand.edit?.description,
            });
        }

        if (this.props.brand.updated === true) {
            this.props.onCloseModal();
            this.props.setUpdateStatus(false);
        }
    }

    handleSubmit(e) {
        this.props.updateBrand(
            this.props.brandId,
            this.state
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const {
            name,
            description
        } = this.state;
        const {open, onCloseModal, onOpenModal} = this.props;
        const {response, loading} = this.props.brand;

        return (
            <FormComponent
                handleChange={this.handleChange.bind(this)}
                handleSubmit={this.handleSubmit.bind(this)}
                loading={loading}
                response={response}
                {...this.props}
                {...this.state}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        brand: state.brand
    };
}

export default connect(
    mapStateToProps,
    {
        updateBrand: (brandId, data) => BrandAction.updateBrand(brandId, data),
        setUpdateStatus: d => BrandAction.setUpdateStatus(d)
    }
)(UpdateBrandContainer);
