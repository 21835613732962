import { empty } from "common";

import PlayerComponent from "./player";

class Opponent {
  players = [];
  item_value = 1;

  constructor(id, component, info, data, players, tickle_del) {
    this.id = id;
    this.component = component;
    this.info = info;
    this.data = data;
    this.active = true;
    this.tickle_del = tickle_del;
    this.addPlayers(players);
  }

  get() {
    return {
      id: this.getId(),
      info: this.getInfo(),
      data: this.getData(),
      component: this.getComponent(),
      players: this.getPlayers(),
    };
  }

  getBrief() {
    const data = this.getData();
    delete data.links_reference;
    delete data.inventory;
    delete data.variations;

    const info = this.getInfo();
    delete info.links_reference;
    delete info.inventory;
    delete info.variation;

    return {
      data,
      info,
      item_value: this.getNumberOfItem(),
    };
  }

  sameValueAs(opponent) {
    return opponent.getData().id === this.getData().id;
  }

  getId() {
    return this.id;
  }

  getComponent() {
    return this.component;
  }

  getInfo() {
    return this.info;
  }

  getData() {
    return this.data;
  }

  getPlayers() {
    return this.players;
  }

  getTickleDel() {
    return this.tickle_del;
  }

  isActive() {
    return this.active;
  }

  getNumberOfItem() {
    return this.item_value;
  }

  addPlayers(_players) {
    let players =
      !empty(_players) && _players.length && _players.length > 0
        ? _players
        : [_players];
    players = [].concat(...players).filter((o) => {
      if (this.players.filter((o_) => o_.sameValueAs(o)).length > 0) {
        return false;
      }
      return true;
    });
    if (!empty(players)) {
      this.players = this.players.concat(...players);
    }

    this.updateTickleCount();
  }

  detachPlayer(player) {
    if (player instanceof PlayerComponent === false) {
      throw "Not a valid player component";
    }

    this.players = this.getPlayers().filter((p) => !p.sameValueAs(player));

    this.updateTickleCount();
  }

  changeActive(active) {
    this.active = active;
  }

  changeNumberOfItem(value) {
    this.item_value = value;
  }

  updateTickleCount() {
    this.getTickleDel().changeTitle(this.getPlayers().length);
  }

  clone() {
    return new Opponent(
      this.id,
      this.component,
      this.info,
      this.data,
      this.players,
      this.tickle_del
    );
  }
}

export default Opponent;
