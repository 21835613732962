import React, { Fragment } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruckLoading,
  faTrashAlt,
  faArrowRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import _debounce from "lodash.debounce";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const BrandItemsListTopDatatableComponent = (props) => {
  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"Brand"}</b>,
            accessor: "name",
            Cell: (row) =>
              row.original.name + " (" + row.original.items.total + ")",
          },
          {
            Header: <b>{"Action"}</b>,
            filterable: false,
            sortable: false,
            style: {
              textAlign: "right",
            },
            Cell: (row) => (
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  props.addBrandToSelectedTemplate(row.original);
                }}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" />
              </Button>
            ),
          },
        ]}
        defaultSorted={[
          {
            id: "product",
            desc: true,
          },
        ]}
        SubComponent={(brand) => {
          const page_total =
            props.brand_items_list_datatable[brand.original.id]?.data[0]?.items
              ?.total ?? brand.original.items.total;
          return (
            <div style={{ padding: "20px" }}>
              <ReactTable
                data={
                  props.brand_items_list_datatable[brand.original.id]?.data[0]
                    ?.items?.data ?? brand.original.items.data
                }
                pages={Math.ceil(page_total / 20)}
                brandId={brand.original.id}
                onFetchData={_debounce(props.requestBrandItemsList, 300)} // Request new data when things change
                showPaginationTop={true}
                collapseOnDataChange={false}
                showPagination={true}
                defaultPageSize={
                  props.brand_items_list_datatable[brand.original.id]?.data[0]
                    ?.items?.data?.length ?? brand.original.items.data.length
                }
                filterable
                manual
                columns={[
                  {
                    Header: <b>{"Product"}</b>,
                    accessor: "title",
                    filterable: true,
                    style: {
                      textAlign: "left",
                    },
                    Cell: (row) =>
                      "(" +
                      row.original.variation.compose.length +
                      ") " +
                      row.original.basic.title,
                  },
                  {
                    Header: <b>{"Action"}</b>,
                    filterable: false,
                    sortable: false,
                    style: {
                      textAlign: "right",
                    },
                    Cell: (row) => (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          props.addBrandItemToSelectedTemplate(
                            brand.original,
                            row.original
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} size="sm" />
                      </Button>
                    ),
                  },
                ]}
                SubComponent={(item) => {
                  return (
                    item.original.has_variation && (
                      <div style={{ padding: "20px" }}>
                        <ReactTable
                          data={item.original.variation.compose}
                          collapseOnDataChange={false}
                          showPagination={false}
                          defaultPageSize={
                            item.original.variation.compose.length
                          }
                          columns={[
                            {
                              Header: <b>{"Variant"}</b>,
                              accessor: "sku",
                              filterable: false,
                              style: {
                                textAlign: "left",
                              },
                            },
                            {
                              Header: <b>{"Action"}</b>,
                              filterable: false,
                              sortable: false,
                              style: {
                                textAlign: "right",
                              },
                              Cell: (row) => (
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    props.addBrandItemVariantToSelectedTemplate(
                                      brand.original,
                                      item.original,
                                      row.original
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlus} size="sm" />
                                </Button>
                              ),
                            },
                          ]}
                        />
                      </div>
                    )
                  );
                }}
              />
            </div>
          );
        }}
        collapseOnDataChange={false}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={props.brand_list_datatable.data}
        pages={props.brand_list_datatable.pages} // Display the total number of pages
        loading={props.brand_list_loading} // Display the loading overlay when we need it
        filterable
        onFetchData={_debounce(props.requestBrandList, 300)} // Request new data when things change
        defaultPageSize={20}
        className="-striped -highlight"
      />
    </div>
  );
};

export default BrandItemsListTopDatatableComponent;
