import LineComponent from "../../gameboard/line-component";

export default class Character {
  constructor(base, opponent) {
    this.base = base;
    this.opponent = opponent;
  }

  attachPlayers(_players) {
    const players = _players.length > 0 ? _players : [_players];
    this.opponent.addPlayers(players);

    this.opponent.getComponent().connected(true);

    return this.opponent;
  }

  connectPlayer(player) {
    this.attachPlayers(player);

    return this.opponent;
  }

  detachPlayer(player) {
    this.opponent.detachPlayer(player);

    return this.opponent;
  }

  changeNumberOfItem(value) {
    this.opponent.changeNumberOfItem(value);

    return this.opponent;
  }
}
