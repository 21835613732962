import React, { Component } from "react";

import { empty } from "common";
import { connect } from "react-redux";
import * as toast from "components/common/toast";
import {
  media as MediaAction,
  variation,
  wholesale,
  destroy,
} from "../../redux/actions";

import MediaView from "../views/media";

class MediaContainer extends Component {
  constructor(props) {
    super(props);
    this.file = [];
    this.handleClickBrowseEventListener = this.handleClickBrowseEventListener.bind(
      this
    );
    this.handleFileBrowseEventListener = this.handleFileBrowseEventListener.bind(
      this
    );
  }

  isKeyAdefaultKey = (key) => {
    return (
      this.props.settings.default_keys.filter((d) => d.key === key).length > 0
    );
  };

  getImage = (key) => {
    if (this.isKeyAdefaultKey(key)) {
      return this.props.media.data.filter((d) => d.key == key)[0]?.url ?? "";
    } else {
      return (
        this.props.variation.compose.filter((row) => row.id === key)[0]?.data
          ?.image?.url ?? ""
      );
    }
  };

  handleOutputImage = (key) => {
    const image = this.getImage(key);
    if (!empty(image)) {
      return <img src={this.getImage(key)} />;
    }
  };

  addFileRef = (key, ref) => {
    if (this.file.filter((f) => f.key === key).length <= 0) {
      this.file.push({ key, ref });
    }
  };

  handleFileBrowseEventListener = (key) => {
    this.props.addOrUpdateImage({
      key,
      image: this.file.filter((d) => d.key === key)[0].ref.files[0],
    });
  };

  handleClickBrowseEventListener = (key) => {
    this.file.filter((d) => d.key === key)[0].ref.click();
  };

  render() {
    const {
      variation: { compose, error, enabled },
      media,
      removeImage,
    } = this.props;
    return (
      <MediaView
        error={error}
        enabled={enabled}
        media={media}
        getImage={this.getImage}
        addFileRef={this.addFileRef}
        compose={compose}
        removeImage={removeImage}
        handleOutputImage={this.handleOutputImage}
        handleFileBrowseEventListener={this.handleFileBrowseEventListener}
        handleClickBrowseEventListener={this.handleClickBrowseEventListener}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      media: state.productReducer.media,
      variation: state.productReducer.variation,
      settings: state.productReducer.settings,
      labels: state.productReducer.labels,
    };
  },
  {
    addOrUpdateImage: MediaAction.addOrUpdateImage,
    removeImage: MediaAction.removeImage,
    clearImages: MediaAction.clear,
    clearWholesale: wholesale.clear,
    destroy: destroy,
  }
)(MediaContainer);
