import * as type from "redux/constants/common";

const initialState = {
  data: [],
  loading: false,
  response: [],
  row: {
    first_name: "",
    last_name: "",
    role: "",
    email: "",
    username: "",
  },
};

export default function Common(state = initialState, action) {
  switch (action.type) {
    case type.FETCH_GLOBAL_CONFIG:
      state = Object.assign({}, state, {
        data: action.payload,
      });
      break;
  }

  return state;
}
