import {
  empty,
  emptyNumeric,
  isNumber,
  numberOnly,
  isNumberPositive,
} from "common";
import * as common from "../actions/wholesale/common";
import { List } from "immutable";

const getInvalidColumns = (store, tier) => {
  const error = [];
  if (parseInt(tier.min_stocks) > parseInt(tier.max_stocks)) {
    error.push({
      s: tier.s,
      column: "min_stocks",
      message: store.getState().productReducer.labels.error.wholesale
        .invalid_range,
    });
  }
  if (emptyNumeric(tier.min_stocks) || tier.min_stocks <= 0) {
    error.push({
      s: tier.s,
      column: "min_stocks",
      message: store.getState().productReducer.labels.error.wholesale
        .invalid_stocks_value,
    });
  }
  if (
    tier.max_stocks !=
      store.getState().productReducer.labels.error.wholesale.max &&
    (emptyNumeric(tier.max_stocks) || tier.max_stocks <= 0)
  ) {
    error.push({
      s: tier.s,
      column: "max_stocks",
      message: store.getState().productReducer.labels.error.wholesale
        .invalid_stocks_value,
    });
  }
  if (emptyNumeric(tier.price) || tier.price <= 0) {
    error.push({
      s: tier.s,
      column: "price",
      message: store.getState().productReducer.labels.error.wholesale
        .invalid_price,
    });
  } else {
    if (!isNumberPositive(tier.price)) {
      error.push({
        column: "price",
        message: store.getState().productReducer.labels.error.wholesale
          .invalid_price,
      });
    }
  }
  /*if (emptyNumeric(tier.unit_cost) || tier.unit_cost <= 0) {
    error.push({
      s: tier.s,
      column: "unit_cost",
      message: store.getState().product.labels.error.wholesale
        .invalid_unit_cost,
    });
  } else {
    if (!isNumberPositive(tier.unit_cost)) {
      error.push({
        column: "unit_cost",
        message: store.getState().product.labels.error.wholesale
          .invalid_unit_cost,
      });
    }
  }
  */
  return error;
};

const rangeError = (store, prevMaxStocks, prevPrice, tier) => {
  const error = [];
  const indexOf = store.getState().productReducer.wholesale.data.indexOf(tier);

  if (parseInt(prevMaxStocks) >= parseInt(tier.min_stocks)) {
    error.push({
      s: tier.s,
      column: "min_stocks",
      message: store.getState().productReducer.labels.error.wholesale
        .invalid_range,
    });
  }
  if (parseFloat(prevPrice) <= parseFloat(tier.price) && indexOf > 0) {
    error.push({
      s: tier.s,
      column: "price",
      message: store.getState().productReducer.labels.error.wholesale
        .tier_price_cheaper_invalid,
    });
  }

  if (tier.min_stocks === tier.max_stocks) {
    error.push({
      s: tier.s,
      column: "max_stocks",
      message: store.getState().productReducer.labels.error.wholesale
        .invalid_range,
    });
  }

  return error;
};

const tierQuantityRangeError = (store, tier) => {
  let data = List(store.getState().productReducer.wholesale.data).toJS();
  let index = data.findIndex((i) => i.id === tier.id);
  let prevMaxStocks = data[index - 1]?.max_stocks ?? 0;
  let prevPrice = data[index - 1]?.price ?? 0;

  return rangeError(store, prevMaxStocks, prevPrice, tier);
};

const removeNonNumericValue = (store, tier) => {
  if (
    tier.max_stocks !== store.getState().productReducer.fields.wholesale.max
  ) {
    tier.max_stocks = numberOnly(tier.max_stocks);
  }
  tier.min_stocks = numberOnly(tier.min_stocks);

  return tier;
};

const getError = (store) => {
  return store
    .getState()
    .productReducer.wholesale.data.reduce((error, tier) => {
      const invalidColumns = getInvalidColumns(store, tier);
      if (invalidColumns.length > 0) {
        error.push(invalidColumns);
      }

      const rangeError = tierQuantityRangeError(store, tier);
      if (rangeError.length > 0) {
        error.push(rangeError);
      }

      const nonNumericValue = removeNonNumericValue(store, tier);
      if (nonNumericValue.length > 0) {
        error.push(nonNumericValue);
      }

      return error;
    }, []);
};

export const dispatchErrorIfFound = (store) => {
  const error = getError(store);
  if (error.length > 0 && error[0].length > 0) {
    store.dispatch(common.loadErrorInfo(error[0]));
    return true;
  }
  store.dispatch(common.loadErrorInfo([]));
  return false;
};

export const addTier = (next, store, action) => {
  if (dispatchErrorIfFound(store)) {
    return false;
  }
  next(action);
};

export const updateTierColumn = (next, store, action) => {
  next(action);
  dispatchErrorIfFound(store);
};

export const removeTier = (next, store, action) => {
  const toBeRemoveTier = store
    .getState()
    .productReducer.wholesale.data.filter(
      (tier) =>
        tier.id == action.payload.id &&
        tier.max_stocks === store.getState().productReducer.fields.wholesale.max
    );

  next(action);
  // run error manipulator
  if (toBeRemoveTier.length > 0) {
    dispatchErrorIfFound(store);
  }
};

export const getWholesaleError = (store) => {
  const error = getError(store);
  if (error.length > 0 && error[0].length > 0) {
    return error[0];
  }
  return [];
};
