import {
  Home,
  Box,
  DollarSign,
  Tag,
  Clipboard,
  Camera,
  AlignLeft,
  UserPlus,
  Users,
  Chrome,
  BarChart,
  Settings,
  Archive,
  LogIn,
} from "react-feather";

import uuid from "react-uuid";

const SID = uuid();

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
    page: "dashboard page",
  },
  {
    title: "Approval",
    icon: UserPlus,
    type: "sub",
    active: false,
    page: "approval-page",
    children: [
      {
        path: "/approval/stock-updates/list",
        title: "Stock Updates",
        type: "link",
        page: "stock-updates-page",
      },
    ],
  },
  {
    title: "Products",
    icon: Box,
    type: "sub",
    active: false,
    page: "product page",
    children: [
      {
        path: "/products/category",
        title: "Category",
        type: "link",
        page: "category page",
      },
      {
        path: "/products/brand",
        title: "Brand",
        type: "link",
        page: "brand page",
      },
      {
        title: "Product",
        type: "sub",
        active: false,
        page: "product page",
        children: [
          {
            path: "/products/product/product-list",
            title: "Product List",
            type: "link",
            page: "product page",
          },
          {
            path: "/products/product/add-product",
            title: "Add Product",
            type: "link",
            page: "product page",
          },
          {
            path: "/products/product/reorder-level",
            title: "Re-order Level",
            type: "link",
            page: "product page",
          },
          {
            path: "/products/product/order-forecast",
            title: "Order Forecast",
            type: "link",
            page: "product page",
          },
        ],
      },
    ],
  },
  {
    title: "Purchases",
    icon: UserPlus,
    type: "sub",
    active: false,
    page: "purchase page",
    children: [
      {
        path: "/purchase/create-purchase-order",
        title: "Add Order",
        type: "link",
        page: "purchase page",
      },
      {
        path: "/purchase/list-purchase-orders",
        title: "Order List",
        type: "link",
        page: "receive order page",
      },
      {
        path: "/purchase/list-received-orders",
        title: "Delivered Orders",
        type: "link",
        page: "receive order page",
      },
    ],
  },
  {
    path: "/inventory",
    title: "Inventory",
    icon: Home,
    type: "sub",
    active: false,
    page: "inventory page",
    children: [
      {
        path: "/inventory/live-monitoring",
        title: "Live Monitoring",
        type: "link",
        page: "inventory page",
      },
      // {
      //   path: "/inventory/allocation",
      //   title: "Allocation",
      //   type: "link",
      //   page: "inventory page",
      // },
      // {
      //   path: "/inventory/modify-stock",
      //   title: "Modify Stock",
      //   type: "link",
      //   page: "inventory page",
      // },
      {
        path: "/inventory/modify-stock-v2",
        title: "Modify Stock",
        type: "link",
        page: "inventory page",
      },
    ],
  },
  {
    title: "Orders",
    icon: DollarSign,
    type: "sub",
    active: false,
    page: "sales page",
    children: [
      // {
      //   path: "/sales/orders",
      //   title: "Orders",
      //   type: "link",
      //   page: "sales page",
      // },
      // {
      //   path: "/sales/upload",
      //   title: "Upload Orders",
      //   type: "link",
      //   page: "sales page",
      // },
      {
        path: "/orders/in-store/list",
        title: "In Store",
        type: "link",
        page: "ecommerce page",
      },
      {
        path: "/orders/commerce",
        title: "commerce",
        type: "link",
        page: "ecommerce page",
      },
      {
        path: "/orders/export",
        title: "Export",
        type: "link",
        page: "export page",
      },
    ],
  },
  // {
  //   title: "Invoice",
  //   path: "/invoice",
  //   icon: Archive,
  //   type: "link",
  //   active: false,
  // },
  // {
  //   title: "Coupons",
  //   icon: Tag,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/coupons/list-coupons", title: "List Coupons", type: "link" },
  //     {
  //       path: "/coupons/create-coupons",
  //       title: "Create Coupons",
  //       type: "link",
  //     },
  //   ],
  // },
  /* {
        title: 'Pages', icon: Clipboard , type: 'sub', active: false, children: [
            { path: '/pages/list-page', title: 'List Page', type: 'link' },
            { path: '/pages/create-page', title: 'Create Page', type: 'link' },
        ]
    },*/
  // {
  //   title: "Media",
  //   path: "/media",
  //   icon: Camera,
  //   type: "link",
  //   active: false,
  // },
  /* {
        title: 'Menus', icon: AlignLeft, type: 'sub', active: false, children: [
            { path: '/menus/list-menu', title: 'List Menu', type: 'link' },
            { path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
        ]
    },*/

  {
    title: "Suppliers",
    icon: UserPlus,
    type: "sub",
    active: false,
    page: "supplier page",
    children: [
      {
        path: "/suppliers/create-supplier",
        title: "Add Supplier",
        type: "link",
        page: "supplier page",
      },
      {
        path: "/suppliers/list-supplier",
        title: "List",
        type: "link",
        page: "supplier page",
      },
    ],
  },
  {
    title: "Users",
    icon: UserPlus,
    type: "sub",
    active: false,
    page: "user page",
    children: [
      {
        path: "/users/list-user",
        title: "User List",
        type: "link",
        page: "user page",
      },
      {
        path: "/users/create-user",
        title: "Add User",
        type: "link",
        page: "user page",
      },
      {
        path: "/users/permission",
        title: "Permission",
        type: "link",
        page: "user page",
      },
    ],
  },
  /*{
        title: 'Vendors', icon: Users, type: 'sub', active: false, children: [
            { path: '/vendors/list_vendors', title: 'Vendor List', type: 'link' },
            { path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
        ]
    },*/
  // {
  //   title: "Localization",
  //   icon: Chrome,
  //   type: "sub",
  //   children: [
  //     {
  //       path: "/localization/transactions",
  //       title: "Translations",
  //       type: "link",
  //     },
  //     {
  //       path: "/localization/currency-rates",
  //       title: "Currency Rates",
  //       type: "link",
  //     },
  //     { path: "/localization/taxes", title: "Taxes", type: "link" },
  //   ],
  // },
  {
    title: "Reports",
    path: "/reports/report",
    icon: BarChart,
    type: "link",
    active: false,
    page: "reports page",
  },
  {
    title: "Settings",
    icon: Settings,
    type: "sub",
    page: "settings page",
    children: [
      {
        path: "/settings/profile",
        title: "Profile",
        type: "link",
        page: "settings page",
      },
      {
        path: "/settings/shop",
        title: "Shop",
        type: "link",
        page: "settings page",
      },
    ],
  },
  /*{
        title: 'Login',path:'/auth/login', icon: LogIn, type: 'link', active: false
    }*/
];
