import * as type from "../constants/index";

export const setLoading = (payload) => {
  return { type: type.LOADING, payload: payload };
};

export const setResponse = (payload) => {
  return { type: type.RESPONSE, payload: payload };
};

export const loadProductList = (payload) => {
  return {
    type: type.LOAD_PRODUCT_LIST,
    payload: payload,
  };
};

export const loadProduct = (payload) => {
  return {
    type: type.LOAD_PRODUCT,
    payload: payload,
  };
};

export const setUpdateStatus = (payload) => {
  return {
    type: type.UPDATE_STATUS,
    payload: payload,
  };
};

export const destroy = (payload) => {
  return {
    type: type.DESTROY,
  };
};

export const updateUnitCost = (id, itemId, variationId, unitCost) => {
  return {
    type: type.UPDATE_UNIT_COST,
    payload: {
      id,
      unit_cost: unitCost,
      item_id: itemId,
      variation_id: variationId,
    },
  };
};

export const updateUnitCostStatus = (id, itemId, variationId, status) => {
  return {
    type: type.UPDATE_UNIT_COST_STATUS,
    payload: { id, status: status, item_id: itemId, variation_id: variationId },
  };
};

export const updateTableRowCellLoading = (
  id,
  itemId,
  variationId,
  column,
  status
) => {
  return {
    type: type.UPDATE_TABLE_ROW_CELL_LOADING,
    payload: { id, item_id: itemId, variation_id: variationId, column, status },
  };
};
