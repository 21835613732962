import React, { Component, Fragment } from "react";

import Emitter from "common/event-emitter";
import { empty } from "common";
import * as toast from "components/common/toast";
import { connect } from "react-redux";
import * as Action from "../../redux/actions/index";
import * as Http from "../../redux/actions/http";
import * as Payload from "../../redux/actions/payload";

import DashboardComponent from "../views//dashboard";

class DashboardContainer extends Component {
  state = {
    template_id: null,
    template_name: "",
    templates: [],
    warehouse: null,
    warehouses: [],
    filter: "ALL_TO_SHIP",
    request: false,
  };

  constructor() {
    super();
  }

  updateState(state) {
    this.setState(state);
  }

  getShop(shop_id) {
    return this.props.dashboardReducer.shops.filter(
      (shop) => shop.id == shop_id
    )[0];
  }

  sendRequestDataTable(status) {
    this.updateState({
      request: !this.state.request,
    });
  }

  componentDidMount() {
    this.sendRequestDataTable(true);
    this.props.requestAllCommon().then((response) => {
      this.props.loadShopList(response.data.shops);
    });
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  handleSelectShop(shop_id) {
    this.props.changeShop(this.getShop(shop_id));
    setTimeout(() => {
      this.sendRequestDataTable(true);
    }, 2000);
  }

  handleRefresh() {
    this.sendRequestDataTable(true);
  }

  handleFilter(code) {
    this.updateState({
      filter: code,
    });
    this.handleRefresh();
  }

  render() {
    return (
      <Fragment>
        <DashboardComponent
          {...this.state}
          dashboard_reducer={this.props.dashboardReducer}
          handleSelectShop={this.handleSelectShop.bind(this)}
          handleFilter={this.handleFilter.bind(this)}
          updateState={this.updateState.bind(this)}
          handleRefresh={this.handleRefresh.bind(this)}
        />
      </Fragment>
    );
  }
}

export default connect(
  (state) => {
    return {
      dashboardReducer: state.dashboardReducer,
    };
  },
  {
    changeShop: Payload.changeShop,
    loadShopList: Payload.loadShopList,
    destroy: Payload.destroy,
    requestAllCommon: Http.requestAllCommon,
  }
)(DashboardContainer);
