import { getDefaultVariant } from "./common";
import UpdateComposer from "./update-composer";

const AddVariant = (state, action) => {
  state = {
    ...state,
    variation: {
      ...state.variation,
      data: state.variation.data.concat(getDefaultVariant()),
    },
  };

  return UpdateComposer(state);
};

export default AddVariant;
