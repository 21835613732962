import Http from "api/http";
import { setResponse, setLoading } from "../actions/payload";
import SaveProduct from "../actions/save-product";
import { dispatchErrorIfFound } from "./wholesale";
import { dispatchComposerCellValueError } from "./variation";
import {
  VaBasicFormValid,
  VaShippingFormValid,
  VaSellingFormValid,
} from "../../components/service/va-form";
import { empty } from "common";
import { List } from "immutable";

const validator = (store, action) => {
  const error = [];
  const product = store.getState().productReducer;
  const e = product.labels.error;

  // Basic Form
  if (!VaBasicFormValid()) {
    error.push(e.validation.invalid_basic);
  }

  // Selling Form
  if (
    !VaSellingFormValid() &&
    product.variation.enabled === false &&
    product.variation.enabled === false
  ) {
    error.push(e.validation.invalid_selling);
  }
  // Brand
  if (empty(product.form.brand?.id)) {
    error.push(e.validation.invalid_brand);
  }

  // Brand
  if (empty(product.form.shop?.id)) {
    error.push(e.validation.invalid_shop);
  }

  // Category
  if (
    empty(store.getState().productReducer.category.confirm) ||
    store.getState().productReducer.category.confirmed === false
  ) {
    error.push(e.validation.invalid_category);
  }

  // Variation
  if (product.variation.enabled === true) {
    if (product.variation.compose.length <= 0) {
      error.push(e.validation.invalid_variation_empty);
    } else {
      dispatchComposerCellValueError(store);
      // Check compose errors
      const composer_error = product.variation.compose.reduce((c, r) => {
        if (r.errors.variation.length > 0) {
          c = true;
        }
        return c;
      }, false);
      if (composer_error) {
        error.push(e.validation.invalid_variation);
      }

      // Check variation data error
      const variant_data_error = product.variation.data.filter(
        (d) => d.error !== undefined && d.error != ""
      );
      if (variant_data_error.length > 0) {
        error.push(e.validation.invalid_variation_title);
      }

      // Check variation options error
      const variant_options_error = product.variation.data.reduce((c, r) => {
        if (
          r.sku_data.filter((d) => d.error !== undefined && d.error != "")
            .length > 0
        ) {
          c = true;
        }
        return c;
      }, false);
      if (variant_options_error) {
        error.push(e.validation.invalid_variation_options);
      }
    }
  }

  // Wholesale
  if (product.wholesale.enabled === true) {
    if (product.wholesale.data.length <= 0) {
      error.push(e.validation.invalid_wholesale_empty);
    } else {
      if (product.wholesale.error.length > 0) {
        error.push(e.validation.invalid_wholesale);
      } else {
        const wh_e = dispatchErrorIfFound(store);
        if (wh_e === true) {
          error.push(e.validation.invalid_wholesale);
        }
      }
    }
  }

  // Description
  if (empty(product.form.details?.short_desc)) {
    error.push(e.validation.invalid_details_short);
  }
  if (empty(product.form.details?.detailed)) {
    error.push(e.validation.invalid_details_detailed);
  }

  // Media
  if (
    product.media.data.filter(
      (m) => m.key === product.media.primaryKey && empty(m.url)
    ).length > 0
  ) {
    error.push(e.validation.invalid_media_primary);
  }

  // Shipping info Form
  if (!VaShippingFormValid()) {
    error.push(e.validation.invalid_shipping);
  }

  // Campaign form
  if (product.variation.enabled === false) {
    if (product.campaign.error.length > 0) {
      error.push(e.validation.invalid_campaign);
    }
  } else {
    const campaign = product.variation.compose.reduce((c, r) => {
      if (r.errors.campaign.length > 0) {
        c.push(r.errors.campaign);
      }
      return c;
    }, []);
    if (campaign.length > 0) {
      error.push(e.validation.invalid_campaign);
    }
  }

  return error;
};

export const submit = (next, store, action) => {
  const error = validator(store, action);
  const product = store.getState().productReducer;

  next(
    setResponse({
      error: error.length > 0 ? true : false,
      message: error.length > 0 ? error : [],
    })
  );

  if (error.length > 0) {
    window.scroll({
      behavior: "smooth",
      top: 0,
    });

    return;
  }

  store.dispatch(
    SaveProduct(store, {
      status: "publish",
      basic: product.form,
      details: {
        short_desc: product.form.details.short_desc,
        detailed: product.form.details.detailed,
      },
      category: product.category.confirm,
      has_variation: product.variation.enabled,
      has_wholesale: product.wholesale.enabled,
      variation: {
        variants: product.variation.data,
        compose: List(product.variation.compose)
          .toJS()
          .map((c) => {
            const data = c.data;
            delete data.image;
            return {
              id: c.id,
              ...data,
            };
          }),
      },
      wholesale: product.wholesale.data,
      shipping: product.form.shipping,
      campaign: product.campaign,
      seo: product.form.seo,
      media: {
        variation: List(product.variation.compose)
          .toJS()
          .reduce((c, i) => {
            if (!empty(i.data.image.url)) {
              c.push({
                id: i.data.image.id,
                key: i.id,
                hash: i.data.image.hash,
              });
            }
            return c;
          }, []),
        main: product.media.data.reduce((c, i) => {
          if (empty(i.url) !== true) {
            c.push({
              id: i.id,
              key: i.key,
              hash: i.hash,
            });
          }
          return c;
        }, []),
      },
    })
  );

  return;
};
