import React from "react";

export default class Combinator {
  checked = [];
  backup_all_data = [];
  all_data = [];
  _counter = 0;

  contructor(all_data) {
    this.all_data = all_data;
  }

  update(data) {
    this.all_data = data;
    return this.arrangeSequence();
  }

  get() {
    return this.all_data;
  }

  storeChecked() {
    this.checked = this.getChecked();
  }

  getChecked() {
    return this.all_data.filter((f) => (f?.checked ?? false) == true);
  }

  getUnChecked() {
    return this.all_data.filter((f) => (f?.checked ?? false) == false);
  }

  showAll() {
    return this.update(this.all_data);
  }

  showChecked(data) {
    this.update(data);
    return this.getChecked();
  }

  arrangeSequence() {
    const check_data = this.all_data.filter(
      (f) => (f?.checked ?? false) == true
    );
    const uncheck_data = this.all_data.filter(
      (f) => (f?.checked ?? false) == false
    );

    return check_data.concat(uncheck_data);
  }

  mergeUpdate(current_data) {
    this.all_data = this.all_data.map((d) => {
      const index = current_data.findIndex(
        (f) => f.id == d.id && f.variant_id == d.variant_id
      );

      if (index != -1) {
        d = current_data[index];
      }

      return d;
    });

    return this.all_data;
  }

  append(new_data) {
    // Append database records checked data to store checked data
    new_data.map((d) => {
      const index = this.all_data.findIndex(
        (f) => f.id == d.id && f.variant_id == d.variant_id
      );

      if (index === -1) {
        this.all_data = this.all_data.concat(d);
      }
    });

    return this.arrangeSequence();
  }

  merge(new_data) {
    this.storeChecked();

    const check_new_data = new_data.filter(
      (f) => (f?.checked ?? false) == true
    );
    const uncheck_new_data = new_data.filter(
      (f) => (f?.checked ?? false) == false
    );

    // Merge database records checked data to store checked data
    check_new_data.map((d) => {
      const index = this.checked.findIndex(
        (f) => f.id == d.id && f.variant_id == d.variant_id
      );

      if (index === -1) {
        this.checked = this.checked.concat(d);
      }
    });

    return this.checked.concat(uncheck_new_data);
  }
}
