import * as type from "redux/constants/user/permission/role";

export const setLoading = (payload) => {
    return { type: type.LOADING, payload: payload};
}

export const setResponse = (payload) => {
    return { type: type.RESPONSE, payload: payload };
}

export const setFormStatus = (payload) => {
	return {type: type.FORM_STATUS, payload: payload }
}

export const fetch = (payload) => {
	return {
		type: type.FETCH_ALL,
		payload: payload
	};
}