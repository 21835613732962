import Http from "api/http";
import * as payload from "./payload";
import * as toast from "components/common/toast";

export const requestGetShops = () => (dispatch) => {
  return Http.POST("product/association/getAllShop", {
    payload: { filtered: [] },
    per_page: 100,
    page: 1,
  });
};

export const requestGetProduct = (product_id) => (dispatch) => {
  return Http.GET("product/association/getProduct/" + product_id);
};

interface con {
  player: Object;
  opponent: Object;
}
export const requestStoreConnection = (data: con, callback) => (dispatch) => {
  return Http.POST("product/association/store", data)
    .then(function (response) {
      dispatch(payload.playerConnectingResult(callback, response.data));
    })
    .catch(function (error) {
      dispatch(callback());
      console.log(error.message);
    });
};

export const requestDeleteConnection = (data: con, callback) => (dispatch) => {
  return Http.POST("product/association/delete", data)
    .then(function (response) {
      dispatch(payload.playerDisConnectingResult(callback, response.data));
    })
    .catch(function (error) {
      toast.error(error.message);
    });
};
