import React, { Component, Fragment, useRef } from "react";
import Modal from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruckLoading,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { empty } from "common";

export default class TickleOpponentModalComponent extends Component {
  render() {
    const {
      tickle,
      onTickleCloseOpponentModal,
      handleDetachPlayer,
    } = this.props;
    if (empty(tickle?.opponent?.opponent)) {
      return "";
    }
    console.log(tickle);
    const style = {
      closeButton: {
        top: "-14px",
        right: "-8px",
      },
      closeIcon: {
        color: "#fff",
        fontSize: "20px",
      },
      modalContainer: {
        width: "400px",
      },
    };

    return (
      <Fragment>
        <Modal
          open={tickle.opponent.popupBox}
          onClose={onTickleCloseOpponentModal}
          closeIcon={FontAwesomeIcon}
          classNames={{
            modal: "tickle_modal",
          }}
          styles={style}
          center
        >
          <div className="modal-header">
            <div className="modal-title">
              <h5 className=" f-w-600">
                {tickle.opponent.opponent.getData().name} <br />
              </h5>
              <small>
                {tickle.opponent.opponent.getInfo().data.item_name}
                <br />
                {tickle.opponent.opponent.getInfo().data.shop.name}(
                {tickle.opponent.opponent.getInfo().data.shop.app})
              </small>
            </div>
          </div>
          <table
            className="table table-bordered table-hover"
            style={{ marginTop: "20px" }}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th style={{ textAlign: "center" }}>Number of Items</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tickle.opponent.opponent.getPlayers().map((player, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {player.getData().sku} <br />
                      <small>
                        <em>
                          {player.getInfo().basic.title} <br />#
                          {player.getData().id} <br />
                          {player.getInfo().basic.shop.name} (
                          {player.getInfo().basic.shop.app})
                        </em>
                      </small>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {player.getOpponents().reduce((c, o) => {
                        if (o.opponent.sameValueAs(tickle.opponent.opponent)) {
                          c = o.opponent.getNumberOfItem();
                        }
                        return c;
                      }, 1)}
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon={faTimes}
                        size="lg"
                        className="cursor-pointer"
                        onClick={(e) => {
                          handleDetachPlayer(tickle.opponent.opponent, player);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal>
      </Fragment>
    );
  }
}
