import CreatePlayers from "./create-player";
import CreateLinkOpponents from "./create-link-opponents";
import UpdatePlayerCoordinates from "./update-coordinates";
import ListPlayers from "./list-players";
import Pagination from "./pagination";
import Character from "./character";
import IsPointerClass from "../pointer";
import SharedClass from "../shared";

const Player = (base) => {
  const updateCoordinates = () => {
    ListPlayers(base)
      .allActive()
      .then((players) => {
        updatePlayers(new UpdatePlayerCoordinates(base).handle(players));
      });
  };

  const activate = () => {
    ListPlayers(base)
      .allActive()
      .then((players) => {
        players.forEach((player) => {
          player.getComponent().update();
          player.getTickleShowCon().update();
          player.getLines().forEach((line) => {
            line.update();
          });
          player.getOpponents().forEach((file) => {
            const Shared = new SharedClass(base);
            Shared.updatePlayerLineCoordinates(
              player,
              file.line,
              file.opponent
            );
          });
        });
      });
  };

  const updateState = (players) => {
    base.updateBaseState((state) => {
      const buttons = new Pagination(base).create();
      return {
        ...state,
        players: players,
        paginations: {
          ...state.paginations,
          players: {
            ...state.paginations.players,
            previous: buttons[0],
            next: buttons[2],
            label: buttons[1],
            page: 0,
          },
        },
      };
    });
  };

  const updatePlayers = (players) => {
    base.updateBaseState((state) => {
      return {
        ...state,
        players: state.players.map((player) => {
          player = players.reduce((c, p) => {
            if (p.sameValueAs(player)) {
              c = p;
            }
            return c;
          }, player);

          return player;
        }),
      };
    });
  };

  const updateLine = async (x, y) => {
    const IsPointer = new IsPointerClass();
    IsPointer.setCoordinates(x, y);
    return await new Promise((resolve, reject) => {
      ListPlayers(base)
        .allActive()
        .then((players) => {
          resolve(
            players.map((player) => {
              if (player.hasOpenLines()) {
                player = character(player).updateLine(
                  IsPointer.getX(),
                  IsPointer.getY()
                );
              }

              return player;
            })
          );
        });
    });
  };

  const createLine = (player, x, y) => {
    return character(player).createLine(x, y);
  };

  const detachOpponent = (player, opponent) => {
    player = character(player).detachOpponent(opponent);

    updatePlayers([player]);
  };

  const createPlayers = (players) => {
    updateState(new CreatePlayers(base).handle(players));
    new CreateLinkOpponents(base).handle(players).then((players) => {
      updatePlayers(players);
      setTimeout(() => {
        base.gameboardUpdater();
      }, 20);
    });
  };

  const changeOpponentNumberOfItem = (player, opponent, value) => {
    player = character(player).changeOpponentNumberOfItem(opponent, value);

    updatePlayers([player]);
  };

  const character = (player) => {
    return new Character(base, player);
  };

  return {
    listPlayers: ListPlayers(base),
    pagination: new Pagination(base),
    detachOpponent,
    character,
    createPlayers,
    updatePlayers,
    updateLine,
    createLine,
    updateCoordinates,
    activate,
    changeOpponentNumberOfItem,
  };
};

export default Player;
