export const removeSelectedTemplateBrand = (state, action) => {
  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: state.template_selected.brands.filter(
        (brand) => brand.id != action.payload
      ),
    },
  };
};

export const removeSelectedTemplateBrandItem = (state, action) => {
  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: state.template_selected.brands.map((brand) => {
        if (brand.id == action.payload.brand_id) {
          brand.items.data = brand.items.data.filter(
            (item) => item.id != action.payload.item_id
          );
        }
        return brand;
      }),
    },
  };
};

export const removeSelectedTemplateBrandItemVariation = (state, action) => {
  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: state.template_selected.brands.map((brand) => {
        if (brand.id == action.payload.brand_id) {
          brand.items.data = brand.items.data.map((item) => {
            if (item.id == action.payload.item_id) {
              item.variation.compose = item.variation.compose.filter(
                (compose) => compose.id != action.payload.variant_id
              );
            }
            return item;
          });
        }
        return brand;
      }),
    },
  };
};
