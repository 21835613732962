import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";

import { Link } from "react-router-dom";
import ListOrderDatatableContainer from "../container/list-order-datatable";

class ManualOrderListContainer extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Order List" parent="Orders" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header text-right">
              <Link
                to="/orders/in-store/create-order"
                className="btn btn-primary"
              >
                Create Order
              </Link>
            </div>
            <div className="card-body">
              <div className="clearfix"></div>
              <div
                id="batchDelete"
                className="category-table user-list order-table coupon-list-delete"
              >
                <ListOrderDatatableContainer />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ManualOrderListContainer;
