import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import Emitter from "common/event-emitter";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import SummaryWidgetContainer from "../container/summary-widget";
import ListSalesDatatableContainer from "../container/daily-sales-datatable";
import FilterComponent from "./filter";

class DashboardComponent extends Component {
  state = {};

  render() {
    return (
      <Fragment>
        <Breadcrumb
          title="Dashboard"
          element={
            <>
              {" "}
              <Row>
                <Col xs={{ span: 5, offset: 7 }}>
                  <InputGroup>
                    <Form.Control
                      onChange={(e) => {
                        this.props.handleSelectShop(e.target.value);
                      }}
                      as="select"
                      value={
                        this.props.dashboard_reducer?.shop_selected?.id ?? 0
                      }
                    >
                      <option value="">Select Shop</option>
                      {(this.props.dashboard_reducer?.shops ?? []).map(
                        (shop) => {
                          return (
                            <option key={shop.id} value={shop.id}>
                              {shop.name}
                            </option>
                          );
                        }
                      )}
                    </Form.Control>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        onClick={(e) => {
                          this.props.handleRefresh();
                        }}
                      >
                        <FontAwesomeIcon icon={faSpinner} size="sm" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                </Col>
              </Row>
            </>
          }
        />
        <div className="container-fluid">
          <SummaryWidgetContainer {...this.props} />
          <FilterComponent {...this.props} />
          <ListSalesDatatableContainer {...this.props} />
        </div>
      </Fragment>
    );
  }
}

export default DashboardComponent;
