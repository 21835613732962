import Http from "api/http";
import * as payload from "redux/actions/user/user/payload";
import * as common from "redux/actions/user/user/common";

export const getAllUserRequest = (filters, per_page, page) => (dispatch) => {
  dispatch(payload.setLoading(true));
  return Http.POST("user/getAll", { payload: filters, per_page, page })
    .then(function (response) {
      dispatch(payload.fetchAll(response.data));
      dispatch(payload.setLoading(false));
    })
    .catch(function (error) {
      dispatch(payload.setLoading(false));
      console.log(error);
    });
};

export const getAllUser = (filters, per_page, page) => (dispatch) => {
  return function (dispatch) {
    return dispatch(getAllUserRequest(filters, per_page, page));
  };
};

export const getUserAccount = (userId) => (dispatch) => {
  dispatch(payload.setFetchLoading(false));
  return Http.GET("user/get/" + userId)
    .then(function (response) {
      dispatch(payload.fetch(response.data));
      dispatch(payload.setFetchLoading(true));
    })
    .catch(function (error) {
      dispatch(payload.setFetchLoading(false));
      dispatch(common.setError(error.message));
    });
};

interface account {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
}

export const createAccount = (data: account) => (dispatch) => {
  dispatch(payload.setLoading(true));
  console.log(data);
  Http.PUT("user/create", data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error) {
      dispatch(common.setError(error.message));
    });
};

export const updateAccount = (userId, data: account) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.PATCH("user/update/" + userId, data)
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(payload.fetch([]));
        dispatch(getAllUserRequest([], 10, 1));
        dispatch(payload.setUpdateStatus(true));
        dispatch(common.setSuccess(response.data.message));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error) {
      dispatch(common.setError(error.message));
    });
};

export const deleteAccount = (data) => (dispatch) => {
  dispatch(payload.setLoading(true));
  Http.POST("user/delete", { payload: data })
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(getAllUserRequest([], 10, 1));
        dispatch(common.setSuccess(response.data.message));
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error) {
      dispatch(common.setError(error.message));
    });
};
