const UpdateComposerCellValue = (state, action) => {
  return {
    ...state,
    variation: {
      ...state.variation,
      compose: state.variation.compose.map((sku) => {
        if (sku.id === action.payload.id) {
          if (action.payload.column === "status") {
            sku.data[action.payload.column] =
              action.payload.value === true ? "publish" : "draft";
          } else {
            sku.data[action.payload.column] = action.payload.value;
          }
        }
        return sku;
      }),
    },
  };
};

export default UpdateComposerCellValue;
