import GameComponent from "../gameboard/game-component";
import PlayerProp from "./player/prop";
import * as ButtonProp from "./button-prop";

class LinkButton {
  constructor(base) {
    this.base = base;
  }

  createComponents() {
    const start_left = this.base.gameboard.canvas().width / 2;

    return new GameComponent(
      this.base.gameboard.canvas(),
      this.base.gameboard.context(),
      ButtonProp.LinkButtonProp.color,
      start_left + 20,
      this.base.gameboard.canvas().height - 100,
      ButtonProp.LinkButtonProp.width,
      ButtonProp.LinkButtonProp.height,
      "LINK",
      "white"
    );
  }

  handleCreate() {
    if (this.base.state.link_button instanceof GameComponent === false) {
      this.base.updateBaseState((state) => {
        return {
          ...state,
          link_button: this.createComponents(),
        };
      });
    }
  }

  progress() {
    this.base.state.link_button.changeTitle("LINKING...");
    this.base.state.link_button.lock();
    this.update(this.base.state.link_button);
  }

  start() {
    this.base.state.link_button.changeTitle("LINK");
    this.base.state.link_button.unlock();
    this.update(this.base.state.link_button);
  }

  update(component) {
    this.base.updateBaseState((state) => {
      return {
        ...state,
        link_button: component,
      };
    });
  }

  activate() {
    this.handleCreate();
    if (this.base.state.link_button instanceof GameComponent) {
      this.base.state.link_button.update();
    }
  }

  create() {
    return this.createComponents();
  }
}

export default LinkButton;
