import React, { Component } from "react";

import { connect } from "react-redux";
import { updateShippingForm } from "../../redux/actions/payload";

import ShippingInfoView from "../views/shipping-info";

class ShippingInfoContainer extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange = (event) => {
    this.props.updateShippingForm(event.target.name, event.target.value);
  };

  render() {
    return (
      <ShippingInfoView
        handleInputChange={this.handleInputChange}
        form={this.props.form}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      form: state.productReducer.form,
    };
  },
  {
    updateShippingForm: updateShippingForm,
  }
)(ShippingInfoContainer);
