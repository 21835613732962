import React, { Component, Fragment } from "react";

import "./style/campaign.scss";
import DatePicker from "react-date-picker";
import { Calendar } from "react-feather";
import { empty } from "common";

import ReactTooltip from "react-tooltip";

export default class CampaignView extends Component {
  render() {
    const {
      error,
      campaign,
      compose,
      defaultKeys,
      variationEnabled,
      updateMultipleCampaign,
      updateSingleCampaign,
    } = this.props;

    if (variationEnabled === true) {
      return (
        <TableCampaigns
          error={error}
          compose={compose}
          updateMultipleCampaign={updateMultipleCampaign}
          {...campaign}
        />
      );
    }

    return (
      <SingleSkuCampaign
        updateSingleCampaign={updateSingleCampaign}
        {...campaign}
      />
    );
  }
}

const TableCampaigns = ({ compose, updateMultipleCampaign, error }) => {
  return (
    <div>
      <Fragment>
        <ReactTooltip />
        <table className="table-campaign">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Price</th>
              <th>Sale Start date</th>
              <th>Sale End date</th>
            </tr>
          </thead>
          <tbody>
            {compose.map((row) => {
              return (
                <tr key={row.id}>
                  <td>{row.data.sku}</td>
                  <td
                    className={
                      row.errors.campaign.filter(
                        (e) => e.column === "sale_price"
                      ).length > 0
                        ? "error-input-style"
                        : ""
                    }
                  >
                    <input
                      type="text"
                      className="form-control no-border-input "
                      name="price"
                      placeholder="..."
                      name="sale_price"
                      value={row.data.sale_price}
                      onChange={(e) => {
                        updateMultipleCampaign(
                          row.id,
                          "sale_price",
                          e.target.value
                        );
                      }}
                    />
                  </td>
                  <td
                    className={
                      row.errors.campaign.filter(
                        (e) => e.column === "sale_start_at"
                      ).length > 0
                        ? "error-input-style"
                        : ""
                    }
                  >
                    <DatePicker
                      className="multi-campaign-calander"
                      name="sale_start_at"
                      onChange={(date) => {
                        updateMultipleCampaign(row.id, "sale_start_at", date);
                      }}
                      value={
                        !empty(row.data.sale_start_at)
                          ? new Date(row.data.sale_start_at)
                          : ""
                      }
                      calendarIcon={<Calendar />}
                    />
                  </td>
                  <td
                    className={
                      row.errors.campaign.filter(
                        (e) => e.column === "sale_end_at"
                      ).length > 0
                        ? "error-input-style"
                        : ""
                    }
                  >
                    <DatePicker
                      name="sale_end_at"
                      className="multi-campaign-calander"
                      onChange={(date) => {
                        updateMultipleCampaign(row.id, "sale_end_at", date);
                      }}
                      value={
                        !empty(row.data.sale_end_at)
                          ? new Date(row.data.sale_end_at)
                          : ""
                      }
                      calendarIcon={<Calendar />}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <code>
          <ol>
            {compose.map((row) => {
              return row.errors.campaign.map((error) => {
                return (
                  <li>
                    <b>SKU {row.data.sku}</b>: {error.message}
                  </li>
                );
              });
            })}
          </ol>
        </code>
      </Fragment>
    </div>
  );
};

const SingleSkuCampaign = ({
  error,
  sale_price,
  sale_end_at,
  sale_start_at,
  updateSingleCampaign,
}) => {
  return (
    <div className="needs-validation">
      <div className="row">
        <div className="form-group col-sm-3">
          <label className="col-form-label pt-0"> Sale Price</label>
          <input
            className="form-control"
            name="single_sale_price"
            type="number"
            value={sale_price}
            onChange={(e) => {
              updateSingleCampaign("sale_price", e.target.value);
            }}
          />
        </div>
        <div className="form-group col-sm-3">
          <label className="col-form-label pt-0"> Sale Start Date</label>
          <DatePicker
            className="single-campaign-calander"
            name="single_sale_start_at"
            onChange={(date) => {
              updateSingleCampaign("sale_start_at", date);
            }}
            value={!empty(sale_start_at) ? new Date(sale_start_at) : ""}
            calendarIcon={<Calendar />}
          />
        </div>
        <div className="form-group col-sm-3">
          <label className="col-form-label pt-0"> Sale End Date</label>
          <DatePicker
            className="single-campaign-calander"
            name="single_sale_end_at"
            onChange={(date) => {
              updateSingleCampaign("sale_end_at", date);
            }}
            value={!empty(sale_end_at) ? new Date(sale_end_at) : ""}
            calendarIcon={<Calendar />}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <code>
            <ol>
              {error.map((d, i) => {
                return (
                  <li key={i}>
                    {d.column} : {d.message}
                  </li>
                );
              })}
            </ol>
          </code>
        </div>
      </div>
    </div>
  );
};
