import * as type from "../../constants/association";

export const loadShops = (shops) => {
  return {
    type: type.LIST_SHOPS,
    payload: shops,
  };
};

export const playerConnectingResult = (callback, response) => {
  return {
    type: type.PLAYER_CONNECTING_RESULT,
    payload: { callback, response },
  };
};

export const playerDisConnectingResult = (callback, response) => {
  return {
    type: type.PLAYER_DISCONNECTING_RESULT,
    payload: { callback, response },
  };
};
