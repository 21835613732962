const ADD_OR_UPDATE = "product/product/media/add-or-update";
const ADD_LISTENER = "product/product/media/add-listener";
const ADD_FILE = "product/product/media/add-file";
const REMOVE = "product/product/media/remove";
const CLEAR = "product/product/media/clear";
const RESPONSE_SUCCESS = "product/product/media/response/success";
const RESPONSE_FAILED = "product/product/media/response/failed";
const LOADING = "product/product/media/loading";
const PROGRESS = "product/product/media/progress";
const UPDATE_FULL_MEDIA = "product/product/media/full/update";

export {
  ADD_OR_UPDATE,
  REMOVE,
  CLEAR,
  RESPONSE_SUCCESS,
  RESPONSE_FAILED,
  LOADING,
  PROGRESS,
  UPDATE_FULL_MEDIA,
};
