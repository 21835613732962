import React, { Component, Fragment, useRef } from "react";
import Modal from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruckLoading,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { empty } from "common";

export default class TicklePlayerModalComponent extends Component {
  render() {
    const {
      tickle,
      onTickleClosePlayerModal,
      handleDetachOpponent,
    } = this.props;
    if (empty(tickle?.player?.player)) {
      return "";
    }
    const style = {
      closeButton: {
        top: "-14px",
        right: "-8px",
      },
      closeIcon: {
        color: "#fff",
        fontSize: "20px",
      },
      modalContainer: {
        width: "400px",
      },
    };

    return (
      <Fragment>
        <Modal
          open={tickle.player.popupBox}
          onClose={onTickleClosePlayerModal}
          closeIcon={FontAwesomeIcon}
          classNames={{
            modal: "tickle_modal",
          }}
          styles={style}
          center
        >
          <div className="modal-header">
            <div className="modal-title">
              <h5 className=" f-w-600">
                {tickle.player.player.getData().sku} <br />
              </h5>
              <small>
                {tickle.player.player.getInfo().basic.title}
                <br />
                {tickle.player.player.getInfo().basic.shop.name}(
                {tickle.player.player.getInfo().basic.shop.app})
              </small>
            </div>
          </div>
          <table
            className="table table-bordered table-hover"
            style={{ marginTop: "20px" }}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th style={{ textAlign: "center" }}>Number of Items</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tickle.player.player.getOpponents().map((file, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {file.opponent.getData().name} <br />
                      <small>
                        <em>
                          {file.opponent.getInfo().data.item_name} <br />#
                          {file.opponent.getData().id} <br />
                          {file.opponent.getInfo().data.shop.name} (
                          {file.opponent.getInfo().data.shop.app})
                        </em>
                      </small>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="text"
                        value={file.opponent.getNumberOfItem()}
                        className="form-control"
                        onChange={(e) => {
                          this.props.handleOpponentChangeNumberOfItem(
                            tickle.player.player,
                            file.opponent,
                            e.target.value
                          );
                        }}
                      />
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon={faTimes}
                        size="lg"
                        className="cursor-pointer"
                        onClick={(e) => {
                          handleDetachOpponent(
                            tickle.player.player,
                            file.opponent
                          );
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal>
      </Fragment>
    );
  }
}
