import React, { Component, Fragment } from 'react';
import ListBrandComponent from "../views/list-brand";

export class ListBrandContainer extends Component {
    state = {
        open: false
    }
    constructor(props) {
        super();
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    render() {
        const {open} = this.state;
        return (
            <ListBrandComponent
                open={open}
                onOpenModal={this.onOpenModal}
                onCloseModal={this.onCloseModal}
            />
        )
    }
}

export default ListBrandContainer;
