const AddSkuError = (state, action) => {
  return {
    ...state,
    variation: {
      ...state.variation,
      data: state.variation.data.map((row) => {
        row.sku_data = row.sku_data.map((sku) => {
          sku.error = "";
          const error = action.payload.filter((d) => d.uuid == sku.uuid)[0];
          if (error) {
            sku.error = error;
          }
          return sku;
        });
        return row;
      }),
    },
  };
};

export default AddSkuError;
