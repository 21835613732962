import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

const DataTableComponent = (props) => {
  const {
    editSupplier,
    removeSupplier,
    selected,
    selectedId,
    data,
    pages,
    supplierId,
    loading,
    fetchSupplier,
  } = props;

  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: <b>{"ID"}</b>,
            accessor: "id",
            filterable: false,
            style: {
              textAlign: "center",
            },
          },
          {
            Header: <b>{"Name"}</b>,
            accessor: "name",
            Cell: (row) => (
              <Link
                to={"edit/" + row.original.id}
                click="editSupplier(row.original.id)"
                title="Edit"
              >
                {row.original.name}
              </Link>
            ),
          },
          {
            Header: <b>{"Address"}</b>,
            accessor: "address",
          },
          {
            Header: <b>{"Contact Name"}</b>,
            accessor: "contact_name",
          },
          {
            Header: <b>{"Contact No."}</b>,
            accessor: "contact_no",
          },
          {
            Header: <b>{"Website"}</b>,
            accessor: "website",
          },
          {
            Header: <b>{"Viber"}</b>,
            accessor: "viber",
          },
          {
            Header: <b>{"Messenger"}</b>,
            accessor: "messenger",
          },
          {
            Header: (
              <button
                onClick={(e) => {
                  removeSupplier(e);
                }}
                className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
              >
                Delete
              </button>
            ),
            accessor: "delete",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) => (
              <div>
                <span>
                  <input
                    defaultChecked={selectedId.includes(row.original.id)}
                    onChange={(e) => {
                      selected(e, row.original.id);
                    }}
                    type="checkbox"
                    className="checkbox_animated"
                  />
                </span>
              </div>
            ),
          },
        ]}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        onFetchData={fetchSupplier} // Request new data when things change
        filterable
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
};

export default DataTableComponent;
