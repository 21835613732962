import React, { Component } from "react";
import Sidebar from "./common/sidebar_components/sidebar";
import Right_sidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import Header from "./common/header_components/header";
import LockModalComponent from "./lock-modal";
import Emitter from "common/event-emitter";

import Http from "api/http";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { matchPath } from "react-router";

export class App extends Component {
  REQUEST = [];
  INTERVAL = null;
  INVERVAL_VALUE = 180000; // 3 minutes interval

  constructor(props) {
    super(props);
    this.state = {
      ltr: true,
      divName: "RTL",
      lock_modal_status: false,
    };
    this.handleLockModalClose = this.handleLockModalClose.bind(this);
  }
  ChangeRtl(divName) {
    if (divName === "RTL") {
      document.body.classList.add("rtl");
      this.setState({ divName: "LTR" });
    } else {
      document.body.classList.remove("rtl");
      this.setState({ divName: "RTL" });
    }
  }

  componentDidMount() {
    this.handleVerify();
    this.handleVerifyUserInterval();
    this.handleEmitCancelPendingRequest();
    this.handleEmitShowVerifyModal();
  }

  handleVerifyUserInterval() {
    this.INTERVAL = setInterval(() => {
      if (window.location.href.includes("/auth/login")) {
        return;
      }
      this.handleVerify();
    }, this.INVERVAL_VALUE);
  }

  handleVerify() {
    this.verifyCurrentLoginUser(
      this.props.userAuthReducer?.session?.user?.username ?? 0
    );
  }

  handleEmitShowVerifyModal() {
    Emitter.on("VERIFY_USER_MODAL_SHOW", () => {
      this.setState({
        lock_modal_status: true,
      });
    });
  }

  handleEmitCancelPendingRequest() {
    Emitter.on("VERIFY_USER_CHECKER", () => {
      this.REQUEST.forEach(function (c) {
        c();
      });
    });
  }

  verifyCurrentLoginUser(userId) {
    const _this = this;
    Http.POST(
      "user/verify/" + userId,
      {
        api_token: this.props.userAuthReducer?.session?.access_token ?? 0,
      },
      {
        cancelToken: new axios.CancelToken(function executor(c) {
          _this.REQUEST.push(c);
        }),
      }
    )
      .then((response) => {
        if ((response.data?.verified ?? false) == false) {
          this.setState({
            lock_modal_status: true,
          });
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled");
        } else {
          this.setState({
            lock_modal_status: true,
          });
        }
      });
  }

  handleLockModalClose() {
    this.setState({
      lock_modal_status: false,
    });
  }

  render() {
    return (
      <div>
        <LockModalComponent
          modal_status={this.state.lock_modal_status}
          handleLockModalClose={this.handleLockModalClose}
        />
        <div className="page-wrapper">
          <Header />
          <div className="page-body-wrapper">
            <Sidebar />
            <Right_sidebar />
            <div className="page-body">{this.props.children}</div>
            <Footer />
          </div>
        </div>
        <div
          className="btn-light custom-theme"
          onClick={() => this.ChangeRtl(this.state.divName)}
        >
          {this.state.divName}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userAuthReducer: state.userAuthReducer,
  };
}

let AppConnect = connect(mapStateToProps)(App);

export default withRouter(AppConnect);
