import * as type  from "../constants/index";

const initialState = {
   data: [],
   loading: false,
   formStatus: false
};

export default function Category(state = initialState, action) {

    switch (action.type) {
        case type.FETCH_ALL:
            state = Object.assign({}, state, {
	            data: action.payload
	        });
        break;
        case type.FETCH:
            state = Object.assign({}, state, {
                toEdit: action.payload
            });
        break;
        case type.FORM_STATUS:
        	state = Object.assign({}, state, {
	            formStatus: action.payload
	        });
        break;
        case type.LOADING:
            const loading = action.payload;
            return {...state, loading}
        break;
        case type.RESPONSE:
            const response = action.payload;
            return {...state, response}
        break;
        case type.UPDATE_STATUS:
            const updated = action.payload;
            return {...state, updated}
        break;
        case type.CHOOSEN:
            const choosen = action.payload;
            return {...state, choosen}
        break;
    }

    return state;
}
