const LOADING = "inventory/progress-loading";
const CALLBACK = "inventory/callback";
const ADD_BRAND = "inventory/add-brand";
const ADD_BRAND_ITEM = "inventory/add-brand-item";
const ADD_BRAND_ITEM_VARIANT = "inventory/add-brand-item-variant";
const DESTROY = "inventory/destroy";
const UPDATE_VARIANT_STOCK = "inventory/update-variant-stock";
const UPDATE_MAIN_STOCK = "inventory/update-main-stock";
const LOAD_STOCKING_TEMPLATE = "inventory/load-stocking-template";
const REMOVE_TEMPLATE_BRAND = "inventory/remove-brand";
const REMOVE_TEMPLATE_BRAND_ITEM = "inventory/remove-brand-item";
const RESET_TEMPLATE_BRAND_ITEM_STOCK = "inventory/reset-brand-item-stock";
const REMOVE_TEMPLATE_BRAND_ITEM_VARIATION =
  "inventory/remove-brand-item-variation";
const CHANGE_GLOBAL_ALLOCATION_TYPE = "inventory/change-allocation-type";

export {
  DESTROY,
  LOADING,
  CALLBACK,
  ADD_BRAND,
  ADD_BRAND_ITEM,
  ADD_BRAND_ITEM_VARIANT,
  UPDATE_VARIANT_STOCK,
  UPDATE_MAIN_STOCK,
  LOAD_STOCKING_TEMPLATE,
  REMOVE_TEMPLATE_BRAND,
  REMOVE_TEMPLATE_BRAND_ITEM,
  REMOVE_TEMPLATE_BRAND_ITEM_VARIATION,
  RESET_TEMPLATE_BRAND_ITEM_STOCK,
  CHANGE_GLOBAL_ALLOCATION_TYPE,
};
