import Http from "api/http";
import * as payload from "./payload";
import * as common from "./common";

export const getProductListRequest = (filters, per_page, page) => (
  dispatch
) => {
  dispatch(payload.setLoading(true));
  return Http.POST("shopee/product/getAllItems", {
    payload: filters,
    per_page,
    page,
  })
    .then(function (response) {
      dispatch(payload.loadProductList(response.data));
      dispatch(payload.setLoading(false));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getProductList = (filters, per_page, page) => (dispatch) => {
  return function (dispatch) {
    return dispatch(getProductListRequest(filters, per_page, page));
  };
};

export const getProductRequest = (id) => (dispatch) => {
  return Http.GET("shopee/product/get/" + id)
    .then(function (response) {
      dispatch(payload.loadProduct(response.data));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const syncShopeeProduct = () => (dispatch) => {
  return Http.GET("shopee/product/sync")
    .then(function (response) {
      dispatch(common.setSuccess(response.data.message));
    })
    .catch(function (error) {
      console.log(error.message);
    });
};

export const getProduct = () => (dispatch) => {
  return function (dispatch) {
    return dispatch(getProductRequest());
  };
};

export const requestUpdateUnitCost = (
  id,
  itemId,
  variationId,
  unitCost,
  currency
) => (dispatch) => {
  dispatch(
    payload.updateTableRowCellLoading(
      id,
      itemId,
      variationId,
      "unit_cost",
      true
    )
  );
  Http.POST("shopee/product/updateUnitCost", {
    id: id,
    item_id: itemId,
    unit_cost: unitCost,
    variation_id: variationId,
    currency: currency,
  })
    .then(function (response) {
      if (response.data.success === true) {
        dispatch(common.setSuccess(response.data.message));
        dispatch(
          payload.updateUnitCost(
            id,
            itemId,
            variationId,
            response.data.unit_cost
          )
        );
        dispatch(payload.updateUnitCostStatus(id, itemId, variationId, false));
        dispatch(
          payload.updateTableRowCellLoading(
            id,
            itemId,
            variationId,
            "unit_cost",
            false
          )
        );
      } else {
        dispatch(common.setError(response.data.message));
      }
    })
    .catch(function (error, content) {
      dispatch(common.setError(error.message));
    });
};
