const AddVariantError = (state, action) => {
  return {
    ...state,
    variation: {
      ...state.variation,
      data: state.variation.data.map((row) => {
        row.error = "";
        const error = action.payload.filter((d) => d.id == row.id)[0];
        if (error) {
          row.error = error;
        }
        return row;
      }),
    },
  };
};

export default AddVariantError;
