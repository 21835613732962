import uuid from "react-uuid";
import { empty } from "common";

export const clearCategorySelected = (state) => {
  return addCategoryBox(defaultCategory(state));
};

export const edit = (state) => {
  return {
    ...state,
    category: {
      ...state.category,
      confirmed: false,
    },
  };
};

export const confirmedCategory = (state) => {
  return {
    ...state,
    category: {
      ...state.category,
      confirmed: !empty(state.category.confirm),
    },
  };
};

export const loadCategoryTree = (state, action) => {
  return createCategoryBox(
    {
      ...state,
      category: {
        ...state.category,
        data: action.payload.categories,
      },
    },
    action
  );
};

export const createCategoryBox = (state, action) => {
  state = removeCategoryBox(state, action);

  if (
    action.payload.checked === true ||
    action.payload.categoryParentId === undefined
  ) {
    state = addCategoryBox(state, action);
  }
  return updateCategoryConfirmed(updateCategoryCheckBox(state, action));
};

// Remove the current check category
// Append this code above the creation of box
export const removeCategoryBox = (state, action) => {
  const categoryParentId = action.payload.categoryParentId ?? null;

  if (categoryParentId === null || categoryParentId === "") {
    return state;
  }

  const boxIndex = state.category.box.findIndex(
    (b) => b.categoryParentId === categoryParentId
  );

  if (boxIndex !== -1) {
    state.category.box.splice(boxIndex + 1, state.category.box.length);
  }

  return {
    ...state,
    category: {
      ...state.category,
      box: state.category.box,
    },
  };
};

const addCategoryBox = (state, action) => {
  const box = getCategoryBox(state, action);
  if (box.length <= 0) {
    return state;
  }
  return {
    ...state,
    category: {
      ...state.category,
      box: state.category.box.concat({
        uuid: uuid(),
        categoryParentId: action?.payload?.categoryId ?? 0,
        categories: box,
      }),
    },
  };
};

const getCategoryBox = (state, action) => {
  const categoryParentId = action?.payload?.categoryParentId ?? null;
  if (categoryParentId === null) {
    return state.category.data.map((c) => {
      c.checked = false;
      return c;
    });
  }
  const currentCheckBoxCategoryIndex = state.category.box.findIndex(
    (b) => b.categoryParentId === categoryParentId
  );
  const children =
    state.category.box[currentCheckBoxCategoryIndex].categories.filter(
      (c) => c.id === action.payload.categoryId
    )[0]?.children ?? [];

  return children.map((c) => {
    c.checked = false;
    return c;
  });
};

const updateCategoryCheckBox = (state, action) => {
  const boxIndex = state.category.box.findIndex(
    (b) => b.categoryParentId === action.payload.categoryParentId
  );
  if (boxIndex === -1) {
    return state;
  }
  state.category.box[boxIndex].categories = state.category.box[
    boxIndex
  ].categories.map((c) => {
    c.checked = false;
    if (c.id === action.payload.categoryId) {
      c.checked = action.payload.checked;
    }
    return c;
  });

  return state;
};

const updateCategoryConfirmed = (state) => {
  return {
    ...state,
    category: {
      ...state.category,
      confirm: state.category.box[
        state.category.box.length - 1
      ].categories.reduce((c, i) => {
        if (i.checked === true) {
          c = i;
        }
        return c;
      }, ""),
      confirmed: false,
    },
  };
};

const defaultCategory = (state) => {
  return {
    ...state,
    category: {
      ...state.category,
      confirmed: false,
      confirm: "",
      box: [],
    },
  };
};

export const updateFullCategory = (state, action) => {
  return {
    ...state,
    category: {
      data: action.payload.data,
      confirmed: true,
      confirm: action.payload.confirmed,
      box: action.payload.box,
    },
  };
};
