import { empty } from "common";

class Opponent {
  players = [];

  constructor(id, component, info, data, players, tickle_del) {
    this.id = id;
    this.component = component;
    this.info = info;
    this.data = data;
    this.active = true;
    this.tickle_del = tickle_del;
    this.addPlayers(players);
  }

  get() {
    return {
      id: this.getId(),
      info: this.getInfo(),
      data: this.getData(),
      component: this.getComponent(),
      players: this.getPlayers(),
    };
  }

  getBrief() {
    return {
      data: this.getData(),
      info: this.getInfo(),
    };
  }

  sameValueAs(opponent) {
    return opponent.getData().id === this.getData().id;
  }

  getId() {
    return this.id;
  }

  getComponent() {
    return this.component;
  }

  getInfo() {
    return this.info;
  }

  getData() {
    return this.data;
  }

  getPlayers() {
    return this.players;
  }

  getTickleDel() {
    return this.tickle_del;
  }

  isActive() {
    return this.active;
  }

  addPlayers(_players) {
    let players =
      !empty(_players) && _players.length && _players.length > 0
        ? _players
        : [_players];
    players = [].concat(...players).filter((o) => {
      if (this.players.filter((o_) => o_.sameValueAs(o)).length > 0) {
        return false;
      }
      return true;
    });
    if (!empty(players)) {
      this.players = this.players.concat(...players);
    }
  }

  changeActive(active) {
    this.active = active;
  }
}

export default Opponent;
