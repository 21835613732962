import React, { Component, Fragment } from "react";

import Modal from "react-responsive-modal";
import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Spinner from "components/common/spinner";
import Message_container from "components/common/message-container";
import "./style.scss";

import { empty } from "common";

export default class AllocationModalComponent extends Component {
  render() {
    const {
      modalStatus,
      handleAllocationModalClose,
      handleSubmitAllocation,
      handleShopItemAllocationInputChange,
      handleStoreItemAllocationInputChange,
      allocation_type,
      allocation_types,
      allocation_platforms,
      allocation_platform,
      handleInvalidSubmit,
      isShopeeStore,
      stocks,
      response,
      loading,
      shop,
      shops,
      selected,
    } = this.props;
    if (empty(selected)) {
      return "";
    }

    const style = {
      closeButton: {
        top: "-14px",
        right: "-8px",
      },
      closeIcon: {
        color: "#fff",
        fontSize: "20px",
      },
      modalContainer: {
        width: "400px",
      },
    };
    console.log(allocation_types);
    return (
      <Fragment>
        <Modal
          open={modalStatus}
          onClose={handleAllocationModalClose}
          center
          closeOnEsc={false}
          closeOnOverlayClick={false}
          styles={style}
          classNames={{
            modal: "allocation_modal",
          }}
        >
          <AvForm
            className="needs-validation user-add text-left"
            onValidSubmit={(e) => handleSubmitAllocation(e)}
            onInvalidSubmit={handleInvalidSubmit}
          >
            <div className="modal-header">
              <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                Allocate Stocks: {!empty(selected) && selected.product.name}
              </h5>
            </div>
            <div className="modal-body">
              <Message_container
                message={response?.message ?? ""}
                error={response?.error ?? false}
              />
              <div className="row bg-primary bg-gradient m-b-20">
                <div className="col-xl-8">
                  {!empty(selected) && (
                    <>
                      <span
                        class="lead text-white m-t-0 p-b-0 m-b-0"
                        style={{ fontSize: 1 + "rem" }}
                      >
                        Available Stocks:
                        <span class="badge bg-secondary">
                          {" "}
                          {selected.available_stock}
                        </span>
                      </span>
                    </>
                  )}
                </div>
                <div className="col-xl-4">
                  {!empty(selected) && (
                    <>
                      <span
                        class="lead text-white m-b-0 fs-6 p-b-0"
                        style={{ fontSize: 0.6 + "rem" }}
                      >
                        Last Trans Date: <br />
                        <span style={{ fontSize: 0.8 + "rem" }}>
                          {" "}
                          {selected.allocations_history &&
                            selected.allocations_history.length > 0 &&
                            selected.allocations_history[
                              selected.allocations_history.length - 1
                            ].created_at.format}
                        </span>
                      </span>
                    </>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th width="20%">Stocks</th>
                        <th width="20%">Option</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selected.shop_links_reference.map((link, i) => {
                        return (
                          <tr>
                            <td>
                              {link.shop_item_name} <br />
                              <small>
                                <em>
                                  ({link.shop.platform.name}) {link.shop.name}
                                </em>
                              </small>
                            </td>
                            <td>
                              <AvField
                                value={link.stocks}
                                label={
                                  "Bal:" + (link?.available_stock ?? "...")
                                }
                                onChange={handleShopItemAllocationInputChange}
                                className="form-control"
                                name={i + "_" + "stocks"}
                                type="number"
                              />
                            </td>
                            <td>
                              <AvRadioGroup
                                name={i + "_" + "allocation_type"}
                                label=""
                                onChange={handleShopItemAllocationInputChange}
                              >
                                {allocation_types.map((type, _i) => {
                                  return (
                                    <AvRadio
                                      customInput
                                      label={type.name}
                                      name={i + "_" + "type" + "_" + _i}
                                      value={type.id}
                                    />
                                  );
                                })}
                              </AvRadioGroup>
                            </td>
                          </tr>
                        );
                      })}
                      {selected.product.has_variation ? (
                        selected.product.variation.compose.map((compose, i) => {
                          if (compose.id === selected.variation_id)
                            return (
                              <tr>
                                <td>
                                  {compose.sku} <br />
                                  <small>
                                    <em>{selected.product.shop.name}</em>
                                  </small>
                                </td>
                                <td>
                                  <AvField
                                    value={compose?.allocate_stocks ?? 0}
                                    label={
                                      "Bal: " +
                                      (compose?.available_stock ?? "...")
                                    }
                                    onChange={
                                      handleStoreItemAllocationInputChange
                                    }
                                    className="form-control"
                                    name={i + "_" + "stocks"}
                                    type="number"
                                  />
                                </td>
                                <td>
                                  <AvRadioGroup
                                    name={i + "_" + "allocation_type_store"}
                                    label=""
                                    onChange={
                                      handleStoreItemAllocationInputChange
                                    }
                                  >
                                    {allocation_types.map((type, _i) => {
                                      return (
                                        <AvRadio
                                          customInput
                                          label={type.name}
                                          name={
                                            i +
                                            "_" +
                                            "type" +
                                            "_" +
                                            _i +
                                            "_store"
                                          }
                                          value={type.id}
                                        />
                                      );
                                    })}
                                  </AvRadioGroup>
                                </td>
                              </tr>
                            );
                        })
                      ) : (
                        <tr>
                          <td>
                            {selected.product.details.basic.title} <br />
                            <small>
                              <em>{selected.product.shop.name}</em>
                            </small>
                          </td>
                          <td>
                            <AvField
                              value={selected.product?.allocated_stocks ?? 0}
                              label={"Bal: " + selected.stocks}
                              onChange={handleStoreItemAllocationInputChange}
                              className="form-control"
                              name={"nothing_" + "stocks"}
                              type="number"
                            />
                          </td>
                          <td>
                            <AvRadioGroup
                              name={"allocation_type"}
                              label=""
                              onChange={handleStoreItemAllocationInputChange}
                            >
                              {allocation_types.map((type, _i) => {
                                return (
                                  <AvRadio
                                    customInput
                                    label={type.name}
                                    name={"nothing_" + "type" + "_" + _i}
                                    value={type.id}
                                  />
                                );
                              })}
                            </AvRadioGroup>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading ? true : ""}
              >
                {loading === true ? <Spinner loading={true} /> : ""}ALLOCATE
              </button>
            </div>
          </AvForm>
        </Modal>
      </Fragment>
    );
  }
}
