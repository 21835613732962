import OpponentClass from "./opponent";
import PlayerClass from "./player";

export default class Shared {
  constructor(base) {
    this.base = base;
    this.opponent = OpponentClass(base);
    this.player = PlayerClass(base);
  }

  isOpponentActive(opponent) {
    return (
      this.opponent.ListOpponents.allActive().filter((o) =>
        o.sameValueAs(opponent)
      ).length > 0
    );
  }

  getAllActiveOpponents() {
    return this.opponent.ListOpponents.allActive();
  }

  getAllOpponents() {
    return this.opponent.ListOpponents.all();
  }

  getAllPlayers() {
    return this.player.listPlayers.all();
  }

  createPlayerLine(player, x, y) {
    return this.player.createLine(player, x, y);
  }

  updatePlayerLineCoordinates(player, line, base_opponent) {
    this.getAllActiveOpponents().forEach((opponent) => {
      if (opponent.sameValueAs(base_opponent)) {
        player.changeLineCooridnates(line, opponent).update();
      }
    });
  }

  createOpponents(products) {
    this.opponent.createOpponents(products);
  }

  connectPlayerOpponent(player, opponent) {
    player = this.createPlayerLine(
      player,
      opponent.getComponent().getX(),
      opponent.getComponent().getY()
    );

    player = this.player
      .character(player)
      .connectOpponent(player.getOpenLines()[0], opponent);

    opponent = this.opponent.character(opponent).connectPlayer(player);

    return { player, opponent };
  }
}
