const LOAD_ACTION = 'user/permission/action/load-all';
const FORM_STATUS = 'user/permission/action/form-status';
const LOADING = 'user/permission/action/progress-loading';
const RESPONSE = 'user/permission/action/response';
const UPDATE_ACTION_CHECKBOX = 'user/permission/action/set-selected';

export {
	LOAD_ACTION,
	FORM_STATUS,
	LOADING,
	RESPONSE,
	UPDATE_ACTION_CHECKBOX
}
