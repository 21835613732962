const LOAD_ACTION_GROUP = 'user/permission/action-group/load-all';
const FORM_STATUS = 'user/permission/action-group/form-status';
const LOADING = 'user/permission/action-group/progress-loading';
const RESPONSE = 'user/permission/action-group/response';
const UPDATE_GROUP_CHECKBOX = 'user/permission/action-group/set-selected';
const UPDATE_GROUP_ACTION_CHECKBOX = 'user/permission/action-group/set-action-selected';
const UPDATE_GROUP_ACTION = 'user/permission/action-group/update-group-action';

export {
	LOAD_ACTION_GROUP,
	FORM_STATUS,
	LOADING,
	RESPONSE,
	UPDATE_GROUP_CHECKBOX,
	UPDATE_GROUP_ACTION_CHECKBOX,
	UPDATE_GROUP_ACTION
}
