import React, { Component } from "react";

import "./style/right-sidebar.css";
import { ChevronRight } from "react-feather";

export default class SidebarView extends Component {
  render() {
    const { variationEnabled, wholesaleEnabled, goto, anchor } = this.props;
    return (
      <>
        <div id="right-sidebar">
          <ul>
            {anchor.map((a, i) => {
              return (
                a.visible === true && (
                  <li key={i} className={a.selected === true ? "active" : ""}>
                    <a
                      href="javascript:;"
                      className={a.selected === true ? "active" : ""}
                      onClick={goto.bind(this, a.selector)}
                    >
                      {a.title}
                    </a>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </>
    );
  }
}
