import React, { Component, Fragment } from "react";
import Modal from "react-responsive-modal";
import Spinner from "components/common/spinner";
import {
  AvField,
  AvForm,
  AvInput,
  AvGroup,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import Message_container from "components/common/message-container";

export default class FormComponent extends Component {
  render() {
    const {
      open,
      onCloseModal,
      handleSubmit,
      handleInvalidSubmit,
      handleChange,
      handleCheckboxChange,
      loading,
      response,
      shop_name,
      app_key,
    } = this.props;

    return (
      <Fragment>
        <Modal open={open} onClose={onCloseModal} center>
          <AvForm
            className="needs-validation category-form"
            onValidSubmit={handleSubmit.bind(this)}
            onInvalidSubmit={this.handleInvalidSubmit}
            style={{ minWidth: 400 + "px" }}
          >
            <div className="modal-header">
              <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                Create Shop
              </h5>
            </div>
            <div className="modal-body">
              <Message_container
                message={response?.message ?? ""}
                error={response?.error ?? false}
              />

              <div className="form-group row">
                <div className="col-xl-12 col-md-12">
                  <AvField
                    value={shop_name}
                    label="Shop Name"
                    onChange={handleChange}
                    className="form-control"
                    name="shop_name"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xl-12 col-md-12">
                  <AvField
                    type="select"
                    name="app_key"
                    label="App"
                    helpMessage="Select app key"
                    value={app_key}
                    onChange={handleChange}
                    required
                  >
                    <option value="">SELECT</option>
                    <option value="store">Website Store</option>
                    <option value="lazada">Lazada</option>
                    <option value="shopee">Shopee</option>
                  </AvField>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => onCloseModal()}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                {loading === true ? <Spinner loading={true} /> : ""}Save
              </button>
            </div>
          </AvForm>
        </Modal>
      </Fragment>
    );
  }
}
