import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.scss";
import App from "./components/app";
import { ScrollContext } from "react-router-scroll-4";

// Components
// import Dashboard from "./components/dashboard";
import DashboardContainer from "./modules/home/components/container/dashboard";

// Products
import List_category from "./modules/product/category/components/container/list-category";
import List_brand from "./modules/product/brand/components/container/list-brand";
import AddProductContainer from "./modules/product/product/components/container/add-product";
import EditProductContainer from "./modules/product/product/components/container/edit-product";
import ListProductContainer from "./modules/product/product/components/container/list-product";
import ReOrderLevelContainer from "./modules/product/re-order-level/components/container/container";
import OrderForeCastContainer from "./modules/product/order-forecast/components/container/container";
import LinkReferenceContainer from "./modules/product/link-reference/components/container/container";

//Sales
import Orders from "./components/sales/orders";
// import Transactions_sales from "./components/sales/transactions-sales";
//Coupons
import ListCoupons from "./components/coupons/list-coupons";
import Create_coupons from "./components/coupons/create-coupons";

//Pages
import ListPages from "./components/pages/list-page";
import Create_page from "./components/pages/create-page";
import Media from "./components/media/media";
import List_menu from "./components/menus/list-menu";
import Create_menu from "./components/menus/create-menu";
import List_user from "./components/users/list-user";
import Create_user from "./components/users/create-user";
import Permission from "./components/users/permission";

// import List_vendors from './components/vendors/list-vendors';
// import Create_vendors from './components/vendors/create.vendors';
import Translations from "./components/localization/translations";
import Rates from "./components/localization/rates";
import Taxes from "./components/localization/taxes";
// import Profile from "./components/settings/profile";
import Reports from "./components/reports/report";
import Invoice from "./components/invoice";
import Datatable from "./components/common/datatable";
import NotFound from "./components/common/not-found";
import Login from "./components/auth/login";

import store from "redux/store/index";
import { Provider } from "react-redux";

import PrivateRoute from "private-route";
import LoginRoute from "login-route";

// Settings
import ListShopContainer from "./modules/settings/shop/components/container/list-shop";
import ProfileContainer from "./modules/settings/profile/components/container/container";
// Sales
// Orders
// Manual
import InStoreOrderListContainer from "./modules/order/in-store/components/container/list-order";
import CreateInStoreOrderContainer from "./modules/order/in-store/components/container/create-order";
import UpdateInStoreOrderContainer from "./modules/order/in-store/components/container/update-order";
import InStoreOrderContainer from "./modules/order/in-store/componentsv2/views/container";
import CreateInStoreOrderContainerV2 from "modules/order/in-store/componentsv2/container/create-order";
import BasketContainer from "modules/order/in-store/basket/container/basket";
import StepsComponent from "modules/order/in-store/steps";

// Ecommerce
import EcommerceContainer from "./modules/order/ecommerce/components/container/ecommerce";
// Transactions
import ExportOrderContainer from "./modules/order/export/components/container/list-transactions";

// Supplier
import ListSupplierContainer from "./modules/supplier/components/container/list-supplier";
import CreateSupplierContainer from "./modules/supplier/components/container/create-supplier";
import UpdateSupplierContainer from "./modules/supplier/components/container/update-supplier";
// Purchases
import ListPuchaseOrderContainer from "./modules/purchase/components/container/list-purchase-order";
import CreatePuchaseOrderContainer from "./modules/purchase/components/container/create-purchase-order";
import UpdatePuchaseOrderContainer from "./modules/purchase/components/container/update-purchase-order";
import CreateReceiveOrderContainer from "./modules/purchase/components/container/create-receive-order";
import ListReceivedOrderContainer from "./modules/purchase/components/container/list-received-order";

// Inventory
import ListAllocationContainer from "./modules/inventory/allocation-and-stocking/components/container/list-allocation";
import ModifyStockContainer from "./modules/inventory/allocation-and-stocking/components/container/modify-stock";
import StockingContainerComponent from "./modules/inventory/stocking/components/container/container";
import LiveMonitoringComponent from "./modules/inventory/live-monitoring/components/container/container";

// Approval
import ApprovalListContainer from "./modules/approval/stock-updates/components/container/approval-list";
import ApprovalContainer from "./modules/approval/stock-updates/components/container/approval";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <ScrollContext>
          <Switch>
            <LoginRoute
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={Login}
            />
            <LoginRoute
              exact
              path={`${process.env.PUBLIC_URL}/auth/login`}
              component={Login}
            />

            <App>
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/dashboard`}
                component={DashboardContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/products/category`}
                component={List_category}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/products/brand`}
                component={List_brand}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/products/product/product-list`}
                component={ListProductContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/products/product/reorder-level`}
                component={ReOrderLevelContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/products/product/add-product`}
                component={AddProductContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/products/product/link-reference/:id`}
                component={LinkReferenceContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/products/product/order-forecast`}
                component={OrderForeCastContainer}
              />

              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/products/product/edit-product/:id`}
                component={EditProductContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/coupons/list-coupons`}
                component={ListCoupons}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/coupons/create-coupons`}
                component={Create_coupons}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/pages/list-page`}
                component={ListPages}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/pages/create-page`}
                component={Create_page}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/media`}
                component={Media}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/menus/list-menu`}
                component={List_menu}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/menus/create-menu`}
                component={Create_menu}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/users/list-user`}
                component={List_user}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/users/create-user`}
                component={Create_user}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/users/permission`}
                component={Permission}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/localization/transactions`}
                component={Translations}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/localization/currency-rates`}
                component={Rates}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/localization/taxes`}
                component={Taxes}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/reports/report`}
                component={Reports}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/settings/profile`}
                component={ProfileContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/invoice`}
                component={Invoice}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/data-table`}
                component={Datatable}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/settings/shop`}
                component={ListShopContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/orders/in-store/list`}
                component={InStoreOrderListContainer}
              />

              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/orders/in-store/create-order-1`}
                component={CreateInStoreOrderContainer}
              />

              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/orders/in-store/create-order/:page`}
                component={StepsComponent}
              />

              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/orders/in-store/create-order`}
                component={BasketContainer}
              />

              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/orders/in-store/edit/:id`}
                component={UpdateInStoreOrderContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/orders/commerce`}
                component={EcommerceContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/orders/export`}
                component={ExportOrderContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/suppliers/list-supplier`}
                component={ListSupplierContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/suppliers/create-supplier`}
                component={CreateSupplierContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/suppliers/edit/:id`}
                component={UpdateSupplierContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/purchase/list-purchase-orders`}
                component={ListPuchaseOrderContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/purchase/create-purchase-order`}
                component={CreatePuchaseOrderContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/purchase/edit/:id`}
                component={UpdatePuchaseOrderContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/purchase/receive/:id`}
                component={CreateReceiveOrderContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/purchase/list-received-orders`}
                component={ListReceivedOrderContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/inventory/allocation`}
                component={ListAllocationContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/inventory/modify-stock`}
                component={ModifyStockContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/inventory/modify-stock-v2`}
                component={StockingContainerComponent}
              />

              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/inventory/live-monitoring`}
                component={LiveMonitoringComponent}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/approval/stock-updates/list`}
                component={ApprovalListContainer}
              />
              <PrivateRoute
                path={`${process.env.PUBLIC_URL}/approval/stock-updates/approval/:id`}
                component={ApprovalContainer}
              />
            </App>
          </Switch>
        </ScrollContext>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById("root")
);
