import React, { useState } from "react";

import BatchUpdateComponent from "../views/batch-update";

const BatchUpdateContainer = (props) => {
  const [all_reorder_level, setAllReOrderLevel] = useState(0);

  function applyToAllItemsAndVariants() {
    props.dataTableApplyReOrderLevelToAlItemsAndVariants(all_reorder_level);
  }

  return (
    <>
      <BatchUpdateComponent
        setAllReOrderLevel={setAllReOrderLevel.bind(this)}
        applyToAllItemsAndVariants={applyToAllItemsAndVariants.bind(this)}
      />
    </>
  );
};

export default BatchUpdateContainer;
