import React, { Component } from "react";

import { connect } from "react-redux";
import { changeUserSession } from "redux/actions/user/user-auth";
import * as Http from "../../redux/actions/http";

import TabsetProfileComponent from "../views/tabset-profile";

class TabsetProfileContainer extends Component {
  getUserId() {
    return this.props.userAuthReducer.session.user.id;
  }

  getUserProfile() {
    return this.props.requestUserProfile(this.getUserId());
  }

  updateUserNotifications(option) {
    this.props.changeUserNotifications(option);
    return this.props.requestUpdateUserNotifications(
      this.getUserId(),
      option.id
    );
  }

  updateUserProfileInfo(name, value) {
    return new Promise((resolve, reject) => {
      this.props
        .requestUpdateUserProfileInfo(this.getUserId(), {
          name,
          value,
        })
        .then((response) => {
          if (response.data.success) {
            this.props.changeUserSession(response.data.user);
          }
          resolve(response);
        });
    });
  }

  render() {
    return (
      <TabsetProfileComponent
        {...this.props}
        getUserProfile={this.getUserProfile.bind(this)}
        updateUserProfileInfo={this.updateUserProfileInfo.bind(this)}
        updateUserNotifications={this.updateUserNotifications.bind(this)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    userAuthReducer: state.userAuthReducer,
  };
}

export default connect(mapStateToProps, {
  changeUserSession: changeUserSession,
  requestUserProfile: Http.requestUserProfile,
  requestUpdateUserNotifications: Http.requestUpdateUserNotifications,
  requestUpdateUserProfileInfo: Http.requestUpdateUserProfileInfo,
})(TabsetProfileContainer);
