import * as type from "redux/constants/user/permission/permission";
import { List } from "immutable";

const initialState = {
  data: [],
  isUpdate: false,
  currentRole: 0,
};

export default function Permission(state = initialState, action) {
  switch (action.type) {
    case type.FETCH:
      const data = action.payload;
      return { ...state, data };
      break;
    case type.STATUS:
      const isUpdate = action.payload;
      return { ...state, isUpdate };
      break;
    case type.LOADING:
      const loading = action.payload;
      return { ...state, loading };
      break;
    case type.RESPONSE:
      const response = action.payload;
      return { ...state, response };
      break;
    case type.CURRENT_ROLE:
      const currentRole = action.payload;
      return { ...state, currentRole };
      break;

    case type.UPDATE_GROUP_CHECKBOX:
      state = {
        ...state,
        data: state.data.map((d) => {
          if (d.id == action.payload) {
            d.checked = d.checked === true ? false : true;
            if (d.checked === true) {
              d.actions = d.actions.map((d) => {
                d.checked = true;
                return d;
              });
            } else {
              d.actions = d.actions.map((d) => {
                d.checked = false;
                return d;
              });
            }
          }

          return d;
        }),
      };
      break;
    case type.UPDATE_GROUP_ACTION_CHECKBOX:
      state = {
        ...state,
        data: state.data.map((d) => {
          if (d.id === action.payload.actionGroupId) {
            d.actions = d.actions.map((d) => {
              if (d.id === action.payload.actionId) {
                d.checked = d.checked === true ? false : true;
              }
              return d;
            });
          }
          return d;
        }),
      };
      break;
    case type.UPDATE_GROUP_ACTION:
      state = {
        ...state,
        data: state.data.map((d) => {
          if (d.checked === true) {
            var actions = List(action.payload)
              .toJS()
              .filter((p) => {
                var c = d.actions.filter((i) => {
                  return i.id === p.id;
                });
                return p?.checked === true && c.length <= 0;
              });
            d.actions = d.actions.concat(actions).map((d) => {
              d.checked = true;
              return d;
            });
          }
          return d;
        }),
      };
      break;
  }

  return state;
}
