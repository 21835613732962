import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import ListSupplierComponent from "../views/list-supplier";

export default class ListSupplierContainer extends Component {
  state = {
    import_modal: {
      open: false,
    },
  };
  constructor() {
    super();
    this.handleShowImportModal = this.handleShowImportModal.bind(this);
    this.handleCloseImportModal = this.handleCloseImportModal.bind(this);
  }

  handleShowImportModal() {
    this.setState({
      import_modal: {
        open: true,
      },
    });
  }

  handleCloseImportModal() {
    this.setState({
      import_modal: {
        open: false,
      },
    });
  }

  render() {
    return (
      <Fragment>
        <ListSupplierComponent
          {...this.state}
          handleShowImportModal={this.handleShowImportModal}
          handleCloseImportModal={this.handleCloseImportModal}
        />
      </Fragment>
    );
  }
}
