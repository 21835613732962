const COLOR = "black";
const WIDTH = 70;
const HEIGHT = 30;
const LABEL_WIDTH = 60;

export default {
  color: COLOR,
  height: HEIGHT,
  width: WIDTH,
  label_width: LABEL_WIDTH,
};
