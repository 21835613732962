import React, { Component, Fragment, useRef } from "react";
import Modal from "react-responsive-modal";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./style/canvas.scss";

import AsyncSelect from "react-select/async";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import SelectProductService from "../service/select-product";
import { debounce } from "underscore";
import "./style/gameboard.scss";
import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";

import TicklePlayerModalComponent from "./tickle-player-modal";
import TickleOpponentModalComponent from "./tickle-opponent-modal";

export default class AssociationModalView extends Component {
  render() {
    const {
      gameboard,
      shopOptionsList,
      selected_shop_input_ref,
      handleCloseGameboardModal,
      handleShopOptionChange,
      handleSearchProduct,
      handleDetachOpponent,
      handleDetachPlayer,
      onTickleClosePlayerModal,
      onTickleCloseOpponentModal,
      addNewOpponent,
      onmousedown,
      onmousemove,
      tickle,
      handleOpponentChangeNumberOfItem,
    } = this.props;

    const customSelectStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        // color: state.isSelected ? "red" : "blue",
        padding: 10,
      }),
      menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
        borderBottom: "1px dotted pink",
        color: state.selectProps.menuColor,
        padding: 10,
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "none transparent",
      }),
    };

    const styles = {
      closeButton: {
        top: "-14px",
        right: "-8px",
      },
      closeIcon: {
        color: "#fff",
        fontSize: "20px",
      },
      modalContainer: {
        width: "1500px",
      },
    };

    return (
      <Fragment>
        <TicklePlayerModalComponent
          tickle={tickle}
          handleDetachOpponent={handleDetachOpponent}
          onTickleClosePlayerModal={onTickleClosePlayerModal}
          handleOpponentChangeNumberOfItem={handleOpponentChangeNumberOfItem}
        />
        <TickleOpponentModalComponent
          tickle={tickle}
          handleDetachPlayer={handleDetachPlayer}
          onTickleCloseOpponentModal={onTickleCloseOpponentModal}
        />

        <Modal
          open={gameboard.open}
          onClose={handleCloseGameboardModal}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          classNames={{
            modal: "gameboard-style",
          }}
          closeIcon={faTruckLoading}
          styles={styles}
          center
        >
          <div style={{ padding: 0 }} className="modal-body gamecontainer">
            <div className="row mt-3 p-10">
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="hidden"
                    className="selected-shop-value"
                    ref={selected_shop_input_ref}
                  />
                  <Select
                    isClearable={true}
                    className="selected-shop"
                    closeMenuOnSelect={true}
                    options={shopOptionsList}
                    placeholder="Select Shop"
                    onChange={handleShopOptionChange}
                    styles={customSelectStyles}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <AsyncSelect
                    isClearable={true}
                    onChange={handleSearchProduct}
                    placeholder="Select Product"
                    closeMenuOnSelect={true}
                    defaultOptions
                    loadOptions={debounce(SelectProductService, 500)}
                    styles={customSelectStyles}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-small btn-primary"
                  onClick={(e) => addNewOpponent()}
                >
                  ADD
                </button>
              </div>
            </div>
            <canvas
              className="gameboard-canvas"
              onMouseDown={(e) => {
                onmousedown(e);
              }}
              onMouseMove={(e) => {
                onmousemove(e);
              }}
            ></canvas>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
