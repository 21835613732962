const HeaderCompose = (state) => {
  console.log(state);
  return {
    ...state,
    variation: {
      ...state.variation,
      header: state.variation.data.reduce((carry, variant) => {
        carry.push({
          id: variant.id,
          name: variant.title,
        });
        return carry;
      }, []),
    },
  };
};

export default HeaderCompose;
