import React, {Component, Fragment} from "react";
import Breadcrumb from "components/common/breadcrumb";
import DatatableContainer from "../container/datatable";
import CreateBrandContainer from "../container/create-brand";

export default class ListBrandComponent extends Component {

    render() {
        const {
            open,
            onOpenModal,
            onCloseModal
        } = this.props;
        return (
            <Fragment>
                <Breadcrumb
                    title="Brand List"
                    parent="Product"
                    desc={(<a onClick={e=>{onOpenModal(e)}} href='javascript:void(0);'>Create Brand</a>)}
                />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="clearfix"></div>
                            <CreateBrandContainer
                                open={open}
                                onOpenModal={e=>{onOpenModal(e)}}
                                onCloseModal={e=>{onCloseModal(e)}}
                            />
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                <DatatableContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
