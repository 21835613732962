import React, { Component, Fragment } from "react";
import Breadcrumb from "components/common/breadcrumb";
import ImportModalComponent from "../views/import-modal";

import { connect } from "react-redux";
import * as SupplierAction from "../../redux/actions/index";

class ImportModalContainer extends Component {
  state = {
    error: false,
    message: "",
    upload_progress: false,
  };
  constructor() {
    super();
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSetFileRef = this.handleSetFileRef.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.file = null;
  }

  handleFileChange(e) {}

  handleSetFileRef(ref) {
    this.file = ref;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      upload_progress: true,
    });
    this.props.upload(
      this.file.files[0],
      (response) => {
        if (response.data.success) {
          this.file = null;
          this.setState({
            error: false,
            upload_progress: false,
            message: response.data.message,
          });
        }
      },
      (error) => {
        this.setState({
          error: true,
          upload_progress: false,
          message: error.message,
        });
      }
    );
  }

  render() {
    return (
      <Fragment>
        <ImportModalComponent
          {...this.props}
          {...this.state}
          handleFileChange={this.handleFileChange}
          handleSetFileRef={this.handleSetFileRef}
          handleSubmit={this.handleSubmit}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierReducer: state.supplierReducer,
  };
}

export default connect(mapStateToProps, {
  upload: SupplierAction.upload,
})(ImportModalContainer);
