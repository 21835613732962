import React, { Component, Fragment } from "react";

import ProductService from "./container";
import { connect } from "react-redux";

import * as Product from "../../redux/actions";
import { getProduct } from "../../redux/actions/product/http";
import AddProductView from "../views/add-product";
import WholesaleTableContainer from "./wholesale-table";
import VariationTableContainer from "./variation-table";
import SellCostContainer from "./sell-cost";

class EditProduct extends React.Component {
  componentDidMount() {
    this.props.updateProductId(this.props.match.params.id);
    this.props.getProduct(this.props.match.params.id);
  }

  render() {
    console.log(this.props);
    const {
      wholesale: { enabled: wholesaleEnabled },
      variation: { enabled: variationEnabled },
      rightSidebarAnchor,
      handleInputChange,
      addRightSideBarAnchor,
    } = this.props;

    return (
      <AddProductView
        handleInputChange={handleInputChange}
        addRightSideBarAnchor={addRightSideBarAnchor}
        rightSidebarAnchor={rightSidebarAnchor}
        wholesaleVariationContainer={WholesaleVariationsContainerWrapper({
          wholesaleEnabled,
          variationEnabled,
          addRightSideBarAnchor,
        })}
        enableSellCostContainer={EnableSellCostContainer({
          wholesaleEnabled,
          variationEnabled,
        })}
        {...this.props}
      />
    );
  }
}

const EnableSellCostContainer = ({ wholesaleEnabled, variationEnabled }) => {
  if (wholesaleEnabled === false && variationEnabled === false) {
    return true;
  }

  return false;
};

const WholesaleVariationsContainerWrapper = ({
  variationEnabled,
  wholesaleEnabled,
  addRightSideBarAnchor,
}) => {
  const bothEnabled = variationEnabled && wholesaleEnabled;
  const eitherEnabled = variationEnabled || wholesaleEnabled;

  const variationTableWrapper = () => {
    return (
      <div
        className="col-sm-12"
        ref={(ref) => {
          addRightSideBarAnchor("Variation", "variation", ref);
        }}
      >
        {variationEnabled === true && (
          <VariationTableContainer
            addRightSideBarAnchor={addRightSideBarAnchor}
          />
        )}
      </div>
    );
  };

  const wholesaleTableWrapper = () => {
    return (
      <div
        className="col-sm-12"
        ref={(ref) => {
          addRightSideBarAnchor("Wholesale", "wholesale", ref);
        }}
      >
        {wholesaleEnabled === true && (
          <WholesaleTableContainer
            addRightSideBarAnchor={addRightSideBarAnchor}
          />
        )}
      </div>
    );
  };

  return (
    <div className="row">
      {variationTableWrapper()}
      {wholesaleTableWrapper()}
    </div>
  );

  return "";
};

const EditProductContainer = ProductService(EditProduct);

export default connect(
  (state) => {
    return {
      variation: state.productReducer.variation,
      wholesale: state.productReducer.wholesale,
      anchor: state.productReducer.anchor,
      response: state.productReducer.response,
      loading: state.productReducer.loading,
    };
  },
  {
    enableVariation: Product.variation.enableVariation,
    enableWholesale: Product.wholesale.enableWholesale,
    getProductSettings: Product.http.getProductSettingsRequest,
    destroy: Product.destroy,
    updateProductId: Product.product.updateProductId,
    getProduct: getProduct,
  }
)(EditProductContainer);
