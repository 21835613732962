import React, { Component } from "react";

import { connect } from "react-redux";
import {
  variation,
  wholesale,
  campaign as CampaignAction,
} from "../../redux/actions";
import CampaignView from "../views/campaign";

class CampaignContainer extends Component {
  render() {
    const {
      campaign,
      updateMultipleCampaign,
      updateSingleCampaign,
      settings: { default_keys: defaultKeys },
      variation: { compose, enabled: variationEnabled, error },
    } = this.props;

    return (
      <CampaignView
        error={error}
        campaign={campaign}
        updateMultipleCampaign={updateMultipleCampaign}
        updateSingleCampaign={updateSingleCampaign}
        defaultKeys={defaultKeys}
        compose={compose}
        variationEnabled={variationEnabled}
      />
    );
  }
}

export default connect(
  (state) => {
    return {
      campaign: state.productReducer.campaign,
      variation: state.productReducer.variation,
      settings: state.productReducer.settings,
      labels: state.productReducer.labels,
    };
  },
  {
    updateMultipleCampaign: CampaignAction.updateMultipleCampaign,
    updateSingleCampaign: CampaignAction.updateSingleCampaign,
  }
)(CampaignContainer);
