import * as type from "redux/constants/user/permission/group";
import {List} from "immutable";

const initialState = {
   data: [],
   selected: [],
   formStatus: false
};

export default function Action_group_permission(state = initialState, action) {
    
    switch (action.type) {
        case type.LOAD_ACTION_GROUP:
            state = Object.assign({}, state, {
	            data: action.payload.map( d => {
                    d.actions = d.actions.map( d => {
                        d.checked = true;
                        return d;
                    });
                    return d;
                })  
	        });
        break; 
        case type.FORM_STATUS:
        	state = Object.assign({}, state, {
	            formStatus: action.payload
	        });
        break;
        case type.LOADING:
            const loading = action.payload;
            return {...state, loading}
        break;
        case type.RESPONSE:
            const response = action.payload;
            return {...state, response}
        break;
        case type.UPDATE_GROUP_CHECKBOX:                       
            state = {...state, data: state.data.map(d => {
                if(d.id == action.payload.id) {
                    d.checked = d.checked === true ? false : true;
                    if (d.checked === true) {
                        var actions = List(action.payload.actions).toJS().filter( p => {
                            var c = d.actions.filter( i => {
                                return i.id === p.id;
                            });
                            return p?.checked === true && c.length <= 0;
                        });
                        d.actions = d.actions.concat(actions).map( d => {
                            d.checked = true;
                            return d;
                        });
                    } else {
                        d.actions = d.actions.map( d => {
                            d.checked = false;
                            return d;
                        });
                    }
                }
                
                return d;
            })}
        break;
        case type.UPDATE_GROUP_ACTION_CHECKBOX:  
            state = {...state, data: state.data.map(d => {
                if (d.id === action.payload.actionGroupId) {
                    d.actions = d.actions.map( d => {
                         if(d.id === action.payload.actionId) {
                            d.checked = d.checked === true ? false : true;
                         }
                         return d;
                    }); 
                }
                return d;
            })}
        break;
        case type.UPDATE_GROUP_ACTION:                       
            state = {...state, data: state.data.map(d => {
                if (d.checked === true) {
                    var actions = List(action.payload).toJS().filter( p => {
                        var c = d.actions.filter( i => {
                            return i.id === p.id;
                        });
                        return p?.checked === true && c.length <= 0;
                    });
                    d.actions = d.actions.concat(actions).map( d => {
                        d.checked = true;                        
                        return d;
                    });
                }
                return d;
            })}
        break;
    }
    
    return state;
}
