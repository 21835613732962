import { numberOnly, empty, emptyNumeric, isNumberPositive } from "common";
import * as payload from "../actions/variation/payload";

const getInvalidSkuColumns = (store, compose) => {
  const error = [];
  if (empty(compose.sku)) {
    error.push({
      column: "sku",
      message: store.getState().productReducer.labels.error.variation
        .invalid_sku,
    });
  }

  if (emptyNumeric(compose.price)) {
    error.push({
      column: "price",
      message: store.getState().productReducer.labels.error.variation
        .invalid_price,
    });
  } else {
    if (!isNumberPositive(compose.price)) {
      error.push({
        column: "price",
        message: store.getState().productReducer.labels.error.variation
          .invalid_price,
      });
    }
  }

  // if (emptyNumeric(compose.stocks)) {
  //   error.push({
  //     column: "stocks",
  //     message: store.getState().productReducer.labels.error.variation.invalid_stocks,
  //   });
  // } else {
  //   if (!isNumberPositive(compose.stocks)) {
  //     error.push({
  //       column: "stocks",
  //       message: store.getState().productReducer.labels.error.variation.invalid_stock,
  //     });
  //   }
  // }
  return error;
};

export const dispatchComposerCellValueError = (store) => {
  store.getState().productReducer.variation.compose.map((row) => {
    const error_sku_columns = getInvalidSkuColumns(store, row.data);

    store.dispatch(
      payload.addComposerCellValueError(row.id, error_sku_columns)
    );
    return row;
  });
};

const getInvalidOption = (store, sku, data) => {
  if (
    data.sku_data.filter(
      (d) => d.name.toLowerCase().trim() == sku.name.toLowerCase().trim()
    ).length > 1
  ) {
    return true;
  }
  return false;
};

const getInvalidTitle = (store, data) => {
  if (
    store
      .getState()
      .productReducer.variation.data.filter(
        (d) => d.title.toLowerCase().trim() === data.title.toLowerCase().trim()
      ).length > 1
  ) {
    return true;
  }
  return false;
};

export const dispatchVariantOptionError = (store) => {
  const error = [];
  store.getState().productReducer.variation.data.map((row) => {
    for (let sku in row.sku_data) {
      if (empty(row.sku_data[sku].name.trim())) {
        error.push({
          uuid: row.sku_data[sku].uuid,
          message: store.getState().productReducer.labels.error.variation
            .invalid_sku_name,
        });
      } else if (getInvalidOption(store, row.sku_data[sku], row)) {
        error.push({
          uuid: row.sku_data[sku].uuid,
          message: store.getState().productReducer.labels.error.variation
            .duplicate_option,
        });
      }
    }
    store.dispatch(payload.addSkuError(error));
    return row;
  });
};

export const dispatchVariantDataError = (store) => {
  const error = [];
  store.getState().productReducer.variation.data.map((row) => {
    const data = store.getState().productReducer.variation.data;
    for (let row in data) {
      if (empty(data[row].title.trim())) {
        error.push({
          id: data[row].id,
          message: store.getState().productReducer.labels.error.variation
            .invalid_name,
        });
      } else if (getInvalidTitle(store, data[row])) {
        error.push({
          id: data[row].id,
          message: store.getState().productReducer.labels.error.variation
            .duplicate_name,
        });
      }
    }

    store.dispatch(payload.addVariantError(error));
    return row;
  });
};

export const updateComposerCellValue = (next, store, action) => {
  next(action);

  dispatchComposerCellValueError(store);
};

export const updateVariantSkuColumn = (next, store, action) => {
  next(action);

  setTimeout(() => {
    store.dispatch(payload.updateComposer());
  }, 300);

  dispatchVariantOptionError(store);
};

export const updateVariantColumn = (next, store, action) => {
  next(action);

  setTimeout(() => {
    store.dispatch(payload.updateComposer());
  }, 300);

  dispatchVariantDataError(store);
};
