const AddComposerCellValueError = (state, action) => {
  return {
    ...state,
    variation: {
      ...state.variation,
      compose: state.variation.compose.map((row) => {
        if (row.id === action.payload.id) {
          row.errors.variation = [];
          row.errors.variation = action.payload.error;
        }
        return row;
      }),
    },
  };
};

export default AddComposerCellValueError;
