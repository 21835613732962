import React from "react";
import "./style/category.scss";
import { ChevronRight, Circle, Edit3 } from "react-feather";
import { empty } from "common";

const CategoryView = (props) => {
  if (props.category?.confirmed === true) {
    return (
      <Confirmed category={props.category} editCategory={props.editCategory} />
    );
  }
  return (
    <>
      <Selection {...props} />
      <Selected category={props.category} />
      <Confirmation
        confirmHandler={props.confirmCategory}
        cancelHandler={props.cancelCategory}
      />
    </>
  );
};

const Selection = ({ createCategoryBox, category }) => {
  return (
    <div className="category-container">
      {category.box.map((b) => {
        return (
          <div className="category-list lst-3" key={b.uuid}>
            {b.categories.map((c) => {
              return (
                <div
                  className={
                    "category-option " + (c.checked === true ? "selected" : "")
                  }
                  key={c.id}
                >
                  <label>
                    <Checkbox
                      category_id={c.id}
                      category_parent_id={b.categoryParentId}
                      handler={createCategoryBox}
                      {...c}
                    />{" "}
                    {c.name}
                  </label>
                  {c.children.length > 0 ? (
                    <span className="right-arrow">
                      <ChevronRight size="15" />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const Selected = ({ category }) => {
  return (
    <div className="category-selected">
      <ol className="category-selected">
        <li>
          <p style={{ marginRight: "10px", color: "#f93a3a" }}>
            The current selection:
          </p>
        </li>
        {category.box.map((b, n) => {
          return (
            <li key={n}>
              {b.categories.reduce((c, i) => {
                if (i.checked === true) {
                  c =
                    i.children.length > 0 ? (
                      <span>
                        <p>{i.name}</p>
                        <ChevronRight size="15" className="arrow-right" />
                      </span>
                    ) : (
                      <p>{i.name}</p>
                    );
                }
                return c;
              }, "")}
            </li>
          );
        })}
        {empty(category.confirm_id) ? (
          <li>
            <p>No category selected</p>
          </li>
        ) : (
          ""
        )}
      </ol>
    </div>
  );
};

const Confirmation = ({ confirmHandler, cancelHandler }) => {
  return (
    <div className="category-confirmation">
      <button
        type="button"
        className="btn btn-confirm btn-sm btn-light m-r-10"
        onClick={(e) => {
          cancelHandler();
        }}
      >
        Cancel
      </button>
      <button
        type="button"
        className="btn btn-confirm btn-sm btn-success"
        onClick={(e) => {
          confirmHandler();
        }}
      >
        Confirm
      </button>
    </div>
  );
};

const Confirmed = ({ category, editCategory }) => {
  return (
    <div className="confirmed-category">
      <ol className="category-selected">
        {category.box.map((b, n) => {
          return (
            <li key={n}>
              {b.categories.reduce((c, i) => {
                if (i.checked === true) {
                  c =
                    i.children.length > 0 ? (
                      <span>
                        <p>{i.name}</p>
                        <ChevronRight size="15" className="arrow-right" />
                      </span>
                    ) : (
                      <>
                        <p>
                          {i.name}{" "}
                          <Edit3
                            title="Edit"
                            size="20"
                            style={{
                              marginTop: "-5px",
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              editCategory();
                            }}
                          />
                        </p>
                      </>
                    );
                }
                return c;
              }, "")}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

const Checkbox = ({ handler, category_parent_id, category_id, checked }) => {
  return (
    <input
      type="checkbox"
      className="custom-control-input"
      className="checkbox_animated"
      checked={checked === true ? "checked" : ""}
      onChange={(e) => {
        handler(category_parent_id, category_id, e.target.checked);
      }}
    />
  );
};

const Inputbox = ({ handler, parent_id }) => {
  return (
    <input
      type="text"
      className="form-control"
      onChange={(e) => {
        handler(e.target.value, parent_id);
      }}
      placeholder="Search..."
    />
  );
};

export default CategoryView;
