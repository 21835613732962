import { empty } from "common";

export const validateSaveStockingTemplate = (data) => {
  if (empty(data.template)) {
    if (empty(data.template_name)) {
      throw "Template name is required!";
    }

    if (empty(data.warehouse)) {
      throw "Warehouse is required!";
    }
  }

  if (empty(data.template?.id) && empty(data.template_name)) {
    throw "Template name is required!";
  }

  if (empty(data.template?.warehouse?.id) && empty(data.warehouse)) {
    throw "Warehouse is required!";
  }

  // Validate items
  if (!empty(data.template?.id)) {
    data.template.brands.forEach((brand) => {
      brand.items.data.forEach((item) => {
        if (!item.has_variation) {
          if (empty(item.allocation_type)) {
            throw "Some of the allocation type is empty!";
          }
        }
        item.variation.compose.forEach((compose) => {
          if (empty(compose.allocation_type)) {
            throw "Some of the allocation type is empty!";
          }
        });
      });
    });
  }
};
