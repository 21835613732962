import * as type from "redux/constants/user/permission/permission";

export const setLoading = (payload) => {
  return { type: type.LOADING, payload: payload };
};

export const setResponse = (payload) => {
  return { type: type.RESPONSE, payload: payload };
};

export const fetch = (payload) => {
  return {
    type: type.FETCH,
    payload: payload,
  };
};

export const changeUpdateStatus = (payload) => {
  return {
    type: type.STATUS,
    payload: payload,
  };
};

export const updateGroupCheckBox = (payload) => {
  return {
    type: type.UPDATE_GROUP_CHECKBOX,
    payload: payload,
  };
};

export const updateGroupActions = (payload) => {
  return {
    type: type.UPDATE_GROUP_ACTION,
    payload: payload,
  };
};

export const updateGroupActionCheckbox = (actionGroupId, actionId) => {
  return {
    type: type.UPDATE_GROUP_ACTION_CHECKBOX,
    payload: { actionGroupId, actionId },
  };
};

export const setCurrentRole = (payload) => {
  return { type: type.CURRENT_ROLE, payload: payload };
};

export const clearCurrentRole = () => (dispatch) => {
  dispatch(setCurrentRole(""));
};
