/**
 * This file is a bridge to our backend api
 * It will re construct the api end points url
 * to the right path
 */

import axios from "axios";
import Cookie from "api/cookie";

// import UserAuth from "Application/User/UserAuth.js";

const API_BASE_URI = () => {
  if (process.env.NODE_ENV.toLowerCase() == "production") {
    return process.env.REACT_APP_API_URL_PRODUCTION;
  }

  if (process.env.NODE_ENV.toLowerCase() == "development") {
    return process.env.REACT_APP_API_URL_DEVELOPMENT;
  }

  return process.env.REACT_APP_API_URL_LOCAL;
};

const INSTANCE = () => {
  return axios.create({
    baseURL: API_BASE_URI(),
    timeout: 0,
    headers: {
      Authorization: "Bearer " + Cookie.get("session")?.access_token ?? "",
    },
  });
};

const JSONHeader = "application/json";

class Http {
  static state = {
    isJSON: true,
  };

  static getBaseUrl() {
    return API_BASE_URI();
  }

  static PUT = (url, data) => {
    let contentType = "";
    if (this.state.isJSON) {
      contentType = JSONHeader;
    }

    INSTANCE().defaults.headers.common["content-type"] = contentType;
    return INSTANCE().put(url, data);
  };

  static POST = (url, data, config) => {
    return INSTANCE().post(url, data, config);
  };

  static CANCELTOKEN = () => {
    return new axios.CancelToken();
  };

  static GET = (url, data) => {
    return INSTANCE().get(url, data);
  };

  static DELETE = (url, data) => {
    return INSTANCE().delete(url, data);
  };

  static PATCH = (url, data) => {
    return INSTANCE().patch(url, data);
  };

  static JSON() {
    this.state.isJSON = true;
  }

  static cancelToken() {
    return axios.CancelToken;
  }
}

export default Http;
