import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Nav } from "react-bootstrap";

const navs = [
  {
    name: "list",
    title: "Orders",
    active: true,
    disabled: false,
    page: "/orders/in-store/create-order/list",
  },
  {
    name: "customer",
    title: "Customer",
    active: false,
    disabled: false,
    page: "/orders/in-store/create-order/customer",
  },
  {
    name: "shipping",
    title: "Shipping",
    active: false,
    disabled: false,
    page: "/orders/in-store/create-order/shipping",
  },
  {
    name: "checkout",
    title: "Payment",
    active: false,
    disabled: false,
    page: "/orders/in-store/create-order/payment",
  },
];
const HeaderComponent = (props) => {
  const { activePage } = props;
  const history = useHistory();

  function clickHander(page) {
    history.push(page);
  }

  return (
    <>
      <Container fluid>
        <div className="page-header">
          <Row>
            <Col>
              <Nav variant="pills" defaultActiveKey="basket">
                {navs.map((menu, i) => {
                  return (
                    <Nav.Item key={i}>
                      <Nav.Link
                        active={activePage == menu.name}
                        eventKey={menu.name}
                        disabled={menu.disabled}
                        onClick={(e) => clickHander(menu.page)}
                      >
                        {menu.title.toUpperCase()}
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default HeaderComponent;
