import * as toast from "components/common/toast";

export const validateConfirmCategory = (next, store, action) => {
  const category = store.getState().productReducer.category;
  if (category.box.length > 0) {
    const lastCategoryBox = category.box[
      category.box.length - 1
    ].categories.reduce((c, i) => {
      if (i.checked === true) {
        c = i;
      }
      return c;
    }, []);

    if (lastCategoryBox.length <= 0) {
      toast.error(
        store.getState().productReducer.labels.error.category.invalid_confirmed
      );
    } else {
      if (lastCategoryBox.children.length > 0) {
        toast.error(
          store.getState().productReducer.labels.error.category
            .invalid_confirmed
        );
      }
    }

    return next(action);
  }
};
