import Http from "api/http";
import { setResponse, setLoading } from "../actions/payload";
import SaveProduct from "../actions/save-product";
import { empty } from "common";
import { List } from "immutable";

import { VaBasicFormValid } from "../../components/service/va-form";

const validator = (store, action) => {
  const error = [];
  const product = store.getState().product;
  const e = product.labels.error;

  // Basic Form
  if (!VaBasicFormValid()) {
    error.push(e.validation.invalid_basic);
  }

  // Category
  if (
    empty(store.getState().productReducer.category.confirm) ||
    store.getState().productReducer.category.confirmed === false
  ) {
    error.push(e.validation.invalid_category);
  }

  // Description
  if (empty(product.form.details?.short_desc)) {
    error.push(e.validation.invalid_details_short);
  }
  if (empty(product.form.details?.detailed)) {
    error.push(e.validation.invalid_details_detailed);
  }

  return error;
};
export const draft = (next, store, action) => {
  const error = validator(store, action);
  const product = store.getState().product;

  next(
    setResponse({
      error: error.length > 0 ? true : false,
      message: error.length > 0 ? error : [],
    })
  );

  store.dispatch(
    SaveProduct(store, {
      status: "draft",
      basic: product.form,
      details: {
        short_desc: product.form.details.short_desc,
        detailed: product.form.details.detailed,
      },
      category: product.category.confirm,
      variation: {
        variants: product.variation.data,
        compose: List(product.variation.compose)
          .toJS()
          .map((c) => {
            const data = c.data;
            delete data.image;
            return {
              id: c.id,
              ...data,
            };
          }),
      },
      wholesale: product.wholesale.data,
      shipping: product.form.shipping,
      campaign: product.campaign,
      seo: product.form.seo,
      media: {
        variation: List(product.variation.compose)
          .toJS()
          .reduce((c, i) => {
            if (!empty(i.data.image.url)) {
              c.push({
                id: i.data.image.id,
                key: i.id,
                hash: i.data.image.hash,
              });
            }
            return c;
          }, []),
        main: product.media.data.reduce((c, i) => {
          if (empty(i.url) !== true) {
            c.push({
              id: i.id,
              key: i.key,
              hash: i.hash,
            });
          }
          return c;
        }, []),
      },
    })
  );

  return;
};
