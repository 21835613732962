import React, { Component } from "react";

import { connect } from "react-redux";
import { wholesale as Wholesale } from "../../redux/actions";
import WholesaleTableView from "../views/wholesale-table";

class WholesaleTableContainer extends Component {
  render() {
    return <WholesaleTableView {...this.props} />;
  }
}

export default connect(
  (state) => {
    return {
      wholesale: state.productReducer.wholesale,
    };
  },
  {
    addTier: Wholesale.addTier,
    removeTier: Wholesale.removeTier,
    updateTierColumn: Wholesale.updateTierColumn,
  }
)(WholesaleTableContainer);
