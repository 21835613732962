import Http from "api/http";
import { debounce } from "underscore";
import axios from "axios";

const SelectProductService = (value, callback) => {
  const el = document.getElementsByClassName("selected-shop-value");
  let shop = "";
  if (el.length > 0) {
    shop = el[0].value;
  }

  Http.POST("product/association/search/" + shop, {
    payload: { criteria: { name: value } },
  })
    .then(function (response) {
      callback(response.data.data);
    })
    .catch(function (error) {
      console.log(error.response?.data);
    });
};

export default SelectProductService;
