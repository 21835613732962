export const changeAllocationTypeViaHeader = (state, action) => {
  return {
    ...state,
    allocation_type_selected: action.payload,
    template_selected: {
      ...state.template_selected,
      brands: state.template_selected.brands.map((brand) => {
        brand.items.data = brand.items.data.map((item) => {
          item.allocation_type = action.payload;
          item.variation.compose = item.variation.compose.map((compose) => {
            compose.allocation_type = action.payload;
            return compose;
          });
          return item;
        });
        return brand;
      }),
    },
  };
};

export const changeAllocationTypeViaItem = (state, action) => {
  const brands_item = state.template_selected.brands.map((brand) => {
    const item_index = brand.items.data.indexOf(action.payload.item);
    if (item_index !== -1) {
      brand.items.data[item_index].allocation_type =
        action.payload.allocation_type;

      brand.items.data[item_index].variation.compose = brand.items.data[
        item_index
      ].variation.compose.map((compose) => {
        compose.allocation_type = action.payload.allocation_type;
        return compose;
      });
    }

    return brand;
  });
  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: brands_item,
    },
  };
};

export const changeAllocationTypeViaItemVariant = (state, action) => {
  const brands_item_variant = state.template_selected.brands.map((brand) => {
    const item_index = brand.items.data.indexOf(action.payload.item);
    if (item_index !== -1) {
      const compose_index = brand.items.data[
        item_index
      ].variation.compose.indexOf(action.payload.variant);

      if (compose_index !== -1) {
        brand.items.data[item_index].variation.compose[
          compose_index
        ].allocation_type = action.payload.allocation_type;
      }
    }

    return brand;
  });
  return {
    ...state,
    template_selected: {
      ...state.template_selected,
      brands: brands_item_variant,
    },
  };
};
