import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";

import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";

import {
  faBook,
  faRegistered,
  faEdit,
  faFileDownload,
  faArrowDown,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

const DataTableComponent = (props) => {
  return (
    <div>
      <ReactTable
        // comment
        columns={[
          {
            Header: (
              <FontAwesomeIcon
                color={"#D1D1D1"}
                icon={faFileDownload}
                size="lg"
                style={{
                  fontSize: "2rem",
                }}
              />
            ),
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 60,
            Cell: (row) => (
              <FontAwesomeIcon
                color={"#9AD0EC"}
                icon={faFileDownload}
                size="lg"
                style={{
                  cursor: "pointer",
                  fontSize: "2rem",
                }}
                title="Download purchase order"
                onClick={(e) => {
                  props.download(row.original.id);
                }}
              />
            ),
          },
          {
            Header: (
              <FontAwesomeIcon
                color={"#D1D1D1"}
                icon={faCopy}
                size="lg"
                style={{
                  fontSize: "2rem",
                }}
              />
            ),
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 60,
            Cell: (row) => (
              <FontAwesomeIcon
                color={"#9AD0EC"}
                icon={faCopy}
                size="lg"
                style={{
                  cursor: "pointer",
                  fontSize: "2rem",
                }}
                title="Copy purchase order download link"
                onClick={(e) => {
                    props.copyTemporarySignedUrl(row.original.view_report_temporary_signed_url);
                }}
              />
            ),
          },
          {
            Header: <b>{"Action"}</b>,
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            width: 150,
            Cell: (row) => (
              <Link
                to={"receive/" + row.original.id}
                click="editPurchaseOrder(row.original.title)"
                title="Receive Delivery"
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon={faRegistered} size="sm" /> RECEIVE
              </Link>
            ),
          },
          {
            Header: <b>{"PO NO."}</b>,
            accessor: "order_no",
            Cell: (row) => (
              <Link
                to={"edit/" + row.original.id}
                click="editPurchaseOrder(row.original.order_no)"
                title="Edit"
                style={{
                  textDecoration: "underline",
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />{" "}
                {row.original.order_no}
              </Link>
            ),
          },
          {
            Header: <b>{"Order Date"}</b>,
            accessor: "order_at.value",
          },

          {
            Header: <b>{"Supplier"}</b>,
            accessor: "supplier.name",
          },
          {
            Header: <b>{"Payment Mode"}</b>,
            accessor: "payment_mode.name",
            id: "payment_mode",
            Filter: ({ filter, onChange }) => (
              <div>
                <span>
                  <select
                    name="status"
                    onChange={(event) => onChange(event.target.value)}
                    value={filter ? filter.value : "all"}
                    style={{ textAlign: "center" }}
                  >
                    <option key={"all"} value="">
                      All
                    </option>
                    {props.payment_modes.map((status) => {
                      return (
                        <option key={status.id} value={status.id}>
                          {status.name}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
            ),
          },
          {
            Header: <b>{"Payment Status"}</b>,
            accessor: "payment_status.name",
            id: "payment_status",
            Filter: ({ filter, onChange }) => (
              <div>
                <span>
                  <select
                    name="status"
                    onChange={(event) => onChange(event.target.value)}
                    value={filter ? filter.value : "all"}
                    style={{ textAlign: "center" }}
                  >
                    <option key={"all"} value="">
                      All
                    </option>
                    {props.payment_statuses.map((status) => {
                      return (
                        <option key={status.id} value={status.id}>
                          {status.name}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
            ),
          },
          {
            Header: <b>{"Delivery Status"}</b>,
            accessor: "delivery_status.name",
            id: "delivery_status",
            Filter: ({ filter, onChange }) => (
              <div>
                <span>
                  <select
                    name="status"
                    onChange={(event) => onChange(event.target.value)}
                    value={filter ? filter.value : "all"}
                    style={{ textAlign: "center" }}
                  >
                    <option key={"all"} value="">
                      All
                    </option>
                    {props.delivery_statuses.map((status) => {
                      return (
                        <option key={status.id} value={status.id}>
                          {status.name}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
            ),
          },
          {
            Header: <b>{"Amount"}</b>,
            accessor: "total_amount.format",
            filterable: false,
            style: {
              textAlign: "right",
            },
          },
          {
            Header: (
              <button
                onClick={(e) => {
                  props.rmovePurchaseOrder(e);
                }}
                className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
              >
                Delete
              </button>
            ),
            accessor: "delete",
            sortable: false,
            filterable: false,
            style: {
              textAlign: "center",
            },
            Cell: (row) => (
              <div>
                <span>
                  <input
                    defaultChecked={props.selectedId.includes(row.original.id)}
                    onChange={(e) => {
                      props.selected(e, row.original.id);
                    }}
                    type="checkbox"
                    className="checkbox_animated"
                  />
                </span>
              </div>
            ),
          },
        ]}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={props.data}
        pages={props.pages} // Display the total number of pages
        loading={props.loading} // Display the loading overlay when we need it
        onFetchData={props.fetchPurchaseOrder} // Request new data when things change
        filterable
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </div>
  );
};

export default DataTableComponent;
